import Vue from 'vue'
import { ref } from "vue"

import { format } from 'date-fns'
// import { es } from 'date-fns/locale';

// import axios from 'axios'
import axios from '@/plugins/axiosConfig';


// URL connection
// const API_BASE   = process.env.VUE_APP_API_BASE
const serverBackend = process.env.VUE_APP_API_BASE

// idRias.value = [2,4,5,6]
export default function useControlIntecmar() {

  const controlIntecmarLoading = ref(false)

  const getCurrentLocalDateTime = () => {
    const now = new Date()
    return format(now, 'yyyyMMdd_HHmmss')
  }

  // Function to download the file
  const onClickControlIntecmar = async () => {

    // Iniciamos progreso (Objetivo 30 segundos)
    controlIntecmarLoading.value = true
    initDownloading(35000)

    const url = `${serverBackend}/backend/api/converter/control-intecmar?_=${new Date().getTime()}`
    try {
      // TODO - Ver si podemos recuperar el nombre del archivo recibido
      const response = await axios.get(url, {
        responseType: 'blob',                    // Ensure response is treated as a binary blob
      })
      // Obtener el JSON del header
      var someError = false
      const jsonHeader = response.headers['x-message']
      if (jsonHeader !== undefined) {
        const jsonData = JSON.parse(jsonHeader);
        console.log('JSON:', jsonData);

        if (jsonData['Batea'] !== undefined) {
          if (jsonData['Batea']['status'] === false) {
            someError = true
          }
        }
        if (jsonData['Infa'] !== undefined) {
          if (jsonData['Infa']['status'] === false) {
            someError = true
          }
        }
      }

      if (someError) {
        // const tag = "<span>Control <a href='http://sig.intecmar.gal/estadozonas/'>Intecmar</a>... Web NO DISPONIBLE</span>"
        Vue.$toast.error('Control Intecmar... Web NO DISPONIBLE', { position: 'top-center', timeout: false, closeOnClick: true, closeButton: "button", icon: 'fas fa-sync' })

      } else {

        // Obtener el nombre del archivo del encabezado Content-Disposition = "attachment; filename=20240412_093813_control_intecmar.zip"
        const contentDisposition = response.headers['content-disposition'];
        const match = contentDisposition && contentDisposition.match(/filename=(.*?)$/);
        var zipFileName = match && match[1];
        if (!zipFileName) {
          zipFileName = `${getCurrentLocalDateTime()}_control_intecmar.zip`
        }

        // Create a temporary URL for the blob
        const link_href = window.URL.createObjectURL(new Blob([response.data]));

        // Create a link element
        const link = document.createElement('a');
        link.href = link_href;
        link.setAttribute('download', zipFileName); // Specify the filename

        // Trigger the download
        document.body.appendChild(link);
        link.click();

        // Clean up
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url);

        // Send message
        Vue.$toast.success("Control Intecmar... descargado", { position: 'top-center', timeout: false, closeOnClick: true, closeButton: "button", icon: 'fas fa-download'})
      }
    } catch (error) {
      Vue.$toast.error("Control Intecmar... Web NO DISPONIBLE -ERROR-", { position: 'top-right', timeout: false, closeOnClick: true, closeButton: "button" })
    }

    // End of loading
    controlIntecmarLoading.value = false

    // Inicializar el contador y el intervalo para la siguiente vez
    controlIntecmarLoadingValue.value = 0
    clearInterval(controlIntecmarLoadingIntervalo.value)
  }

  // Valor inicial y tiempo total en milisegundos
  const controlIntecmarLoadingValue = ref(0)
  const controlIntecmarLoadingIntervalo = ref(false)

  // Actualizar el valor periódicamente
  const initDownloading = (totalTime) => {
    const tiempoInicio = Date.now()
    controlIntecmarLoadingIntervalo.value = setInterval(() => {

      // Calcular el progreso en función del tiempo transcurrido
      const tiempoTranscurrido = Date.now() - tiempoInicio
      const progreso = (tiempoTranscurrido / totalTime) * 100;

      // Actualizar el valor
      controlIntecmarLoadingValue.value = Math.round(progreso);

      // Detener el intervalo cuando alcancemos el 100%
      if (controlIntecmarLoadingValue.value >= 100) {
        clearInterval(controlIntecmarLoadingIntervalo.value);
      }
    }, 1000)
  }


  return {
    controlIntecmarLoading,
    controlIntecmarLoadingValue,
    onClickControlIntecmar
  }
}
