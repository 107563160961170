import { format, parseISO } from 'date-fns'

export default function useUtils() {

  // Get boolean value if are stored at LocalStorage
  const getLocalStorageBoolean = (key, defaultValue) => {
    const value = localStorage.getItem(key)
    if (value == 'true' || value == 'false') {
      return JSON.parse(value) === true
    }
    return defaultValue
  }

  // Get value if are stored at LocalStorage
  // const getLocalStorageForecastIntoxication = () => {
  //   const value = localStorage.getItem('menu-option-toxins')
  //   if (value == 'intoxication' || value == 'forecast') {
  //     return value
  //   }
  //   return undefined
  // }
  const getLocalStorageInList = (key, defaultValue, validList = []) => {
    const value = localStorage.getItem(key)
    if (validList.includes(value)) {
      return value
    }
    return defaultValue
  }


  // Date Ref Setter: key = dateRef
  const getLastDateRef = (key) => {
    const ls = localStorage.getItem(key)
    if (ls === 'now') {
      return format(parseISO(new Date().toISOString()), 'yyyy-MM-dd')
    }
    return localStorage.getItem(key)
  }
  const getLastDateRefDefault = (key) => {
    return format(parseISO(getLastDateRef(key) || new Date().toISOString()), 'yyyy-MM-dd')
  }

  const setLastDateRef = (key, dateRefString) => {
    if (dateRefString === format(parseISO(new Date().toISOString()), 'yyyy-MM-dd')) {
      localStorage.setItem(key, 'now')
    } else {
      localStorage.setItem(key, dateRefString)
    }
  }


  const getObject = (key) => {
    let object = null
    try {
      object = JSON.parse(localStorage.getItem(key))
    } catch (error) {
      localStorage.removeItem(key)
    }
    return object
  }
  const setObject = (key, objectString) => {
    try {
      localStorage.setItem(key, JSON.stringify(objectString))
    } catch (error) {
      localStorage.removeItem(key)
    }
  }

  return {
    getLocalStorageBoolean,
    getLocalStorageInList,

    // DateRef
    getLastDateRefDefault,
    getLastDateRef,
    setLastDateRef,

    // Object
    getObject,
    setObject
  }
}
