<template>
  <!-- rootDivRef: Usamos para saber la altura de todo el componente (Tabla Incluida) -->
  <div ref="rootDivRef">
    <v-card class="d-flex flex-column w-100 rounded-0" :style="{ 'opacity': 0.9 }">

      <!-- <v-toolbar dense :elevation="8" title="Tabla de intoxicación" color="rgb(0, 192, 255)"> -->
      <!-- <v-toolbar dense :elevation="8" title="Tabla de intoxicación" :color="getToxinTypeColor()"> -->
      <!-- <div class="d-flex align-center px-2" :style="{ height: '48px', 'background-color': getToxinTypeColor() }" :class="getToxinTypeColor()"> -->
      <div class="d-flex align-center px-2" :style="{ height: '48px' }" :class="getToxinTypeColor()">

        <!-- Select View Toxins Vs Cells -->
        <ForecastSelectViewToxinCells
          :viewIdSelected.sync="viewSelected"
        />

        <!-- Filtros para la Tabla de intoxicación por Poligonos -->
        <ForecastCardToxinParams
          v-if="viewSelected === 'POL' && intoxicationTableLocal"
          :filters="intoxicationTableLocal?.data?.filters"
        />

        <!-- Filtros para la Tabla de células por PMI -->
        <ForecastCardCellsParams
          v-if="viewSelected === 'PMI' && intoxicationTableLocal"
          :ria="intoxicationTableLocal?.data?.filters?.ria"
          :feedTypeAPs="intoxicationTableLocal?.data?.filters?.feedTypeAPs"
          :cellsOptions.sync="cellsOptions"
        />


        <v-spacer></v-spacer>

        <!-- Lista de Rías -->
        <ForecastSelectRia
          class="mx-2"
          :intoxicationTableList="intoxicationTableListLocal"
          :riaIdSelected.sync="riaIdSelected"
        />

        <!-- color="primary" -->
        <!-- v-if="rootDivRef?.clientHeight >= 400" -->
        <v-btn
          v-if="heightCollapsed !== null"
          class="mr-2"
          x-small
          fab
          @click="onToggleHeight(maxHeight)"
        >
          <v-icon>{{ heightCollapsed === true ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
          <!-- <v-icon>{{rootDivRef?.clientHeight > 400 ? 'mdi-chevron-down' : 'mdi-chevron-up'}}</v-icon> -->
        </v-btn>

        <!-- Ojo que estamos sacando CLOSE fuera del DIV -->
        <v-btn
          style="position: absolute; right: 10px; top: -24px; z-index: 10;"
          class=""
          x-small
          fab
          color="purple"
          @click="$emit('close', true)"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <!-- No pilla la clase porque se la indica al Dialog class="ml-2" -->
        <!-- :isDialogActive.sync="isDialogSettingsActive" -->
        <ForecastHandsonTableSettingsDialog
          mode="button"
          :tableSettings.sync="tableSettings"
          :infoTable="intoxicationTableLocal?.data?.info"
        />
      </div>
      <!-- </v-toolbar> -->
    </v-card>

    <hot-table ref="hansontableRef" :settings="hotSettings" v-if="hotSettings"></hot-table>
  </div>

</template>

<script>
  import { onMounted, ref, watch, nextTick } from 'vue';

  import { HotTable } from '@handsontable/vue';
  import { registerAllModules } from 'handsontable/registry';
  import 'handsontable/dist/handsontable.full.css';

  // register Handsontable's modules
  registerAllModules()

  // eslint-disable-next-line no-unused-vars
  // import intoxicationFile from '../geojson/intoxication.json';


  import ForecastCardToxinParams from './ForecastCardToxinParams.vue'
  import ForecastCardCellsParams from './ForecastCardCellsParams.vue'
  import ForecastSelectRia from './ForecastSelectRia.vue';
  import ForecastSelectViewToxinCells from './ForecastSelectViewToxinCells.vue';
  import ForecastHandsonTableSettingsDialog from './ForecastHandsonTableSettingsDialog.vue';
  import useAppConfig from '@/store/useAppConfig';

  import useHandsontable from '@/service/useHansontable'

  export default {
    name: 'intoxication-table2-component',
    components: {
      HotTable,
      ForecastCardToxinParams,
      // eslint-disable-next-line vue/no-unused-components
      ForecastCardCellsParams,
      ForecastSelectRia,
      ForecastSelectViewToxinCells,
      ForecastHandsonTableSettingsDialog,
    },
    props: {
      intoxicationTableList: {
        type: Array,
        default: () => [],
      },
    },
    setup(props, { emit }) {
      // *** Indicador del tipo de toxina
      const { toxinType, tableSettings } = useAppConfig()

      // Lista de Tipos de Toxinas (Ver ForecastToxinType)
      const toxinTypeOptions = [
        { value: 'DSP', text: 'LIPO', color: 'blue', image: ''},   // blue = rgb(33, 150, 243)
        { value: 'ASP', text: 'ASP', color: 'orange', image: ''},
        { value: 'PSP', text: 'PSP', color: 'green', image: ''},
      ]
      const getToxinTypeColor = () => {
        const toxinTypeOption = toxinTypeOptions.find(item => item.value === toxinType.value)
        return toxinTypeOption?.color
      }
      // <<<

      // Switch: Muestreos PMI Vs. Toxinas POL
      // PMI -> Visualizamos la tabla de células (Ver opciones de células)
      // POL
      //    -> Visualizamos la tabla de toxinas (Ver Estado y Analíticas)
      //    -> Visualizamos la tabla de toxinas con células (Ver opciones de células)
      const { view: viewSelected } = useAppConfig()

      // const showIntoxication = ref(true)
      // const showIntoxicationCells = ref(true) // Visualizar células o toxina en la tabla de intoxicación


      const intoxicationTableListLocal = ref(JSON.parse(JSON.stringify(props.intoxicationTableList)));
      const intoxicationTableLocal = ref(null)


      // Opciones de célula: [ 'All', 'Acuminata', 'Acuta', 'Caudata'] + Aplicar TF (Toxicidad por Fitoplancton)
      const { cellsOptions } = useAppConfig()


      const rootDivRef = ref(null)
      const hansontableRef = ref(null)
      const hotSettings = ref(null)


      // Externalizamos la generación de ta tabla porque tiene muchas líneas de código
      const { getHotSettings } = useHandsontable(
        intoxicationTableListLocal,
        intoxicationTableLocal,
        viewSelected,
        cellsOptions
      )

      // Reset data
      const resetHOT = () => {
        if (hansontableRef.value) {
          hansontableRef.value.hotInstance.loadData([])
        }
      }
      const makeHotSettings = (idRia) => {

        // Ya está esa ría cargada ???
        // if (intoxicationTableLocal.value && intoxicationTableLocal.value.riaId === idRia) return

        // Existe la tabla de intoxicación para esa ría ???
        intoxicationTableLocal.value = intoxicationTableListLocal.value.find((item) => item.riaId == idRia)
        if (!intoxicationTableLocal.value) return

        hotSettings.value = getHotSettings(idRia)

        // Update
        if (hansontableRef.value) {
          // hansontableRef.value.hotInstance.loadData(rowData)
          hansontableRef.value.hotInstance.loadData(hotSettings.value.data)
          // hansontableRef.value.hotInstance.updateSettings(hotSettings.value.data, true)
          // TODO - Actualizar
        }

        // NextTick() no ofrece la oportunidad de ejecutar configuraciones despues de renderizar el componente handsontableRef
        nextTick(() => {
          updateHeight()                    // Actualizamos la altura de la tabla despues del DOM
          setHandsontableInitialSettigns()  // Inscribir a eventos
        });
      }

      // TODO - Cuando abrimos la tabla sin que haya datos, no se calcula bien
      // Actualizamos la altura de la tabla despues del DOM
      const maxHeight = 353   // 353 es la altura de la tabla de VIGO que es la segunda mayor ( Valor anterior 400)
      const updateHeight = () => {
        // Creemos que no es necesario ya que usamos nextTick
        if (!hansontableRef.value) {
          console.error('HOTRef')
          return
        }

        heightCollapsed.value = null
        if (!tableSettings.value?.fullTableHeight) {
          onToggleHeight(maxHeight)
        }
      };

      // Inicializar HOT solo la primera vez
      const isHandsontableInitializated = ref(false)
      const setHandsontableInitialSettigns = () => {

        if (!hansontableRef.value) {
          console.error('HOTRef')
          return
        }

        // Checking... Solo inicializamos una vez -> Sino se ejecutan los eventos varias veces
        if (isHandsontableInitializated.value === true) return

        // Set as configured (Solo queremos configurar esto una vez)
        isHandsontableInitializated.value = true

        // Desplazar al final de la tabla
        // Obtener el número máximo de filas y columnas
        // var maxRows = hansontableRef.value.hotInstance.countRows();
        var maxColumns = hansontableRef.value.hotInstance.countCols();
        hansontableRef.value.hotInstance.scrollViewportTo(1, maxColumns - 1)


        // Click celda: https://handsontable.com/docs/api/hooks/#afterselectionend
        hansontableRef.value.hotInstance.addHook('afterSelectionEnd', function (r, c) {
          if (c !== 0) return

          // https://handsontable.com/docs/api/core/#getsourcedata
          const sourceDataCell = hansontableRef.value.hotInstance.getSourceDataAtCell(r, c);
          if (sourceDataCell && sourceDataCell['ef:pmId'] > 0) {
            emit('onFeatureSelected', sourceDataCell['ef:pmId']) // sourceDataCell['ef:feedDate']
            console.log(sourceDataCell['ef:pmId'])
          }
        })
      }
      onMounted(() => {
        // Ahora se inizializa despues de la primera configuración
        // setHandsontableInitialSettigns()
      })

      // props passed to setup function is reactive object (made probably by reactive()), it's properties are getters.
      // Watching a getter: https://v3.vuejs.org/api/computed-watch-api.html#watching-a-single-source
      // No esta funcionando el deep true porque al cambiar de fecha de referencia sigue sin detectar cambios en la tabla
      const tableLoadedAfterChanges = ref(false)
      watch(() => props.intoxicationTableList, () => {
        intoxicationTableListLocal.value = JSON.parse(JSON.stringify(props.intoxicationTableList))

        // Checking... Reseteamos la tabla cuando no haya ninguna tabla de intoxicacion
        if (intoxicationTableListLocal.value.length === 0) {
          resetHOT()
        }

        // Checking... Todavía no se han efectuado los cambios ????
        if (!tableLoadedAfterChanges.value) {

          // Si la tabla de intoxicación de la ría esta disponible -> La cargamos y teminamos
          const index = intoxicationTableListLocal.value.findIndex((item) => item.riaId == riaIdSelected.value)
          if (index !== -1){
            // Indicamos que ya se han hecho los cambios necesarios y no volveremos ha esperar nuevos hasta nueva actualización (5 segundos de seguridad)
            tableLoadedAfterChanges.value = true
            setTimeout(() => {
              tableLoadedAfterChanges.value = false
            }, 5000);

            makeHotSettings(riaIdSelected.value) // Por si se mantiene en la misma ría
          }
        }
      }, { deep: true })

      // Ría por defecto
      const { riaId: riaIdSelected } = useAppConfig()

      // Por defecto se indica la primera
      const setRiaDefault = () => {
        if (!riaIdSelected.value) {
          if (intoxicationTableListLocal.value.length > 0) {
            riaIdSelected.value = intoxicationTableListLocal.value[0]?.ria?.id
          }
        }
      }
      setRiaDefault()

      // Cada vez que se cambie la ría, actualizamos
      watch( [
        () => riaIdSelected.value,
        () => viewSelected.value,
      ], () => {
        makeHotSettings(riaIdSelected.value)
      }, { immediate: true})

      // Han cambiado las opciones
      watch( () => cellsOptions.value, () => {
        makeHotSettings(riaIdSelected.value)
      }, { deep: true })


      // Height control
      //  NULL  -> La altura no ha sido modificada
      //  true  -> La altura ha sido alterada
      //  false -> Altura máxima
      const heightCollapsed = ref(null)
      const onToggleHeight = (baseHeight = maxHeight) => {

        const currentHeight = hansontableRef.value.hotInstance.rootElement.offsetHeight
        if (currentHeight > baseHeight) {
          hansontableRef.value.hotInstance.updateSettings({ height: baseHeight })

          // Iniciamos el control de collapsed
          heightCollapsed.value = true
        } else {
          hansontableRef.value.hotInstance.updateSettings({ height:'auto' })
          if (heightCollapsed.value !== null) {
            heightCollapsed.value = false
          }
        }
      }

      return {
        viewSelected,         // PMI Vs POL
        cellsOptions,         // Opciones de célula: Sel.Fito, Aplicar TF y Agrupar

        getToxinTypeColor,    // Indicaremos el color según la toxina filtrada

        // Control de la Ría seleccionada
        riaIdSelected,

        intoxicationTableListLocal,
        intoxicationTableLocal,
        // onChangeIntoxication,

        rootDivRef,
        hansontableRef,
        hotSettings,

        // Height control
        onToggleHeight,
        maxHeight,
        heightCollapsed,  // NULL, true, false

        // Global Table Settings
        tableSettings
      }
    }
  }
</script>

<!-- Estilos para modificar el componente v-toolbar -->
<style>
  /* .v-toolbar__content, .v-toolbar__extension {
    padding: 4px 4px;
  } */
</style>

<!-- Se han sustituido las clases por clases bootstrap manual -->
<style lang="css">

/* .progress {
    display: -ms-flexbox;
    display: flex;
    font-size: .75rem;
    background-color: #e9ecef;
} */

/* .progress-bar {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #007bff;
    transition: width .6s ease;
} */
</style>

<style>
  /* .handsontable table thead th {
      background-color: #FF7878;
  } */

  /* Color1: Con Recuentos */
  .handsontable THEAD TH.color1 {
      background: #d7f1e1;
  }

    /* Color1: Sin Recuentos */
  .handsontable THEAD TH.color2 {
      background: #f3c2fa;
  }
</style>
<style lang="css" scoped>

  .hot_container {
    overflow-x: scroll;
  }
  /* *** Mobile Intoxication Table *** */
  /* #mobile-intoxication-table { */
  #mobile-calendar {
    position: relative;
    left: 0;
    right: 0;
    /* bottom: -240px;  */
    display: block;
    transition: bottom 0.3s;

    /* Font color */
    color: white;
  }


  #mobile-calendar .timecode {
    position: absolute;
    left: 50%;
    top: inherit;
    bottom: 105px;

    width: 80px;
    margin-left: -40px;

    font-size: 18px;
  }

    /* *** Days *** */
  #mobile-calendar #days {
    overflow-x: scroll;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    position: relative;

    /* style */
    height: 240px;
    background-color: rgba(0, 0, 0, 0.63);
  }

  #mobile-calendar #days::-webkit-scrollbar {
    display: none;
  }

  /* Marcador de la hora actual */
  #mobile-calendar #days b {
    position: absolute;
    display: block;
    top: 40px;
    bottom: 0;
    width: 3px;
    background-color: yellow;
    opacity: 0.4;
  }

  /* Each Day: 160px width fix */
  #mobile-calendar #days > div {
    padding: 7px 0 56px 0;
    font-size: 18px;
    display: inline-block;
    max-width: 160px;
    width: 160px;
    height: 32px;
    box-sizing: content-box;
    border-top: solid 40px transparent;
    background-clip: padding-box;
  }

  /* 2º === equivale al primer dia del calendario (Centramos scroll por la izquierda) */
  #mobile-calendar #days > div:nth-child(2) {
    padding-left: 50%;
  }

  /* Ultimo dia (Ajustamos para poder hacer scroll por la derecha) */
  #mobile-calendar #days > div:last-child {
    padding-right: 50%;
  }

  #mobile-calendar #days > div:nth-child(odd) {
    background-color: rgba(0, 0, 0, 0.63);
  }

  #mobile-calendar #days > div:nth-child(even) {
    background-color: rgba(63, 61, 80, 0.76);
  }

  #mobile-calendar #days > div .day {
    display: inline-block;
    position: relative;
    padding-left: 0.5em;
  }

  /* #mobile-calendar #days > div .day .premium-flag {
    position: absolute;
    top: -3px;
    right: -10px;
    font-size: 12px;
  } */

  #mobile-calendar #days ul {
    /* >>> SFR */
    padding-left: 0px;

    font-size: 11px;
    white-space: nowrap;
    display: table;
    width: 100%;
    list-style: none;
    opacity: 0;
    transition: opacity 0.3s;
    width: calc(100% - 12px);
    margin: 6px 4px 0 8px;
  }

  .mobile-calendar-expanded #mobile-calendar #days ul {
    opacity: 0.6;
  }

  #mobile-calendar #days ul li {
    text-align: center;
    display: table-cell;
  }
</style>
