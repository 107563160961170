// Buckets
//  ToxA-Forecast:
//  Analisis
export default function useIntoxicationLeafLet() {


  // "2022-07-18": {
  //   "ef:feedDate": "2022-07-18",
  //   "ef:pmId": 1,
  //   "ef-feed:toxin-type": "DSP",
  //   "ef-feed:toxa": "43",
  //   "ef-feed:analisis": null,
  //   "ef-feed:analisis-progress": null,
  //   "ef-feed:plan": null,
  //   "ef-feed:estado": null
  // },
  const getIntoxicationData = (intoxication, dateRef, bucketName = 'ef-feed:toxa') => {
    if (intoxication == undefined) return

    const value = intoxication[dateRef]?.[bucketName]
    return value
  }


  // Estilo del Polígono (Analitica + Prevision)
  const getIntoxicationStyle = (intoxication, fillOpacity=0.8, dateRef='') => {

    const forecastData_ToxA = getIntoxicationData(intoxication, dateRef, 'ef-feed:toxa')
    const forecastData_Analisis = undefined //getIntoxicationData(intoxication, dateRef, 'Analisis')

    return {
      fillColor: forecastData_ToxA?.color || '#3388ff',
      fillOpacity: fillOpacity,
      color: forecastData_Analisis?.color || '#fff8eb',
      weight: 1, // 3
      opacity: 1,
    }
  }

  return {
    getIntoxicationData,
    getIntoxicationStyle,
  }
}
