<template>
  <!-- v-model="isDialogActive" -->
  <v-dialog
    :value="isDialogActive"
    width="500"
    scrollable
    @click:outside="$emit('update:is-dialog-active',false)"
  >
    <!-- Controlamos manualmente -->
    <!-- <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="red lighten-2"
        dark
        v-bind="attrs"
        v-on="on"
      >
        LOGIN
      </v-btn>
    </template> -->

    <v-card
      flat
      class="rounded-0 d-flex flex-column"
    >

      <!-- <v-card-title class="text-h5 grey lighten-2">
      </v-card-title> -->
      <!-- <v-card-actions>
      </v-card-actions> -->

      <!-- Close icon (Sin titulo) -->
      <div class="d-flex my-2 mx-4">
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('update:is-dialog-active', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>


      <!-- <v-card-text class="pt-6"> -->
      <div class="d-flex flex-row justify-center align-items-center">
        <v-img
          alt="emproMar Logo"
          class="shrink mr-2"
          contain
          src="@/assets/logo.png"
          transition="scale-transition"
          width="120"
        />
        <div class="d-flex flex-column justify-center">
          <span class="font-weight-bold" style="font-size: x-large;">emproMar</span>
          <span style="font-size: large;">predicción</span>
        </div>
      </div>
      <!-- </v-card-text> -->
      <v-divider></v-divider>

      <!-- login form -->
      <v-card-text class="pt-6">
        <v-form
          ref="form"
          @submit.prevent="handleFormSubmit"
        >

          <!-- :rules="[required]" -->
          <v-text-field
            v-model="username"
            outlined
            label="Correo"
            placeholder="Correo"
            hide-details="auto"
            class="mb-6"
            :error-messages="errorMessages.username"
          ></v-text-field>

          <!-- :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline" -->
          <!-- :rules="[required]" -->
          <v-text-field
            v-model="password"
            outlined
            :type="isPasswordVisible ? 'text' : 'password'"
            label="Contraseña"
            placeholder="Contraseña"
            :append-icon="isPasswordVisible ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
            hide-details="auto"
            class="mb-2"
            :error-messages="errorMessages.password"
            @click:append="isPasswordVisible = !isPasswordVisible"
          ></v-text-field>

          <div class="d-flex align-center justify-space-between flex-wrap">
            <v-checkbox
              v-model="remember"
              hide-details
              label="Recordar"
              class="mt-0"
            >
            </v-checkbox>

            <!-- forget link -->
            <span>Olvidó la contraseña</span>
          </div>

          <v-btn
            block
            color="primary"
            type="submit"
            class="mt-6"
          >
            <div v-if="loading">
              <v-progress-circular
                indeterminate
                color="red"
                @click.prevent="onCancelRequest"
              >
                <v-icon>mdi-close</v-icon>
              </v-progress-circular>
            </div>
            <span v-else>LOGIN</span>
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  // import { emailValidator, passwordValidator, required } from '@core/utils/validation'
  // import { mdiClose, mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
  import { ref } from 'vue'

  // Components
  // import store from '@/store'

  // UI Services
  // import useAppConfigUser from '@/services/app/useAppConfigUser'

  // Services
  // import useJwt from '@/service/api/modules/auth/useJwt'
  import axios from '@/plugins/axiosConfig';
  import useAppConfig from '@/store/useAppConfig';

  // import useAuthHandler from '@/views/pages/auth/useAuthHandler'

  // Server: localhost:8050/backend/api/
  const serverBackend = process.env.VUE_APP_API_BASE

  export default {
    components: {
    // AppBarLogo,
    },
    props: {
      isDialogActive: {
        type: Boolean,
        required: true,
      },
    },
    setup(props, { emit }) {

      // User info
      const { user } = useAppConfig()

      // Data
      const username = ref(localStorage.getItem('username'))
      const password = ref(localStorage.getItem('password'))
      const remember = ref(localStorage.getItem('remember'))
      const errorMessages = ref([])
      const isPasswordVisible = ref(false)

      // Form
      const form = ref(null)
      const loading = ref(false)

      // Events
      const handleFormSubmit = () => {
        const isFormValid = form.value.validate()

        if (!isFormValid) return

        // eslint-disable-next-line no-use-before-define
        onUserLogin()
      // eslint-disable-next-line no-use-before-define
      // onUserLoginFake()
      //   .then(response => {
      //     debugger
      //     sendAuthNotification(response.message, 'success')
      //   })
      //   .catch(error => {
      //     debugger
      //     sendAuthNotification(error.message, 'error')
      //   })
      }

      // eslint-disable-next-line no-unused-vars
      const onUserLoginFake = () => new Promise((resolve, reject) => {
        loading.value = !loading.value

        // ok
        // sendAuthNotification('Login', 'success')
        // resolve(loading)

        // Error
        // sendAuthNotification('Login', 'error')
        const error = {
          message: 'Check your password',
          error: {
            username: null,
            password: 'Check your password',
          },
        }
        reject(error)
      })

      const setRememberMe = (remember, username, password) => {
        if (remember) {
          localStorage.setItem('remember', remember)
          localStorage.setItem('username', username)
          localStorage.setItem('password', password)
        } else {
          localStorage.removeItem('remember')
          localStorage.removeItem('username')
          localStorage.removeItem('password')
        }
      }

      const onCancelRequest = () => new Promise(resolve => {
        loading.value = false
        resolve()
      })

      const onUserLogin = () => {
        loading.value = true

        // useJwt.accessLogin({username: username.value, password: password.value})
        // const url = `${serverBackend}/backend/api/auth/access-login`
        // axios.post('http://localhost:8050/backend/api/auth/access-login', {
        axios.post(`${serverBackend}/backend/api/auth/access-login`, {
          username: username.value,
          password: password.value
        })
          .then(response => {
            // { user: { ... }, accessToken: "..."}
            const { user: userData } = response.data
            if (userData) {
              localStorage.setItem('token', response.data.accessToken);

              // VUEX user
              user.value = userData
            }
            // useJwt.setRememberMe(remember.value, username.value, password.value)
            setRememberMe(remember.value, username.value, password.value)

            // Send event
            emit('update:isDialogActive', false);
          })
          .catch(error => {
            // console.error('Error en inicio de sesión:', error);
            // Checking... si es un error de dominio lo pasamos al formulario
            if (error.response && error.response.data && error.response.data.error) {
              // refFormObserver.value.setErrors(error.response.data.error)
              errorMessages.value = error.response.data.error
            }
          })
          .finally(() => {
            loading.value = false
          })
      }

      return {
        // Form validation
        form,
        handleFormSubmit,
        // required,
        // emailValidator,
        // passwordValidator,

        // Data
        username,
        password,
        remember,
        errorMessages,
        isPasswordVisible,

        // Interface events
        loading,
        onCancelRequest,
        onUserLogin, // No se usa en el Template
      }
    },
  }
</script>

<style lang="scss" scoped>
// @import '@core/preset/preset/pages/auth.scss';
</style>
