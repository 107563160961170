/* eslint-disable no-unused-vars */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-undef */

// dependencies
import chroma from 'chroma-js';
// import heatBin from 'leaflet-heatbin'; // Se usa pero no usando el objeto
import './L.HeatBin'

// >>> Constantes Particulas
// Tipos de particulas (ParticleType.java)
const Constants = {
  DSP_ACUMINATA: 9,
  DSP_ACUTA: 10,
  DSP_CAUDATA: 11,
  DSP_SSP: 12,
  ASP_PSEUDO_NITZ: 13,
  PSP_ALEXANDRIUM: 14,
  PSP_CATENATUM: 15,
  CHLa: 38
}
// Resoluciones (FeedServerRelease.java en ichthyop)
const FeedServerRelease = {
  CELLS_IN_ONE_M3: 1000,  // cels/litro   --> cels/m3     (x1000)
  CHLa_RESOLUTION: 1000   // mg/m3        --> μg/m3       (x1000)
}
// <<<
const ParticlesLayer = (L.Layer ? L.Layer : L.Class).extend({

  // misc
  _particleLayer: null,
  _frameIndex:    0,
  _markers:       [],
  _colors:        null,

  /*------------------------------------ LEAFLET SPECIFIC ------------------------------------------*/

  _active:   false,
  _map:      null,
  // the L.canvas renderer
  _renderer: null,
  // the DOM leaflet-pane that contains html canvas
  _pane:     null,
  _paneName: 'particles',

  // user options
  options: {
    data:            null,
    dataFormat: {
      idIndex:    0,
      lonIndex:   1,
      latIndex:   2,
      depthIndex: 3,
      ageIndex:   4
    },
    displayMode:     '',
    startFrameIndex: 0,
    ageColorScale:   null,
    ageDomain:       null,
    heatOptions: {
      blur: 1,
      // radius should be small ONLY if scaleRadius is true (or small radius is intended)
      // if scaleRadius is false it will be the constant radius used in pixels
      "radiusMeters": 1000,
      "fixedRadius": false,
      "radius": 20,
      "heatBin": {
        "enabled": false,
        "cellSizeKm": 1
      },
      "maxOpacity": .8,
      // scales the radius based on map zoom
      "scaleRadius": false,
      // if set to false the heatmap uses the global maximum for colorization
      // if activated: uses the data maximum within the current map boundaries
      //   (there will always be a red spot with useLocalExtremas true)
      "useLocalExtrema": false,
      // which field name in your data represents the latitude - default "lat"
      latField: 'lat',
      // which field name in your data represents the longitude - default "lng"
      lngField: 'lng',
      // which field name in your data represents the data value - default "value"
      valueField: 'value'
    },
    exposureIntensity: 1,
    finalIntensity: 1
  },

  initialize: function (options) {
    // TODO investigate L.setOptions ability to handle nested objects
    // note that use of _extendObject in this context breaks encapsulation for multiple layers
    L.setOptions(this, options);
  },

  /**
	 * Initialise renderer when layer is added to the map / becomes active,
	 * and draw circle markers if user has specified the displayMode
	 *
	 * @param map {Object} Leaflet map
	 */
  onAdd: function (map) {
    this._active = true;
    this._map = map;
    this._createRenderer();
    if (this.options.displayMode) this.setDisplayMode(this.options.displayMode);
    if (this.options.onAdd) this.options.onAdd();
  },

  /**
	 * Remove the pane from DOM, and void renderer when layer removed from map
	 */
  onRemove () {
    this._map.removeLayer(this._particleLayer);
    L.DomUtil.remove(this._pane);
    this._renderer = null;
    this._particleLayer = null;
    this._active = false;
    if (this.options.onRemove) this.options.onRemove();
  },

  /*------------------------------------ PUBLIC ------------------------------------------*/

  /**
	 * check if the particle layer is currently active on the map
	 * @returns {boolean}
	 */
  isActive () {
    return this._active;
  },

  /**
	 * Update the layer with new data
	 * @param data
	 */
  setData (data) {
    this.options.data = data;
    this.setDisplayMode(this.options.displayMode);
  },

  /**
	 * Set options object, updates layer
	 * @param options
	 */
  setOptions (options) {
    this.options = this._extendObject(this.options, options);
    this.update();
  },

  /**
	 * Trigger layer update/redraw
	 */
  update () {
    this.setDisplayMode(this.options.displayMode);
  },

  /**
	 * Set the display mode of the layer
	 * @param mode {string} One of: ['FINAL', 'EXPOSURE', 'KEYFRAME']
	 */
  setDisplayMode (mode) {

    this.options.displayMode = mode;

    if (!this.isActive()) return;

    switch (this.options.displayMode) {

    case 'EXPOSURE':
      this._initDisplayExposure();
      break;

    case 'FINAL':
      this._initDisplayFinal();
      break;

    case 'KEYFRAME':
      this._initDisplayKeyframe();
      break;

    default:
      console.error(`Attempted to initialise with invalid displayMode: ${this.options.displayMode}`);
      break;
    }
  },

  /**
	 * Returns the current `displayMode`
	 * @returns {string} One of: ['FINAL', 'EXPOSURE', 'KEYFRAME', null]
	 */
  getDisplayMode () {
    return this.options.displayMode;
  },

  /**
	 * Get the current frame index
	 * @returns {number} the keyframe index
	 */
  getFrameIndex () {
    if (!this.isActive()) return -1;
    return this._frameIndex;
  },

  // calculateCircleRadius (currentResolution, currentZoom) {
  //   // debugger
  //   // Obtener la resolución actual del mapa view.value
  //   // const currentResolution = map.value.getView().getResolution();

  //   // Calcular el radio en píxeles en función del nivel de zoom
  //   // const zoom = view.value.getZoom();
  //   const radiusInPixels = 10 * Math.pow(2, currentZoom);

  //   // Calcular el radio en unidades de mapa
  //   const radiusInMapUnits = radiusInPixels * currentResolution;

  //   return radiusInMapUnits;
  // },


  /**
	 * Display the particles at the given frame TS
	 * @param ts {number} the timestamp (key of data)
	 */
  setFrameByTimestamp (ts) {
    if (!this.isActive()) return;
    const self = this;

    // if (!self.options.data) return

    const keys = Object.keys(self.options.data)
    const index = keys.findIndex(element => parseInt(element) === ts)
    if (index !== -1 && index !== self._frameIndex) {
      this.setFrameIndex(index)
    }
  },

  /**
   * SFR - Particula: Densidad
   */

  getDensityConv(particleTypeId) {

    var densConv  = 1;

    switch (particleTypeId) {
    case Constants.DSP_ACUMINATA:
    case Constants.DSP_ACUTA:
    case Constants.DSP_CAUDATA:
    case Constants.PSP_CATENATUM:

      densConv = FeedServerRelease.CELLS_IN_ONE_M3;
      break;

      // >>> SFR 10.07.2018 : Empezamos manteniendo la densidad real. Alexandrium (== que Pseudo Nitz.)
    case Constants.PSP_ALEXANDRIUM:
    case Constants.ASP_PSEUDO_NITZ:
      //valorEscala = (density / FeedServerRelease.CELLS_IN_ONE_M3) * 0.0001d;  // Convertimos + DIV 10000  300.000 -> 30
      //                density = density * FeedServerRelease.CELLS_IN_ONE_M3 * 10000;
      //densConv = FeedServerRelease.CELLS_IN_ONE_M3 * 10000;   // Esto no lo tengo muy claro, supongo que habrá que verlo
      densConv = 1;                                           // Vamos a empezar por mantener la densidad real
      break;

    case Constants.CHLa:
      densConv = FeedServerRelease.CHLa_RESOLUTION;
      break;
    }

    return densConv;
  },

  getParticlePixelSizeByDensity(particleTypeId, particleDensity) {

    if (particleDensity == 0)
      return 0;

    // Release: 80 células/litro ==> 80.000 células/m3
    //      0 ..  9.999     1
    //  10001 .. 19.999     2
    //  90001 .. 99.999     10
    //  +++++ .. ++++++     10
    //        int div = (int)(density/10000d) +1;
    //        return Math.min(div, 10);

    // Cambio de escala:
    //       0 ..    10     1       x10
    //      11 ..    20     2       Anterior x2
    //      21 ..    40     3
    //      41 ..    80     4
    //      81 ..   160     5
    //     161 ..   320     6
    //     321 ..   640     7
    //     641 .. 1.280     8
    //   1.281 .. 2.560     9
    //   2.561 .. 5.120    10

    //double valorEscala = density / (1000d * 10);   // Convertimos + DIV 10
    //double valorEscala = (density / FeedServerRelease.CELLS_IN_ONE_M3) * 0.10d;   // Convertimos + DIV 10       160 -> 80
    //double valorEscala = (density / FeedServerRelease.CHLa_RESOLUTION) * 20;      // Convertimos + x20            4 -> 80
    var valorEscala = particleDensity;
    if (particleTypeId != null) {

      //  En la liberación:   >>> Multilpicamos por densConv
      //  En la lectura:      >>> Dividimos
      valorEscala =  particleDensity / this.getDensityConv(particleTypeId);       // Division

      // Esto es especifico de esta clase: Influye en la representación visual de la particula
      //                                                             Conversion por calculo   Real    Representacion
      switch (particleTypeId) {
      case Constants.DSP_ACUMINATA:
      case Constants.DSP_ACUTA:
      case Constants.DSP_CAUDATA:
      case Constants.PSP_CATENATUM:
        // valorEscala = valorEscala * 0.1;                 // x1000                    160     -> 16  (x 0.1)
        valorEscala = valorEscala * 0.012;                   // x1000                    160     -> 1.6 (x 0.01)
        break;

        // >>> SFR 10.07.2018 : Alexandrium (Entre 1.000 y 100.000 pero es una especie poco conocida)
      case Constants.PSP_ALEXANDRIUM:
        valorEscala = valorEscala * 0.01 * 0.1;           // x1                       100.000 -> 100
        break;

      case Constants.ASP_PSEUDO_NITZ:
        valorEscala = valorEscala * 0.001 * 0.1;          // x1                       300.000 -> 30
        break;
      case Constants.CHLa:
        //valorEscala = valorEscala * 10;                     // Convertimos + x10
        valorEscala = valorEscala * 0.10;                   // Temporal  == Acuminata
        break;
      default:
                // throw new AssertionError();
      }
    }
    //        switch (particle.getParticleType().getCode()) {
    //            case ParticleType.Constants.DSP_ACUMINATA:
    //            case ParticleType.Constants.PSP_CATENATUM:
    //                valorEscala = (density / FeedServerRelease.CELLS_IN_ONE_M3) * 0.10d;    // Convertimos + DIV 10         160 -> 16
    //                break;
    //            case ParticleType.Constants.ASP_PSEUDO_NITZ:
    //                valorEscala = (density / FeedServerRelease.CELLS_IN_ONE_M3) * 0.0001d;  // Convertimos + DIV 10000  300.000 -> 30
    //                break;
    //            case ParticleType.Constants.CHLa:
    //              //valorEscala = (density / FeedServerRelease.CHLa_RESOLUTION) * 10;       // Convertimos + x10
    //                valorEscala = (density / FeedServerRelease.CHLa_RESOLUTION) * 0.10;     // Temporal  == Acuminata
    //                break;
    //            default:
    //                throw new AssertionError();
    //        }
    var count = 1;
    while (valorEscala > 1) {
      valorEscala = valorEscala / 2;              // DIV 2 (Inverso x2)
      count++;
    }

    return Math.min(count, 10);
  },

  /**
	 * Display the particles at the given frame index
	 * @param index {number} the keyframe index
	 */
  setFrameIndex (index) {

    if (!this.isActive()) return;
    const self = this;
    self._frameIndex = index;

    const keys = Object.keys(self.options.data);
    const frame = self.options.data[keys[index]];

    // there's no addLayer*s* function, either need to add each
    // L.circleMarker individually, or reinit the entire layer
    if (self._particleLayer) self._particleLayer.clearLayers();

    // console.log(self.options.dataFormat)
    for (let i = 0; i < frame.length; i++) {


      const particle = frame[i];
      // if (i == 0) {
      //   // debugger
      //   console.log(particle)
      //   console.log('Profundidad', particle[self.options.dataFormat.depthIndex]);
      //   console.log('Densidad(Edad)', particle[self.options.dataFormat.ageIndex]);
      //   var density = particle[self.options.dataFormat.ageIndex]
      // }
      var density_radius = this.getParticlePixelSizeByDensity(Constants.DSP_ACUMINATA, particle[self.options.dataFormat.ageIndex]) // [1..10]
      // var color = this._colors(particle[self.options.dataFormat.ageIndex]).hex()
      var color = this._colors(particle[self.options.dataFormat.depthIndex]).hex()

      const pos = self._map.wrapLatLng([particle[self.options.dataFormat.latIndex], particle[self.options.dataFormat.lonIndex]]);
      let marker = L.circleMarker(pos, {
        renderer:    self._renderer,
        stroke:      false,
        fillOpacity: 0.3,
        radius:      density_radius,
        fillColor:   color,
        _feature:    particle

      });

      self._markers.push(marker);
      self._particleLayer.addLayer(marker);
    }
  },

  /**
	 * Returns leaflet LatLngBounds of the layer
	 */
  getLatLngBounds () {

    if (!this.options.data || !this._map) return null;

    // get keys, flatten the data
    const snapshots = this._flattened();

    return L.latLngBounds(snapshots.map((s) => {
      return this._map.wrapLatLng([s[this.options.dataFormat.latIndex], s[this.options.dataFormat.lonIndex]]);
    }));
  },

  /**
	 * A wrapper function for `L.heatBin.getGridInfo` to get information about the grid used for binning
	 * @returns {*}
	 */
  getGridInfo () {
    if (!this._active || !this._particleLayer || !this._particleLayer.getGridInfo) return null;
    return this._particleLayer.getGridInfo();
  },

  /**
	 * Return readonly particleLayer
	 * @returns {null}
	 */
  getParticleLayer () {
    return this._particleLayer;
  },

  /**
	 * Return an array of unique particle ID's
	 * @returns {Array}
	 */
  getParticleIds () {
    const snapshots = this._flattened();
    // get an array of uniq particles
    let uids = [];
    snapshots.forEach((snapshot) => {
      if (uids.indexOf(snapshot[this.options.dataFormat.idIndex]) === -1) uids.push(snapshot[this.options.dataFormat.idIndex]);
    });
    return uids;
  },

  /**
	 * Return the min/max percent range on a heatBin layer with unique ID's
	 * i.e. what is the min/max percent of unique data points that have touched any grid cell
	 * @returns {*}
	 */
  getUniquePercentRange (ignoreStatic) {
    if (!this._active || !this._particleLayer || !this._particleLayer.getGridInfo) return null;
    const gridInfo = this.getGridInfo();
    const ids = this.getParticleIds();
    if (!gridInfo.hasOwnProperty('maxCellCount')) return null;
    let minPercent = gridInfo.minCellCount / ids.length;

    let max = gridInfo.maxCellCount / ids.length * 100;
    if (this.options.heatOptions.heatBin && this.options.heatOptions.heatBin.staticMax && !ignoreStatic) {
      max = this.options.heatOptions.heatBin.staticMax;
    }
    return {
      min: minPercent >= 0 ? minPercent : 0,
      max: max
    };
  },

  /*------------------------------------ PRIVATE ------------------------------------------*/

  _flattened () {
    let keys = Object.keys(this.options.data);
    let snapshots = [];
    keys.forEach((key) => { snapshots = snapshots.concat(this.options.data[key]); });
    return snapshots;
  },

  /**
	 * Create the L.canvas renderer and custom pane to display particles
	 * @private
	 */
  _createRenderer () {
    // create separate pane for canvas renderer
    let pane = this.options.pane ? this.options.pane : this._paneName;
    this._pane = pane;
    this._renderer = L.canvas({ pane: pane });
  },

  /**
	 * Remove the particle layer from the map and clear our reference
	 * @private
	 */
  _clearDisplay () {
    if (this._particleLayer) this._map.removeLayer(this._particleLayer);
    this._particleLayer = null;
  },

  /**
	 * @summary Create a chroma-js color scale with user settings or auto scaled to keyframe range
	 * @returns {Object} chromaJs color object
	 * @private
	 */
  _createColors () {
    // if (!this.options.ageDomain) this.options.ageDomain = [0, Object.keys(this.options.data).length];
    // this._colors = chroma.scale(this.options.ageColorScale).domain(this.options.ageDomain);
    // return this._colors;

    // set domain to [0,100]
    this._colors = chroma.scale(['red', 'blue']).domain([0,-12])
    return this._colors;
  },

  /**
	 * Create the display layer (heatmap) for FINAL distribution.
	 * @private
	 */
  _initDisplayFinal () {

    this._clearDisplay();

    if (this.options.data){

      this._createColors();
      const finalData = this._createFinalData();
      let pane = this.options.pane ? this.options.pane : this._paneName;
      this.options.heatOptions.pane = pane;
      this._particleLayer = L.heatBin(this.options.heatOptions);
      this._particleLayer.addTo(this._map);
      this._particleLayer.setData(finalData);
    }
  },

  /**
	 * Process data into expected leaflet.heat format,
	 * plotting only particles at their end of life
	 * [ [lat, lon, intensity], ... ]
	 * @private
	 */
  _createFinalData () {

    let finalData = [];

    // get keys, moving forward in time
    let keys = Object.keys(this.options.data);
    keys.sort((a, b) => { return new Date(a) - new Date(b); });

    // flatten the data
    let snapshots = [];
    keys.forEach((key) => { snapshots = snapshots.concat(this.options.data[key]); });

    // get an array of uniq particles
    let uids = [];
    snapshots.forEach((snapshot) => {
      if (uids.indexOf(snapshot[this.options.dataFormat.idIndex]) === -1) uids.push(snapshot[this.options.dataFormat.idIndex]);
    });

    // step backwards from the end of the sim collecting
    // final snapshots for each uniq particle
    keys.reverse();

    for (let i = 0; i < keys.length; i++) {

      if (uids.length === 0) break;

      // check each particle in the snapshot
      this.options.data[keys[i]].forEach((snapshot) => {

        // if not recorded
        let index = uids.indexOf(snapshot[this.options.dataFormat.idIndex]);
        if (index !== -1) {

          // grab it, and remove it from the list
          finalData.push({
            lat:   snapshot[this.options.dataFormat.latIndex],
            lng:   snapshot[this.options.dataFormat.lonIndex],
            value: this.options.finalIntensity
          });
          uids.splice(index, 1);
        }

      });
    }

    return {
      max: 10,
      data: finalData
    };
  },

  /**
	 * Process data into expected leaflet.heat format,
	 * plotting all particles for every snapshot
	 * [ [lat, lon, intensity], ... ]
	 * @private
	 */
  _createExposureData () {

    let exposureData = [];
    let keys = Object.keys(this.options.data);

    keys.forEach((key) => {
      this.options.data[key].forEach((particle) => {
        exposureData.push({
          lat:   particle[this.options.dataFormat.latIndex],
          lng:   particle[this.options.dataFormat.lonIndex],
          uid:   particle[this.options.dataFormat.idIndex],
          value: this.options.exposureIntensity
        });
      });
    });

    return { min: 0, max: 100, data: exposureData };
  },

  /**
	 * Create the display layer (heatmap) for cumulative EXPOSURE
	 * @private
	 */
  _initDisplayExposure () {

    this._clearDisplay();

    if (this.options.data){
      this._createColors();
      const exposureData = this._createExposureData();
      this.options.heatOptions.pane = this.options.pane || this._paneName;
      this._particleLayer = L.heatBin(this.options.heatOptions);
      this._particleLayer.addTo(this._map);
      this._particleLayer.setData(exposureData);
    }
  },

  /**
	 * Create the display layer (L.CircleMarkers) for KEYFRAME's
	 * @private
	 */
  _initDisplayKeyframe () {

    this._clearDisplay();

    if (this.options.data){
      // init the feature group and display first frame
      this._createColors();
      this._particleLayer = L.featureGroup();
      this._markers = [];
      this.setFrameIndex(this._frameIndex);
      this._particleLayer.addTo(this._map);
    } else {
      // console.error('Attempted to display keyframes but there is no data.')
    }
  },

  /**
	 * Deep merge Objects,
	 * Note that destination arrays will be overwritten where they exist in source.
	 * @param destination
	 * @param source
	 * @returns {*}
	 */
  _extendObject (destination, source) {
    let self = this;
    for (const property in source) {
      // .constructor avoids tripping over prototypes etc.
      // don't traverse the data..
      if (property === 'data') {
        destination[property] = source[property];
      } else if (source[property] && source[property].constructor && source[property].constructor === Object) {
        destination[property] = destination[property] || {};
        self._extendObject(destination[property], source[property]);
      } else {
        destination[property] = source[property];
      }
    }
    return destination;
  }

});

L.particlesLayer = function(options) {
  return new ParticlesLayer(options);
};

export default L.particlesLayer;
