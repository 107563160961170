

export default function useDecorateFiabilidad(){

  const RANGOS_AFINIDAD = [0, 0, 40, 70, 100, 130, 160, 200]

  const code = (feedValue) =>
  {
    // Rangos
    if (!isNaN(feedValue)) {

      // Afinidad por encima del valor medio
      if (feedValue >= RANGOS_AFINIDAD[7]) {   // 200 %
        return 'A4';
      }
      if (feedValue >= RANGOS_AFINIDAD[6]) {   // 160 %
        return 'A3';
      }
      if (feedValue >= RANGOS_AFINIDAD[5]) {   // 130 %
        return 'A2';
      }
      if (feedValue >= RANGOS_AFINIDAD[4]) {   // 100 %
        return 'A1';
      }

      // Afinidad por debajo
      if (feedValue >= RANGOS_AFINIDAD[3]) {   // 70 %
        return 'D1';
      }
      if (feedValue >= RANGOS_AFINIDAD[2]) {   // 40 %
        return 'D2';
      }
      if (feedValue >= RANGOS_AFINIDAD[1]) {   // 0
        return 'D3';
      }
      if (feedValue < RANGOS_AFINIDAD[0]) {   // 0
        return 'D4';
      }
    }
    return '';
  }

  const motion = (feedValueCode) =>
  {
    switch (feedValueCode) {
    case 'A1':
    case 'D1':
      return 'Mucha afinidad';
    case 'A2':
    case 'D2':
      return 'Aproximado';
    case 'A3':
    case 'D3':
      return 'Alejado';
    case 'A4':
    case 'D4':
      return 'Poca afinidad';
    }

    return ''; //'Sin avance';
  }

  // #00C0FF, #C090FF, CD001E, FF0000
  // const DECORATE_FIABILIDAD_COLOR = [
  //   { offset: 0, color: '#00C0FF' },
  //   { offset: 35, color: '#C090FF' },
  //   { offset: 50, color: '#CD001E' },
  //   { offset: 70, color: '#FF0000' },
  // ]
  // const getDecorateFiabilidadColor = (value) => {
  //   return DECORATE_FIABILIDAD_COLOR.find((item) => item.offset >= value)?.color || '#FF0000'
  // }

  // ToxA Color
  //   A   Azul            #00C0FF Pte. de verificar en imagen real (Sin ejemplo)
  //   B1  Morado(Oscuro)  #805CFF
  //   B2  Morado(Clarito) #C090FF
  //   B3  Rosa            #E05CFF
  //   CC  Magenta         #CD001E No coincide con leyenda
  //   C1  Rojo            #FF0000 No coincide con leyenda
  //   C2  Naranja         #FF8000 Pte. de verificar en imagen real (Sin ejemplo)
  //   C3  Amarillo        #FFC000
  //   D   Verde           #40A080
  const color = (feedValueCode) =>
  {
    switch (feedValueCode) {
    case 'A1':
    case 'D1':
      return '#00C0FF';
    case 'A2':
    case 'D2':
      return '#C090FF';
    case 'A3':
    case 'D3':
      return '#CD001E';
    case 'A4':
    case 'D4':
      return '#FF0000';
    }
    return '#000000';
  }

  const icon = (feedValueCode) => {
    switch (feedValueCode) {
    case 'A1':
    case 'D1':
      return 'fas fa-exchange-alt'; // fa-sort-alt, fas fa-exchange-alt mdi-cancel
    case 'A2':
    case 'A3':
    case 'A4':
      return 'fas fa-arrow-up';
    case 'D2':
    case 'D3':
    case 'D4':
      return 'fas fa-arrow-down';     // Precaución
    }
    return '';
  }


  const getDecorateFiabilidadColor = (value) => {
    return color(code(value))
  }
  const getDecorateFiabilidadIcon = (value) => {
    return icon(code(value))
  }

  return {
    code,
    motion,
    color,
    icon,
    getDecorateFiabilidadColor,
    getDecorateFiabilidadIcon,
  }
}
