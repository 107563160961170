import Vue from 'vue'
import Vuex from 'vuex'

// Modules
// import authStoreModule from '@/service/api/modules/auth/authStoreModule'
import appStoreModule from './appStoreModule'
import authStoreModule from './authStoreModule'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    app: appStoreModule,
    auth: authStoreModule,
  },
})
