<template>
  <div class="d-flex flex-column">

    <v-card class="d-flex flex-column w-100 rounded-0">

      <!-- Header -->
      <div class="d-flex px-3 py-2">
        <div class="d-flex align-baseline">
          <h3>Afinidad</h3><span class="ml-1">con intoxicación Real</span>
        </div>

        <v-spacer></v-spacer>
        <!-- <div class="d-flex align-center">
          <v-icon>mdi-monitor-dashboard</v-icon>
        </div> -->
      </div>

      <!-- Body -->
      <div class="d-flex flex-column px-3 py-0">

        <!-- Bucket AD Left + Right -->
        <div class="d-flex">
          <div class="d-flex flex-column">
            <span>
              <span class="font-weight-bold">AVG</span>
              <span class="font-weight-bold" :style="{ 'font-size': '2rem', color: getBucketLeft(forecastLocal).color }">
                <i :class="getBucketLeft(forecastLocal).icon"></i>
                {{ getBucketLeft(forecastLocal).value }}
              </span>
              {{ getHeader(forecastLocal).feedType.feedTypeUnit }}
            </span>
            <span>
              <div class="text-muted" :style="{ 'font-size': '0.75rem' }">{{ getBucketLeft(forecastLocal).text }}</div>
              <!-- <div class="text text-h6">{{ getBucketADPas(forecastLocal).motion }}</div> -->
            </span>
          </div>

          <div class="ml-auto d-flex flex-column text-right">
            <span>
              <span class="text-bold" :style="{ 'font-size': '2rem', color: getBucketRight(forecastLocal).color }">
                <!-- <i :class="getBucketRight(forecastLocal).icon"></i> -->
                {{ getBucketRight(forecastLocal).value }}%
              </span>
              <!-- {{ getHeader(forecastLocal).feedType.feedTypeUnit }} -->
            </span>
            <span>
              <div class="text-muted" :style="{ 'font-size': '0.75rem' }">{{ getBucketRight(forecastLocal).text }}</div>
              <!-- <div class="text text-h6">{{ getBucketADFut(forecastLocal).motion }}</div> -->
            </span>
          </div>
        </div>

        <!-- <div class="position-relative" style="height: 300px;"> -->
        <div class="position-relative">
          <LineChart
            id="chart-tox"
            :data="chartData"
            :options="chartOptions"
          />
        </div>
      </div>

    </v-card>

  </div>
</template>

<script>
  import { computed, ref, watch } from 'vue';

  import { Line as LineChart } from 'vue-chartjs'
  import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip,Legend } from 'chart.js'
  import { BoxPlotController, BoxAndWiskers } from '@sgratzl/chartjs-chart-boxplot'
  import annotationPlugin from 'chartjs-plugin-annotation';

  ChartJS.register( BoxPlotController, BoxAndWiskers, annotationPlugin, CategoryScale, LinearScale, PointElement, LineElement,Title,Tooltip,Legend)


  export default {
    name: 'forecast-chart-tox-component',
    components: {
      // eslint-disable-next-line vue/no-unused-components
      LineChart,
    },
    props: {
      forecast: {
        type: Object,
        default: () => {}
      }
    },
    setup(props) {
      const forecastLocal = ref(JSON.parse(JSON.stringify(props.forecast)))

      // props passed to setup function is reactive object (made probably by reactive()), it's properties are getters.
      // Watching a getter: https://v3.vuejs.org/api/computed-watch-api.html#watching-a-single-source
      watch(() => props.forecast, () => {
        forecastLocal.value = JSON.parse(JSON.stringify(props.forecast));
      });


      const getHeader = (forecast) => forecast?.forecastItemAfinidad?.forecastHeader;
      const getChart = (forecast) => forecast?.forecastItemAfinidad?.forecastChart;

      // const getBucketLeft = (forecast) => forecast?.forecastItemHeader?.forecastHeader?.forecastBuckets[0];
      // const getBucketRight = (forecast) => forecast?.forecastItemHeader?.forecastHeader?.forecastBuckets[1];

      const getBucketLeft = (forecast) => forecast?.forecastItemAfinidad?.forecastHeader?.forecastBuckets[0];
      const getBucketRight = (forecast) => forecast?.forecastItemAfinidad?.forecastHeader?.forecastBuckets[1];


      const getUrlInforme = (forecast) => {
        const dateRef = getHeader(forecast)?.dateRef;
        const riaId = getHeader(forecast)?.pm?.idRia;

        return `https://prediccion.empromar.com/backoffice/intoxicacion/launch?ria=${riaId}&dateRef=${dateRef}`
      }

      // const chartLabels = computed(() => {
      //   // return getChart(forecastLocal.value)?.labels
      //   return forecastLocal.value?.forecastItemAD?.forecastChart?.labels
      // })
      // const getDatasetToxA_Avance = computed(() => {
      //   // return getChart(forecastLocal.value)?.series[0]
      //   return forecastLocal.value?.forecastItemAD?.forecastChart?.series[0]
      // })
      // const getDatasetToxA_Descenso = computed(() => {
      //   // return getChart(forecastLocal.value)?.series[1]
      //   return forecastLocal.value?.forecastItemAD?.forecastChart?.series[1]
      // })
      // const options = computed(() => {
      //   // return getChart(forecastLocal.value)?.options
      //   return forecastLocal.value?.forecastItemAD?.forecastChart?.options
      // })

      const getDataPlotBox = (forecast) => {
        const data = forecast?.forecastItemAfinidad?.forecastChart?.series[3].map((item) => {
          if (item) {
            const valueIntecmar = Math.round(parseInt(item))
            return {
              min: valueIntecmar - (valueIntecmar * 0.15),
              q1: valueIntecmar - (valueIntecmar * 0.10),
              median: valueIntecmar,  // Mediana
              mean: valueIntecmar,    // Media
              q3: valueIntecmar + (valueIntecmar * 0.10),
              max: valueIntecmar + (valueIntecmar * 0.15),
              items: [],
            }
          }
          return null
        })
        return data
      }

      // eslint-disable-next-line no-debugger
      // debugger
      const chartData = computed( () => {
        return {
          labels: forecastLocal.value?.forecastItemAfinidad?.forecastChart?.labels,
          datasets: [
            // {
            //   label: 'Avance',
            //   data: forecastLocal.value?.forecastItemAfinidad?.forecastChart?.series[0],
            //   backgroundColor: 'rgb(214,28,105)',
            //   stack: 'Stack 0',
            // },
            // {
            //   label: 'Descenso',
            //   data: forecastLocal.value?.forecastItemAfinidad?.forecastChart?.series[1],
            //   backgroundColor: 'rgba(60,141,188,0.9)',
            //   stack: 'Stack 0',
            // }
            {
              label: 'ToxA',
              backgroundColor: 'rgb(214,28,105)',
              borderColor: 'rgb(214,28,105)',
              pointRadius: false,
              pointColor: 'rgba(210, 214, 222, 1)',
              pointStrokeColor: '#c1c7d1',
              pointHighlightFill: '#fff',
              pointHighlightStroke: 'rgba(220,220,220,1)',
              data: forecastLocal.value?.forecastItemAfinidad?.forecastChart?.series[0],
              fill: false,
              hidden: false
            },
            {
              label: 'ToxR',
              backgroundColor: 'rgba(44,97,188, 0.5)',
              borderColor: 'rgba(44,97,188, 0.5)',
              data: forecastLocal.value?.forecastItemAfinidad?.forecastChart?.series[4],
              pointRadius: false,
              hidden: true
            },
            {
              label: 'Intecmar',
              backgroundColor: 'rgb(44,97,188)',
              borderColor: 'rgb(44,97,188)',
              data: forecastLocal.value?.forecastItemAfinidad?.forecastChart?.series[3],
              hidden: false
            },
            {
              label: 'Rango',
              type: 'boxplot',

              // Rectangle
              borderColor: 'rgb(44,97,188)',
              backgroundColor: 'rgb(44,97,188, 0.5)',
              borderWidth: 1,

              // Outer: item style used to render outliers
              outlierStyle: 'circle',
              outlierBackgroundColor: 'red',
              outlierBorderColor: 'red',

              // Items a partir de los cuales se calcula la media y los cuartos
              // Item: item style used to render items
              // itemStyle: 'triangle',
              // itemRadius: 3,


              // Mean(Promedio): item style used to render mean dot
              // meanStyle: 'circle',
              // meanBackgroundColor: 'blue',
              // meanBorderColor: 'blue',

              data: getDataPlotBox(forecastLocal.value),
              hidden: true
            }
            // {
            //   label: 'Intec Lo',
            //   backgroundColor: 'rgba(60,141,188,0.9)',
            //   borderColor: 'rgba(60,141,188,0.8)',
            //   data: forecastLocal.value?.forecastItemAfinidad?.forecastChart?.series[1],
            //   fill: '+1', // Fill until next serie
            //   hidden: true
            // },
            // {
            //   label: 'Intec Hi',
            //   backgroundColor: 'rgba(60,141,188,0.9)',
            //   borderColor: 'rgba(60,141,188,0.8)',
            //   data: forecastLocal.value?.forecastItemAfinidad?.forecastChart?.series[2],
            //   fill: false,
            //   hidden: true
            // },
          ]
        }
      })

      // Tooltip para BoxPlot (Min - Med - Máx)
      // eslint-disable-next-line no-unused-vars
      const tooltipCallbackLabel = (context) => {
        if (context.dataset.type === 'boxplot') {
          let label = context.dataset.label || '';
          if (label) {
            label += ': '
          }
          const data = context.dataset.data[context.dataIndex]
          if (data) {
            label += `(${data.min} - ${data.median} - ${data.max})`
          }
          return label
        }
        return
      }

      // Average Annotation
      const lineAverageAnnotation = computed( () => {
        const value = getBucketLeft(forecastLocal.value)?.value
        return {
          type: 'line',
          yMin: value,
          yMax: value,
          // scaleID: 'y',
          // value: value,
          borderColor: 'rgba(214,28,105, .5)',
          borderWidth: 1
        }
      })

      return {
        forecastLocal,

        getHeader,
        getChart,

        getUrlInforme,

        getBucketLeft,
        getBucketRight,

        chartData,
        chartOptions: {
          maintainAspectRatio: false,
          responsive: true,
          interaction: {
            intersect: false,
            mode: 'index', // Para que se vean todos los datos de ese día al pasar con el cursor
          },
          scales: {
            x: {
              display: true,
            },
            y: {
              beginAtZero: true,
              // min: 0,
              // max: 300
            }
          },
          plugins: {
            legend: {
              display: true,
            },
            tooltip: {
              enabled: true,
              callbacks: {
                label: tooltipCallbackLabel,
              }
            },
            annotation: {
              annotations: {
                line: lineAverageAnnotation.value
              }
            }
          }
        }
      }
    }
  }
</script>

<style>

</style>
