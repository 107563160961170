import { computed } from 'vue'
import store from '@/store'

export default function useAppConfig() {

  // User
  const user = computed({
    get: () => store.state.auth.user,
    set: value => {
      store.commit('auth/UPDATE_USER', value)
    },
  })

  // DateRef: Fecha de Referencia para el cálculo de la predicción
  const dateRef = computed({
    get: () => store.state.app.dateRef,
    set: value => {
      store.commit('app/UPDATE_DATE_REF', value)
    },
  })

  // ToxinType: Tipo de toxina (DSP, PSP, ASP)
  const toxinType = computed({
    get: () => store.state.app.toxinType,
    set: value => {
      store.commit('app/UPDATE_TOXIN_TYPE', value)
    },
  })

  // View: Toxinas Vs Células
  const view = computed({
    get: () => store.state.app.view,
    set: value => {
      store.commit('app/UPDATE_VIEW', value)
    },
  })

  // Selector de Ría
  const riaId = computed({
    get: () => store.state.app.riaId,
    set: value => {
      store.commit('app/UPDATE_RIA_ID', value)
    },
  })


  // ------------------------------------------------
  // Settings: Opciones globales
  // ------------------------------------------------
  const settings = computed({
    get: () => store.state.app.settings,
    set: value => {
      store.commit('app/UPDATE_SETTINGS', value)
    },
  })
  const settingsSimulationStrategyKey= computed({
    get: () => store.state.app.settings?.simulationStrategyKey,
    set: value => {
      store.commit('app/UPDATE_SETTINGS_SIMULATION_STRATEGY_KEY', value)
    },
  })


  // ------------------------------------------------
  // Table: Table settings (Intoxication and Cells): changeWithMap(Boolean), changeWithFeature(Boolean)
  // ------------------------------------------------
  const tableSettings = computed({
    get: () => store.state.app.tableSettings,
    set: value => {
      store.commit('app/UPDATE_TABLE_SETTINGS', value)
    },
  })

  // Mapa
  const mapBaseLayer = computed({
    get: () => store.state.app.mapBaseLayer,
    set: value => {
      store.commit('app/UPDATE_MAP_BASE_LAYER', value)
    },
  })


  // User Data
  //    Get: Read store data
  //    Set: Not in use (Por ahora usamos store directamente)
  // const userData = computed({
  //   get: () => store.state.user.userData,
  //   set: val => {
  //     // store.commit('user/CHANGE_APP_MODE', val)
  //     store.dispatch('user/SET', val)
  //   },
  // })

  // Usamos Watch para activar reactividad ante cambios de estado
  // watch(() => store.state.user.userData, () => {
  //   userData.value = store.state.user.userData
  // })

  // Layers
  //  Capa de Células en partículas
  //  Capa de Polígonos
  //    Borde     -> Estado del polígono
  //    Interior  -> RenderHTML(ToxinasDiarias) o RenderHTML(ToxinasForecast)
  //                 Background(Estado/Plan) o RenderTXT(Nombre POL)
  //  Capa de PMI
  //    Borde     -> Fijo en Amarillo
  //    Interior  -> RenderHTML(CélulasDiarias) o RenderHTML(CélulasForecast) o RenderHTML(CélulasRecuento)
  //              -> RenderTXT(Nombre PMI)

  //  isLayerParticles  -> Visualizar la capa de células en partículas  -> api.empromar.com
  //    layerParticlesActive:     Boolean
  //
  //  isLayerPOL        -> Visualizar la capa de polígonos              -> prediction.empromar.com/api
  //    layerPOLActive:     Boolean
  //    layerPOLBorder:     [ 'none', 'color-estado', 'color-plan', 'color-simulation-daily', 'color-simulation-forecast' ]
  //    layerPOLBackground: [ 'none', 'color-estado', 'color-plan', 'color-simulation-daily', 'color-simulation-forecast' ]
  //    layerPOLRender:     [ 'none', 'simulation-daily', 'simulation-forecast' ]
  //
  //  isLayerPMI        -> Visualizar la capa de polígonos              -> prediction.empromar.com/api
  //    layerPMIActive:     Boolean
  //    layerPMIBorder:     [ 'none', 'yellow', 'color-simulation-daily', 'color-simulation-forecast' ]
  //    layerPMIBackground: [ 'none', 'color-simulation-daily', 'color-simulation-forecast' ]
  //    layerPMIRender:     [ 'none', 'simulation-daily', 'simulation-forecast' ]

  //  cellsOptions: Modo Visualización de células
  //    feedTypeAP:     [ 'all', 'acuminata', 'acuta', 'caudata', ... ]
  //    isTF:           Boolean
  //    isGroup:        Boolean

  // ------------------------------------------------
  // layerParticles: Capa de Partículas -> Células en movimiento
  // ------------------------------------------------
  const layerParticles= computed({
    get: () => store.state.app.layerParticles,
    set: value => {
      store.commit('app/UPDATE_LAYER_PARTICLES', value)
    },
  })
  // const layerParticlesParticles= computed({
  //   get: () => store.state.app.layerParticles?.particles,
  //   set: value => {
  //     store.commit('app/UPDATE_LAYER_PARTICLES_PARTICLES', value)
  //   },
  // })
  // const layerParticlesVideo= computed({
  //   get: () => store.state.app.layerParticles?.video,
  //   set: value => {
  //     store.commit('app/UPDATE_LAYER_PARTICLES_VIDEO', value)
  //   },
  // })
  // const layerParticlesSimulationStrategyKey= computed({
  //   get: () => store.state.app.layerParticles?.simulationStrategyKey,
  //   set: value => {
  //     store.commit('app/UPDATE_LAYER_PARTICLES_SIMULATION_STRATEGY_KEY', value)
  //   },
  // })

  // ------------------------------------------------
  // layerPOL: Capa de Polígonos
  // ------------------------------------------------
  const layerPOL = computed({
    get: () => store.state.app.layerPOL,
    set: value => {
      store.commit('app/UPDATE_LAYER_POL', value)
    },
  })

  // ------------------------------------------------
  // layerPMI: Capa de PMI
  // ------------------------------------------------
  const layerPMI = computed({
    get: () => store.state.app.layerPMI,
    set: value => {
      store.commit('app/UPDATE_LAYER_PMI', value)
    },
  })


  // ------------------------------------------------
  // cellsOptions: Modo Visualización de células
  // ------------------------------------------------
  const cellsOptions = computed({
    get: () => store.state.app.cellsOptions,
    set: value => {
      store.commit('app/UPDATE_CELLS_OPTIONS', value)
    },
  })
  const cellsOptionsFeedTypeAP = computed({
    get: () => store.state.app.cellsOptions?.feedTypeAP,
    set: value => {
      store.commit('app/UPDATE_CELLS_OPTIONS_FEED_TYPE_AP', value)
    },
  })

  const celulas = computed({
    get: () => store.state.app.celulas,
    set: value => {
      store.commit('app/UPDATE_CELULAS', value)
    },
  })

  // ------------------------------------------------
  // infoTable: Info de la tabla
  // ------------------------------------------------
  const infoTable = computed({
    get: () => store.state.app.infoTable,
    set: value => {
      store.commit('app/UPDATE_INFO_TABLE', value)
    },
  })

  return {
    // Auth
    user,

    // Global
    dateRef,
    toxinType,
    view,
    riaId,

    // Settings global
    settings,
    settingsSimulationStrategyKey,

    // Table
    tableSettings,

    // Map
    mapBaseLayer,

    // Layers
    layerParticles,
    layerPOL,
    layerPMI,

    // Cells
    cellsOptions,
    cellsOptionsFeedTypeAP,

    celulas,

    // InfoTable
    infoTable,
  }
}
