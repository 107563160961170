<template>
  <div class="d-flex flex-column">

    <!-- TODO - El header sería bueno tenerlo aquí y meterle el componente dentro -->
    <!-- Emitimos los eventos hacia el padre -->
    <ForecastCardHeader
      :forecast="forecastLocal"
      :minimize="minimize"
      @update:minimize="(value) => $emit('update:minimize', value)"
      @close="() => $emit('close', true)"
      @dashboard="(riaId) => $emit('dashboard', riaId)"
    >
    </ForecastCardHeader>

    <ChartBarGauge :forecast="forecastLocal" v-show="forecast"></ChartBarGauge>
    <ForecastCardAD :forecast="forecastLocal" v-show="forecast"/>
    <ForecastCardChartTox :forecast="forecastLocal" v-show="forecast"/>
    <ForecastCardChartCells :forecast="forecastLocal" v-show="forecast"/>
  </div>
</template>

<script>
  import { ref, watch } from 'vue';

  import ForecastCardHeader from './ForecastCardHeader.vue'
  import ForecastCardAD from './ForecastCardAD.vue'
  import ForecastCardChartTox from './ForecastCardChartTox.vue'
  import ForecastCardChartCells from './ForecastCardChartCells.vue'
  import ChartBarGauge from './ChartBarGauge.vue'

  export default {
    name: 'forecast-component',
    components: {
      ForecastCardHeader,
      ForecastCardAD,
      ForecastCardChartTox,
      ForecastCardChartCells,
      ChartBarGauge,
    },
    props: {
      forecast: {
        type: Object,
        default: () => {},
      },
      minimize: {
        type: Boolean,
        default: true,
      },
    },
    setup(props) {
      // const isDialogToxinParamsVisible = ref(true)
      const forecastLocal = ref(JSON.parse(JSON.stringify(props.forecast)));

      // props passed to setup function is reactive object (made probably by reactive()), it's properties are getters.
      // Watching a getter: https://v3.vuejs.org/api/computed-watch-api.html#watching-a-single-source
      watch(() => props.forecast, () => {
        forecastLocal.value = JSON.parse(JSON.stringify(props.forecast));
      });

      return {
        forecastLocal,
      };
    },
  };
</script>
  <style>

  </style>
