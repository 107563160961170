<template>
  <div class="d-flex flex-row align-center">

    <!-- Nombre de la capa -->
    <!-- class="d-none d-sm-block layer-text" -->
    <!-- :class="layerLocal.active ? 'text-decoration-underline' : ''" -->
    <span
      class="layer-text"
      :class="[
        layerLabelVisible ? '' : 'd-none d-sm-block',
        layerLocal.active ? 'text-decoration-underline' : ''
      ]"
      @click="isDialogActiveLocal = true"
    >
      <!-- {{ layerType === 'toxins' ? 'Toxinas POL' : 'Células PMI' }} -->
      {{ layerTypeConfigLocal.label }}
    </span>

    <!-- Parámetros de la capa en diálogo -->
    <MenuLayerParamsDialog
      v-if="layerType == 'toxins' || layerType === 'cells'"
      mode="hidden"
      :isDialogActive.sync="isDialogActiveLocal"
      :key="layerType"
      :layerType="layerType"
      :layer.sync="layerLocal"
    />
    <MenuLayerParamsParticlesDialog
      v-if="layerType == 'particles'"
      mode="hidden"
      :isDialogActive.sync="isDialogActiveLocal"
      :key="layerType"
      :layerType="layerType"
      :layer.sync="layerLocal"
    />

    <!-- Icono Representa la capa: on/off: En Mobile accedemos al Dialog -->
    <!-- $vuetify.breakpoint.xs ? isDialogActiveLocal = true : layerLocal.active = !layerLocal.active -->
    <v-btn
      :loading="loading"
      fab
      :x-small="true"
      :color="layerLocal.active ? layerTypeConfigLocal.color : layerTypeConfigLocal.colorOff"
      @click="layerLocal.active = !layerLocal.active"
    >
      <!-- <v-icon x-small text>{{ layerType === 'toxins' ? 'POL' : 'PMI' }}</v-icon> -->
      <v-icon x-small text v-if="!layerTypeConfigLocal.icon">{{ layerTypeConfigLocal.text }}</v-icon>
      <v-icon color="white" v-if="layerTypeConfigLocal.icon">{{ layerLocal.active ? layerTypeConfigLocal.icon : layerTypeConfigLocal.iconOff}}</v-icon>
    </v-btn>
  </div>

</template>
<script>
  import { ref, watch } from 'vue';

  import MenuLayerParamsDialog from './MenuLayerParamsDialog.vue';
  import MenuLayerParamsParticlesDialog from './MenuLayerParamsParticlesDialog.vue';

  export default {
    name: 'menu-layer-component',
    components: {
      MenuLayerParamsDialog,
      MenuLayerParamsParticlesDialog
    },
    props: {
      layerType: {
        type: String,
        required: true, // ['']
        default: 'toxins' // 'toxins' || 'cells' || 'particles'
      },
      layer: {
        type: Object,
        required: true,
        default: () => ({
          active: false,
          border: undefined,
          background: undefined,
          render: undefined,
        }),
      },
      loading: {
        type: Boolean,
        default: false
      },
      layerLabelVisible: {
        type: Boolean,
        default: false
      }
    },
    setup(props, { emit }) {
      const isDialogActiveLocal = ref(false)


      // Opciones del componente: Textos e iconos
      const menuOptionsLayerTypeConfig = [
        { value: 'toxins', label: 'Toxinas POL', color: 'purple', colorOff: 'grey', icon: false, text: 'POL' },
        { value: 'cells', label: 'Células PMI', color: 'purple', colorOff: 'grey', icon: false, text: 'PMI' },
        { value: 'particles', label: 'Partículas', color: 'primary', colorOff: 'secondary', icon: 'mdi-dots-hexagon', iconOff: 'mdi-blur-off', text: 'P' },
      ]

      // Particulas: El icono puede cambiar según su configuración
      const setOptionParticles = (render) => {
        var option = menuOptionsLayerTypeConfig.find( (element) => element.value == 'particles' )
        if (option) {
          if (render === 'particles') {
            option.icon = 'mdi-dots-hexagon'
            option.iconOff = 'mdi-blur-off'
          }
          if (render === 'video') {
            option.icon = 'mdi-video'
            option.iconOff = 'mdi-video-off'
          }
        }
      }

      // Set particles custom
      if (props.layerType === 'particles') {
        setOptionParticles(props.layer?.render)
      }


      const layerTypeConfigLocal = ref(null)
      const setLayerTypeConfig = (val) => {
        layerTypeConfigLocal.value = menuOptionsLayerTypeConfig.find( (element) => element.value == val )
        if (!layerTypeConfigLocal.value) {
          layerTypeConfigLocal.value = menuOptionsLayerTypeConfig[0]
        }
      }
      setLayerTypeConfig(props.layerType)


      // Layer
      const layerLocal = ref(props.layer)
      watch(() => props.layer, () => {
        layerLocal.value = props.layer

        // Modificar las opciones de Particulas
        if (props.layerType === 'particles') {
          setOptionParticles(layerLocal.value?.render)
        }
      }, { deep: true })
      watch(() => layerLocal.value, () => {
        emit('update:layer', layerLocal.value)
      }, { deep: true })

      return {
        isDialogActiveLocal,
        layerLocal,

        // Opciones de visualización { colorOn, colorOff,  }
        layerTypeConfigLocal
      }
    }
  }
</script>

<style scoped>
  .layer-text {
    white-space: nowrap;

    /* Indicamos un radio y un pading para que se vea la sombra */
    border-radius: 1.2em;
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    /* Define the default styles for the element */
    /* background-color: #f0f0f0; */
    /* text-shadow: 0px 0px 4px black; */
    color: #333;
    transition: background-color 0.3s ease; /* Add a smooth transition effect */
  }

  /* Define the styles for the element when hovered */
  .layer-text:hover {
    background-color: #ccc;
    color: #555;
    cursor: pointer;
  }
</style>
