<template>
  <!--- Home --->
  <div id="home">

    <!-------------- Search Empty Notification ----------->
    <v-dialog v-model="searchEmpty">
      <v-card>
        <v-card-text>
          You need to type something before you can search for it!
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" block @click="searchEmpty = false">ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-------------------------- Search Bar ----------------------->
    <div id="custom-toolbar" style="display: none-;">
      <div id="custom-search-wrapper" class="d-flex align-center">

        <!-- Menu button (Oculto, se muestra en el mapa y se activa con evento) -->
        <!-- <v-tooltip text="Menu" location="bottom">
          <template v-slot:activator="{ props }">
            <v-btn
              class="ma-auto"
              v-bind="props"
              elevation="0"
              icon
              large
              @click="showMenu = true"
            >
              <v-icon size="24">mdi-menu</v-icon>
            </v-btn>
          </template>
        </v-tooltip> -->

        <!----- Search Field ----->
        <!-- item-text="id" -->
        <!-- style="width: 100%;" -->
        <!-- <v-autocomplete
          bg-color="white"
          class="w-100 custom"
          v-model="productionZoneFilterLocal"
          :items="productionZoneFilterOptions"
          placeholder="Buscar polígonos"
          solo
          :dense="false"
          clearable
          hide-details="auto"
          @change="goProductionZone()"
        /> -->

        <!---------- Search Icon Button --------------------->
        <!-- <v-spacer></v-spacer>
        <v-tooltip text="Search" location="bottom">
          <template v-slot:activator="{ props }">
            <v-btn
              v-bind="props"
              class="ma-auto"
              elevation="0"
              icon
              large
              @click="goProductionZone()"
            >
              <v-icon size="24">mdi-magnify</v-icon>
            </v-btn>
          </template>
        </v-tooltip> -->

        <!-- Search Divider -->
        <!-- <div id="custom-search-divider"></div> -->

        <!-- Directions Icon Button: mdi-directions -->
        <!-- @click="showNavigation = true" -->
        <!-- <v-tooltip text="Navigate" location="bottom">
          <template v-slot:activator="{ props }">
            <v-btn
              v-bind="props"
              class="ma-auto"
              elevation="0"
              icon
              large
              @click="goMapHome"
            >
              <v-icon size="24">mdi-crosshairs-gps</v-icon>
            </v-btn>
          </template>
        </v-tooltip> -->
      </div>
    </div>

    <!-------------------------- End of Search Bar -------------------------------->

    <!-------------- Main Menu Drawer ---------------->
    <!-- :temporary="$vuetify.breakpoint.smAndDown" -->
    <v-navigation-drawer
      v-model="showMenu"
      width="400"
      mobile-breakpoint="sm"
      absolute
      :right="$vuetify.rtl"
      touchless
      :temporary="true"
    >
      <!-- Toolbar -->
      <v-toolbar
        src="https://cdn.vuetifyjs.com/images/backgrounds/vbanner.jpg"
      >
        <v-app-bar-nav-icon>
          <v-icon color="white">mdi-home</v-icon>
        </v-app-bar-nav-icon>
        <v-toolbar-title class="white--text">emproMar</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon color="white">
          <v-icon @click="showMenu = false">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <!-- BaseLayers -->
      <div class="d-flex flex-column ml-4 mt-4 mb-2">
        <v-radio-group
          v-model="mapBaseLayer"
          column
          hide-details
        >
          <template v-slot:label>
            <div class="d-flex align-center">
              <v-icon class="me-2">mdi-map</v-icon>
              <h3>Capa base</h3>
            </div>
          </template>
          <v-radio
            class="ml-4"
            v-for="option in baseLayerOptions"
            :key="option.value"
            :label="option.text"
            :color="option.color"
            :value="option.value"
          ></v-radio>
        </v-radio-group>
      </div>

      <!-- Capas -->
      <div class="d-flex flex-column ml-4 mt-4 mb-2">

        <div class="d-flex">
          <v-icon class="me-2">mdi-layers</v-icon>
          <span class="font-weight-bold text-h7">Capas</span>
        </div>
        <v-divider class="mt-2"></v-divider>

        <div class="d-flex flex-column ml-4 mt-2">

          <div class="d-flex align-center" style="height: 48px;">
            <v-switch
              class="mt-0"
              v-model="layerParticles.active"
              inset
              label="Partículas"
              hide-details
            ></v-switch>
            <v-spacer></v-spacer>
            <MenuLayerParamsParticlesDialog
              mode="button"
              key="particles"
              layerType="particles"
              :layer.sync="layerParticles"
            />
          </div>
          <div class="d-flex align-center" style="height: 48px;">
            <v-switch
              class="mt-0"
              v-model="layerPMI.active"
              inset
              label="Células en PMI"
              hide-details
            ></v-switch>
            <v-spacer></v-spacer>
            <MenuLayerParamsDialog
              mode="button"
              key="cells"
              layerType="cells"
              :layer.sync="layerPMI"
            />
          </div>
          <div class="d-flex align-center" style="height: 48px;">
            <v-switch
              class="mt-0"
              v-model="layerPOL.active"
              inset
              label="Toxinas en Polígos"
              hide-details
            ></v-switch>
            <v-spacer></v-spacer>
            <MenuLayerParamsDialog
              mode="button"
              key="toxins"
              layerType="toxins"
              :layer.sync="layerPOL"
            />
          </div>
        </div>
      </div>

      <!-- Rias -->
      <div class="d-flex flex-column ml-4 mt-4 mb-2">

        <div class="d-flex">
          <v-icon class="me-2">mdi-layers</v-icon>
          <span class="font-weight-bold text-h7">Rías (Toxinas y Células)</span>
        </div>
        <v-divider class="mt-2"></v-divider>

        <div class="d-flex flex-column ml-4 mt-2">
          <v-switch
            class="mt-0"
            style="height: 48px;"
            v-model="showLayerRiasMUR"
            inset
            label="Muros e Noia"
            hide-details
            @change="($value) => onchangeLayerRias('MUR', $value)"
          ></v-switch>
          <v-switch
            class="mt-0"
            style="height: 48px;"
            v-model="showLayerRiasARO"
            inset
            label="Arousa"
            hide-details
            @change="($value) => onchangeLayerRias('ARO', $value)"
          ></v-switch>
          <v-switch
            class="mt-0"
            style="height: 48px;"
            v-model="showLayerRiasPON"
            inset
            label="Pontevedra"
            hide-details
            @change="($value) => onchangeLayerRias('PON', $value)"
          ></v-switch>
          <v-switch
            class="mt-0"
            style="height: 48px;"
            v-model="showLayerRiasVIG"
            inset
            label="Vigo e Baiona"
            hide-details
            @change="($value) => onchangeLayerRias('VIG', $value)"
          ></v-switch>
          <v-switch
            class="mt-0"
            style="height: 48px;"
            v-model="showLayerRiasARE"
            inset
            label="Ares y Betanzos"
            hide-details
            @change="($value) => onchangeLayerRias('ARE', $value)"
          ></v-switch>
        </div>
      </div>

      <v-divider class="mt-2"></v-divider>
      <div class="d-flex flex-row align-center ml-4 mt-4 mb-2">
        <GlobalSettingsDialog
          mode="button"
          :settings.sync="settings"
        />
        <span>Configuraciones globales</span>
      </div>
    </v-navigation-drawer>


    <!--- Map Component --->
    <MapComponent
      ref="map"
      :idRias="idRias"
      @menuOpen="showMenu = true"
    />
  </div>

</template>

<script>
  import { ref, watch } from 'vue';
  import useMap from '@/service/useMap';
  import zoneListArousa from '../geojson/POL_Interior_Arousa.json'; // Import rooms GeoJSON

  // Main component
  // import MapComponent from '../components/MapOL.vue'; // Import Map Component
  // import MapComponent from '../components/MapOLExt2.vue'; // Import Map Component
  // import MapComponent from '../components/MapOLAnimation.vue'; // Import Map Component
  import MapComponent from '../maps/mapLeaflet3.vue'; // Import Map Component

  // Components
  import MenuLayerParamsDialog from '@/components/MenuLayerParamsDialog.vue';
  import MenuLayerParamsParticlesDialog from '@/components/MenuLayerParamsParticlesDialog.vue';
  import GlobalSettingsDialog from '@/components/GlobalSettingsDialog.vue';

  // Utils
  import useUtils from '@/service/useUtils'
  import useAppConfig from '@/store/useAppConfig'

  export default {
    // Name of component
    name: 'home-component',

    // Child components
    components: {
      MapComponent,
      MenuLayerParamsDialog,
      MenuLayerParamsParticlesDialog,
      GlobalSettingsDialog,
    },

    setup() {
      const showMenu = ref(false)
      const searchEmpty = ref(false)

      // Map component
      const map = ref(null);
      const goMapHome = () => {
        map.value.goMapHome(); // La funcion ha sido desarrollada en el componente Map.vue
      };
      const goProductionZone = () => {
        if (!productionZoneFilterLocal.value) {
          return;
        }
        const selected = zoneListArousa.features.find((item) => item?.properties?.name === productionZoneFilterLocal.value);
        if (selected) {
          map.value.goProductionZone(selected.properties.name); // La funcion ha sido desarrollada en el componente Map.vue
        }
      };

      // Production Zones filter
      const productionZoneFilterLocal = ref(null);
      const productionZoneFilterOptions = ref([]);

      // Initial loal all filters
      productionZoneFilterOptions.value = zoneListArousa.features.map((item) => item.properties.name);
      // Listen for changes
      watch(() => productionZoneFilterLocal.value, () => {
        goProductionZone();
      });

      // Get boolean value if are stored at LocalStorage
      const { getLocalStorageBoolean } = useUtils()

      // >>> Base layer selection
      // Capas Leaflet: { id, url, attribution, }
      const { tileLayerOptions } = useMap();
      const baseLayerOptions = ['windy', 'openstreetmap', 'mapbox', 'google-satellite-only'].map((key) => {
        const tileLayer = tileLayerOptions.find((element) => element.id === key);
        return {
          value: tileLayer?.id || key,
          text: tileLayer?.id || key,
          color: 'info',
          icon: 'mdi-map',
        };
      });

      // Maps: Base layer
      const { mapBaseLayer } = useAppConfig()

      // Layers
      // AppConfig: Layers
      const { layerParticles, layerPOL, layerPMI } = useAppConfig()

      // Layer Rías
      const showLayerRiasMUR = ref(getLocalStorageBoolean('map-layer-rias-mur', true));
      const showLayerRiasARO = ref(getLocalStorageBoolean('map-layer-rias-aro', true));
      const showLayerRiasPON = ref(getLocalStorageBoolean('map-layer-rias-pon', true));
      const showLayerRiasVIG = ref(getLocalStorageBoolean('map-layer-rias-vig', true));
      const showLayerRiasARE = ref(getLocalStorageBoolean('map-layer-rias-are', false));
      const idRias = ref([])
      const completeIdRias = () => {
        idRias.value = []
        if (showLayerRiasMUR.value) { idRias.value.push(6)}
        if (showLayerRiasARO.value) { idRias.value.push(5)}
        if (showLayerRiasPON.value) { idRias.value.push(4)}
        if (showLayerRiasVIG.value) { idRias.value.push(2)}
        if (showLayerRiasARE.value) { idRias.value.push(1)}
      }
      completeIdRias()
      watch(
        [
          () => showLayerRiasMUR.value,
          () => showLayerRiasARO.value,
          () => showLayerRiasPON.value,
          () => showLayerRiasVIG.value,
          () => showLayerRiasARE.value,
        ], () => {
          completeIdRias()
        })

      const onchangeLayerRias = (key, value) => {
        localStorage.setItem('map-layer-rias-' + key.toLowerCase(), value)
      }

      // Settings
      const { settings } = useAppConfig()

      return {
        showMenu,
        searchEmpty,

        map,
        goMapHome,
        goProductionZone,

        // Search
        productionZoneFilterLocal,
        productionZoneFilterOptions,

        // Base layer
        baseLayerOptions,
        mapBaseLayer,

        // AppConfig
        layerParticles, layerPOL, layerPMI,   // Layers

        // Rias
        showLayerRiasMUR,
        showLayerRiasARO,
        showLayerRiasPON,
        showLayerRiasVIG,
        showLayerRiasARE,
        onchangeLayerRias,
        idRias,

        // Settings
        settings
      };
    },
  };
</script>

<style lang="scss" scoped>

  /* Remove Chrome's button:focus borders */
  button:focus { outline: 0; }

  /* Home Component */
  #home {
    height: 100%;
    width: 100%;
    position:fixed;
  }

  /* Custom Toolbar for Search Bar */
  #custom-toolbar {
    background: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    // width: 100%;
    // display:block;
    // height: 0px;
    padding:10px;
  }

  /* Custom Search Wrapper for formatting*/
  #custom-search-wrapper {
    background: white;
    max-width: 400px;
    // width: 400px;
    height: 56px;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 3px 0px rgb(0 0 0 / 25%);
    -moz-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 0px 3px 0px rgb(0 0 0 / 25%);
  }

  .map-play {
    color: #000;
    position: absolute;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,.4);
    bottom: 36px;
    left: 12px;
    width: calc(100% - 24px);
    z-index: 0
  }
  /* Contenedor de la leyenda */
  .map-tools {
    background: white;
    position: absolute;
    bottom: 0;
    margin: 0 auto;
    max-width: 960px;
    width: 100%;
    z-index: 3
  }

  /* Custom Search Divider */
  #custom-search-divider {
    background: rgba(0,0,0,0.25);
    width:1px;
    height:30px;
    margin:auto;
  }



</style>
<style>
  /* .custom.v-text-field>.v-input__control>.v-input__slot:before {
    border-style: none;
  }
  .custom.v-text-field>.v-input__control>.v-input__slot:after {
      border-style: none;
  } */
  .custom.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
      box-shadow: none;
  }
</style>
