<template>
  <div>
    <div class="map_container_wrapper" :class="[isPOLOpen ? 'one_sidebar_after' : 'none_sidebar_after']">

      <!-- Capas de la izquierda -->
      <div class="map_controls">
        <div class="d-flex flex-column">

          <v-btn
            class="my-1 mb-4"
            x-small
            fab
            @click="$emit('menuOpen', true)"
          >
            <v-icon>mdi-menu</v-icon>
          </v-btn>

          <MapBaseLayer/>
          <v-btn
            class="my-1"
            x-small
            fab
            :color="'grey'"
            @click="onMapZoomControl(1)"
          >
            <v-icon color="white">mdi-plus</v-icon>
          </v-btn>
          <v-btn
            class="my-1"
            x-small
            fab
            :color="'grey'"
            @click="onMapZoomControl(-1)"
          >
            <v-icon color="white">mdi-minus</v-icon>
          </v-btn>

          <ForecastDateRefDialog :loading="loading" />
        </div>
      </div>

      <!-- Capas de la derecha: Usuario + Tipo de Toxina -->
      <div class="map_controls_top_user">
        <div class="d-flex flex-column align-end">
          <!-- Login + UserInfo + Logout -->
          <AppBarMenuUser
            class="my-1"
          />
        </div>
      </div>

      <!-- Capas de la derecha: Tipo de Toxina + Nav.Tabla de intoxicación -->
      <div class="map_controls_top">
        <div class="d-flex flex-column align-end">
          <ForecastToxinType
            class="my-1"
          />
          <v-btn
            class="my-1"
            color="blue"
            small
            fab
            @click="isIntoxicationTableVisible = true"
          >
            <v-icon color="white">mdi-monitor-dashboard</v-icon>
          </v-btn>

          <!-- Control Intecmar -->
          <div>
            <v-btn
              :loading="controlIntecmarLoading"
              class="my-1"
              color="blue"
              small
              fab
              @click="onClickControlIntecmar()"
            >
              <v-img width="24px" src="../assets/intecmar.png" alt="Control Intecmar" style="margin: 3px; border-radius: 50%; background-color: white;" />
              <!-- <v-img width="24px" src="../assets/intecmar.jpeg" alt="Control Intecmar" style="margin: 5px; border-radius: 50%;" /> -->

              <!-- Desactivamos el loader cuando alcancemos el 100%: Despues se usa el efecto loading habitual -->
              <!-- Se podría usar un icono especial de descarga en movimiento, haciendo el efecto de descarga hasta que finalice el proceso -->
              <template v-slot:loader v-if="controlIntecmarLoadingValue < 100">
                <v-progress-circular
                  :rotate="-90"
                  :value="controlIntecmarLoadingValue"
                  color="white"
                  small
                >
                  <span style="font-size: 10px;">{{ controlIntecmarLoadingValue }}%</span>
                </v-progress-circular>
              </template>
            </v-btn>
          </div>
        </div>
      </div>

      <!-- Capas de predicción -->
      <div class="map_controls_layers custom_background">
        <div class="d-flex flex-column align-end">



          <!-- Capa Rain: isRainViewerActive -->
          <!-- Capa Velocity: isVelocityViewerActive -->

          <!-- Capa de párticulas -->
          <MenuLayer
            key="particles"
            class="my-1"
            layerType="particles"
            :layer.sync="layerParticles"
            :loading="videoParticleLayerVideoLoading && layerParticles.active"
            :layerLabelVisible="layerLabelVisible"
          />

          <!-- Capa de Células en PMI's -->
          <MenuLayer
            key="cells"
            class="my-1"
            layerType="cells"
            :layer.sync="layerPMI"
            :loading="loading && layerPMI.active"
            :layerLabelVisible="layerLabelVisible"
          />

          <!-- Capa de Intoxicación en POL's-->
          <MenuLayer
            key="toxins"
            class="my-1"
            layerType="toxins"
            :layer.sync="layerPOL"
            :loading="loading && layerPOL.active"
            :layerLabelVisible="layerLabelVisible"
          />

          <!-- Solo en Mobile -->
          <v-btn
            v-if="$vuetify.breakpoint.xs"
            class="my-1"
            fab
            :x-small="true"
            :color="layerLabelVisible ? 'grey' : 'transparent'"
            @click="layerLabelVisible = !layerLabelVisible"
          >
            <v-icon size="x-large">{{ layerLabelVisible ? 'mdi-chevron-right' : 'mdi-chevron-left'}}</v-icon>
          </v-btn>
        </div>
      </div>


      <!-- Capas de la izquierda: Video -->
      <!-- <div class="map_controls_desktop_calendar">
        <ForecastDesktopCalendar
          v-if="timestamps.length > 0"
          class="ml-1"
          :timestamps="timestamps"
          :animationTimer="animationTimer"
          :animationPosition.sync="animationPosition"
          :animationVelocity="animationVelocity"
          :animationStepFrequency="animationStepFrequency"
          :videoParticleLayerVideoPlay.sync="videoParticleLayerVideoPlay"
          @startstop="startstop"
          @showFrameByTimestamp="(ts) => showFrameByTimestamp(ts)"
          @onChangeAnimationVelocity="onChangeAnimationVelocity"
          @onChangeAnimationStepFrequency="onChangeAnimationStepFrequency"
        />
      </div> -->

      <div
        class="map_controls_mobile_calendar"
        :class="[ isIntoxicationTableVisible ? 'map_controls_mobile_calendar_set_on_top' : '']">
        <ForecastMobileCalendar
          v-if="timestamps.length > 0"
          :timestamps="timestamps"
          :animationTimer="animationTimer"
          :animationPosition.sync="animationPosition"
          :animationVelocity="animationVelocity"
          :animationStepFrequency="animationStepFrequency"
          :videoParticleLayerVideoPlay.sync="videoParticleLayerVideoPlay"
          :videoParticleLayerVideoLoading="videoParticleLayerVideoLoading"
          :isOnTop="isIntoxicationTableVisible"
          @showFrameByTimestamp="(ts) => showFrameByTimestamp(ts)"
          @onChangeAnimationVelocity="onChangeAnimationVelocity"
          @onChangeAnimationStepFrequency="onChangeAnimationStepFrequency"
        />
      </div>

      <div class="overlay-intoxication-table">
        <ForecastHandsonTable2
          v-if="isIntoxicationTableVisible"
          :class="{ 'overlay-intoxication-table-collapsed-x' : forecastSelected }"
          :intoxicationTableList="intoxicationTableList"
          @close="isIntoxicationTableVisible = false"
          @onFeatureSelected="(pmId) => setFeatureSelectedByExternal(pmId)"
        />
      </div>

      <div class="map_controls_toxin_leyend">
        <ForecastToxinTypeLeyend />
      </div>

      <div class="map_controls_cells_leyend">
        <ForecastLeyend />
      </div>

      <!-- ForecastSelected -->
      <!-- :class="{ 'overlay-forecast-card-max' : $vuetify.breakpoint.xs || !forecastMinimize }" -->
      <div
        v-if="forecastSelected"
        class="overlay-forecast-card"
        :class="{ 'overlay-forecast-card-max' : !forecastMinimize }"
      >

        <!-- Imagen Superior: Rana del tiempo -->
        <!-- <div
          v-if="$vuetify.breakpoint.xs || !forecastMinimize"
          :style="{
            'background-image':'url(assets/forecast_beach.jpeg)',
            'height': '130px',
            'background-size': 'cover',
            'background-position-y': 'center'
          }">
        </div> -->

        <!-- @dashboard: Visualizar Tabla de intoxicación de esa ría -->
        <ForecastCard
          :forecast="forecastSelected"
          :minimize="forecastMinimize"
          @update:minimize="(value) => forecastMinimize = value"
          @close="() => forecastSelected = null"
          @dashboard="(riaId) => { riaIdSelected = riaId; isIntoxicationTableVisible = true }"
        >
        </ForecastCard>
      </div>

      <!-- <div id="info_container"></div> -->


      <!-- Leaflet Map -->
      <!-- <div id="map"></div> -->
      <!-- :zoomAnimation="true" -->
      <l-map
        id="map"
        ref="map"
        :zoom.sync="mapZoom"
        :center.sync="mapCenter"
        :options="{
          zoomControl: false,
          // attributionControl: false,
          // zoomSnap: true
          // zoomSnap: 0.25,
        }">

        <l-tile-layer :url="tileLayerSelected.url" :options="{ maxNativeZoom:19, maxZoom:21, minZoom:4 }"></l-tile-layer>

        <!-- Ver marker circle de windy -->
        <!-- <div class="leaflet-pane leaflet-marker-pane">
          <div class="leaflet-marker-icon icon-dot leaflet-zoom-animated leaflet-interactive" tabindex="0"
               style="margin-left: -5px;margin-top: -5px;width: 10px;height: 10px;transform: translate3d(1070px, 97px, 0px);z-index: 97;">
            <div class="pulsating-icon repeat"></div>
          </div>
        </div> -->

        <l-layer-group>
          <l-circle-marker
            v-if="layerSelectedPoint"
            :lat-lng="layerSelectedPoint"
            :radius="2"
          />

          <!-- POL: Contorno(Estado actual) + Predicción -->
          <!-- v-if="showLayerPOLLocal" -->
          <l-geo-json
            v-if="layerPOL?.active || layerPOL?.isBorderAlwaysVisible"
            :geojson="vectorLayerGeojsonPOL"
            :options="{ style: getGeojsonStylePOL, onEachFeature: onEachFeaturePOL }"
          ></l-geo-json>
          <l-geo-json
            v-if="layerPOL?.active || layerPOL?.isBorderAlwaysVisible"
            :geojson="vectorLayerGeojsonPOLCenter"
            :options="{ pointToLayer: onPointToLayerPOLCenter, onEachFeature: onEachFeaturePOL}"
          ></l-geo-json>

          <!-- PMI: Yellow Circle + Recounts -->
          <!-- v-if="showLayerPMILocal" -->
          <l-geo-json
            v-if="layerPMI?.active || layerPMI?.isBorderAlwaysVisible"
            :geojson="vectorLayerGeojsonPMI"
            :options="{ pointToLayer: onPointToLayerPMI, onEachFeature: onEachFeaturePOL }"
          ></l-geo-json>
          <l-geo-json
            v-if="layerPMI?.active || layerPMI?.isBorderAlwaysVisible"
            :geojson="vectorLayerGeojsonRias"
            :options="{ pointToLayer: onPointToLayerRias }"
          ></l-geo-json>
        </l-layer-group>
      </l-map>
    </div>
  </div>
</template>

<script>
  import { onMounted, ref, watch } from 'vue';

  // Leaflet
  import "leaflet/dist/leaflet.css";
  import L from "leaflet";

  import { LMap, LTileLayer, LGeoJson, LLayerGroup, LCircleMarker } from 'vue2-leaflet'; // Import vue leaflet

  // GeoJSON Files (Import before undate from API)
  //  Ojo que las coordendas en un Geojson se indican LonLat
  // eslint-disable-next-line no-unused-vars
  // import geojsonDataPOL from '../geojson/POL.json'; // Import vectorLayerGeojsonPOL GeoJSON (LonLat)
  // import geojsonDataPMI from '../geojson/PMI.json'; // Import vectorLayerGeojsonPMI GeoJSON (LonLat)
  import geojsonDataRias from '../geojson/Rias.json'; // Import vectorLayerGeojsonPMI GeoJSON (LonLat)



  // DateRef + MapCenter + MapZoom + MapRotation
  import useAppConfig from '@/store/useAppConfig'
  import useMap from '@/service/useMap';
  import useUtilsMap from '@/service/useUtilsMap';
  import useForecastLeafLet from '@/service/useForecastLeafLet';
  import useIntoxicationLeafLet from '@/service/useIntoxicationLeafLet';
  // import useForecast from '@/service/useForecast';

  // Timeline control
  import useTimeMap from './useTimeMapParticles';

  // Control Intecmar
  import useControlIntecmar from '../service/useControlIntecmar'


  // Components
  import ForecastCard from '../components/ForecastCardV.vue'
  import ForecastToxinType from '../components/ForecastToxinType.vue';
  import ForecastTable from '@/components/ForecastTable.vue';
  import ForecastHandsonTable from '@/components/ForecastHandsonTable.vue';
  import ForecastHandsonTable2 from '@/components/ForecastHandsonTable2.vue';
  import SidebarPOL from '@/components/SidebarPOL.vue';
  import MapBaseLayer from '@/components/MapBaseLayer.vue';
  import ForecastMobileCalendar from '@/components/ForecastMobileCalendar.vue'
  import ForecastDesktopCalendar from '@/components/ForecastDesktopCalendar.vue';
  import ForecastToxinTypeLeyend from '@/components/ForecastToxinTypeLeyend.vue'
  import ForecastLeyend from '@/components/ForecastLeyend.vue'
  import MenuForecastIntoxication from '@/components/MenuForecastIntoxication.vue'
  import MenuLayer from '@/components/MenuLayer.vue'
  import MenuLayerParamsParticlesDialog from '@/components/MenuLayerParamsParticlesDialog.vue';

  import AppBarMenuUser from '@/components/auth/AppBarMenuUser.vue'
  import ForecastDateRefDialog from '@/components/ForecastDateRefDialog.vue';


  export default {
    name: "map-component",
    components: {
      LMap,
      LTileLayer,
      LGeoJson,
      LLayerGroup,
      LCircleMarker,
      ForecastCard,
      ForecastDateRefDialog,
      ForecastToxinType,
      // eslint-disable-next-line vue/no-unused-components
      ForecastTable,
      // eslint-disable-next-line vue/no-unused-components
      ForecastHandsonTable,
      // eslint-disable-next-line vue/no-unused-components
      ForecastHandsonTable2,
      // eslint-disable-next-line vue/no-unused-components
      SidebarPOL,
      // eslint-disable-next-line vue/no-unused-components
      MapBaseLayer,
      // eslint-disable-next-line vue/no-unused-components
      ForecastMobileCalendar,
      // eslint-disable-next-line vue/no-unused-components
      ForecastDesktopCalendar,
      ForecastToxinTypeLeyend,
      // eslint-disable-next-line vue/no-unused-components
      ForecastLeyend,
      // eslint-disable-next-line vue/no-unused-components
      MenuForecastIntoxication,
      MenuLayer,

      AppBarMenuUser,
      // eslint-disable-next-line vue/no-unused-components
      MenuLayerParamsParticlesDialog,
    },
    props: {
      idRias: {
        type: Array,
        default: () => [2,5]
      }
    },
    setup(props) {

      // Login control -> Se mantiene actualizado por el sync del componente
      //  Se pasa como referencia useTimeMap() y se hace watch
      // const userLocal = ref(null)


      // AppConfig: Capas
      const layerLabelVisible = ref(false)                                      // Only on mobile
      const { layerParticles, layerPOL, layerPMI } = useAppConfig()
      // const { cellsOptions } = useAppConfig()
      // const { celulas } = useAppConfig()

      // Control de Ría seleccionada para visualizar la tabla de tox/dTox desde un polígono
      const { riaId: riaIdSelected } = useAppConfig()

      // TODO - Usar la lista de Vuex
      const idRias = ref(props.idRias)
      watch( () => props.idRias, () => {
        idRias.value = props.idRias
      })

      // Table settigns: ChangeWithMap, ChangeWithFeature
      const { tableSettings } = useAppConfig()


      // AppConfig: Fecha de referencia (Solo se usa para la capa de estado -> Hay que revisar)
      const { dateRef } = useAppConfig()

      /** TimeMap */
      const {
        isIntoxicationTableLoading: loading,
        onAdd : addTimeMapParticles,
        onFeatureSelected,
        setFeatureSelectedByExternal,
        timestamps, animationPosition, animationPositionMin, animationPositionMax,
        showFrameByTimestamp,
        animationTimer,
        // startstop, stop,
        animationVelocity, onChangeAnimationVelocity,
        animationStepFrequency, onChangeAnimationStepFrequency,
        vectorLayerGeojsonPMI,
        vectorLayerGeojsonPOL,
        intoxicationTableList,
        videoParticleLayerVideoPlay,
        videoParticleLayerVideoLoading,
      } = useTimeMap(idRias)

      // Suscribirse al evento => Se ha echo click en una Feature
      watch(() => onFeatureSelected.value, () => {
        // Solo queremos detectar el evento => Se ha cliqueado sobre una Feature
        //   La ponemos a NULL para detectar el watch si se vulve a hacer click en la misma feature
        if (onFeatureSelected.value != null) {
          onFeatureClick(onFeatureSelected.value)
          onFeatureSelected.value = null
        }
      })


      // IntoxicationTable
      const isIntoxicationTableVisible = ref(false)
      // watch(() => intoxicationTableList.value, () => {
      //   debugger
      // }, {deep: true})


      const { getCenterCoordinateOfPoligone, reverseCoord } = useUtilsMap()

      // Productions Zones (Preload)
      // Geojson:
      //  EPSG:4326 equivale a WGS84 => L.CRS.EPSG4326 (Default) (LNG-LAT)
      //  EPSG 3857 google Earth
      // const vectorLayerGeojsonPMI = ref(geojsonDataPMI.features)   // Declarado en useTimeMap
      const vectorLayerGeojsonRias = ref(geojsonDataRias.features)

      // POL = Poligon + Central point for info
      // const vectorLayerGeojsonPOL = ref(geojsonDataPOL.features)   // Declarado en useTimeMap
      const vectorLayerGeojsonPOLCenter = vectorLayerGeojsonPOL.value.map((feature) => {
        const latlng = getCenterCoordinateOfPoligone(feature.geometry.coordinates)
        return {
          type: "Feature",
          properties: feature.properties,
          geometry: { type: "Point", coordinates: latlng }
        }
      })

      // Map (With LeafLet no se puede cambiar mapProjection)
      const map = ref(null)
      const { mapCenter, mapZoom } = useMap()


      // Mapa base
      const { mapBaseLayer } = useAppConfig()
      watch(() => mapBaseLayer.value, () => {
        onBaseLayerChange(mapBaseLayer.value)
      })


      // >>> Base layer selection: Ojo que se puede cambiar en 2 sitios (Gestionar)
      const { tileLayerOptions } = useMap()

      // BaseLayer Selected
      const tileLayerSelected = ref(null) // { id, url, attibutions }
      const onBaseLayerChange = (baseLayer = 'openstreetmap') => {
        tileLayerSelected.value = tileLayerOptions.find(item => item.id === baseLayer)
      }
      onBaseLayerChange(mapBaseLayer.value)
      // <<<


      // >>> Events
      const forecastSelected = ref(null)
      const forecastMinimize = ref(true)
      const showForecast = (forecast) => {
        forecastSelected.value = forecast;

        // Ajustar la tabla de intoxicación a la ría del polígono seleccionado
        if ( isIntoxicationTableVisible.value === true                              // Ya se está visualizando la tabla
          && tableSettings.value?.changeWithFeature                                 // Funcionalidad activada
          && forecast?.forecastItemHeader?.forecastHeader?.pm?.riaId !== undefined  // Feature asociada a una Ria
        ) {
          riaIdSelected.value = forecast.forecastItemHeader?.forecastHeader?.pm?.riaId
        }
      }

      // On click feature
      const onFeatureClick = (feature) => {
        if (feature) {
          showForecast(feature?.properties?.data?.forecast)

          // Indicamos la coordenada donde mostraremos un círculo (Vers tags arriba)
          if (feature?.geometry?.type === 'Polygon' && feature?.geometry?.coordinates) {
            layerSelectedPoint.value = reverseCoord(getCenterCoordinateOfPoligone(feature.geometry.coordinates))
          }

          if (feature?.geometry?.type === 'Point' && feature?.geometry?.coordinates) {
            layerSelectedPoint.value = reverseCoord(feature.geometry.coordinates)
          }

        } else {
          if (forecastMinimize.value) {
            showForecast(null)
          }
        }
      }

      const onMapZoomControl = (zoomDelta) => {
        const newZoom = map.value.mapObject.getZoom() + zoomDelta
        map.value.mapObject.flyTo(mapCenter.value, newZoom, {
          animate: true,
          duration: 1.5
        });
      }

      // PMI Render: Recount
      const templatePMI = '<div class="d-flex algign-center forecast-point" style="background-color: _COLOR_;">_TEXT_</div>'
      const getTemplatePMI = (text, color) => {
        return templatePMI.replace('_TEXT_', text).replace('_COLOR_', color)
      }
      // eslint-disable-next-line no-unused-vars
      const iconPMI = (text, color='rgb(54, 127, 137)') => {
        return L.divIcon({
          html: getTemplatePMI(text, color),
          iconSize: [],
          className: ''
        })
      };

      // POL Render: Recount
      const templatePOL = '<div class="d-flex algign-center forecast-point forecast-icon" style="background-color: _COLOR_;"><i class="v-icon notranslate mdi mdi-navigation theme--light white--text" style="font-size: 14px; transform: rotate(_ANGLE_deg); -webkit-transform:rotate(_ANGLE_deg);"></i>_TEXT_</div>'
      const getTemplatePOL = (text, color, rotation) => {
        if (rotation > 0) {
          return templatePOL.replace('_TEXT_', text).replace('_COLOR_', color).replaceAll('_ANGLE_', rotation)
        } else {
          return templatePMI.replace('_TEXT_', text).replace('_COLOR_', color).replaceAll('_ANGLE_', rotation)
        }
      }
      const iconPOL = (text, color='rgb(54, 127, 137)', rotation = 0) => {
        return L.divIcon({
          html: getTemplatePOL(text, color, rotation),
          iconSize: [],
          className: ''
        })
      };

      const layerToxin = ref('intoxication') // forecast, intoxication

      // Intoxication Functions
      const {
        getIntoxicationData,
        // eslint-disable-next-line no-unused-vars
        getIntoxicationStyle,
      } = useIntoxicationLeafLet()

      // Forecast Functions
      const {
        getRotationAngle,
        getForecastData,
        getForecastStyle,
        getPMIStyle,
        getRiaStyle,
      } = useForecastLeafLet()

      const getGeojsonStylePOL = (feature) => {
        return getForecastStyle(feature?.properties?.data?.forecast, 0) // opacidad: 0 -> No fill
      }

      const onEachFeaturePOL = (feature, layer) => {

        layer.on('click', () => {
          // No es necesario restablecer el estilo
          // layer.setStyle(getForecastStyle(feature?.properties?.data?.forecast));

          // Set forecast selected
          onFeatureClick(feature)
        });

        // Change polygon style on mouseover event
        layer.on('mouseover', () => {
          // Solo modificamos el ancho del polígono (Evitamos calcular otra vez el estilo)
          // layer.setStyle(featureHoverStyle);
          if (typeof layer.setStyle === "function") {
            const currentStyle = {
              fillColor: layer.options.fillColor,
              fillOpacity: layer.options.fillOpacity,
              color: layer.options.color,
              weight: 3, //layer.options.weight,
              opacity: layer.options.opacity,
            }
            layer.setStyle(currentStyle)
          }
        })
        layer.on('mouseout', () => {

          // Solo modificamos el ancho del polígono (Evitamos calcular otra vez el estilo)
          // layer.setStyle(getForecastStyle(event?.target?.feature?.properties?.data?.forecast, 0));
          if (typeof layer.setStyle === "function") {
            const currentStyle = {
              fillColor: layer.options.fillColor,
              fillOpacity: layer.options.fillOpacity,
              color: layer.options.color,
              weight: 1, //layer.options.weight,
              opacity: layer.options.opacity,
            }
            layer.setStyle(currentStyle)
          }
        })
      }


      // GeoJson - TODO - VER layerToxin
      const onPointToLayerPOLCenter = (feature, latlng) => {
        // debugger
        if (layerToxin.value == 'intoxication') {

          const forecastData_ToxA = getIntoxicationData(feature?.properties?.data?.intoxication, dateRef.value)
          if (forecastData_ToxA) {
            const text = forecastData_ToxA

            return L.marker(latlng, { icon: iconPOL(text) })
          }
        } else {
          const forecastData_ToxA_AD = getForecastData(feature?.properties?.data?.forecast, 'ToxA-AD')
          const forecastData_ToxACurrent = getForecastData(feature?.properties?.data?.forecast, 'ToxA-Current')
          if (forecastData_ToxACurrent) {
            const text = forecastData_ToxACurrent?.value || ''
            const color = forecastData_ToxACurrent?.color || 'rgb(54, 127, 137)'
            const rotation = getRotationAngle(forecastData_ToxA_AD)

            return L.marker(latlng, { icon: iconPOL(text, color, rotation) })
          }
        }
      }

      // TODO
      // PMI - Círculo Amarillo
      const onPointToLayerPMI = (feature, latlng) => {
        // debugger
        // const forecastData_ToxA = getIntoxicationData(feature?.properties?.data?.simValue, dateRef.value)
        // const forecastData_ToxA = feature?.properties?.data?.simValue
        // if (forecastData_ToxA) {
        //   const text = forecastData_ToxA

        //   return L.marker(latlng, { icon: iconPOL(text) })
        // }
        return new L.Circle(latlng, 250, getPMIStyle(feature));
      }

      // Rías - Círculo
      const onPointToLayerRias = (feature, latlng) => {
        return new L.Circle(latlng, 400, getRiaStyle(feature));
      }


      // Rias: Que ría es la mas cercana a esta posicion
      const getRiaByLatitude = (lat) => {
        const lista = vectorLayerGeojsonRias.value.map((feature) => ({
          riaId: feature.properties.id,
          longitude: feature.geometry.coordinates[0],
          latitude: feature.geometry.coordinates[1]
        }))

        // Checking... Alguna ría para buscar
        if (lista.length === 0) {
          return null
        }

        // Suponemos que el primer elemento es el más cercano inicialmente
        lista.sort((a, b) => a.latitude - b.latitude)

        // Calculamos la distancia inicial y vamos comparando con el siguiente elemento
        let puntoMasCercano = lista[0]
        let distanciaMinima = Math.abs(lat - lista[0].latitude)

        for (let i = 1; i < lista.length; i++) {
          const distanciaActual = Math.abs(lat - lista[i].latitude)
          if (distanciaActual < distanciaMinima) {
            puntoMasCercano = lista[i]
            distanciaMinima = distanciaActual
          }
        }

        return puntoMasCercano.riaId
      }

      // TODO - Por ahora usamos el centro del mapa (Habría que quitar esto de aquí)
      // Calcula el punto central de lo que se ve de mapa (El problema es saber los pixeles que están ocultando el mapa)
      // eslint-disable-next-line no-unused-vars
      const getCenterOfMapViewed = () => {

        // Obtén las latitudes máxima y mínima de los límites
        const bounds = map.value.mapObject.getBounds();
        const latMax = bounds.getNorth();
        const latMin = bounds.getSouth();

        // Coordenadas de píxeles en el mapa
        const x1 = 100;   // Píxel de inicio (100 para ajustar)
        const x2 = 400;   // Píxel de destino Arousa 500, Vigo 400

        // debugger
        // Convierte las coordenadas de píxeles a coordenadas geográficas (latitud y longitud)
        const coordenadaInicio = map.value.mapObject.unproject([x1, 0], map.value.mapObject.getZoom());
        const coordenadaDestino = map.value.mapObject.unproject([x2, 0], map.value.mapObject.getZoom());

        // Calcula la diferencia en longitud entre las coordenadas
        const diferenciaLongitud = coordenadaDestino.lng - coordenadaInicio.lng;
        // console.log(`Distancia Longitudinal en Grados: ${diferenciaLongitud}`);

        const center3 = (latMax + (latMin+diferenciaLongitud)) / 2

        return { lat: center3, lng: map.value.mapObject.getCenter().lng }
      }

      onMounted( () => {
        // map.value.addEventListener('click', onFeatureClick, false) // Este click se define en onEachFeature
        // map.value.mapObject.on("zoom", onMapZoomUpdate)
        // map.value.on("rotate", onMapRotationUpdate)
        // map.value.on('moveend', () => {
        //   const center = map.value.mapObject.getCenter();
        //   onMapCenterUpdate([center.lat, center.lng])
        // });

        // Particle layer (Rain layers + Velocity layers + Particle layers)
        if (map.value) {
          addTimeMapParticles(map.value.mapObject)

          // Al mover el mapa -> Si estamos visualizando la tabla, automatizamos la selección
          map.value.mapObject.on("moveend", () => {

            // TODO - Usar
            // new L.Circle(map.value.mapObject.getCenter(), 250, { color: 'red' }).addTo(map.value.mapObject)
            // new L.Circle(getCenterOfMapViewed(), 250, { color: 'orange' }).addTo(map.value.mapObject)

            // Ya se está visualizando la tabla y Funcionalidad activada
            if ( isIntoxicationTableVisible.value === true && tableSettings.value?.changeWithMap) {

              // Obtener la latitud del punto central del mapa => La latitud nos marca la cercanía (Las rías se disponen de Norte a Sur)
              const centralLat = map.value.mapObject.getCenter().lat
              // const centralLat = getCenterOfMapViewed().lat
              const riaId = getRiaByLatitude(centralLat)
              if (riaId) {
                // Solo cambiamos de ría si hay datos cargados
                const riaExist = intoxicationTableList.value.find((item) => item.riaId == riaId)
                if (riaExist) {
                  riaIdSelected.value = riaId
                }
              }
            }
          })

        }
      })

      // coordinate: [Lat, Lng]
      const goToSpot = (coordinate = [0, 0]) => {

        map.value.mapObject.flyTo(coordinate, 12.5, {
          animate: true,
          duration: 1.5
        });
      }
      // goMapHome: Pans back to the center of the map
      const goMapHome = () => {
        goToSpot(mapCenter.value)
      }

      // Called from the Home component when a room is searched,
      // this method handles changing the floor level, then pans and zooms to the desired location
      const layerSelectedPoint = ref(null)
      const goProductionZone = (pmName) => {
        const selectedPM = vectorLayerGeojsonPOL.value.find((feature) => (feature.properties.name === pmName))
        if (selectedPM) {
          if (selectedPM?.properties?.data?.forecast) {
            showForecast(selectedPM?.properties?.data?.forecast)
          }

          // Create a LatLng coordinate at the requested polygon (Ojo que los GeoJson usan LngLat)
          var coordinate = reverseCoord(getCenterCoordinateOfPoligone(selectedPM.geometry.coordinates))
          if (coordinate) {
            // Indicamos la coordenada donde mostraremos un círculo (Vers tags arriba)
            layerSelectedPoint.value = coordinate

            // Pan to the location of the coordinatex
            goToSpot(coordinate)
          }
        }
      }


      /* --- Forecast --- */
      // const loading = ref(false)
      // const {
      //   loading,
      //   // eslint-disable-next-line no-unused-vars
      //   loadForecastAll
      // } = useForecast(vectorLayerGeojsonPOL, dateRef, toxinTypeSelected, layerToxin)
      // loadForecastAll([2,4,5,6])

      // Toolbox options
      const isPOLOpen = ref(false)

      // Control Intecmar
      const { controlIntecmarLoading, controlIntecmarLoadingValue, onClickControlIntecmar} = useControlIntecmar()

      return {
        // mapRotation: 12,

        // Mapbox tools (Layer, Map zoom +/-)
        mapBaseLayer,
        onMapZoomControl, // Map zoom +/-

        // AppConfig
        layerLabelVisible,
        layerParticles, layerPOL, layerPMI,   // Layers
        // cellsOptions,
        // celulas,

        // CAPAS: Choose prediction vs Current
        layerToxin,

        // Toolbox options
        isPOLOpen,

        // Control Intecmar
        controlIntecmarLoading,
        controlIntecmarLoadingValue,
        onClickControlIntecmar,

        // Map
        map,
        mapCenter,
        mapZoom,

        // Map external FM's
        goMapHome,
        goProductionZone,

        // Tile
        tileLayerSelected,

        // Geojson (POL)
        vectorLayerGeojsonPOL,
        getGeojsonStylePOL,
        onEachFeaturePOL, // hover, unhover and click events
        //POL Center layer for information
        vectorLayerGeojsonPOLCenter, // Geojson: poligon => Central point
        onPointToLayerPOLCenter, // Logo info in the mi

        // POL -
        setFeatureSelectedByExternal,

        // Geojson (PMI)
        vectorLayerGeojsonPMI,
        onPointToLayerPMI,

        // GeoJson (Rias)
        vectorLayerGeojsonRias,
        onPointToLayerRias,


        // Selected Point
        layerSelectedPoint,

        // Forecast
        loading,
        forecastSelected,
        forecastMinimize,

        // TimeMap
        // isIntoxicationTableLoading,
        // Intoxication
        intoxicationTableList,                  // API results
        isIntoxicationTableVisible,
        riaIdSelected,                      // Ria por defecto en la tabla de intoxicación

        // Slider
        timestamps,
        animationPosition,
        animationPositionMin,
        animationPositionMax,
        showFrameByTimestamp,
        // Start-Stop toggle
        // startstop,
        // stop,
        animationTimer,
        videoParticleLayerVideoPlay,      // isPlay or isPause (Change icons)
        videoParticleLayerVideoLoading,

        // Velocity
        animationVelocity,
        onChangeAnimationVelocity,

        // Step Frequency
        animationStepFrequency,
        onChangeAnimationStepFrequency,
      }
    }
  }
</script>

<style>
  .forecast-point {
    /* height: calc(2em); */
    white-space: nowrap;
    width: inherit;
    border-radius: 1em;
    padding: 0px 4px;

    /* Centramos: Dimension total sin icono: 28x18 */
    transform: translate(-14px,-9px);
  }
  .forecast-icon {
    /* Centramos: Dimension total con icono: 42x18 */
    transform: translate(-21px,-9px);
  }
  /* Ver marker circle de w¡ndy */
  /* .icon-dot {
      height: 6px;
      width: 6px;
      background-color: white;
      opacity: .7;
      border-radius: 30px;
      -webkit-border-radius: 30px;
  }

  .pulsating-icon.repeat {
      animation: pulsate 2s ease-out;
      -webkit-animation: pulsate 2s ease-out;
      animation-iteration-count: infinite;
      -webkit-animation-iteration-count: infinite;
  }

  .pulsating-icon {
      animation: pulsate .5s ease-out;
      -webkit-animation: pulsate .5s ease-out;
      animation-iteration-count: 3;
      -webkit-animation-iteration-count: 3;
      border: 3px solid #fff;
      pointer-events: none;
      height: 28px;
      width: 28px;
      margin-left: -9px;
      margin-top: -9px;
      border-radius: 30px;
      -webkit-border-radius: 30px;
      opacity: 0;
  } */

</style>
<style lang="css" scoped>
  /* Full screen */
  .map_container_wrapper {
    /* float: left; */
    /* position: relative; */
    height: 100vh;
  }
  .none_sidebar_after {
      width: 100%;
  }
  .one_sidebar_after {
      width: calc(100% - 280px);
  }

  /* Mapa */
  #map {
    z-index: 0;
    position: relative;
    width: 100%;
    height: 100%;
  }
  .map_controls_desktop_calendar {
    z-index: 3;
    position: absolute;
    bottom:120px;
  }

  .map_controls_mobile_calendar {
    background: none;
    z-index: 3; /* Por encima del resto porque se hace grande al hacer scroll */

    /* Apilado encima de la 2º leyenda */
    position: absolute;

    /* Bottom: full width */ /* map_controls_toxin_leyend = 18px */
    bottom: 19px;
    width: 100%;
  }
  .map_controls_mobile_calendar_set_on_top {
    z-index: 2; /* Por debajo de los botones que esten arriba */

    /* Top */
    /* width: calc(100% - 200px); */
    /* margin-left: 100px; */
    bottom: unset;
    top: 0;
    width: 100%;
  }

  .map_controls_cells_leyend {
    background: none;
    z-index: 2;

    position: absolute;
    bottom: 40px;
    /* bottom: 54px; */
  }
  .map_controls_toxin_leyend {
    background: none;
    z-index: 3;

    position: absolute;
    bottom: 0;
    width: 100%;
  }

  .map_controls_top {
    position: absolute;
    top: 4px;
    right: 10px;
    z-index: 3;
  }
  .map_controls_top_user {
    position: absolute;
    top: 4px;
    right: 60px;
    z-index: 3;
  }
  .map_controls {
    z-index: 3;
    position: absolute;
    top: 60px;
    left: 10px;
  }
  .map_controls_layers {
    z-index: 3;
    position: absolute;
    top: 170px;
    right: 8px; /* -2px por la sonbra */
  }

  /* TODO - Crear un componente para las capas: Sonbra para los botones de las capas */
  .map_controls_layers, .custom_background {
    background-color: #ccc;
    /* color: #333; */

    /* Ancho de los botones 32px */
    width: 36px;
    padding-right: 2px;
    border-radius: 1.2em;
  }

  /* Intoxication Table */
  .overlay-intoxication-table {
    background: none;
    z-index: 3;

     /*Para que se vea la escala*/
    position: absolute;
    bottom: 18px;
    /* bottom: 60px; */
    width: 100%;

    /* Nunca sobrepasar y si no es suficiente, scroll */
    /* Los 20 de la escala de valores y otros 20 para los botones */
    max-height: calc(100% - 40px);
    /* overflow-y: scroll; */ /* Lo desactivamos porque queremos sacar el boton de CLOSE fuera del DIV */
    /* overflow-x: scroll; */
  }

  /* Cuando se visualiza el Forecast Card, dejamos el espacio de 420px a la izquierda */
  .overlay-intoxication-table-collapsed-x {
    margin-left: 420px;
    max-width: calc(100% - 420px);
  }


  /* Forecast */
  .overlay-forecast-card{
    z-index: 6;

    position: absolute;
    bottom: 20px; /* Para que se vea la escala */

    width: 100%;

    /* Nunca sobrepasar y si no es suficiente, scroll */
    max-width: 420px;
    max-height: calc(100% - 20px); /* Los 20 de la escala */
    overflow-y: scroll;
  }

  .overlay-forecast-card-max {
    height: 100%;
    background: white;
  }
</style>


<!-- Global scoped: Se insertan en el mapa -->
<style lang="css">

  .arrow {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform: rotate(90deg);
    cursor: pointer;
  }

  .arrow span {
    display: block;
    width: 1.5vw;
    height: 1.5vw;
    border-bottom: 5px solid white;
    border-right: 5px solid white;
    transform: rotate(45deg);
    margin: -10px;
    animation: animate 2s infinite;
  }

  .arrow span:nth-child(2) {
    animation-delay: -0.2s;
  }

  .arrow span:nth-child(3) {
    animation-delay: -0.4s;
  }

  @keyframes animate {
    0% {
      opacity: 0;
      transform: rotate(45deg) translate(-20px, -20px);
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: rotate(45deg) translate(20px, 20px);
    }
  }
</style>

