import { ref, watch } from "vue"
import { format, parseISO } from 'date-fns'

// import axios from 'axios'
import axios from '@/plugins/axiosConfig';


// Leaflet
import L from "leaflet";
// import "../plugins/leaflet/Velocity/leaflet-velocity.js"
// import "leaflet-particles/src/js/L.ParticlesLayer" // Like windy particles
import "../plugins/leaflet/Particles/L.ParticlesLayer.js"

// Import
// import geojsonDataTest from '../geojson/Velocity/test.json'; // Import vectorLayerGeojsonPMI GeoJSON (LonLat)
// import geojsonDataWindGlobal from '../geojson/Velocity/wind-global.json'; // Import vectorLayerGeojsonPMI GeoJSON (LonLat)

// GeoJSON Files (Import before undate from API)
//  Ojo que las coordendas en un Geojson se indican LonLat
import geojsonDataPOL from '../geojson/POL.json'; // Import vectorLayerGeojsonPOL GeoJSON (LonLat)
import geojsonDataPMI from '../geojson/PMI.json'; // Import vectorLayerGeojsonPMI GeoJSON (LonLat)

// Utilidades de mapas
import useUtilsMap from '@/service/useUtilsMap';
import useForecastLeafLet from '@/service/useForecastLeafLet';
// import useUtils from '@/service/useUtils';
import useAppConfig from "@/store/useAppConfig.js";
import useDecorateToxA from "@/service/useDecorateToxA";

// const serverParticles = 'http://127.0.0.1:5000'
// const serverParticles = 'http://192.168.31.17:5000'
// const serverParticles = 'http://5.224.60.224:5000/:5000'
// const serverParticles = 'http://localhost:8000/reader'
// const API_DT = process.env.VUE_APP_API_DT
const serverParticles = process.env.VUE_APP_API_DT + '/reader'
const serverBackend = process.env.VUE_APP_API_BASE

// toxinType
export default function useTimeMap(idRias) {


  // Options
  // eslint-disable-next-line no-unused-vars
  const opacityDefault = .5
  const animationIntervalDefault = 500  // 0.5 seconds by interval
  // const animationStep = 3               // 1 = 1 hora

  // Map control
  const map = ref(null)
  const maxParticles = 10000;


  // By Layers
  // const radarTimestamps = ref([])
  // const radarLayers = ref([])     // Capas indexadas por el timestamp

  // Only one layers with data change
  // const velocityTimestamps = ref([])
  // const velocityDataList = ref([])
  // const velocityLayer = ref(null)

  // Particle layer
  const particleTimestamps = ref([])
  const paticleDataList = ref([])
  const particleLayer = ref(null)

  // Particle video layer
  const videoParticleTimestamps = ref([])
  const videoParticleLayer = ref(null)

  // Intoxication POL layer (GeoJson)
  const toxinsPOLTimestamps = ref([])
  const toxinsPOLDataList = ref([]) // [ 1234567890: [{ pmId: 1, pmValue: 100 }, { pmId: 2, pmValue: 200 }, ...] ]
  const toxinsPOLLayers = ref([])

  // Cells PMI layer (GeoJson)
  const cellsPMITimestamps = ref([])
  const cellsPMIDataList = ref([]) // [ 1234567890: [{ pmId: 1, pmValue: 100 }, { pmId: 2, pmValue: 200 }, ...] ]
  const cellsPMILayers = ref([])

  // Current and next index (Global)
  const timestamps = ref([])      // @type { number[] }

  // const positionSlider = ref({min: 0, max: 10, value: 0})
  const animationPosition = ref(0)
  const animationPositionMin = ref(0)
  const animationPositionMax = ref(7)
  const animationTimer = ref(false)

  // Globales
  const { dateRef, toxinType, user } = useAppConfig()

  // Actualizar la predicción al cambiar el contexto
  watch([
    () => dateRef.value,
    () => toxinType.value,
    () => user.value
  ], () => {
    console.log('watch() => dateRef|toxinType|user')
    unload()
    initUseTimeMap()
  })

  // const idRias = ref([])
  watch( () => idRias.value, () => {
    reloadIntoxication()
  })

  // Volver a cargar la tabla de intoxicación
  const reloadIntoxication = () => {
    // Células por PMI
    unloadCellsPMI()
    cellsPMITimestamps.value = []
    cellsPMIDataList.value = []

    // Toxinas por POL
    unloadToxinsPOL()
    toxinsPOLTimestamps.value = []
    toxinsPOLDataList.value = []

    // Se cargan los datos de las 2 capas
    loadIntoxication(idRias.value)
  }


  // Opciones de célula: [ 'All', 'Acuminata', 'Acuta', 'Caudata'] + Aplicar TF (Toxicidad por Fitoplancton)
  const { cellsOptions, cellsOptionsFeedTypeAP } = useAppConfig()
  watch( () => cellsOptionsFeedTypeAP.value, () => {

    unloadParticlesLayer()
    unloadVideoParticlesLayer()

    // Particles: Video-Simulation
    videoParticleTimestamps.value = []
    videoParticleLayer.value = null

    if (layerParticles.value.render === 'video') {
      loadVideoParticles()
    }

  })

  // Capa de particulas
  const { layerParticles } = useAppConfig()
  watch( () => layerParticles.value, () => {
    if (layerParticles.value.active) {

      // if (layerParticles.value.particles) {
      if (layerParticles.value.render === 'particles') {

        // No cargar cuando ya estén cargados de antes
        if (particleTimestamps.value && particleTimestamps.value.length > 0) {

          unloadParticlesLayer()                      // Esto creo que no hace falta
          showFrame(animationPosition.value)          // Visualizar y posicionar
        } else {
          loadParticles()
        }
      } else {
        unloadParticlesLayer()
      }

      // if (layerParticles.value.video) {
      if (layerParticles.value.render === 'video') {
        // No cargar cuando ya estén cargados de antes
        if (videoParticleTimestamps.value && videoParticleTimestamps.value.length > 0) {

          unloadVideoParticlesLayer()                 // Esto creo que no hace falta
          videoParticleLayerVideoPlay.value = false   // Paused on init (!== undefined)
          showFrame(animationPosition.value)          // Visualizar y posicionar
        } else {
          loadVideoParticles()
        }
      } else {
        unloadVideoParticlesLayer()
      }

    } else {
      unloadParticlesLayer()
      unloadVideoParticlesLayer()
    }
  }, { deep: true })


  // TODO - PROBAR BIEN
  // Settings
  const { settings } = useAppConfig()
  watch( () => settings.value, () => {
    reloadIntoxication()
  }, { deep: true })

  // Para detectar cambios en la estrategia
  // const { settingsSimulationStrategyKey } = useAppConfig()
  // watch( () => settingsSimulationStrategyKey.value, () => {
  //   reloadIntoxication()
  //   reloadVideoParticleLayer()
  // })

  // Capa de Toxinas por POL
  const { layerPOL } = useAppConfig()
  watch( () => layerPOL.value, () => {
    if (layerPOL.value.active && layerPOL.value.render) {

      // No cargar cuando ya estén cargados de antes
      if (toxinsPOLTimestamps.value && toxinsPOLTimestamps.value.length > 0) {
        unloadToxinsPOL()
        showFrame(animationPosition.value)
      } else {
        if (!isIntoxicationTableLoading.value) {
          loadIntoxication(idRias.value)
        }
      }
    } else {
      unloadToxinsPOL()
    }
  }, { deep: true })


  // Capa de Células por PMI
  const { layerPMI } = useAppConfig()
  watch( () => layerPMI.value, () => {
    if (layerPMI.value.active && layerPMI.value.render) {

      // No cargar cuando ya estén cargados de antes
      if (toxinsPOLTimestamps.value && toxinsPOLTimestamps.value.length > 0) {
        unloadCellsPMI()
        showFrame(animationPosition.value)
      } else {
        if (!isIntoxicationTableLoading.value) {
          loadIntoxication(idRias.value)
        }
      }
    } else {
      unloadCellsPMI()
    }
  }, { deep: true })


  // Ojo que no puede ser más que 2 segundos sino no funciona.
  // Ver onHandleScroll de ForecastMobileCalendar -> Usa un time setTimeout de 2 segundos
  // Intervalo de tiempo en milisegundos
  const animationIntervalTime = ref(500);   // 500 => 0.5 seconds by interval
  const onChangeAnimationIntervalTime = () => {
    switch(animationStepFrequency.value) {
    case 1:
      animationIntervalTime.value = animationIntervalDefault;       // 0.5 seg
      break;
    case 6:
      animationIntervalTime.value = animationIntervalDefault * 3;   // 1.0 seg
      break;
    case 12:
      animationIntervalTime.value = animationIntervalDefault * 3;   // 1.5 seg
      break;
    case 18:
      animationIntervalTime.value = animationIntervalDefault * 4;   // 2.0 seg
      break;
    case 24:
      animationIntervalTime.value = animationIntervalDefault * 4;   // 2.0 seg
      break;
    }
  }

  // Control de la velocidad (Al cambiar se actualiza sola porque está dentro de la FM play)
  const animationVelocity = ref(1)
  const onChangeAnimationVelocity = () => {
    if (animationVelocity.value >= 2) {   // antes 20
      animationVelocity.value = 0.5
    } else {
      animationVelocity.value = animationVelocity.value + 0.5
    }

    // Set Video params
    if (videoParticleLayerVideo.value) {
      videoParticleLayerVideo.value.playbackRate = animationVelocity.value
    }
  }

  // Control de la Frecuencia (Al cambiar se actualiza sola porque está dentro de la FM play)
  const animationStepFrequency = ref(6)
  const onChangeAnimationStepFrequency = () => {
    if (animationStepFrequency.value >= 24) {
      animationStepFrequency.value = 1
    } else {
      if (animationStepFrequency.value == 1) {
        animationStepFrequency.value = 6
      } else {
        animationStepFrequency.value = animationStepFrequency.value + 6
      }
    }

    // Cambiamos el intervalo
    onChangeAnimationIntervalTime()
  }

  // START HERE !!!!
  const initUseTimeMap = () => {
    console.log('initUseTimeMap()')

    // Reset all previous load
    reset()

    // Initial load: Particles (TimeStamps)
    // if (layerParticles.value.active && layerParticles.value.particles) {
    if (layerParticles.value.active && layerParticles.value.render === 'particles') {
      loadParticles()
    }
    // if (layerParticles.value.active && layerParticles.value.video) {
    if (layerParticles.value.active && layerParticles.value.render === 'video') {
      loadVideoParticles()
    }

    // Initial Load: Intoxication (TimeStamps + data) (Toxinas y Células)
    if (layerPOL.value.active || layerPMI.value.active) {
      loadIntoxication(idRias.value) // [5] 2, 4, 5, 6
    }
  }


  // Nivel de zoom
  //  10 - 4 Rías
  //  11 - 2 Rías
  //  12 - 1 Ría
  const HIDDEN_LIMIT_ZOOM = 11

  const onMapZoomEnd = () => {

    // const zoom = map.value.getZoom()
    // console.log('ZOOMEND', zoom, event)

    // Células por PMI
    if (layerPMI.value.isRenderHiddenOnZoom) {
      if (map.value.getZoom() < HIDDEN_LIMIT_ZOOM) {
        unloadCellsPMI()
      } else {
        reloadCellsPMILayer()
      }
    }

    // Toxinas por POL
    if (layerPOL.value.isRenderHiddenOnZoom) {
      if (map.value.getZoom() < HIDDEN_LIMIT_ZOOM) {
        unloadToxinsPOL()
      } else {
        reloadIntoxicationLayer()
      }
    }
  }

  // eslint-disable-next-line no-unused-vars
  const onAdd = (_map) => {
    map.value = _map
    //map.value.on("zoom", onMapZoomUpdate) // Activamos el evento Zoom
    map.value.on("zoomend", onMapZoomEnd)   // Activamos el evento ZoomEnd

    // >>> No hacemos primera carga porque el watch([dateRef,toxinType,user], () => {}) se ejecutará inmediatamente
    reset()            // Reset all
    initUseTimeMap()   // Initial load
  }

  // Reset all changeable vars: Variables que dependen del dateRef
  //  Exclude: Map and Type of Layers
  const reset = () => {

    // Particles
    particleTimestamps.value = []
    paticleDataList.value = []
    particleLayer.value = null

    // Particles: Video-Simulation
    videoParticleTimestamps.value = []
    videoParticleLayer.value = null

    // Toxinas
    toxinsPOLTimestamps.value = []
    toxinsPOLDataList.value = []
    toxinsPOLLayers.value = []

    // Células
    cellsPMITimestamps.value = []
    cellsPMIDataList.value = []
    cellsPMILayers.value = []

    // Control time stamp
    timestamps.value = []

    // Animation
    animationPosition.value = 0;
    animationPositionMin.value = 0;
    animationPositionMax.value = 0;
    animationTimer.value = false;
  }


  /* *** Get Times: Duration  *** */

  // URL Example= /times?dateRef=20230321&toxinType=DSP
  //  queryParams = { dateRef: '20230321', toxinType: 'DSP'}
  //  const url = 'data/particles/particles_timestamp.json'
  const fetchTimeStampsParticles = (queryParams) => {
    const url = `${serverParticles}/times`
    return new Promise((resolve, reject) => {
      axios
        .get( url, { params: queryParams })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  const fetchIntoxication = (queryParams) => {
    // http://localhost:8050/backend/api/forecast?riaId=5&dateRef=1-08-2022&toxinType=ASP
    // const url = `${serverBackend}/backend/api/intoxication`
    const url = `${serverBackend}/backend/api/intoxication`
    return new Promise((resolve, reject) => {
      axios
        .get( url, { params: queryParams })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }


  /** Particles */
  // Particle(API): {lat, lon, depth, density}
  // Object: { id, lon, lat, depth, age } : p[2] => density
  const mapParticle = (particleAPI, index) => {
    // return [ index, p[1], p[0], 1, 1.0 ]
    return [ index, particleAPI[1], particleAPI[0], particleAPI[2], particleAPI[3] ]
  }

  // Si no encontramos nada devolvemos [] para que no afecte al desarrollo del video
  // format(fromUnixTime(timestamp), "EEE d - H:mm", { locale: es }) : ''
  const fetchParticles = (ts) => {
    const queryParams = {
      dateRef: dateRef.value.replaceAll('-', ''),
      toxinType: toxinType.value || 'DSP',
      simulationStrategyKey: settings.value?.simulationStrategyKey || 'A',
      numberOfParticles: 10000
    }

    // Filename: particles_2017-07-10T00
    // const stringDate = format(fromUnixTime(ts), "yyyy-MM-dd'T'HH")
    // const url = `/data/particles/particles_${stringDate}.json`
    // http://127.0.0.1:5000/particles/1499644800?dataRef=20230320&toxinType=DSP
    // /reader/particles/daily?dateRef=20230425&toxinType=DSP
    // const url = `${serverParticles}/particles`
    const url = `${serverParticles}/particles/${ts}`
    return new Promise((resolve) => {
      axios
        .get( url, { params: queryParams })
        .then(response => {
          if (response.data) {
            const particles = response.data.slice(0, maxParticles).map((p, index) => {
              return mapParticle(p, index)
            })
            return resolve(particles)
          }
          return resolve([])
        })
        // .catch(error => reject(error))
        .catch(error => {
          console.log(error)
          resolve([])
        })
    })
  }
  const fetchParticlesRemote = async (ts, position = -1) => {
    const particles = await fetchParticles(ts)
    if (particles) {
      addLayerParticleData(ts, particles, position)
    }
  }

  /* *** TIMESTAMPS *** */

  // Merge... Permite añadir nuevos periodos dinámicamente
  // eslint-disable-next-line no-unused-vars
  const mergeTimeStamps = (newTimeStamps, startAtPosition = 0) => {

    // Actualizar el animationPosition a la nueva posicion despues de incorporar nuevos timeStamps
    // Buscamos el TS según el animationPosition Actual
    let currentTS = -1
    if (animationPosition.value > 0) {
      currentTS = timestamps.value[animationPosition.value]
    }

    // Añadir las que no existan y ordenar
    newTimeStamps.map(ts1 => {
      const index = timestamps.value.findIndex(ts2 => ts2 === ts1)
      if (index === -1) {
        timestamps.value.push(ts1)
        timestamps.value.sort()     // Ordenamos para mantener el orden temporal
      }
    })

    // Establecemos el horizonte
    animationPositionMin.value = 0
    animationPositionMax.value = timestamps.value.length - 1

    // Indicamos
    if (currentTS != -1) {
      animationPosition.value = timestamps.value.findIndex(ts => ts >= currentTS);
    }
  }

  // Transforma una lista de timestams (Seconds) Diario -> Horario
  const transformTSDaily2Hourly = (timestampsS) => {

    // Checking... Se ha indicado una lista válida
    if (timestampsS === undefined || timestampsS.length === 0) {
      return []
    }
    const iLast = timestampsS.length - 1

    // Obtener la fecha inicial y final en segundos
    const tsInicial = timestampsS[0]
    const tsFinal = timestampsS[iLast]

    // Calcular el número de horas entre las dos fechas
    const diferencia = tsFinal - tsInicial
    // let horas = Math.round(diferencia / (60 * 60))
    let horas = Math.ceil(diferencia / (60 * 60))           // Redondeamos por arriba por si el video no es justo
    if (horas === 0) { horas = 1 }

    // Enumerar las horas (Usamos setHours para evitar fallos de cambio de hora)
    // var tsList = []
    // for (let i = 0; i < horas; i++) {
    //   const ts = tsInicial + (i * 60 * 60)
    //   tsList.push(ts)
    // }

    // Usamos setHours para evitar fallos con el cambio de hora
    const fecha = new Date(tsInicial * 1000)
    const tsList = []
    for (let i = 0; i < horas; i++) {

      // Sumamos 1 hora usando la funcion setHours para evitar fallos con el cambio de hora
      // const fecha = new Date(fechaInicial + (i * 60 * 60))
      if (i > 0) {
        fecha.setHours(fecha.getHours() + 1)
      }
      tsList.push(fecha.getTime() / 1000) // Add timestamp in seconds
    }

    return tsList
  }
  /** */

  /* *** LOADERS *** */
  const loadParticles = () => {
    // No cargar cuando ya estén cargados de antes
    if (particleTimestamps.value && particleTimestamps.value.length > 0) {
      showFrame(animationPosition.value)
      return
    }

    fetchTimeStampsParticles(
      {
        dateRef: dateRef.value.replaceAll('-', ''),
        toxinType: toxinType.value || 'DSP',
      })
      .then(response => {
        if (response.data) {
          particleTimestamps.value = response.data
          mergeTimeStamps(particleTimestamps.value)

          // Al finalizar actualizamos
          reloadParticleLayer()
        }
      })
  }

  const loadVideoParticles = () => {
    // No cargar cuando ya estén cargados de antes
    if (videoParticleTimestamps.value && videoParticleTimestamps.value.length > 0) {
      showFrame(animationPosition.value)
      return
    }

    // FetchVideo (Ojo que no hay fetch previo, al cargar la capa se lanza el evento onLoad del video y se cubren los TS)
    // reloadVideoParticleLayer()
    addLayerVideoParticle()
  }

  /** Intoxication */


  // rowDataItem con Intoxication data
  // "2022-07-18": {
  //   "ef:feedDate": "2022-07-18",
  //   "ef:pmId": 1,
  //   "ef-feed:toxin-type": "DSP",
  //   "ef-feed:toxa": "43",
  //   "ef-feed:analisis": null,
  //   "ef-feed:analisis-progress": null,
  //   "ef-feed:plan": null,
  //   "ef-feed:estado": null
  // },
  const readIntoxicationData = (rowDataItem, dateRef, bucketName = 'ef-feed:toxa') => {
    if (rowDataItem == undefined) return

    const value = rowDataItem[dateRef]?.[bucketName]
    return value
  }
  const readIntoxicationForecastData = (intoxication) => {
    if (intoxication == undefined) return

    const forecast = {
      forecastItemHeader: intoxication['ef:forecast']?.['forecastItemHeader'],
      forecastItemAfinidad: intoxication['ef:forecast']?.['forecastItemAfinidad'],
      forecastItemAD: intoxication['ef:forecast']?.['forecastItemAD'],
      forecastItemPG: intoxication['ef:forecast']?.['forecastItemPG'],
      forecastItemCells: intoxication['ef:forecast']?.['forecastItemCells'],
    }
    return forecast
  }

  // Sumas
  function sum(lista) {
    var total = undefined
    lista.forEach((item) => {
      const number = Number(item)
      if (!isNaN(number)) {
        if (total === undefined) {
          total = 0
        }
        total = total + number
      }
    })
    return total
  }

  // Forecast Functions
  const {
    getRotationAngle,
    getForecastData,
    // getForecastStyle,
    // getPMIStyle,
  } = useForecastLeafLet()


  const datePattern = /^\d{4}-\d{2}-\d{2}$/; // Expresión regular para validar el formato AAAA-MM-DD

  // Lista de equivalencias entre fitos: SIM vs IP
  const FITO_EQUIVALENT = []
  FITO_EQUIVALENT['ef-feed:fito-acuminata-sim'] = 'ef-feed:fito-acuminata-ip'
  FITO_EQUIVALENT['ef-feed:fito-acuta-sim'] = 'ef-feed:fito-acuta-ip'
  FITO_EQUIVALENT['ef-feed:fito-caudata-sim'] = 'ef-feed:fito-caudata-ip'
  FITO_EQUIVALENT['ef-feed:fito-pseudo-nitz-sim'] = 'ef-feed:fito-pseudo-nitz-ip'
  FITO_EQUIVALENT['ef-feed:fito-alexandrium-sim'] = 'ef-feed:fito-alexandrium-ip'
  FITO_EQUIVALENT['ef-feed:fito-gym-catenatum-sim'] = 'ef-feed:fito-catenatum-ip'


  // Opciones de célula: [ 'All', 'Acuminata', 'Acuta', 'Caudata'] + Aplicar TF (Toxicidad por Fitoplancton)
  // const { cellsOptions } = useAppConfig()

  // InfoTable: Información de la tabla de intoxicación por ría
  const { infoTable } = useAppConfig()

  const updateInfoTable = (riaId, intoxicationTable) => {

    // const ria = response?.data?.intoxicationTable?.filters.ria
    const newRecord = {
      riaId: riaId,
      ria: intoxicationTable?.filters?.ria,
      riaSort: intoxicationTable?.filters?.ria?.sort || 0,  // Se usa como orden en la tabla
      // data: intoxicationTable,
      info: intoxicationTable?.info,
      filters: intoxicationTable?.filters
    }

    // Vamos añadiendo a la lista y ordenamos
    const index = infoTable.value.findIndex( (item) => item.riaId === riaId)
    if (index == -1) {
      infoTable.value.push(newRecord)
    } else {
      infoTable.value[index] = newRecord
    }
    infoTable.value.sort((a, b) => a.riaSort - b.riaSort)
  }

  // Tabla de intoxicación por ría => [ 5: { colFormat: [], colHeaderStyle: [], rowData: [] }, 6: ... ]
  const intoxicationTableList = ref([])
  const loadIntoxicationByRia = (riaId) => {

    // intoxicationTableList.value[riaId] = {}
    // intoxicationTableList.value = null
    return fetchIntoxication(
      {
        riaId: riaId,
        dateRef: dateRef.value,
        toxinType: toxinType.value || 'DSP',
        simulationStrategyKey: settings.value?.simulationStrategyKey || 'A',  // A,B
        period: settings.value?.periodKey || 'P15D',                          // P15D, ...
      })
      .then(response => {

        // Nuevos TimeStamps
        const newTimeStamps = []

        // TODO - Encapsular esto en una funcion para poder ejecutar sin que se vuelvan a cargar todos los datos
        if (response?.data?.intoxicationTable) {

          // Actualizamos VUEX infoTable
          updateInfoTable(riaId, response?.data?.intoxicationTable)

          // Indicar opciones
          cellsOptions.value.feedTypeAPOptions = response?.data?.intoxicationTable?.filters?.feedTypeAPs

          // Compiamos la tabla de intoxicación
          // intoxicationTableList.value[riaId] = response?.data?.intoxicationTable
          // intoxicationTableList.value = response?.data?.intoxicationTable

          // const ria = response?.data?.intoxicationTable?.filters.ria
          const newRecord = {
            riaId: riaId,
            ria: response?.data?.intoxicationTable?.filters?.ria,
            riaSort: response?.data?.intoxicationTable?.filters?.ria?.sort || 0,  // Se usa como orden en la tabla
            data: response?.data?.intoxicationTable,
            info: response?.data?.intoxicationTable?.info
          }

          // Vamos añadiendo a la lista y ordenamos
          const index = intoxicationTableList.value.findIndex( (item) => item.riaId === riaId)
          if (index == -1) {
            intoxicationTableList.value.push(newRecord)
          } else {
            intoxicationTableList.value[index] = newRecord
          }
          intoxicationTableList.value.sort((a, b) => a.riaSort - b.riaSort)


          // Extract: Timestamp and data
          response?.data?.intoxicationTable.colFormat.map((colFormat) => {
            const dateString = colFormat?.data
            if (datePattern.test(dateString)) {

              const ts = parseISO(format(parseISO(dateString), 'yyyy-MM-dd')).getTime() / 1000
              const dataValues = []

              response?.data?.intoxicationTable.rowData.map( (rowDataItem) => {
                const pmId = rowDataItem['ef:pmKey']
                const pmName = rowDataItem['ef:pmName']?.['ef:pmName']
                // Add to the list
                dataValues.push(
                  {
                    pmId: pmId,
                    pmName: pmName,
                    pmValue: readIntoxicationData(rowDataItem, dateString, 'ef-feed:toxa'),
                    forecast: readIntoxicationForecastData(rowDataItem)
                  })
              })

              // Set TS + Data
              newTimeStamps.push(ts)

              // Añadir los datos (Estos siempre son feeds nuevos)
              if (!toxinsPOLDataList.value[ts]) {
                toxinsPOLDataList.value[ts] = dataValues
              } else {
                toxinsPOLDataList.value[ts] = toxinsPOLDataList.value[ts].concat(dataValues)
              }
            }
          })

          // Calcular Feaures a partir de la capa base y la tabla de intoxicacion
          if (toxinsPOLDataList.value.length > 0) {
            overWriteIntoxicationData(toxinsPOLDataList.value[toxinsPOLDataList.value.length-1])
          }

          // TODO - Encapsular esto en una funcion para poder ejecutar sin que se vuelvan a cargar todos los datos
          // NEW - TABLA DE CÉLULAS POR PMI
          // Extract: Timestamp and data
          response?.data?.intoxicationTable?.cellsTable.colFormat.map((colFormat) => {
            const dateString = colFormat?.data
            if (datePattern.test(dateString)) {

              const ts = parseISO(format(parseISO(dateString), 'yyyy-MM-dd')).getTime() / 1000
              const dataValues = []

              response?.data?.intoxicationTable?.cellsTable.rowData.map( (rowDataItem) => {
                const pmId = rowDataItem['ef:pmKey']
                // const pmName = rowDataItem['ef:pmName']?.['ef:pmName']
                const pmKey = rowDataItem['ef:pmKey']

                var simValue = undefined
                var ipValue = undefined

                // Extraer lista de fitos
                const fitoList = []
                response?.data?.intoxicationTable?.filters?.feedTypeAPs.forEach((feedTypeAP) => {

                  // Feeds equivalentes SIM vs IP
                  const feedTypeSim = feedTypeAP.feedType.feedTypeKey
                  const feedTypeIp  = FITO_EQUIVALENT[feedTypeSim]

                  // Extraer valores
                  var simValue = readIntoxicationData(rowDataItem, dateString, feedTypeSim)
                  var ipValue = readIntoxicationData(rowDataItem, dateString, feedTypeIp)

                  // Aplicamos filtro y agrupamos en un único fitoplancton
                  if (cellsOptions.value.isTF && typeof feedTypeAP.asimilacion === 'number' && !isNaN(feedTypeAP.asimilacion)) {
                    if (simValue) {
                      simValue = Math.round(Number(simValue) * feedTypeAP.asimilacion / 100)
                    }
                    if (ipValue) {
                      ipValue = Math.round(Number(ipValue) * feedTypeAP.asimilacion / 100)
                    }
                  }

                  // Guardamos en la lista
                  fitoList.push({
                    'feedTypeKey': feedTypeSim,
                    'sim': simValue,
                    'ip': ipValue,
                  })
                })

                // Solo agrupamos si se seleccionan todos
                if (cellsOptions.value.feedTypeAP === 'all') {

                  const simList = fitoList.map(fito => fito.sim)
                  const ipList = fitoList.map(fito => fito.ip)

                  // Aplicamos la suma (Solo sumamos los valores correctos)
                  simValue = sum(simList)
                  ipValue = sum(ipList)
                } else {
                  fitoList.forEach((fito) => {
                    if ( cellsOptions.value.feedTypeAP === fito.feedTypeKey || cellsOptions.value.feedTypeAP === 'all') {
                      simValue = fito.sim
                      ipValue = fito.ip
                    }
                  })
                }

                // Add to the list
                dataValues.push(
                  {
                    pmId: pmId,
                    pmName: pmKey, // TODO - HAY QUE REVISAR !!
                    simValue: simValue,
                    ipValue: ipValue,
                  })
              })

              // Set TS + Data
              newTimeStamps.push(ts)

              // Añadir los datos (Estos siempre son feeds nuevos)
              if (!cellsPMIDataList.value[ts]) {
                cellsPMIDataList.value[ts] = dataValues
              } else {
                cellsPMIDataList.value[ts] = cellsPMIDataList.value[ts].concat(dataValues)
              }
            }
          })
        }

        // { 'pmId', 'pmName', ipValue, simValue}
        // const cellsList = cellsPMIDataList.value[newTimeStamps[0]]
        // overWriteCellsData(cellsList)
        /** <<< Celldatalist */

        // Actualizamos timestamps (Se añaden los nuevos)
        newTimeStamps.map(ts1 => {
          const index = toxinsPOLTimestamps.value.findIndex(ts2 => ts2 === ts1)
          if (index === -1) {
            toxinsPOLTimestamps.value.push(ts1)
            toxinsPOLTimestamps.value.sort()     // Ordenamos para mantener el orden temporal
          }
        })

        // Actualizamos timestamps (Se añaden los nuevos)
        mergeTimeStamps(transformTSDaily2Hourly(toxinsPOLTimestamps.value))
      })
      // .finally(() => isIntoxicationTableLoading.value = false)
  }

  // Leer Toxinas y Células de API Backend prediccion.empromar.com
  const isIntoxicationTableLoading = ref(false)

  // const loadIntoxication = (riaIds = [2, 4]) => {
  const loadIntoxication = (riaIds = [2, 4, 5, 6]) => {

    // console.log(riaIds)
    // No cargar cuando ya estén cargados de antes
    if (toxinsPOLTimestamps.value && toxinsPOLTimestamps.value.length > 0) {
      showFrame(animationPosition.value)
      return
    }

    // Iniciamos
    isIntoxicationTableLoading.value = true
    intoxicationTableList.value = []          // Reiniciamos y Forzamos cambios en watch

    // Lanzamos las promesas en paralelo
    const promises = riaIds.map(riaId => loadIntoxicationByRia(riaId))
    Promise.all(promises)
      // .finally(() => {
      .then(() => {
        isIntoxicationTableLoading.value = false

        // Al finalizar actualizamos
        reloadIntoxicationLayer()
        reloadCellsPMILayer()
      })
      // Si el token no es válido falla
      .finally(() => {isIntoxicationTableLoading.value = false})
  }


  // Elimina las capas del mapa
  const unload = () => {
    console.log('unload()')
    // First stop (if running)
    stop()

    // Se ha desactivado la capa y hay una creada
    // Remove all layers from map (Not from memory)
    unloadParticlesLayer()
    unloadVideoParticlesLayer()
    unloadToxinsPOL()
    unloadCellsPMI()

    // Reset
    reset()
  }

  const unloadParticlesLayer = () => {
    if (particleLayer.value) {
      if (map.value.hasLayer(particleLayer.value)) {
        map.value.removeLayer(particleLayer.value)
      }
    }
  }
  const unloadVideoParticlesLayer = () => {
    if (videoParticleLayer.value) {
      if (map.value.hasLayer(videoParticleLayer.value)) {
        map.value.removeLayer(videoParticleLayer.value)
      }
    }

    // Paused on init (!== undefined)
    videoParticleLayerVideoPlay.value = null  // Inhabilitamos el Boton de play
  }

  const unloadToxinsPOL = () => {
    if (toxinsPOLLayers.value) {
      Object.keys(toxinsPOLLayers.value).forEach(function (key) {
        if (map.value.hasLayer(toxinsPOLLayers.value[key])) {
          map.value.removeLayer(toxinsPOLLayers.value[key])
        }
      })
      toxinsPOLLayers.value = []
    }
  }
  const unloadCellsPMI = () => {
    if (cellsPMILayers.value) {
      Object.keys(cellsPMILayers.value).forEach(function (key) {
        if (map.value.hasLayer(cellsPMILayers.value[key])) {
          map.value.removeLayer(cellsPMILayers.value[key])
        }
      })
      cellsPMILayers.value = []
    }
  }



  /** *** GeoJson Layer *** */
  let toxinsPOLLayer = null

  // Recargamos las capas de Intoxicación
  const reloadIntoxicationLayer = () => {

    // Como hay que actualizar el contenido de la capa. Las borramos todas para que se regeneren
    toxinsPOLLayers.value = []

    // Usar una posicion correcta
    const position = getTSPosition(animationPosition.value)
    if (position == -1) return

    const nextTimestamp = timestamps.value[animationPosition.value]

    if (layerPOL.value.active && layerPOL.value.render) {
      loadIntoxicationLayer(nextTimestamp, true)
    }
  }

  // eslint-disable-next-line no-unused-vars
  const searchIndexAcending = (timestamps, searchTS) => {
    const index = timestamps.findIndex(ts => ts >= searchTS)              // Ojo que buscamos el superior o igual
    if (index !== -1) {
      return timestamps[index]
    }
    return false
  }
  // eslint-disable-next-line no-unused-vars
  const searchIndexDescending = (timestamps, searchTS) => {

    // Copiamos porque el reverse() altera el orden del array
    let reversedArray = timestamps

    // Para hacer búsqueda Descendente el Array debe estar descendente
    if (timestamps.length > 1 && timestamps[0] < timestamps[1]) {
      reversedArray.reverse()
    }

    // Orden descendente. Cuando encontremos uno inferior
    const index = reversedArray.findIndex(ts => ts <= searchTS) // Ojo que buscamos el inferior o igual
    if (index !== -1) {
      return reversedArray[index]
    }
  }
  // const searchIndex = (timestamps, searchTS, ascending = true) => {
  //   if (ascending) {
  //     return searchIndexAcending(timestamps, searchTS)
  //   }
  //   return searchIndexDescending(timestamps, searchTS)
  // }

  // Load layer
  const loadIntoxicationLayer = (nextTimestamp, force=false) => {

    // Nivel de zoom  Hidden
    //  10 - 4 Rías     Si
    //  11 - 2 Rías     No      *** HIDDEN_LIMIT_ZOOM ***
    //  12 - 1 Ría      No
    if (map.value.getZoom() < HIDDEN_LIMIT_ZOOM && layerPOL.value.isRenderHiddenOnZoom) {
      return
    }

    // const index = toxinsPOLTimestamps.value.findIndex(ts => ts >= nextTimestamp)
    // if (index !== -1) {
    //   console.log('IntoxicationIndex', index, toxinsPOLTimestamps.value[index])
    //   addLayerGeoJson(toxinsPOLTimestamps.value[index], force)
    // }

    // Buscar el siguiente TS disponible desde el TS actual => Búsqueda descendente
    const ts = searchIndexDescending(toxinsPOLTimestamps.value, nextTimestamp)
    if (ts) {
      addLayerGeoJson(ts, force)
    }
  }

  const addLayerGeoJson = (ts, force=false) => {

    // Set layer
    if (!toxinsPOLLayers.value[ts] || force) {
      toxinsPOLLayers.value[ts] = createIntoxicationLayer(ts)
    }

    // Eliminar la capa anterior (Del grupo de intoxicacion
    // const toxinsPOLLayer = map.value.getLayer('intoxication')
    if (toxinsPOLLayer && (toxinsPOLLayer.options.name !== ts || force)){
      map.value.removeLayer(toxinsPOLLayer)
    }

    // Show layer
    if (!map.value.hasLayer(toxinsPOLLayers.value[ts])) {
      map.value.addLayer(toxinsPOLLayers.value[ts]);

      toxinsPOLLayer = toxinsPOLLayers.value[ts]
    }
  }

  /** *** GeoJson Layer -- PMI -- *** */
  let cellsPMILayer = null

  // Recargamos las capas de Intoxicación
  const reloadCellsPMILayer = () => {

    // Como hay que actualizar el contenido de la capa. Las borramos todas para que se regeneren
    cellsPMILayers.value = []

    // Usar una posicion correcta
    const position = getTSPosition(animationPosition.value)
    if (position == -1) return

    const nextTimestamp = timestamps.value[animationPosition.value]

    if (layerPMI.value.active && layerPMI.value.render) {
      loadCellsPMILayer(nextTimestamp, true)
    }
  }

  // Load
  const loadCellsPMILayer = (nextTimestamp, force=false) => {

    // Nivel de zoom  Hidden
    //  10 - 4 Rías     Si
    //  11 - 2 Rías     No      *** HIDDEN_LIMIT_ZOOM ***
    //  12 - 1 Ría      No
    if (map.value.getZoom() < HIDDEN_LIMIT_ZOOM && layerPMI.value.isRenderHiddenOnZoom) {
      return
    }

    // Buscar el siguiente TS disponible desde el TS actual => Búsqueda descendente
    const ts = searchIndexDescending(toxinsPOLTimestamps.value, nextTimestamp)
    if (ts) {
      addLayerGeoJsonCellsPMI(ts, force)
    }
  }
  const addLayerGeoJsonCellsPMI = (ts, force=false) => {

    // Set layer
    if (!cellsPMILayers.value[ts] || force) {
      cellsPMILayers.value[ts] = createCellsPMILayer(ts)
    }

    // Eliminar la capa anterior (Del grupo de intoxicacion
    // const cellsLayer = map.value.getLayer('cells')
    if (cellsPMILayer && (cellsPMILayer.options.name !== ts || force)) {
      map.value.removeLayer(cellsPMILayer)
    }

    // Show layer
    if (!map.value.hasLayer(cellsPMILayers.value[ts])) {
      map.value.addLayer(cellsPMILayers.value[ts]);

      cellsPMILayer = cellsPMILayers.value[ts]
    }
  }
  /** PMI end */

  // POL = Poligon + Central point for info
  const vectorLayerGeojsonPMI = ref(geojsonDataPMI.features)

  const overWriteCellsData = ( cellsList ) => {
    cellsList.map( (cells) => {
      const pmId = cells?.pmId
      const pmName = cells?.pmName
      const simValue = cells?.simValue
      const ipValue = cells?.ipValue

      // Method 2: Array map overwritte
      vectorLayerGeojsonPMI.value = vectorLayerGeojsonPMI.value.map( (feature) => {
        const properties = feature.properties

        if (properties?.id === pmId || properties?.name === pmName) {
          if (!properties.data) {
            properties.data = { }
          }
          properties.data.simValue = simValue
          properties.data.ipValue = ipValue
        }
        feature.properties = properties

        return feature
      })
    })
  }

  // POL = Poligon + Central point for info
  const vectorLayerGeojsonPOL = ref(geojsonDataPOL.features)
  const { getCenterCoordinateOfPoligone } = useUtilsMap()

  const overWriteIntoxicationData = ( list ) => {

    list.map( (intoxication) => {
      const pmId = intoxication?.pmId
      const pmName = intoxication?.pmName
      const pmValue = intoxication?.pmValue
      const forecast = intoxication?.forecast

      if (pmId == 1) {
        console.log('Ribeira B:', intoxication)
      }

      // Method 2: Array map overwritte
      vectorLayerGeojsonPOL.value = vectorLayerGeojsonPOL.value.map( (feature) => {
        const properties = feature.properties

        if (properties?.id === pmId || properties?.name === pmName) {
          if (!properties.data) {
            properties.data = { }
          }
          properties.data.intoxication = pmValue
          properties.data.forecast = forecast
        }
        feature.properties = properties


        return feature
      })
    })
  }

  const { getDecorateToxAColor } = useDecorateToxA()

  // PMI Render: Recount
  const templatePMI = '<div class="d-flex algign-center forecast-point" style="background-color: _COLOR_;">_TEXT_</div>'
  const getTemplatePMI = (text, color) => {
    return templatePMI.replace('_TEXT_', text).replace('_COLOR_', color)
  }
  // eslint-disable-next-line no-unused-vars
  const iconPMI = (text, color='rgb(54, 127, 137)') => {
    return L.divIcon({
      html: getTemplatePMI(text, color),
      iconSize: [],
      className: ''
    })
  };

  // POL Render: Recount
  const templatePOL = '<div class="d-flex algign-center forecast-point forecast-icon" style="background-color: _COLOR_;"><i class="v-icon notranslate mdi mdi-navigation theme--light white--text" style="font-size: 14px; transform: rotate(_ANGLE_deg); -webkit-transform:rotate(_ANGLE_deg);"></i>_TEXT_</div>'
  const getTemplatePOL = (text, color, rotation) => {
    if (rotation > 0) {
      return templatePOL.replace('_TEXT_', text).replace('_COLOR_', color).replaceAll('_ANGLE_', rotation)
    } else {
      return templatePMI.replace('_TEXT_', text).replace('_COLOR_', color).replaceAll('_ANGLE_', rotation)
    }
  }
  const iconPOL = (text, color='rgb(54, 127, 137)', rotation = 0) => {
    return L.divIcon({
      html: getTemplatePOL(text, color, rotation),
      iconSize: [],
      className: ''
    })
  };

  const onPointToLayerPOLCenter = (feature, latlng) => {

    if (layerPOL.value.render === 'name') {
      const text = feature?.properties?.name
      if (text) {
        return L.marker(latlng, { icon: iconPOL(text, 'transparent', 0) })
      }
    }

    if (layerPOL.value.render === 'simulation-daily') {
      // const forecastData_ToxA = getIntoxicationData(feature?.properties?.data?.intoxication, dateRef.value)
      const text = feature?.properties?.data?.intoxication
      if (text) {
        const color = getDecorateToxAColor(parseInt(text), toxinType.value)
        return L.marker(latlng, { icon: iconPOL(text, color) })
      }
    }

    if (layerPOL.value.render === 'simulation-forecast') {
      if (feature?.properties?.data?.forecast) {
        const forecastData_ToxA_AD = getForecastData(feature?.properties?.data?.forecast, 'ToxA-AD')
        const forecastData_ToxACurrent = getForecastData(feature?.properties?.data?.forecast, 'ToxA-Current')
        if (forecastData_ToxACurrent) {
          const text = forecastData_ToxACurrent?.value || ''
          const color = forecastData_ToxACurrent?.color || 'rgb(54, 127, 137)'
          const rotation = getRotationAngle(forecastData_ToxA_AD)

          return L.marker(latlng, { icon: iconPOL(text, color, rotation) })
        }
      }
    }
  }

  // PMI Render recount or simulation particles
  const onPointToLayerPMICenter = (feature, latlng) => {

    if (layerPMI.value.render === 'name') {
      const text = feature?.properties?.name
      if (text) {
        return L.marker(latlng, { icon: iconPOL(text, 'transparent', 0) })
      }
    }

    if (layerPMI.value.render === 'simulation-daily') {
    // const forecastData_ToxA = getIntoxicationData(feature?.properties?.data?.simValue, dateRef.value)
      const forecastData_ToxA = feature?.properties?.data?.simValue
      if (forecastData_ToxA) {
        const text = forecastData_ToxA
        const color = getDecorateToxAColor(parseInt(text), toxinType.value)

        return L.marker(latlng, { icon: iconPOL(text, color) })
      }
    }
    //
    // return new L.Circle(latlng, 250, getPMIStyle(feature));
  }

  // Declaramos esta variable como un evento y hacemos watch en el padre
  const onFeatureSelected = ref(null)
  const createIntoxicationLayer = (ts) => {
    // Add to the list
    const toxinsPOLList = toxinsPOLDataList.value[ts]

    // Calcular Feaures a partir de la capa base y la tabla de intoxicacion
    overWriteIntoxicationData(toxinsPOLList)

    const vectorLayerGeojsonPOLCenter = vectorLayerGeojsonPOL.value.map((feature) => {
      const latlng = getCenterCoordinateOfPoligone(feature.geometry.coordinates)
      return {
        type: "Feature",
        properties: feature.properties,
        geometry: { type: "Point", coordinates: latlng }
      }
    })

    // vectorLayerGeojsonPOLCenter.map((feature) => {
    //   toxinsPOLLayer.addData(feature)
    // })

    // Capa
    return new L.geoJson(vectorLayerGeojsonPOLCenter, {
      name: ts,
      pointToLayer: onPointToLayerPOLCenter,
      onEachFeature: (feature, layer) => {
        layer.on('click', () => {
          onFeatureSelected.value = feature
        });
      }
    });
  }

  // Desde el exterior se indica un polígono
  const setFeatureSelectedByExternal = (pmId) => {
    // TODO - feature?.properties?.id no esta definido para todos, es un dato que procede de un fichero
    const sel = vectorLayerGeojsonPOL.value.find((feature) => {
      return feature?.properties?.id === pmId || feature?.properties?.data?.forecast?.forecastItemHeader?.forecastHeader?.pm?.id === pmId
    })
    if (sel) {
      onFeatureSelected.value = sel
    }
  }

  const createCellsPMILayer = (ts) => {

    // Add to the list
    const cellsPMIList = cellsPMIDataList.value[ts]

    // Calcular Feaures a partir de la capa base y la tabla de intoxicacion
    overWriteCellsData(cellsPMIList)

    const vectorLayerGeojsonPMICenter = vectorLayerGeojsonPMI.value.map((feature) => {
      // const latlng = getCenterCoordinateOfPoligone(feature.geometry.coordinates)
      const latlng = feature.geometry.coordinates
      return {
        type: "Feature",
        properties: feature.properties,
        geometry: { type: "Point", coordinates: latlng }
      }
    })

    return new L.geoJson(vectorLayerGeojsonPMICenter, {
      name: ts,
      pointToLayer: onPointToLayerPMICenter,
      onEachFeature: (feature, layer) => {
        layer.on('click', () => {
          onFeatureSelected.value = feature
        });
      }
    });
  }


  /** Particle Layer */
  // Recargamos las capas
  // TODO - debuggerar este cambio
  const reloadParticleLayer = () => {

    // Como hay que actualizar el contenido de la capa. Las borramos todas para que se regeneren
    // toxinsPOLLayers.value = []
    particleLayer.value = null

    // Usar una posicion correcta
    const position = getTSPosition(animationPosition.value)
    if (position == -1) return

    const nextTimestamp = timestamps.value[animationPosition.value]

    // if (layerParticles.value.active && layerParticles.value.particles) {
    if (layerParticles.value.active && layerParticles.value.render === 'particles') {
      loadParticleLayer(nextTimestamp, false, position)
    }
  }

  // Load
  const loadParticleLayer = (nextTimestamp, preloadOnly, position) => {

    // if (layerParticles.value.active) {
    const particleIndex = particleTimestamps.value.findIndex(ts => ts >= nextTimestamp)
    if (particleIndex !== -1) {
      addLayerParticle()

      // Buscar dato en Remoto si no existe
      if (!paticleDataList.value[nextTimestamp]) {

        // TODO - Stop and Continue after PreLoad new data
        // Solicita más datos y actatualiza la posición si NOT(preload)
        fetchParticlesRemote(nextTimestamp, !preloadOnly ? position : -1)
      } else {
        if (!preloadOnly) {
          particleLayer.value.setFrameByTimestamp(nextTimestamp)
        }
      }
    }
  }

  const addLayerParticle = () => {

    // Create layer (First time)
    if (!particleLayer.value) {
      particleLayer.value = L.particlesLayer({

        // an array of keyframes, default: null
        data: null,

        pane: 'overlayPane', // name of an existing leaflet pane to add the layer to, default: 'overlayPane'

        // define the indices of your data point arrays, default:
        dataFormat: {
          idIndex:    0,
          lonIndex:   1,
          latIndex:   2,
          depthIndex: 3,
          ageIndex:   4
        },

        // one of: 'FINAL', 'EXPOSURE', 'KEYFRAME', default: null
        displayMode: null,

        // which keyframe should display on init, default: 0
        startFrameIndex: -1,

        // the colors to use in chroma-js scale, default: (shown below)
        ageColorScale: ['green', 'yellow', 'red'],

        // the domain to fit the ageColorScale, default: keyframe length
        ageDomain: [0, 1000],

        // heatmap.js options for heatmap layers, see:
        // https://www.patrick-wied.at/static/heatmapjs/example-heatmap-leaflet.html
        // note that additionally; we have an enhanced version of the leaflet-heatmap.js plugin (see /src)
        // that provides advanced cell/radius options, see: https://github.com/danwild/leaflet-heatbin
        heatOptions: {

          // example fixed radius of 1000m
          fixedRadius: true,
          radiusMeters: 100,

          // e.g. bin values into 250m grid cells
          heatBin: {
            showBinGrid: false, // debugger only
            enabled: true,
            cellSizeKm: 0.25
          }
        },

        // the intensity value to use for each point on the heatmap, default: 1
        // only used if not heatBin.enabled
        exposureIntensity: 1,
        finalIntensity: 1,

        // callbacks when layer is added/removed from map
        onAdd: () => {},
        onRemove: () => {}
      });
    }

    if (!map.value.hasLayer(particleLayer.value)) {
      map.value.addLayer(particleLayer.value);
    }
  }

  const addLayerParticleData = (ts, particles, position= -1) => {

    // Add to the list
    paticleDataList.value[ts] = particles

    // TODO - setData sería util la primera vez
    //        Para actualizaciones sucesivas podríamos implementar otro
    // data es NULL pero queda vinculado a la variable
    const keys = Object.keys(paticleDataList.value);
    if (keys.length === 1) {
      particleLayer.value.setDisplayMode('KEYFRAME')
      particleLayer.value.setData(paticleDataList.value)
    }

    if (position >= 0) {
      // animationPosition.value = position
      // particleLayer.value.setFrameIndex(position)
      particleLayer.value.setFrameByTimestamp(ts)
    }
  }


  // >>> VideoParticles
  // Recargamos las capas
  // eslint-disable-next-line no-unused-vars
  const reloadVideoParticleLayer = () => {

    // Como hay que actualizar el contenido de la capa. Las borramos todas para que se regeneren
    videoParticleLayer.value = null

    // Usar una posicion correcta
    const position = getTSPosition(animationPosition.value)
    if (position == -1) return

    const nextTimestamp = timestamps.value[animationPosition.value]

    // if (layerParticles.value.active && layerParticles.value.video) {
    if (layerParticles.value.active && layerParticles.render === 'video') {
      loadVideoParticleLayer(nextTimestamp, false, position)
    }
  }

  // Load
  // eslint-disable-next-line no-unused-vars
  const loadVideoParticleLayer = (nextTimestamp, preloadOnly, position) => {

    const videoParticleIndex = videoParticleTimestamps.value.findIndex(ts => ts >= nextTimestamp)
    if (videoParticleIndex !== -1) {
      addLayerVideoParticle() // Al añadir la capa, se carga el video => Escuchar evento onLoadVideo()

      if (videoParticleLayerVideo.value) {

        // Método directo (Ya tenemos el VideoParticleIndex)
        // Solo indicamos posición cuando el video esté en MODO MANUAL (Modo PLAY no tocamos -> El video manda)
        if (videoParticleLayerVideoPlay.value === false) {
          videoParticleLayerVideo.value.currentTime = videoParticleIndex / ratioHorasVideoPorSegundo   // 1 segundo => 4 horas
        }

        // Método indirecto (Solo tenemos la posicion)
        // const newCurrentTime = convertSliderPositionToVideoCurrentTime(position)
        // if (newCurrentTime != -1) {
        //   videoParticleLayerVideo.value.currentTime = newCurrentTime;
        // }
      }
    // } else {
    //   addLayerVideoParticle() // Al añadir la capa, se carga el video => Escuchar evento onLoadVideo()
    }
  }


  const getFirstDayDate = (fechaRef) => {

    // python weekday(): "Return day of the week, where Monday == 0 ... Sunday == 6."
    var weekDay = fechaRef.getDay()
    if (weekDay === 0){
      weekDay = 6
    } else {
      weekDay = weekDay - 1
    }
    var firstDayDate = new Date(fechaRef)
    firstDayDate.setDate(firstDayDate.getDate() - weekDay)

    return firstDayDate
  }

  // TODO - LISTA GLOBAL FeedTypeList
  const feedTypeAPOptions = [
    { value: 'ef-feed:fito-acuminata-sim', label: 'Acuminata', videoCode: '9'  },
    { value: 'ef-feed:fito-acuta-sim', label: 'Acuta', videoCode: '10' },
    { value: 'ef-feed:fito-caudata-sim', label: 'Caudata', videoCode: '11' },

    // PSP
    { value: 'ef-feed:fito-alexandrium-sim', label: 'Alexandrium', videoCode: '14' },
    { value: 'ef-feed:fito-gym-catenatum-sim', label: 'Gym Catenatum', videoCode: '15' },

    // ASP
    { value: 'ef-feed:fito-pseudo-nitz-sim', label: 'Pseudo Nitz', videoCode: '13' },
  ]

  const getDefaultVideoCode = (toxinType) => {

    if (toxinType === 'DSP') return '9'
    if (toxinType === 'PSP') return '14'
    if (toxinType === 'ASP') return '13'

    return '9'
  }
  const getFeedTypeVideoCode = (toxinTypeString, feedTypeKey) => {
    const feedTypeAP = feedTypeAPOptions.find((item) => item.value === feedTypeKey)
    return feedTypeAP?.videoCode || getDefaultVideoCode(toxinTypeString)   //'9'  // Acuminata
  }

  // https://prediccion.empromar.com/cdn/videos/pastoreo_20230918_DSP_9.mp4
  const getVideoURL = (fecha, toxinTypeString, feedTypeKey) => {

    // Extraer primer día de la semana
    const firstDayDate = getFirstDayDate(fecha)
    const fechaString = format(firstDayDate, 'yyyyMMdd')

    // Extraer el FeedType Video Code
    const feedTypeVideoCode = getFeedTypeVideoCode(toxinTypeString, feedTypeKey)


    // const template = 'https://prediccion.empromar.com/cdn/videos/pastoreo_DDDDDDDD_TTT_9.mp4'.replace('DDDDDDDD',fechaString).replace('TTT', toxinTypeString)
    const template = 'https://prediccion.empromar.com/cdn/videos/pastoreo_DDDDDDDD_TTT_FTVC.mp4'.replace('DDDDDDDD',fechaString).replace('TTT', toxinTypeString).replace('FTVC', feedTypeVideoCode)
    return template
  }
  // eslint-disable-next-line no-unused-vars
  const ajustarImagen = (southWest, northEast) => {

    // Conversion de los ejes
    // const x = 0.00
    // const y = 0.5
    // const x1 =  -0.0020
    // const x2 =  +0.003
    // const y1 =  +0.09
    // const y2 =  -0.09
    const x1 =  0.0
    const x2 =  -0.00
    const y1 = -0.00
    const y2 =  0.0

    const northEastI = [ northEast[0]+y1, northEast[1]-x2]
    const southWestI = [ southWest[0]+y2, southWest[1]-x1]

    return { northEastI, southWestI }
  }

  const getExamples = (zone = 'portugal') => {

    var videoUrls = []
    var bounds = null

    // if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
    //   zone = 'california'
    // }

    if (zone == 'portugal') {
      // 'data/portugal2.mp4'
      videoUrls = [ 'data/videos/portugal2.webm' ]
      bounds = L.latLngBounds([[ 40.3263, -7.5359], [ 39.2307, -8.6314]]);
    }

    if (zone == 'california') {

      // https://www.mapbox.com/bites/00188/
      videoUrls = [
        // 'data/videos/patricia_nasa.webm',
        // 'data/videos/patricia_nasa.mp4'
        'https://labs.mapbox.com/bites/00188/patricia_nasa.webm',
        'https://labs.mapbox.com/bites/00188/patricia_nasa.mp4'
      ];
      bounds = L.latLngBounds([[32, -130], [13, -100]])
    }

    // NW North-West: 42.704842, -9.083992
    // NE North-East: 42.704842, -8.606967 *
    // SE South-East: 42.131629, -8.606967
    // SW South-West: 42.122668, -9.567049 *
    if (zone == 'galifornia') {
      // 'data/videos/pastoreo_20230904_DSP_9.mp4',
      // 'data/videos/pastoreo_20230911_DSP_9.mp4',
      // const fecha = parseISO(dateRef.value)
      const videoUrl = getVideoURL(parseISO(dateRef.value), toxinType.value, cellsOptions.value?.feedTypeAP)
      if (videoUrl) {
        videoUrls.push(videoUrl)
      }

      const southWest = [42.099, -9.605]
      const northEast = [42.704, -8.600]

      // >>> SFR 16.02.2024 - Movemos 0.100 grados Lat Norte para incluir Muros
      if (parseISO(dateRef.value) >= new Date('2024-02-12')) {
        southWest[0] += 0.100
        northEast[0] += 0.100
      }
      // const southWest = [42.122668, -9.567049]
      // const northEast = [42.704842, -8.606967]
      const { southWestI, northEastI, } = ajustarImagen(southWest, northEast)
      bounds = L.latLngBounds(southWestI, northEastI) // (_southWest,_northEast)
      // bounds = L.latLngBounds(southWest, northEast) // (_southWest,_northEast)

      // Rectangle
      // const rectBounds = L.latLngBounds(southWest, northEast)
      // L.rectangle(rectBounds, { stroke: true, fillOpacity: 0 }).addTo(map.value)
    }

    return {
      videoUrls: videoUrls,
      bounds: bounds
    }
  }
  // Configuraciones del video
  const ratioHorasVideoPorSegundo = 4         // 1 Segundo de Video => 4 horas de simulación

  // El video se carga
  // Cuando el video se esté ejecutando
  const videoParticleLayerVideo = ref(null)           // <video>
  const videoParticleLayerVideoPlay = ref(null)       // Play/Pause Control del video
  const videoParticleLayerVideoLoading = ref(false)   // Video loading

  watch( () => videoParticleLayerVideoPlay.value, () => {
    // No hacemos nada mientras
    if (!videoParticleLayerVideo.value) return

    if (videoParticleLayerVideoPlay.value === true) {
      videoParticleLayerVideo.value.play();
    }

    if (videoParticleLayerVideoPlay.value === false) {
      videoParticleLayerVideo.value.pause();
    }
  })

  const onLoadVideo = () => {
    // Indicamos el video (<video>)
    videoParticleLayerVideo.value = videoParticleLayer.value.getElement()
    if (! videoParticleLayerVideo.value) return

    // IOS obliga a usar autoplay para controlar el video (Sino solo funciona en Android y navegador)
    //  El evento timeupdate
    //  El atributo currentTime
    if (videoParticleLayerVideo.value.autoplay) {
      videoParticleLayerVideoPlay.value = false   // Esto no es necesario pero tiene sentio
      videoParticleLayerVideo.value.pause()
    }

    console.log('onLoadVideo', videoParticleLayerVideo.value)

    // Indicamos el fin de la carga del video
    videoParticleLayerVideoLoading.value = false

    // Generamos los TS del video con la fecha Ref
    videoParticleTimestamps.value = []

    // Contar las horas del video desde la fecha inicial
    // const fechaInicial = new Date(dateRef.value)
    // const fechaInicial = parseISO(dateRef.value)
    const fechaInicial = getFirstDayDate(parseISO(dateRef.value))
    for (let i = 0; i < (videoParticleLayerVideo.value.duration * ratioHorasVideoPorSegundo); i++) {
      const fecha = new Date(fechaInicial.getTime() + (i * 60 * 60 * 1000))  // Días => (i * 24 * 60 * 60 * 1000)
      videoParticleTimestamps.value.push(fecha.getTime() / 1000)
    }
    mergeTimeStamps(videoParticleTimestamps.value)


    // Set playRate
    videoParticleLayerVideo.value.playbackRate = 1

    // Agrega un evento "timeupdate" al video para actualizar el valor del slider
    videoParticleLayerVideo.value.addEventListener('timeupdate', function() {
      console.log('timeupdate', videoParticleLayerVideo.value.currentTime)
      // console.log('timeupdate', videoParticleLayerVideo.value.currentTime)
      // Esto es solo cuando no esté en modo play
      if (videoParticleLayerVideoPlay.value) {
        const pos = convertVideoToSliderPosition(videoParticleLayerVideo.value.currentTime)
        if (pos !== 1) {
          showFrame(pos);
        }
      }
    })

    // Posicionar el video en el instante actual
    // setVideoPosition(animationPosition.value)
    const newCurrentTime = convertSliderPositionToVideoCurrentTime(animationPosition.value)
    if (newCurrentTime != -1) {
      videoParticleLayerVideo.value.currentTime = newCurrentTime;
      console.log('onLoadVideoSetCurrentTime', newCurrentTime)
    }
  }

  // TODO - Desarrollar conversiones
  //   Slider     -> VideoTime  (Sync)    convertVideoToSliderPosition(currentTime): aPosition
  //   VideoPlay  -> Slider     (Sync)    setVideoPosition(aPosition): currentTime
  // const setVideoPosition = (aPosition) => {
  const convertSliderPositionToVideoCurrentTime = (aPosition) => {

    // No indicar Posición del video en modo PLAY (Es el video el que manda)
    if (videoParticleLayerVideoPlay.value) return -1

    // Current TS
    const position = getTSPosition(aPosition)
    if (position == -1) return -1
    const nextTimestamp = timestamps.value[animationPosition.value]

    const videoParticleIndex = videoParticleTimestamps.value.findIndex(ts => ts >= nextTimestamp)
    if (position == -1) return -1

    // Devolvemos el tiempo de video transcurrido en segundos
    return videoParticleIndex / ratioHorasVideoPorSegundo   // 1 segundo => 4 horas
  }

  // Video(Tiempo de visualización) --> TS index global
  const convertVideoToSliderPosition = (currentTime) => {

    // No indicar Posición del video en modo PLAY (Es el video el que manda)
    if (!videoParticleLayerVideoPlay.value) return -1

    // Convertir el TiempoVideo a TS
    // ratioHorasVideoPorSegundo: 4;  Ratio = 4 h/seg; 1 Segundo de Video equivale a 4 horas Reales
    // HorasTranscurridas = currentTime * ratioHorasVideoPorSegundo
    // SegundosTranscurridos = HorasTranscurridas * 60 * 60
    const tsInicial = videoParticleTimestamps.value[0]
    const tsVideo = tsInicial + (currentTime * ratioHorasVideoPorSegundo * 60 * 60) // TiempoVideo * Ratio * 60 * 60


    // Posicion global
    const position = timestamps.value.findIndex(ts => ts >= tsVideo)
    if (position == -1) return

    return position
  }
  const addLayerVideoParticle = () => {

    if (!videoParticleLayer.value) {

      const videoConf = getExamples('galifornia')
      if (videoConf?.bounds) {
        // map.value.mapObject.fitBounds(videoConf.bounds);
        videoParticleLayer.value = L.videoOverlay(videoConf.videoUrls, videoConf.bounds, {
          opacity: 0.8,
          errorOverlayUrl: 'https://cdn-icons-png.flaticon.com/512/110/110686.png',
          interactive: false,
          autoplay: true,
          muted: true,
          playsInline: true,
          keepAspectRatio: false
        })

        // Escuchamos el evento para reaccionar al final de la carga del video
        videoParticleLayer.value.addEventListener('load', onLoadVideo)          // onLoadVideo
        // setTimeout(onLoadVideo, 5000)

        videoParticleLayerVideoPlay.value = false                               // Paused on init (!== undefined)
        videoParticleLayerVideoLoading.value = true                             // Loading...
      } else {
        return
      }
    }

    if (!map.value.hasLayer(videoParticleLayer.value)) {
      map.value.addLayer(videoParticleLayer.value)
    }
  }

  // <<< VideoParticles



  const getTSPosition = (position) => {
    // Evitar cambios cuando no haya timestamp
    if (timestamps.value && timestamps.value.length == 0) return -1

    while (position >= timestamps.value.length) {
      position -= timestamps.value.length
    }
    while (position < 0) {
      position += timestamps.value.length
    }

    return position
  }
  /**
 * Display particular frame of animation for the @position
 * If preloadOnly parameter is set to true, the frame layer only adds for the tiles preloading purpose
 * @param position
 * @param preloadOnly
 */
  const changeRadarPosition = (position, preloadOnly=false) => {

    // Usar una posicion correcta
    position = getTSPosition(position)
    if (position == -1) return

    //currentTimestamp.value = timestamps.value[animationPosition.value]
    const nextTimestamp = timestamps.value[position]
    console.log('changeRadarPosition', position, nextTimestamp)

    // Particle Layer
    // if (layerParticles.value.active && layerParticles.value.particles) {
    if (layerParticles.value.active && layerParticles.value.render === 'particles') {
      loadParticleLayer(nextTimestamp, preloadOnly, position)
    }
    // if (layerParticles.value.active && layerParticles.value.video) {
    if (layerParticles.value.active && layerParticles.value.render === 'video') {
      loadVideoParticleLayer(nextTimestamp, preloadOnly, position)
    }


    // Toxin POL Layer
    if (layerPOL.value.active && layerPOL.value.render) {
      loadIntoxicationLayer(nextTimestamp, false) // Cargamos la capa de Toxicidad por POL
    }
    // Cells PMI Layer
    if (layerPMI.value.active && layerPMI.value.render) {
      loadCellsPMILayer(nextTimestamp, false)  // Cargamos la capa de Células por PMI
    }

    if (!preloadOnly) {
      animationPosition.value = position
    }

    if (preloadOnly) {
      return;
    }

    // Indicamos al slider la nueva posición
    // animationPosition.value = position

    // if (layerParticles.value.active) {
    //   particleLayer.value.setFrameIndex(position)
    // }

    // // Radar carga todas las capas (Hay que oculata una y visualizar la otra)
    // if (isRainViewerActive.value) {
    //   switchRadarLayer(currentTimestamp.value, nextTimestamp)
    // }
  }

  // const switchRadarLayer = (currentTimestamp, nextTimestamp) => {

  //   if (radarLayers.value[currentTimestamp]) {
  //     radarLayers.value[currentTimestamp].setOpacity(0);
  //   }
  //   radarLayers.value[nextTimestamp].setOpacity(opacityDefault);

  //   //document.getElementById("timestamp").innerHTML = (new Date(this.nextTimestamp * 1000)).toLocaleString();
  // }


  /**
   * Check avialability and show particular frame position from the this.timestamps list
   */
  const showFrame = (nextPosition) => {
    // eslint-disable-next-line no-unused-vars
    // var preloadingDirection = nextPosition - (animationPosition.value > 0 ? 1 : -1)

    changeRadarPosition(nextPosition, false);

    // preload next next frame (typically, +1 frame)
    // if don't do that, the animation will be blinking at the first loop
    // changeRadarPosition(nextPosition + preloadingDirection, true);
  }

  // TODO - El parametro de entrada debe ser TIMESTAMP
  // e has same value that animationPosition
  // const onChangeAnimationPositionSlider = (newPosition) => {
  //   debugger
  //   // Calculamos la posicion anterior para que el cambio a la nueva capa oculte la anterior
  //   const pos = timestamps.value.findIndex(element => element === currentTimestamp.value)
  //   if (pos !== -1) {
  //     animationPosition.value = pos
  //     showFrame(newPosition)
  //   }
  // }
  const showFrameByTimestamp = (ts) => {
    const pos = timestamps.value.findIndex(element => element >= ts)
    console.log('showFrameByTimestamp', ts, pos)
    if (pos !== -1) {
      // animationPosition.value = pos
      showFrame(pos)
    }
  }

  /**
  * Stop the animation
  * Check if the animation timeout is set and clear it.
  */
  const stop = () => {
    if (animationTimer.value) {
      clearTimeout(animationTimer.value);
      animationTimer.value = false;
      return true;
    }
    return false;
  }

  // Hay 2 métodos
  //  1.- Usamos un setTimeout y controlamos la frecuencia
  //  2.- Usamos el play del video y escuchamos el evento
  const play = () => {
    // showFrame(animationPosition.value + 1);
    showFrame(animationPosition.value + animationStepFrequency.value);

    // Main animation driver. Run this function every 500 ms
    // animationTimer.value = setTimeout(play, animationIntervalDefault / animationVelocity.value);
    animationTimer.value = setTimeout(play, animationIntervalTime.value / animationVelocity.value);
    // console.log('animationTimer', animationTimer.value)
  }

  const playStop = () => {
    if (!stop()) {
      play();
    }
  }

  // eslint-disable-next-line no-unused-vars
  const prev = (e) => {
    stop()
    // showFrame(animationPosition - 1)
    showFrame(animationPosition - animationStepFrequency.value)

    return
  }

  // eslint-disable-next-line no-unused-vars
  const startstop = (e) => {
    playStop()
  }

  // eslint-disable-next-line no-unused-vars
  const next = (e) => {
    stop();
    // showFrame(animationPosition + 1)
    showFrame(animationPosition + animationStepFrequency.value)

    return
  }

  // eslint-disable-next-line no-unused-vars
  const onRemove = (_map) => {
    // Nothing to do here
  }

  return {
    onAdd, // Init
    onRemove,
    onFeatureSelected,    // Se ha seleccionado una Feature GeoJson
    setFeatureSelectedByExternal,   // External selection

    // Control
    startstop,
    play,
    stop,
    prev,
    next,
    animationTimer, // Check state: true = Play; false = Pause

    // Control Video
    videoParticleLayerVideoPlay,
    videoParticleLayerVideoLoading,

    // Slider
    timestamps,
    animationPosition,
    animationPositionMin,               // Only for v-slider
    animationPositionMax,
    showFrameByTimestamp,               // Cambio manual de la position por TIMESTAMP (Más versatil)

    // Velocity
    animationVelocity,
    onChangeAnimationVelocity,

    // Step Frequency
    animationStepFrequency,
    onChangeAnimationStepFrequency,

    // Intoxicacion and forecast info readed from API
    isIntoxicationTableLoading,   // Intoxication Table loading
    vectorLayerGeojsonPMI,
    vectorLayerGeojsonPOL,
    intoxicationTableList,
  }
}
