<template>
  <!-- <div class="d-flex flex-column"> -->
  <!-- <hot-table :data="data" :rowHeaders="true" :colHeaders="true"></hot-table> -->

  <!-- <div style="overflow-x: scroll;"> -->
  <div>
    <v-btn icon @click="$emit('close', true)">
      <v-icon>mdi-close</v-icon>
    </v-btn>

    <div>
      <div id="mobile-calendar">
        <div id="days" class="w-100">
          <hot-table ref="hansontableRef" v-if="hotSettings" :settings="hotSettings"></hot-table>
          <span v-else>NADA</span>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
  import { ref, watch } from 'vue';

  import { HotTable } from '@handsontable/vue';
  import { registerAllModules } from 'handsontable/registry';
  import 'handsontable/dist/handsontable.full.css';

  // register Handsontable's modules
  registerAllModules()

  import Handsontable from 'handsontable';

  // eslint-disable-next-line no-unused-vars
  import intoxicationFile from '../geojson/intoxication.json';

  export default {
    name: 'intoxication-table-component',
    components: {
      // eslint-disable-next-line vue/no-unused-components
      HotTable
    },
    props: {
      intoxicationTable: {
        type: Object,
        default: () => {},
      }
    },
    setup(props) {
      // debugger
      const riaId = ref(5)
      const intoxicationTableListLocal = ref(JSON.parse(JSON.stringify(props.intoxicationTable)));

      const hansontableRef = ref(null)
      const hotSettings = ref(null)
      // props passed to setup function is reactive object (made probably by reactive()), it's properties are getters.
      // Watching a getter: https://v3.vuejs.org/api/computed-watch-api.html#watching-a-single-source
      watch(() => props.intoxicationTable, () => {
        // debugger
        intoxicationTableListLocal.value = JSON.parse(JSON.stringify(props.intoxicationTable))


        const rowData = Object.values(intoxicationFile.rowData);
        const colFormats = intoxicationFile.colFormat
        const colHeaderStyle = intoxicationFile.colHeaderStyle

        // Multi o Single
        let rowPlan = -1;
        let rowEstado = -1;
        let fixedColumnsLeft = 1;
        let colPrevisionCols = 6;
        // Checking... Single (Detail view)
        if (colFormats.length > 0 && colFormats[0]['data'] === 'ef:feedType') {
          // eslint-disable-next-line no-unused-vars
          rowPlan = 4;
          // eslint-disable-next-line no-unused-vars
          rowEstado = 5;
          fixedColumnsLeft = 1;
          colPrevisionCols = 4;
        }

        //const colPrevisionCols = 6;
        // eslint-disable-next-line no-unused-vars
        const colPrevision = colFormats.length - colPrevisionCols;

        const afterGetColHeader = (col, TH) => {
          var TR = TH.parentNode;
          var THEAD = TR.parentNode;
          var headerLevel = (-1) * THEAD.childNodes.length + Array.prototype.indexOf.call(THEAD.childNodes, TR);

          // function applyClass(elem, className) {
          //   debugger
          //   // eslint-disable-next-line no-undef
          //   // if (!Handsontable.dom.hasClass(elem, className)) {
          //   // if (!hansontableRef.value.dom.hasClass(elem, className)) {
          //   if (!hansontableRef.classList.contains('mi-clase')) {
          //     // eslint-disable-next-line no-undef
          //     Handsontable.dom.addClass(elem, className);
          //   }
          // }

          // first level from the top (Pasado + Previsión 4d.)
          if (headerLevel === -2) {
            // applyClass(TH, 'color1');
            if (col >= colPrevision) {
              // applyClass(TH, 'color2');
              if (!TH.classList.contains('color2')) {
                TH.classList.add('color2')
              }
            } else {
              // applyClass(TH, 'color1');
              if (!TH.classList.contains('color1')) {
                TH.classList.add('color1')
              }
            }
          }
          // second level from the top (Dias)
          if (headerLevel === -1) {
            // if (col >= colPrevision) {
            //     applyClass(TH, 'color2');
            // }
            // if (col === 7) {
            // debugger;
            // classColor indica la clase de la columna que ha sido indicada en el controlador
            if (colFormats[col]) {
              const classColor = colFormats[col]['classColor'];
              if (classColor !== undefined && classColor !== null && classColor !== "") {
                // applyClass(TH, classColor);
                if (!TH.classList.contains(classColor)) {
                  TH.classList.add(classColor)
                }
              }
            }
          }
        }

        function getEstadoStyle(feedValueText) {

          // Estado
          //   1   Abierto     white
          //   2   Cerrado     Rojo
          switch (feedValueText) {
          case 'A':
            return 'blue';                        // '1'   Open #F2F2F2
          case 'C':
            return 'red';                         // '2'   Close
          default:
            return '';
          }
        }

        function getPlanActuacionStyle(feedValueText) {

          // Plan de actuación
          //   A   Azul            #00C0FF Pte. de verificar en imagen real (Sin ejemplo)
          //   B1  Morado(Oscuro)  #805CFF
          //   B2  Morado(Clarito) #C090FF
          //   B3  Rosa            #E05CFF
          //   CC  Magenta         #CD001E No coincide con leyenda
          //   C1  Rojo            #FF0000 No coincide con leyenda
          //   C2  Naranja         #FF8000 Pte. de verificar en imagen real (Sin ejemplo)
          //   C3  Amarillo        #FFC000
          //   D   Verde           #40A080
          switch (feedValueText) {
          case 'A':
            return '#00C0FF';
          case 'B1':
            return '#805CFF';
          case 'B2':
            return '#C090FF';
          case 'B3':
            return '#E05CFF';
          case 'CC':
            return '#CD001E';
          case 'C1':
            return '#FF0000';
          case 'C2':
            return '#FF8000';
          case 'C3':
            return '#FFC000';
          case 'D':
            return '#40A080';
          default:
            return '#F2F2F2';           // gris
          }
        }

        function getObjectValue(object, key, defaultValue) {
          defaultValue = typeof defaultValue !== 'undefined' ? defaultValue : '';          // Por defecto ''

          var value = object[key];
          if (object[key] === 'undefined')
            return defaultValue;

          if (typeof value == 'string' && value === "")
            return defaultValue;

          return value;
        }

        // Render Para la tabla Tox/dTox multiPM
        // eslint-disable-next-line no-unused-vars
        function customRendererHTML2Vals(instance, td, row, col, prop, value, cellProperties) {
          var divElement = document.createElement("div");
          if (value != null && typeof value == 'object') {                            // null es considerado un objeto

            //  ef-feed:analisis            => ef-feed:[dsp|asp|psp]-analisis
            //  ef-feed:analisis-progress   => ef-feed:[dsp|asp|psp]-analisis-progress
            //  ef-feed:plan                => ef-feed:[dsp|asp|psp]-plan
            //  ef-feed:estado              => ef-feed:[dsp|asp|psp]-estado
            //  ef-feed:toxa                => ef-feed:[dsp|asp|psp]-toxa
            var toxinTypeValue = getObjectValue(value, 'ef-feed:toxin-type', null);
            var analisisValue = getObjectValue(value, 'ef-feed:analisis', null);
            var analisisProgressValue = getObjectValue(value, 'ef-feed:analisis-progress', null);
            var planValue = getObjectValue(value, 'ef-feed:plan', null);
            var estadoValue = getObjectValue(value, 'ef-feed:estado', null);
            var toxAValue = getObjectValue(value, 'ef-feed:toxa', 0);

            var styleWidth = 100;

            if (analisisValue != null) {
              styleWidth -= 30;

              var style = "width: 30%; text-align: center; font-weight: bold; font-size: 10px; background-color: BACKGROUND-COLOR; color: white;";
              // if (analisisValue > 160) {}
              if (
                (toxinTypeValue == 'DSP' && analisisValue > 160) ||     // 160 µg equiv. AO/kg
                (toxinTypeValue == 'ASP' && analisisValue > 20) ||      // 20 mg DA/kg
                (toxinTypeValue == 'PSP' && analisisValue > 800)        // 800 µg equiv. STX kg
              ) {
                style = style.replace('BACKGROUND-COLOR', 'red');
              } else {
                style = style.replace('BACKGROUND-COLOR', 'blue');
              }

              // Set DSP info: background-color: blue;
              var labelElement = document.createElement("label");
              labelElement.setAttribute("style", style);
              labelElement.innerHTML = analisisValue;
              divElement.appendChild(labelElement)
            }
            if (analisisProgressValue != null) {
              styleWidth -= 30;

              // eslint-disable-next-line no-redeclare
              var style = "width: 30%; text-align: center; font-weight: bold; font-size: 10px; background-color: lightblue; color: white;";

              // Set DSP info: background-color: blue;
              // eslint-disable-next-line no-redeclare
              var labelElement = document.createElement("label");
              labelElement.setAttribute("style", style);
              labelElement.innerHTML = analisisProgressValue;
              divElement.appendChild(labelElement)
            }
            if (planValue != null) {
              styleWidth -= 20;
              //var dspPlanValueText = getPlanActuacionText(planValue);

              // eslint-disable-next-line no-redeclare
              var style = "width: 20%; text-align: center; font-weight: normal; font-size: 10px; background-color: BACKGROUND-COLOR; color: black;";
              style = style.replace('BACKGROUND-COLOR', getPlanActuacionStyle(planValue)); // planValue

              // Set DSP info: background-color: blue;
              // eslint-disable-next-line no-redeclare
              var labelElement = document.createElement("label");
              labelElement.setAttribute("style", style);
              labelElement.innerHTML = planValue; // dspPlanValueText
              divElement.appendChild(labelElement)
            }
            if (estadoValue != null) {
              styleWidth -= 10;
              //var polEstadoText = getEstadoText(estadoValue);

              // eslint-disable-next-line no-redeclare
              var style = "width: 10%; text-align: center; font-weight: normal; font-size: 10px; background-color: BACKGROUND-COLOR; color: white;";
              style = style.replace('BACKGROUND-COLOR', getEstadoStyle(estadoValue)); // polEstadoText

              // Set DSP info: background-color: blue;
              // eslint-disable-next-line no-redeclare
              var labelElement = document.createElement("label");
              labelElement.setAttribute("style", style);
              labelElement.innerHTML = estadoValue; // polEstadoText
              divElement.appendChild(labelElement)
            }

            // Write cell value
            var styleWeight = 'normal';
            // eslint-disable-next-line no-redeclare
            var style = "width: WIDTH%; text-align: right; font-weight: WEIGHT;";
            // if (toxAValue > 160) {
            if (
              (toxinTypeValue == 'DSP' && toxAValue > 160) ||     // 160 µg equiv. AO/kg
              (toxinTypeValue == 'ASP' && toxAValue > 20) ||      // 20 mg DA/kg
              (toxinTypeValue == 'PSP' && toxAValue > 800)        // 800 µg equiv. STX kg
            ) {
              styleWeight = 'bold';
              style += "color: red;";
            }
            style = style.replace('WIDTH', styleWidth);
            style = style.replace('WEIGHT', styleWeight);

            var labelElement2 = document.createElement("label");
            labelElement2.setAttribute("style", style);
            labelElement2.innerHTML = toxAValue;
            divElement.appendChild(labelElement2)

          } else {
            // eslint-disable-next-line no-redeclare
            var labelElement2 = document.createElement("label");
            labelElement2.setAttribute("style", "width: 100%; text-align: right; font-weight: normal");
            labelElement2.innerHTML = value;
            divElement.appendChild(labelElement2)
          }

          Handsontable.dom.fastInnerHTML(td, divElement.outerHTML);
        }

        // Render para toxA-Afinidad
        // eslint-disable-next-line no-unused-vars
        function customRendererFiabilidad(instance, td, row, col, prop, value, cellProperties) {

          var template = null;
          if (value != null && typeof value == 'object') {                            // null es considerado un objeto

            var _bucketToxA_Afinidad = getObjectValue(value, 'ef-bucket-toxa-afinidad', null);
            var _bucketToxA_Forecast = getObjectValue(value, 'ef-bucket-toxa-forecast', null);

            if (_bucketToxA_Afinidad && _bucketToxA_Forecast) {
              template =
                "<div class='d-flex flex-row justify-content-between align-items-center'>" +
                "   <span class='progress w-100' style='height: 1.2rem;'>" +
                "       <span style='background-color: AFINIDAD_COLOR; width: AFINIDAD_VALUE%' class='progress-bar' role='progressbar'>" +
                "           <span>AFINIDAD_VALUE%</span>" +
                "       </span>" +
                "   </span>" +
                "   <span class='d-flex flex-row align-items-center ml-1' style='font-size: 1.5rem; color: FORECAST_COLOR'>" +
                "       <i class='FORECAST_ICON'></i>" +
                "   </span>" +
                "</div>"

              template = template.replaceAll('FORECAST_COLOR', _bucketToxA_Forecast.color);
              template = template.replaceAll('FORECAST_ICON', _bucketToxA_Forecast.icon);

              template = template.replaceAll('AFINIDAD_COLOR', _bucketToxA_Afinidad.color);
              template = template.replaceAll('AFINIDAD_VALUE', _bucketToxA_Afinidad.value);  // Repetimos
            }
          }

          // Checking... Si no hay plantilla sacamos el valor directamente
          if (template == null) {
            var divElement = document.createElement("div");
            var labelElement2 = document.createElement("label");
            labelElement2.setAttribute("style", "width: 100%; text-align: right; font-weight: normal");
            labelElement2.innerHTML = value;
            divElement.appendChild(labelElement2)

            template = divElement.outerHTML;
          }

          Handsontable.dom.fastInnerHTML(td, template);
        }

        // Render para tox/dTox detalle
        // eslint-disable-next-line no-unused-vars
        function customRendererForecast(instance, td, row, col, prop, value, cellProperties) {

          var template = null;
          if (value != null && typeof value == 'object') {                            // null es considerado un objeto

            var _bucketToxA_Forecast = getObjectValue(value, 'ef-bucket-toxa-forecast', null);
            var _bucketToxA_AD = getObjectValue(value, 'ef-bucket-toxa-ad', null);

            if (_bucketToxA_Forecast && _bucketToxA_AD) {
              template =
                "<div class='d-flex flex-row justify-content-between align-items-center'>" +
                // "   <span class='d-flex flex-row align-items-center' style='font-size: 1.5rem; color: FORECAST_COLOR'>" +
                // "       <i class='FORECAST_ICON'></i>" +
                // "   </span>" +
                // "   <div class='d-flex flex-row justify-content-between align-items-center'>" +
                "       <span class='d-flex flex-row justify-content-between align-items-center pr-1' style='font-size: 1rem; color: FORECAST_COLOR'>" +
                "           <span>FORECAST_VALUE</span>" +
                "       </span>" +
                "       <span class='d-flex flex-row justify-content-between align-items-center' style='font-size: 0.75rem; color: AD_COLOR'>" +
                "           <i class='AD_ICON'></i>" +
                "           <span class='text-right' style='min-width: 20px'>AD_VALUE</span>" +
                "       </span>" +
                // "   </div>" +
                "</div>"

              template = template.replaceAll('FORECAST_COLOR', _bucketToxA_Forecast.color);
              template = template.replaceAll('FORECAST_ICON', _bucketToxA_Forecast.icon);
              template = template.replaceAll('FORECAST_VALUE', _bucketToxA_Forecast.value);

              template = template.replaceAll('AD_COLOR', _bucketToxA_AD.color);
              template = template.replaceAll('AD_ICON', _bucketToxA_AD.icon);
              template = template.replaceAll('AD_VALUE', _bucketToxA_AD.value);
            }
          }

          // Checking... Si no hay plantilla sacamos el valor directamente
          if (template == null) {
            var divElement = document.createElement("div");
            var labelElement2 = document.createElement("label");
            labelElement2.setAttribute("style", "width: 100%; text-align: right; font-weight: normal");
            labelElement2.innerHTML = value;
            divElement.appendChild(labelElement2)

            template = divElement.outerHTML;
          }

          Handsontable.dom.fastInnerHTML(td, template);
        }

        // Render para tox/dTox detalle
        // eslint-disable-next-line no-unused-vars
        function customRendererPMName(instance, td, row, col, prop, value, cellProperties) {

          var template = null;
          if (value != null && typeof value == 'object') {                            // null es considerado un objeto

            var _pmName = getObjectValue(value, 'ef:pmName', null);
            var _bucketPlan = getObjectValue(value, 'ef-bucket-plan', null);
            var _bucketEstado = getObjectValue(value, 'ef-bucket-estado', null);

            if (_pmName && _bucketPlan && _bucketEstado) {
              template =
                "<div class='d-flex flex-row justify-content-between align-items-center'>" +
                "       <span class='d-flex flex-row justify-content-between align-items-center pr-1'>" +
                "           <span>FORECAST_VALUE</span>" +
                "       </span>" +
                "       <span class='d-flex flex-row justify-content-between align-items-center' style='font-size: 0.75rem;'>" +
                "           <span class='text-right' style='min-width: 10px; background-color: AD_COLOR_PLAN;'>AD_VALUE_PLAN</span>" +
                "           <span class='text-right' style='min-width: 10px; background-color: AD_COLOR;'>AD_VALUE</span>" +
                // "           <span>AD_DAYS</span>" +
                "       </span>" +
                "</div>"

              template = template.replaceAll('FORECAST_VALUE', _pmName);

              // PLAN
              // _bucketPlan.color = 'red';
              // _bucketPlan.value = 'CC';
              template = template.replaceAll('AD_COLOR_PLAN', _bucketPlan.color);
              template = template.replaceAll('AD_VALUE_PLAN', _bucketPlan.value);

              // Estado
              template = template.replaceAll('AD_COLOR', _bucketEstado.color);
              template = template.replaceAll('AD_VALUE', _bucketEstado.value);
              template = template.replaceAll('AD_DAYS', _bucketEstado.text);
            }
          }

          // Checking... Si no hay plantilla sacamos el valor directamente
          if (template == null) {
            var divElement = document.createElement("div");
            var labelElement2 = document.createElement("label");
            labelElement2.setAttribute("style", "width: 100%; text-align: left; font-weight: normal");
            labelElement2.innerHTML = value;
            divElement.appendChild(labelElement2)

            template = divElement.outerHTML;
          }

          Handsontable.dom.fastInnerHTML(td, template);
        }

        // Render para tox/dTox detalle
        // eslint-disable-next-line no-unused-vars
        function customRendererCalc_estado(instance, td, row, col, prop, value, cellProperties) {

          if (value !== '') {
            // eslint-disable-next-line no-undef
            Handsontable.renderers.TextRenderer.apply(this, arguments);
            td.style.fontWeight = 'normal';
            td.style.backgroundColor = '#F2F2F2';    // Gris
            td.style.textAlign = 'center';

            //td.style.backgroundColor = getEstadoStyle(getEstadoText(parseInt(value))); "1" -> A -> Blue
            td.style.backgroundColor = getEstadoStyle(value); //"A" -> Blue
          }
        }

        // Render para tox/dTox detalle
        // eslint-disable-next-line no-unused-vars
        function customRendererCalc_dsp_plan(instance, td, row, col, prop, value, cellProperties) {

          if (value !== '') {
            // eslint-disable-next-line no-undef
            Handsontable.renderers.TextRenderer.apply(this, arguments);
            td.style.fontWeight = 'normal';
            td.style.backgroundColor = '#F2F2F2';    // Gris
            td.style.textAlign = 'center';
            td.style.color = 'black';      // Negro para que de contraste

            // td.style.backgroundColor = getPlanActuacionStyle(getPlanActuacionText(parseInt(value)));
            td.style.backgroundColor = getPlanActuacionStyle(value); // C1 -> Red
          }
        }


        const cells = (row, col, prop) => {
          if (prop === 'ef:forecast') {
            return {renderer: customRendererForecast}
          }
          if (prop === 'ef:fiabilidad') {
            return {renderer: customRendererFiabilidad}
          }
          if (prop === 'ef:pmName') {
            return {renderer: customRendererPMName}
          }
          if (prop.startsWith('ef')) {
            return;
          }
          if (row === rowPlan) {
            return {renderer: customRendererCalc_dsp_plan}
          }
          if (row === rowEstado) {
            return {renderer: customRendererCalc_estado}
          }
          //return renderCellWithOtherRowData(row, col, prop);
          return {renderer: customRendererHTML2Vals};
        }

        // Set
        hotSettings.value = {
          data: rowData,
          colHeaders: true,
          rowHeaders: false,
          columns: colFormats,
          // height: 'auto',
          // width: 'auto',
          nestedHeaders: [
            [
              {label: 'Pasado', colspan: colFormats.length - colPrevisionCols},
              {label: 'Previsión 4d.', colspan: colPrevisionCols}
            ],
            colHeaderStyle
          ],
          fixedColumnsLeft: fixedColumnsLeft,
          licenseKey: 'non-commercial-and-evaluation', // for non-commercial use only
          afterGetColHeader: afterGetColHeader,
          cells: cells,
          customBorders: [
            {
              // Separador de previsión
              range: {
                from: {
                  row: 0,                                     // Primera fila
                  col: colFormats.length - colPrevisionCols    // Columnas de prevision
                },
                to: {
                  row: rowData.length - 1,                    // Última fila
                  col: colFormats.length - colPrevisionCols    // Columnas de prevision
                },
              },
              left: {
                width: 2,
                color: 'magenta'
              },
            },
          ],
          manualRowResize: true,                  // Resize
          manualColumnResize: true,
        }
        console.log(hotSettings.value)
      })

      return {
        riaId,
        intoxicationTableListLocal,
        hotSettings,
        hansontableRef
      }
    }
  }
</script>

<style lang="css" scoped>

  /* *** Mobile Intoxication Table *** */
  /* #mobile-intoxication-table { */
  #mobile-calendar {
    position: relative;
    left: 0;
    right: 0;
    /* bottom: -240px;  */
    display: block;
    transition: bottom 0.3s;

    /* Font color */
    color: white;
  }


  #mobile-calendar .timecode {
    position: absolute;
    left: 50%;
    top: inherit;
    bottom: 105px;

    width: 80px;
    margin-left: -40px;

    font-size: 18px;
  }

    /* *** Days *** */
  #mobile-calendar #days {
    overflow-x: scroll;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    position: relative;

    /* style */
    height: 240px;
    background-color: rgba(0, 0, 0, 0.63);
  }

  #mobile-calendar #days::-webkit-scrollbar {
    display: none;
  }

  /* Marcador de la hora actual */
  #mobile-calendar #days b {
    position: absolute;
    display: block;
    top: 40px;
    bottom: 0;
    width: 3px;
    background-color: yellow;
    opacity: 0.4;
  }

  /* Each Day: 160px width fix */
  #mobile-calendar #days > div {
    padding: 7px 0 56px 0;
    font-size: 18px;
    display: inline-block;
    max-width: 160px;
    width: 160px;
    height: 32px;
    box-sizing: content-box;
    border-top: solid 40px transparent;
    background-clip: padding-box;
  }

  /* 2º === equivale al primer dia del calendario (Centramos scroll por la izquierda) */
  #mobile-calendar #days > div:nth-child(2) {
    padding-left: 50%;
  }

  /* Ultimo dia (Ajustamos para poder hacer scroll por la derecha) */
  #mobile-calendar #days > div:last-child {
    padding-right: 50%;
  }

  #mobile-calendar #days > div:nth-child(odd) {
    background-color: rgba(0, 0, 0, 0.63);
  }

  #mobile-calendar #days > div:nth-child(even) {
    background-color: rgba(63, 61, 80, 0.76);
  }

  #mobile-calendar #days > div .day {
    display: inline-block;
    position: relative;
    padding-left: 0.5em;
  }

  /* #mobile-calendar #days > div .day .premium-flag {
    position: absolute;
    top: -3px;
    right: -10px;
    font-size: 12px;
  } */

  #mobile-calendar #days ul {
    /* >>> SFR */
    padding-left: 0px;

    font-size: 11px;
    white-space: nowrap;
    display: table;
    width: 100%;
    list-style: none;
    opacity: 0;
    transition: opacity 0.3s;
    width: calc(100% - 12px);
    margin: 6px 4px 0 8px;
  }

  .mobile-calendar-expanded #mobile-calendar #days ul {
    opacity: 0.6;
  }

  #mobile-calendar #days ul li {
    text-align: center;
    display: table-cell;
  }
</style>
