<template>
  <div class="d-flex flex-column">

    <v-card class="d-flex flex-column w-100 rounded-0">

      <!-- Header -->
      <div class="d-flex px-3 py-2">
        <div class="d-flex align-baseline">
          <h3>Células</h3><span class="ml-1">en agua</span>
        </div>

        <v-spacer></v-spacer>
        <!-- <div class="d-flex align-center">
          <v-icon>mdi-monitor-dashboard</v-icon>
        </div> -->
      </div>

      <!-- Body -->
      <div class="d-flex flex-column px-3 py-0">

        <!-- Bucket AD Left + Right -->
        <div class="d-flex">
          <div class="d-flex flex-column">
            <span>
              <span class="font-weight-bold">AVG</span>
              <span class="font-weight-bold" :style="{ 'font-size': '2rem', color: getBucketLeft(forecastLocal).color }">
                <i :class="getBucketLeft(forecastLocal).icon"></i>
                {{ getBucketLeft(forecastLocal).value }}
              </span>
              {{ getHeader(forecastLocal).feedType.feedTypeUnit }}
            </span>
            <span>
              <div class="text-muted" :style="{ 'font-size': '0.75rem' }">{{ getBucketLeft(forecastLocal).text }}</div>
              <!-- <div class="text text-h6">{{ getBucketADPas(forecastLocal).motion }}</div> -->
            </span>
          </div>

          <div class="ml-auto d-flex flex-column text-right">
            <span>
              <span class="font-weight-bold">AVG</span>
              <span class="text-weight-bold" :style="{ 'font-size': '2rem', color: getBucketRight(forecastLocal).color }">
                {{ getBucketRight(forecastLocal).value }}
              </span>
              {{ getHeader(forecastLocal).feedType.feedTypeUnit }}
            </span>
            <span>
              <div class="text-muted" :style="{ 'font-size': '0.75rem' }">{{ getBucketRight(forecastLocal).text }}</div>
            </span>
          </div>
        </div>

        <div class="position-relative">
          <LineChart
            id="chart-tox"
            :data="chartData"
            :options="chartOptions"
          />
        </div>
      </div>

    </v-card>

  </div>
</template>

<script>
  import { computed, ref, watch } from 'vue';

  import { Line as LineChart } from 'vue-chartjs'
  import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip,Legend } from 'chart.js'
  import annotationPlugin from 'chartjs-plugin-annotation';

  ChartJS.register( CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, annotationPlugin)

  export default {
    name: 'forecast-chart-cells-component',
    components: {
      LineChart,
    },
    props: {
      forecast: {
        type: Object,
        default: () => {}
      }
    },
    setup(props) {
      const forecastLocal = ref(JSON.parse(JSON.stringify(props.forecast)));

      // props passed to setup function is reactive object (made probably by reactive()), it's properties are getters.
      // Watching a getter: https://v3.vuejs.org/api/computed-watch-api.html#watching-a-single-source
      watch(() => props.forecast, () => {
        forecastLocal.value = JSON.parse(JSON.stringify(props.forecast));
      });

      const getHeader = (forecast) => forecast?.forecastItemCells?.forecastHeader;
      const getChart = (forecast) => forecast?.forecastItemCells?.forecastChart;

      const getBucketLeft = (forecast) => forecast?.forecastItemCells?.forecastHeader?.forecastBuckets[0];
      const getBucketRight = (forecast) => forecast?.forecastItemCells?.forecastHeader?.forecastBuckets[1];

      const createDatasets = (options, series) => {
        if (options?.datasets && options?.datasets.length === 0) return []

        const datasets = options.datasets.map((dataset, index) => {
          return {
            label: dataset.label,
            backgroundColor: dataset.color || 'rgb(214,28,105)',  // Lila
            borderColor: dataset.color || 'rgb(214,28,105)',
            data: series[index],
            pointRadius: false,
            hidden: index === 0 ? true : false
          }
        })
        return datasets
      }
      const chartData = computed( () => {
        return {
          labels: forecastLocal.value?.forecastItemCells?.forecastChart?.labels,
          datasets: createDatasets(
            forecastLocal.value?.forecastItemCells?.forecastChart?.options,
            forecastLocal.value?.forecastItemCells?.forecastChart?.series
          )
        }
      })

      // Media
      const lineAverageAnnotation = computed( () => {
        const value = getBucketLeft(forecastLocal.value)?.value
        return {
          type: 'line',
          yMin: value,
          yMax: value,
          // scaleID: 'y',
          // value: value,
          borderColor: 'rgba(214,28,105, .5)',
          borderWidth: 1
        }
      })

      return {
        forecastLocal,

        getHeader,
        getChart,

        getBucketLeft,
        getBucketRight,

        chartData,
        chartOptions: {
          maintainAspectRatio: false,
          responsive: true,
          legend: {
            display: true
          },
          interaction: {
            intersect: false,
            mode: 'index', // Para que se vean todos los datos de ese día al pasar con el cursor
          },
          scales: {
            x: {
              display: true,
            },
            y: {
            }
          },
          plugins: {
            annotation: {
              annotations: {
                line: lineAverageAnnotation.value
              }
            }
          }
        }
      }
    }
  }
</script>

<style>

</style>
