import { render, staticRenderFns } from "./ForecastToxinTypeLeyend.vue?vue&type=template&id=33f5aac7&scoped=true&"
import script from "./ForecastToxinTypeLeyend.vue?vue&type=script&lang=js&"
export * from "./ForecastToxinTypeLeyend.vue?vue&type=script&lang=js&"
import style0 from "./ForecastToxinTypeLeyend.vue?vue&type=style&index=0&id=33f5aac7&prod&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33f5aac7",
  null
  
)

export default component.exports