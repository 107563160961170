import useAppConfig from "@/store/useAppConfig";

// Buckets
//  ToxA-Forecast:
//  Analisis
export default function useForecastLeafLet() {

  // Angulo de rotacion del icono en funcion de la prevision de AD
  const getRotationAngle = (forecastData_ToxA_AD) => {

    if (forecastData_ToxA_AD) {
      switch(forecastData_ToxA_AD.icon) {
      case 'fas fa-sort': return 90;
      case 'fas fa-arrow-up': return 45;
      case 'fas fa-angle-double-up': return 45;
      case 'fas fa-arrow-down': return 135;
      case 'fas fa-angle-double-down': return 180;
      }
    }
    return -1
  }

  const { layerPOL } = useAppConfig()

  const getForecastData = (forecast, bucketName) => {
    return forecast?.forecastItemHeader?.forecastHeader?.forecastBuckets.find((forecastBucket) => forecastBucket.name === bucketName)
  }

  // Estilo del Polígono
  // const getForecastStyle = (forecast, fillOpacity=0.8) => {

  //   const forecastData_ToxAForecast = getForecastData(forecast, 'ToxA-Forecast')
  //   // const forecastData_Analisis = getForecastData(forecast, 'Analisis')
  //   let forecastData_Estado = getForecastData(forecast, 'Estado') // Bucket Estado

  //   return {
  //     fillColor: forecastData_ToxAForecast?.color || '#3388ff',
  //     fillOpacity: fillOpacity,
  //     color: forecastData_Estado?.color || '#fff8eb', // Antes forecastData_Analisis?.color
  //     weight: 1, // 3
  //     opacity: 1,
  //     zIndex: 10
  //   }
  // }

  // Opacity: De 0-1  =>  0-Transparent
  // eslint-disable-next-line no-unused-vars
  const getForecastStyle = (forecast, fillOpacity=0.8) => {

    // Border
    let border = undefined
    let borderOpacity = 0

    // Background
    let background = undefined
    let backgroundOpacity = 0

    if (forecast) {

      if (!layerPOL.value?.border) {
        border = 'transparent'
        borderOpacity = 0
      }
      if (layerPOL.value?.border === 'color-estado') {
        border = getForecastData(forecast, 'Estado')?.color
        borderOpacity = 1
      }
      if (layerPOL.value?.border === 'color-plan') {
        border = getForecastData(forecast, 'Plan')?.color
        borderOpacity = 1
      }

      // Background
      if (!layerPOL.value?.background) {
        background = 'transparent'
        backgroundOpacity = 0
      }
      if (layerPOL.value?.background === 'color-estado') {
        background = getForecastData(forecast, 'Estado')?.color
        backgroundOpacity = 1
      }
      if (layerPOL.value?.background === 'color-plan') {
        background = getForecastData(forecast, 'Plan')?.color
        backgroundOpacity = 1
      }
    } else {
      border = 'white'
      borderOpacity = 1
      background = 'transparent'
      backgroundOpacity = 0
    }

    return {
      fillColor: background || 'transparent',
      fillOpacity: backgroundOpacity,
      color: border || 'transparent',
      opacity: borderOpacity,
      weight: 1,
      zIndex: 10
    }
  }
  const featureHoverStyle = {
    //fillColor: '#a7c5f8',
    //color: '#448aff',
    fillColor: '#bdcdc7',
    color: '#016836',
    weight: 2,
  }

  // PMI Style
  const defaultStylePMI = {
    fillColor: '#448aff', // Si no se indica usa la propiedad color
    fillOpacity: 0,
    color: 'yellow',
    weight: 1,
    opacity: 1,
  }
  // eslint-disable-next-line no-unused-vars
  const getPMIStyle = (object) => {
    return defaultStylePMI
  }

  // Rias Style
  const defaultStyleRia = {
    fillColor: '#448aff', // Si no se indica usa la propiedad color
    fillOpacity: 0,
    color: 'green',
    weight: 1,
    opacity: 1,
  }
  // eslint-disable-next-line no-unused-vars
  const getRiaStyle = (object) => {
    return defaultStyleRia
  }

  return {
    getForecastData,
    getRotationAngle, // Angulo de rotacion del icono en funcion de la prevision de AD

    getForecastStyle,
    featureHoverStyle,

    // PMI styling
    getPMIStyle,
    defaultStylePMI,

    // Ria Styling
    getRiaStyle,
    defaultStyleRia
  }
}
