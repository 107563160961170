<template>
  <!-- :fullscreen="$vuetify.breakpoint.xs" -->
  <v-dialog
    v-model="isDialogActiveLocal"
    max-width="500"
    scrollable
    @click:outside="$emit('update:is-dialog-active',false); isDialogActiveLocal = false"
  >
    <!-- <v-bottom-sheet
    v-model="isDialogActiveLocal"
    max-width="500px"
    @click:outside="$emit('update:is-dialog-active',false); isDialogActiveLocal = false"
  > -->
    <template v-slot:activator="{ on, attrs }">

      <!-- Modo: hidden => Se ocula el boton que activa el dialogo, es el Root el encargado de activarlo -->
      <v-btn
        v-if="mode == 'button'"
        v-on="on"
        v-bind="attrs"
        style="margin-left: -0.5rem;"
        height="32px"
        :small="$vuetify.breakpoint.xs"
        :icon="true"
      >
        <!-- <v-icon>mdi-dots-vertical</v-icon> -->
        <v-icon>mdi-cog</v-icon>
      </v-btn>
      <!-- <span class="d-none d-md-block  ml-2" style="font-size: .625rem;">Filtrar <br> {{  getLabelFeedTypeAP(feedTypeAPSelectedLocal) }}</span> -->

      <!-- Modo inline: Se visualiza directamente los botones y no el diálogo -->
      <!-- Ejemplo de visualizar los botones en linea -->
      <div
        v-if="mode == 'inline'"
        class="d-flex flex-row align-center">
        <v-btn
          icon
          :color="layerSimulationStrategyLocal.color"
          @click="onChangesimulationStrategyKey()"
        >
          <v-icon>{{ layerSimulationStrategyLocal.icon }}</v-icon>
        </v-btn>
      </div>
    </template>

    <v-card class="d-flex flex-column w-100 rounded-0 mt-4">
      <v-toolbar :elevation="8" title="Capa" color="rgb(0, 192, 255)">
        <!-- <v-toolbar-title>{{ layerType === 'toxins' ? 'Toxinas POL' : 'Células PMI' }}</v-toolbar-title> -->
        <v-toolbar-title>Partículas</v-toolbar-title>

        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('update:is-dialog-active', false); isDialogActiveLocal = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <!-- Body -->
      <div class="d-flex flex-column px-3 py-3">

        <!-- <div class="d-flex align-center my-3">
          <span class="ml-1">Título y explicación</span>
        </div>
        <v-divider></v-divider> -->

        <div class="d-flex flex-row align-center ml-3 my-1">
          <v-switch
            style="width: 33%;"
            v-model="layerLocal.active"
            label="Mostrar capa"
          ></v-switch>
          <div class="flex-fill d-flex flex-column align-center" style="width: 66%;">

            <span class="text-decoration-underline" :style="{ height: '32px'}">Simulación</span>
            <v-btn-toggle
              v-model="layerLocal.render"
              color="deep-purple accent-3"
              mandatory
            >
              <v-btn
                v-for="(render) in menuOptionsRenderLocal"
                :key="render.value"
                :value="render.value"
                height="32px"
                text
                :disabled="!layerLocal.active"
              >
                <v-icon class="mx-2">{{ render.icon }}</v-icon>
              </v-btn>
            </v-btn-toggle>
            <span :style="{ height: '32px'}">{{ layerRenderLocal.label }}</span>
          </div>
        </div>

        <!--  -->
        <v-divider class="mt-2 ml-6"></v-divider>
        <div class="d-flex flex-row ml-6">

          <!-- Checkbox -->
          <div class="d-flex flex-column" style="width: 50%;">
            <v-checkbox
              v-model="layerLocal.video"
              label="Visualizar video"
              hide-details
              :disabled="true"
            ></v-checkbox>
            <v-checkbox
              v-model="layerLocal.particles"
              label="Visualizar particulas"
              hide-details
              :disabled="true"
            ></v-checkbox>
          </div>
        </div>

        <!-- Explicación -->
        <v-divider class="mt-4 ml-6"></v-divider>
        <div class="d-flex align-center mt-2" :style="{ 'font-size': '0.8rem' }">
          <v-icon size="18">mdi-information</v-icon>
          <p class="ml-2 ma-0">
            *Simulación
            <br><span class="text-decoration-underline">Video</span>: Visualización del video precargado (Recomendado)
            <br><span class="text-decoration-underline">Particulas</span>: Representación de la células en tiempo real
          </p>
        </div>
      </div>

    </v-card>

  </v-dialog>
  <!-- </v-bottom-sheet> -->
</template>

<script>
  import { ref, watch } from 'vue';


  export default {
    name: 'menu-layer-params-dialog-component',
    components: {
    },
    props: {
      mode: {
        type: String,
        required: false,
        default: 'button' // hidden, inline, button
      },
      isDialogActive: {
        type: Boolean,
        required: false,
        default: false
      },
      layerType: {
        type: String,
        required: true, // ['']
        default: 'toxins' // 'toxins' || 'cells' || 'particles'
      },
      layer: {
        type: Object,
        required: true,
        default: () => ({
          active: false,
          simulationStrategyKey: 'A',
        }),
      },
    },
    setup(props, { emit }) {
      const isDialogActiveLocal = ref(props.isDialogActive)
      watch(( () => props.isDialogActive), () => {
        isDialogActiveLocal.value = props.isDialogActive
      })

      // Lista de opciones
      const menuOptionsSimulationStrategy = [
        { value: 'A', label: 'Moderada', color: 'grey', icon: 'mdi-alpha-a-box', text: 'A' },
        { value: 'B', label: 'Extrema', color: 'grey', icon: 'mdi-alpha-b-box', text: 'B' },
      ]
      const menuOptionsSimulationStrategyLocal = ref(menuOptionsSimulationStrategy)

      // Opciones de Renderizado
      const menuOptionsRender = [
        { value: 'video', label: 'Video', color: 'grey', icon: 'mdi-video', text: 'V' },
        { value: 'particles', label: 'Partículas', color: 'grey', icon: 'mdi-dots-hexagon', text: 'P' },
      ]
      const menuOptionsRenderLocal = ref(menuOptionsRender)

      // Layer
      const layerLocal = ref(props.layer)
      // Watch for external changes
      watch(() => props.layer, () => {
        layerLocal.value = props.layer

        setSimulationStrategy(props.layer.simulationStrategyKey)
        setRender(props.layer.render)
      }, { deep: true})

      watch(() => layerLocal.value, () => {
        emit('update:layer', layerLocal.value)
      }, { deep: true})

      // Simulation strategy
      const layerSimulationStrategyLocal = ref(null)
      const setSimulationStrategy = (val) => {
        layerSimulationStrategyLocal.value = menuOptionsSimulationStrategy.find( (element) => element.value == val )
        if (!layerSimulationStrategyLocal.value) {
          layerSimulationStrategyLocal.value = menuOptionsSimulationStrategy[0]
        }
        layerLocal.value.simulationStrategyKey = layerSimulationStrategyLocal.value.value
      }

      // Set initial and Watch for changes
      setSimulationStrategy(props.layer.simulationStrategyKey)

      // Renderizado
      const layerRenderLocal = ref(null)
      const setRender = (val) => {
        layerRenderLocal.value = menuOptionsRender.find( (element) => element.value == val )
        if (!layerRenderLocal.value) {
          layerRenderLocal.value = menuOptionsRender[0]
        }
        layerLocal.value.render = layerRenderLocal.value.value

        // Indicar checks
        layerLocal.value.video = layerRenderLocal.value.value === 'video'
        layerLocal.value.particles = layerRenderLocal.value.value === 'particles'
      }

      // Set initial and Watch for changes
      setRender(props.layer.render)

      return {
        isDialogActiveLocal,

        // Toda la capa
        layerLocal,

        // Simulation Strategy
        layerSimulationStrategyLocal,
        menuOptionsSimulationStrategyLocal,

        // Renderizado de células: Video o Partículas
        layerRenderLocal,
        menuOptionsRenderLocal,
      }
    }
  }
</script>

<style>
.custom-slider .v-label {
  width: 100px; /* Establece el ancho fijo que desees */
  /* min-width: 80px; */
}
</style>
