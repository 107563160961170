<template>
  <div class="d-flex align-center">

    <v-menu
      transition="scale-transition"
      origin="center center"
      v-model="isSelectorOpen"
      open-on-hover
      offset-x
      right
      direction="right"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-on="on"
          v-bind="attrs"
          v-model="isSelectorOpen"
          x-small
          fab
          :color="(baseLayerTileLocal && baseLayerTileLocal.color) || 'blue darken-2'"
        >
          <v-icon v-if="isSelectorOpen">
            mdi-close
          </v-icon>
          <!-- <span v-else>{{ baseLayerLocal ? baseLayerLocal.value : '??' }}</span> -->
          <v-icon v-else>
            {{ baseLayerTileLocal.icon }}
          </v-icon>
        </v-btn>
      </template>

      <!-- <div class="d-flex flex-column" :class="[$vuetify.breakpoint.xs ? 'mb-12' : 'mt-12']"> -->
      <div class="d-flex flex-column-">
        <v-btn
          class="mx-2"
          v-for="(item, index) in baseLayerOptions"
          :key="index"
          fab
          x-small
          :color="item.color"
          @click="mapBaseLayer = item.value"
        >
          <!-- <v-tooltip location="top">{{ item.value }}</v-tooltip> -->
          <v-icon v-if="item.icon">{{ item.icon }}</v-icon>
          <span v-else>{{ item.text }}</span>
        </v-btn>
      </div>
    </v-menu>
  </div>
</template>
<script>
  import { ref, watch } from 'vue';
  import useMap from '@/service/useMap';
  import useAppConfig from '@/store/useAppConfig';

  export default {
    name: 'map-base-layer',
    props: {
    },
    setup() {

      // Only for internal use of control (Change icon when selected)
      const isSelectorOpen = ref(false)

      // >>> Base layer selection: { value: 'openstreetmap', text: 'OS', color: 'blue', image: 'mdi-terrain' }
      const { tileLayerOptions } = useMap();
      const baseLayerOptions = ['openstreetmap', 'mapbox', 'windy', 'google-satellite-only'].map((key) => {
        const tileLayer = tileLayerOptions.find((element) => element.id === key);
        return {
          value: tileLayer?.id || key,
          text: tileLayer?.text || key,
          color: tileLayer?.color ||'info',
          icon: tileLayer?.icon || 'mdi-map',
        };
      });

      // Maps: Mapa base
      const { mapBaseLayer } = useAppConfig()
      watch(() => mapBaseLayer.value, () => {
        onBaseLayerChange(mapBaseLayer.value)
      })

      // BaseLayer Selected
      const baseLayerTileLocal = ref(null) // { id, url, attibutions }
      const onBaseLayerChange = (baseLayer = 'openstreetmap') => {
        baseLayerTileLocal.value = tileLayerOptions.find(item => item.id === baseLayer)
      }
      onBaseLayerChange(mapBaseLayer.value)

      return {
        isSelectorOpen,
        baseLayerOptions,
        mapBaseLayer,
        baseLayerTileLocal,  // BaseLayerTile Selected (Object)

        // baseLayerLocal,
        // onChangeLayerBase
      }
    }
  }
</script>
