

import { ref, watch } from 'vue'
import useAppConfig from '@/store/useAppConfig';

export default function useToxinType(){

  // TODO - Crear una clase para DecorateToxA
  // #00C0FF, #805CFF, #C090FF, #E05CFF, #FFC000, #FF8000, #FF0000
  const DECORATE_TOXA_COLOR = [
    { offset: 0, color: '#00C0FF' },
    { offset: 15, color: '#805CFF' },
    { offset: 35, color: '#C090FF' },
    { offset: 50, color: '#E05CFF' },
    { offset: 65, color: '#FFC000' },
    { offset: 85, color: '#FF8000' },
    { offset: 100, color: '#FF0000' },
  ]
  const getDecorateToxAColor = (value) => {
    return DECORATE_TOXA_COLOR.find((item) => item.offset >= value)?.color || '#FF0000'
  }


  // Rangos leyenda
  const rangoDSP = [40, 80, 120, 160, 200, 300];
  const rangoASP = [5, 10, 15, 20, 25, 37];             // DSP /8
  const rangoPSP = [200, 400, 600, 800, 1000, 1500];    // DSP x5

  const getToxinUM = (toxinType) => {
    if (toxinType === 'DSP') return 'DSP µg equiv AO/kg'  // µg de equiv AO/kg (AO + DTX2) (Lim. 160)
    if (toxinType === 'ASP') return 'ASP mg AD/kg'        // ASP mg AD/kg (Lim.20)
    if (toxinType === 'PSP') return 'PSP µg equiv STX/kg' // µg de equiv STX/kg (Lim. 800)

    return ''
  }
  const getToxinRango = (toxinType) => {
    if (toxinType === 'DSP') return rangoDSP
    if (toxinType === 'ASP') return rangoASP
    if (toxinType === 'PSP') return rangoPSP

    return rangoDSP
  }

  // Global Vuex
  const { toxinType } = useAppConfig()

  // UM + Rango + Límite (Indicamos el del medio)
  const toxinUM = ref(getToxinUM(toxinType.value))
  const toxinRango = ref(getToxinRango(toxinType.value))

  const toxinMin = ref(0)
  const toxinLimit = ref(toxinRango.value[3])
  const toxinMax = ref(toxinRango.value[5])
  watch( () => toxinType.value, () => {
    toxinUM.value = getToxinUM(toxinType.value)
    toxinRango.value = getToxinRango(toxinType.value)
    toxinLimit.value = toxinRango.value[3]
    toxinMax.value = toxinRango.value[5]
  })


  return {
    // Leyend
    toxinUM,
    toxinRango,
    toxinMin,
    toxinLimit,
    toxinMax,

    // Decorate
    getDecorateToxAColor,

    getToxinRango,
  }
}
