import useUtils from "@/service/useUtils"

//  isLayerParticles  -> Visualizar la capa de células en partículas  -> api.empromar.com
//    layerParticlesActive:     Boolean
//
//  isLayerPOL        -> Visualizar la capa de polígonos              -> prediction.empromar.com/api
//    layerPOLActive:     Boolean
//    layerPOLBorder:     [ 'none', 'color-estado', 'color-plan', 'color-simulation-daily', 'color-simulation-forecast' ]
//    layerPOLBackground: [ 'none', 'color-estado', 'color-plan', 'color-simulation-daily', 'color-simulation-forecast' ]
//    layerPOLRender:     [ 'none', 'simulation-daily', 'simulation-forecast', 'name' ]
//
//  isLayerPMI        -> Visualizar la capa de polígonos              -> prediction.empromar.com/api
//    layerPMIActive:     Boolean
//    layerPMIBorder:     [ 'none', 'yellow', 'color-simulation-daily', 'color-simulation-forecast' ]
//    layerPMIBackground: [ 'none', 'color-simulation-daily', 'color-simulation-forecast' ]
//    layerPMIRender:     [ 'none', 'simulation-daily', 'simulation-forecast', 'name' ]

//  Modo Visualización de células
//    feedTypeAP:     [ 'all', 'acuminata', 'acuta', 'caudata', ... ]
//    isTF:           Boolean
//    isGroup:        Boolean


// eslint-disable-next-line no-unused-vars
const {
  getLocalStorageBoolean,
  getLastDateRefDefault, setLastDateRef
} = useUtils()


export default {
  namespaced: true,
  state: {
    // Estado global
    dateRef: getLastDateRefDefault('date-ref'),
    toxinType: localStorage.getItem('toxin-type') ? localStorage.getItem('toxin-type') : 'DSP',   // [DSP, PSP, ASP]
    view: localStorage.getItem('view') ? localStorage.getItem('view') : 'POL',                    // [POL, PMI]
    riaId: Number(localStorage.getItem('riaId')) || undefined,

    // Globlal Settings
    settings: {
      simulationStrategyKey: localStorage.getItem('settings-simulation-strategy-key') || 'A',     // A, B
      periodKey: localStorage.getItem('settings-period-key') || 'P45D',                           // 15, 30, 45,
    },

    // Table Settings (Intoxication and Cells table)
    tableSettings: {
      changeWithMap: getLocalStorageBoolean('table-settings-change-with-map', false),                       // Cambiar tabla al mover el mapa
      changeWithFeature: getLocalStorageBoolean('table-settings-change-with-feature', true),                // Cambiar tabla al seleccionar feature
      fullTableHeight: getLocalStorageBoolean('table-settings-full-table-height', false),                   // Fisualizar toda la tabla (Arousa tiene mucho poligonos)
    },

    // Maps
    mapBaseLayer: localStorage.getItem('map-base-layer') ? localStorage.getItem('map-base-layer') : 'openstreetmap',

    // Layers
    layerParticles: {
      active: getLocalStorageBoolean('layer-particles-active', true),

      render: localStorage.getItem('layer-particles-render') || 'video',  // [video, particles]
      video: getLocalStorageBoolean('layer-particles-video', undefined),
      particles: getLocalStorageBoolean('layer-particles-particles', true),

      // particlesOnVideoPaused: getLocalStorageBoolean('layer-particles-video', false),
      // simulationStrategyKey: localStorage.getItem('layer-particles-simulation-strategy-key') || 'A',
    },
    layerPOL: {
      active: getLocalStorageBoolean('layer-pol-active', true),
      border: localStorage.getItem('layer-pol-border') ? localStorage.getItem('layer-pol-border') : 'color-estado',
      background: localStorage.getItem('layer-pol-background') ? localStorage.getItem('layer-pol-background') : undefined,
      render: localStorage.getItem('layer-pol-render') ? localStorage.getItem('layer-pol-render') : 'simulation-daily',       //'simulation-forecast'

      isBorderAlwaysVisible: getLocalStorageBoolean('layer-pol-is-border-always-visible', true),
      isRenderHiddenOnZoom: getLocalStorageBoolean('layer-pol-is-render-hidden-on-zoom', true),
    },
    layerPMI: {
      active: getLocalStorageBoolean('layer-pmi-active', false),
      border: localStorage.getItem('layer-pmi-border') ? localStorage.getItem('layer-pmi-border') : 'border',
      background: localStorage.getItem('layer-pmi-background') ? localStorage.getItem('layer-pmi-background') : undefined,
      render: localStorage.getItem('layer-pmi-render') ? localStorage.getItem('layer-pmi-render') : 'simulation-daily',

      isBorderAlwaysVisible: getLocalStorageBoolean('layer-pmi-is-border-always-visible', true),
      isRenderHiddenOnZoom: getLocalStorageBoolean('layer-pmi-is-render-hidden-on-zoom', true),
    },

    // infoTable por rías
    infoTable: localStorage.getItem('info-table') ? localStorage.getItem('info-table') : [],

    // Células
    cellsOptions: {
      feedTypeAP: localStorage.getItem('cells-options-feed-type-ap') ? localStorage.getItem('cells-options-feed-type-ap') : 'all',  // Debería llamarse feedTypeAPKey
      isTF: getLocalStorageBoolean('cells-options-is-tf', true),
      isGroup: getLocalStorageBoolean('cells-options-is-group', true),

      feedTypeAPOptions: JSON.parse(localStorage.getItem('cells-options-feed-type-ap-options')) || [],  // Array(FeedTypeAp)
    },
  },
  mutations: {
    // Global
    UPDATE_DATE_REF(state, value) {
      state.dateRef = value
      setLastDateRef('date-ref', value)       // localStorage.setItem('date-ref', value)
    },
    UPDATE_TOXIN_TYPE(state, value) {
      state.toxinType = value
      localStorage.setItem('toxin-type', value)
    },
    UPDATE_VIEW(state, value) {
      state.view = value
      localStorage.setItem('view', value)
    },
    UPDATE_RIA_ID(state, value) {
      state.riaId = value
      localStorage.setItem('riaId', value)
    },


    // Settings
    UPDATE_SETTINGS(state, value) {
      state.settings = value
      localStorage.setItem('settings-simulation-strategy-key', value.simulationStrategyKey)
      localStorage.setItem('settings-period-key', value.periodKey)
    },
    UPDATE_SETTINGS_SIMULATION_STRATEGY_KEY(state, value) {
      state.settings.simulationStrategyKey = value
      localStorage.setItem('settings-simulation-strategy-key', value)
    },

    // Table (Intoxication and Cells)
    UPDATE_TABLE_SETTINGS(state, value) {
      state.table = value
      localStorage.setItem('table-settings-change-with-map', value.changeWithMap)
      localStorage.setItem('table-settings-change-with-feature', value.changeWithFeature)
      localStorage.setItem('table-settings-full-table-height', value.fullTableHeight)
    },

    // Maps
    UPDATE_MAP_BASE_LAYER(state, value) {
      state.mapBaseLayer = value
      localStorage.setItem('map-base-layer', value)
    },

    // Layers
    UPDATE_LAYER_PARTICLES(state, value) {
      state.layerParticles = value
      localStorage.setItem('layer-particles-active', value.active)
      localStorage.setItem('layer-particles-render', value.render)
      localStorage.setItem('layer-particles-particles', value.particles)
      localStorage.setItem('layer-particles-video', value.video)
      localStorage.setItem('layer-particles-simulation-strategy-key', value.simulationStrategyKey)
    },
    // UPDATE_LAYER_PARTICLES_PARTICLES(state, value) {
    //   state.layerParticles.particles = value
    //   localStorage.setItem('layer-particles-particles', value)
    // },
    // UPDATE_LAYER_PARTICLES_VIDEO(state, value) {
    //   state.layerParticles.video = value
    //   localStorage.setItem('layer-particles-video', value)
    // },
    // UPDATE_LAYER_PARTICLES_SIMULATION_STRATEGY_KEY(state, value) {
    //   state.layerParticles.simulationStrategyKey = value
    //   localStorage.setItem('layer-particles-simulation-strategy-key', value)
    // },

    UPDATE_LAYER_POL(state, value) {
      state.layerPOL = value
      localStorage.setItem('layer-pol-active', value.active)
      localStorage.setItem('layer-pol-border', value.border)
      localStorage.setItem('layer-pol-background', value.background)
      localStorage.setItem('layer-pol-render', value.render)

      localStorage.setItem('layer-pol-is-border-always-visible', value.render)
      localStorage.setItem('layer-pol-is-render-hidden-on-zoom', value.render)
    },
    UPDATE_LAYER_PMI(state, value) {
      state.layerPMI = value
      localStorage.setItem('layer-pmi-active', value?.active)
      localStorage.setItem('layer-pmi-border', value?.border)
      localStorage.setItem('layer-pmi-background', value?.background)
      localStorage.setItem('layer-pmi-render', value?.render)

      localStorage.setItem('layer-pmi-is-border-always-visible', value.render)
      localStorage.setItem('layer-pmi-is-render-hidden-on-zoom', value.render)
    },

    // InfoTable
    UPDATE_INFO_TABLE(state, value) {
      state.infoTable = value
      localStorage.setItem('info-table', value)
    },

    // Células
    UPDATE_CELLS_OPTIONS(state, value) {
      state.cellsOptions = value
      localStorage.setItem('cells-options-feed-type-ap', value?.feedTypeAP)
      localStorage.setItem('cells-options-feed-type-ap-options', JSON.stringify(value?.feedTypeAPOptions))
      localStorage.setItem('cells-options-is-tf', value?.isTF)
      localStorage.setItem('cells-options-is-group', value?.isGroup)
    },
    UPDATE_CELLS_OPTIONS_FEED_TYPE_AP(state, value) {
      state.cellsOptions.feedTypeAP = value
      localStorage.setItem('cells-options-feed-type-ap', value)
    },
  },
}
