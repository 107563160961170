<template>
  <div>
    <v-progress-linear
      v-model="valueLocal"
      :color="colorLocal"
      height="25"
      style="width: 80px; height: 1.2rem; left: unset; transform: unset"
    >
      <template v-slot:default="{ value }">
        <strong>{{ Math.ceil(value) }}%</strong>
      </template>
    </v-progress-linear>
  </div>
</template>

<script>
  import { ref, watch } from 'vue';

  export default {
    name: 'ChartProgress',
    props: {
      value: {
        type: Number,
        default: 0
      },
      color: {
        type: String,
        default: '#FFF'
      }
    },
    setup(props) {
      const valueLocal = ref(props.value)
      const colorLocal = ref(props.color)
      watch([
        () => props.value,
        () => props.color,
      ], () => {
        valueLocal.value = props.value
        colorLocal.value = props.color
      })
      return {
        valueLocal,
        colorLocal
      }
    }
  }
</script>
