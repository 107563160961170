<template>
  <div>
    <!-- Título -->
    <!-- style="background: #00C0FF; border-bottom-left-radius: 10px; border-top-left-radius: 10px;" -->
    <!-- style="background: #00C0FF; min-width: 160px;" -->
    <div
      style="position: absolute; left: 14px; bottom: 42px; cursor: pointer; user-select: none;"
    >
      <ForecastCardCellsParams
        mode="none"
        :ria="{ name: 'Células'}"
        :feedTypeAPs="cellsOptions.feedTypeAPOptions"
        :cellsOptions.sync="cellsOptions"
        :isDialogActive.sync="isDialogCellsActive"
      />
      <div class="d-flex flex-row align-center" @click="isDialogCellsActive = true">
        <!-- <v-icon color="green">mdi-grain</v-icon> -->
        <v-icon color="blue">mdi-bacteria-outline</v-icon>
        <span class="text-decoration-underline ml-2">{{ feedTypeAPName }}</span>
      </div>
    </div>

    <!-- Giramos para poner en vertical -->
    <div
      style="
        position: absolute;
        bottom: 60px;
        transform: rotate(270deg);
        width: 300px;
        left: 26px;
        transform-origin: left;
      "
      id="legend-mobile"
      class="metric-legend bottom-border d-flex align-center">

      <!-- Título -->
      <!-- style="background: #00C0FF; border-bottom-left-radius: 10px; border-top-left-radius: 10px;" -->
      <!-- <div
        class="px-2"
        style="background: #00C0FF; min-width: 160px;"
      >
        <ForecastCardCellsParams
          mode="none"
          :ria="{ name: 'Células'}"
          :feedTypeAPs="cellsOptions.feedTypeAPOptions"
          :cellsOptions.sync="cellsOptions"
          :isDialogActive.sync="isDialogCellsActive"
        >
        </ForecastCardCellsParams>
        <span class="text-decoration-underline" @click="isDialogCellsActive = true">{{ feedTypeAPName }}</span>
      </div> -->

      <!-- Giramos lo números para que se lean mejor -->
      <div
        style="pointer-events: none; border-radius: 10px; padding: 2px 0; font-size: 12px;"
        class="d-flex justify-space-around align-center w-100"
        :style="{ background: gradientColor }"
      >

        <!-- Tick del límite legal: limit-h || limit-v -->
        <!-- Giramos lo números para que se lean mejor -->
        <span v-for="(value, index) in toxinRango"
              :key="index"
              :class="[toxinLimit === value ? 'limit-h' : '']"
              :style="{ transform: !isNaN(parseFloat(value)) ? 'rotate(90deg)' : '' }"
        >
          {{ value }}

          <!-- Tick del límite legal -->
          <!-- <b></b> -->
        </span>
      </div>
    </div>
  </div>

</template>

<script>
  import { computed, ref, watch } from 'vue'

  // Componentes
  import MenuLayerParamsParticlesDialog from '@/components/MenuLayerParamsParticlesDialog.vue';
  import MenuLayer from '@/components/MenuLayer.vue';
  import ForecastCardCellsParams from '@/components/ForecastCardCellsParams.vue';

  // Servicios
  import useAppConfig from '@/store/useAppConfig';

  export default {
    name: "forecast-leyend",
    components: {
      // eslint-disable-next-line vue/no-unused-components
      MenuLayerParamsParticlesDialog,
      // eslint-disable-next-line vue/no-unused-components
      MenuLayer,
      // eslint-disable-next-line vue/no-unused-components
      ForecastCardCellsParams
    },
    setup() {
      const isDialogCellsActive = ref(false)
      // Opciones de célula: [ 'All', 'Acuminata', 'Acuta', 'Caudata'] + Aplicar TF (Toxicidad por Fitoplancton)
      const { cellsOptions } = useAppConfig()


      // TODO - LISTA GLOBAL FeedTypeList
      const feedTypeAPOptions = [
        // DSP
        { value: 'ef-feed:fito-acuminata-sim', label: 'Acuminata', videoCode: '9'  },
        { value: 'ef-feed:fito-acuta-sim', label: 'Acuta', videoCode: '10' },
        { value: 'ef-feed:fito-caudata-sim', label: 'Caudata', videoCode: '11' },

        // PSP
        { value: 'ef-feed:fito-alexandrium-sim', label: 'Alexandrium', videoCode: '14' },
        { value: 'ef-feed:fito-gym-catenatum-sim', label: 'Gym Catenatum', videoCode: '15' },

        // ASP
        { value: 'ef-feed:fito-pseudo-nitz-sim', label: 'Pseudo Nitz', videoCode: '13' },
      ]
      const feedTypeAPName = computed(() => {
        const feedTypeAP = feedTypeAPOptions.find((item) => item.value === cellsOptions.value.feedTypeAP)
        return feedTypeAP?.label || 'default'
      })

      const { layerParticles } = useAppConfig()

      // Colors
      // const gradientColor = ref('linear-gradient(to right, #00C0FF, #805CFF, #C090FF, #E05CFF, #FFC000, #FF8000, #FF0000)')
      // const gradientColor = ref('linear-gradient(to right, blue, green, orange, red)')
      const gradientColor = ref('linear-gradient(to right, #00C0FF, green, orange, red)')

      // Rangos leyenda
      const rangoCells = [-12, -6, 'Profundidad (m.)', -3, 0];
      const rangoDSP = [0, -3, -6, -9, -12];
      const rangoASP = [5, 10, 15, 20, 25, 37];             // DSP /8
      const rangoPSP = [200, 400, 600, 800, 1000, 1500];    // DSP x5


      const getToxinUM = (toxinType) => {
        if (toxinType === 'cells') return 'Acuminata'
        if (toxinType === 'DSP') return 'DSP µg equiv AO/kg'  // µg de equiv AO/kg (AO + DTX2) (Lim. 160)
        if (toxinType === 'ASP') return 'ASP mg AD/kg'        // ASP mg AD/kg (Lim.20)
        if (toxinType === 'PSP') return 'PSP µg equiv STX/kg' // µg de equiv STX/kg (Lim. 800)

        return ''
      }
      const getToxinRango = (toxinType) => {
        if (toxinType === 'cells') return rangoCells
        if (toxinType === 'DSP') return rangoDSP
        if (toxinType === 'ASP') return rangoASP
        if (toxinType === 'PSP') return rangoPSP

        return rangoDSP
      }

      // Global Vuex
      // const { toxinType } = useAppConfig()
      const toxinType = ref('cells')

      // UM + Rango + Límite (Indicamos el del medio)
      const toxinUM = ref(getToxinUM(toxinType.value))
      const toxinRango = ref(getToxinRango(toxinType.value))
      const toxinLimit = ref(toxinRango.value[3])
      watch( () => toxinType.value, () => {
        toxinUM.value = getToxinUM(toxinType.value)
        toxinRango.value = getToxinRango(toxinType.value)
        toxinLimit.value = toxinRango.value[3]
      })


      return {
        isDialogCellsActive,
        // Opciones de células
        cellsOptions,
        feedTypeAPName,

        // Configuración de las partículas
        layerParticles,

        // Leyend
        toxinUM,
        toxinRango,
        toxinLimit,

        gradientColor
      }
    },
  }
</script>



<style lang="css" scoped>


  /* *** Leyend *** */
  #legend-mobile {
    /* pointer-events: none; */
    cursor: pointer;

    /* Esto ya se indica en el contenedor */
    /* position: fixed;
    left: 0;
    right: 0;
    bottom: 0; */
  }

  .metric-legend {
    /* width: 100%; */
    color: white;
    white-space: nowrap;
    font-size: 14px;
    /* background-color: #7c7c7c; */
    /* box-shadow: 0 0 4px 0 black; */

    height: 18px;
  }

  /* Tick indicador del límite legal */
  #legend-mobile .limit-v b {
    position: absolute;
    display: block;
    top: 0px;
    bottom: 0;

    width: 3px;

    background-color: red;
    opacity: 0.6;
  }
  #legend-mobile .limit-h b {
    position: absolute;
    display: block;
    /* top: 0px; */
    bottom: 0;

    /* Raya Horizontal: Indicamos ancho y poca altura*/
    width: 25px;
    height: 4px;

    background-color: red;
    opacity: 0.8;
  }

</style>
