<template>
  <div class="d-flex w-100" style="">
    <BarChart
      id="my-chart-box-plot"
      :data="chartData"
      :options="chartOptions"
    />
  </div>
</template>

  <script>
  import { ref, watch, computed } from 'vue'

  import { Bar as BarChart } from 'vue-chartjs';
  import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip,Legend } from 'chart.js'
  import annotationPlugin from 'chartjs-plugin-annotation';

  ChartJS.register( CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, annotationPlugin)

  export default {
    name: 'BoxPlotChartH',
    components: {
      BarChart,
    },
    props: {
      median: {
        type: Number,
        default: 100,
      },
      // RealTime o Prev72h
      formula: {
        type: Object,
        default: () => ({ toxValue: 100, toxValueME: 20, toxValueHigh: 10, toxValueLow: 10 })
      },
    },
    setup(props) {
      const medianLocal = ref(props.median)
      watch(() => props.median, () => {
        medianLocal.value = props.median
      })

      const formulaLocal = ref(props.formula)
      watch(() => props.formula, () => {
        formulaLocal.value = props.formula
      }, { deep: true })

      // const randomValues = (count, min, max) => {
      //   const delta = max - min;
      //   return Array.from({length: count}).map(() => Math.random() * delta + min);
      // }


      const getBoxPlotDataItem = (formula) => {
        // const valueIntecmar = parseInt(value)
        // const value = formula.toxValue
        return {
          min: formula?.toxValue - formula?.toxValueME,
          q1: formula?.toxValue - (formula?.toxValueME / 2),
          median: formula?.toxValue,                            // Mediana
          mean: formula?.toxValue,                              // Media
          q3: formula?.toxValue + (formula?.toxValueME / 2),
          max: formula?.toxValue + formula?.toxValueME,
          items: [],
        }
      }

      const chartData = computed( () => {
        return {
          labels: [''],
          datasets: [
            {
              label: 'Box',
              type: 'boxplot',

              // Rectangle
              borderColor: 'rgb(44,97,188)',
              backgroundColor: 'transparent', //'rgb(44,97,188, 0.5)',
              borderWidth: 1,

              // Outer: item style used to render outliers
              outlierStyle: 'circle',
              outlierBackgroundColor: 'red',
              outlierBorderColor: 'red',

              // Items a partir de los cuales se calcula la media y los cuartos
              // Item: item style used to render items
              // itemStyle: 'triangle',
              // itemRadius: 3,


              // Mean(Promedio): item style used to render mean dot
              // meanStyle: 'circle',
              // meanBackgroundColor: 'blue',
              // meanBorderColor: 'blue',

              data: [getBoxPlotDataItem(formulaLocal.value)],
              hidden: false
            },
          ],
        }
      })

      // Tooltip para BoxPlot (Min - Med - Máx)
      const tooltipCallbackLabel = (context) => {
        if (context.dataset.type === 'boxplot') {
          let label = context.dataset.label || '';
          if (label) {
            label += ': '
          }
          const data = context.dataset.data[context.dataIndex]
          if (data) {
            label += `(${data.min} - ${data.median} - ${data.max})`
          }
          return label
        }
        return
      }

      // Labeling
      const getAnnotationLabel = (value, content, fontSize, yValue, position, color) => {
        const roundValue = Math.round(value)
        // let contentValue = Math.round(content)
        // if (position !== 'center') {
        //   contentValue = content > 0 ? `+${contentValue.toString()}` : contentValue.toString()
        // }
        return {
          type: 'label',
          xValue: roundValue,
          yValue: yValue,
          content: [roundValue],
          color: color,
          font: {
            size: fontSize,
            weight: position === 'center' ? 'bold' : 'normal',
          },
          position: position
        }
      }

      // ChartOptions: https://www.chartjs.org/docs/latest/configuration/
      // Annotations: https://www.chartjs.org/chartjs-plugin-annotation/latest/guide/types/label.html https://github.com/chartjs/chartjs-plugin-annotation/blob/master/src/types/label.js
      const chartOptions = computed( () => {
        return {
          plugins: {
            tooltip: {
              enabled: false,
              callbacks: {
                label: tooltipCallbackLabel
              }
            },
            legend: {
              display: false,
            },
            annotation: {
              annotations: {
                labelMin: getAnnotationLabel(formulaLocal.value?.toxValue -formulaLocal.value?.toxValueME, -formulaLocal.value?.toxValueME, 12, 0.1, 'start', 'black'),
                labelCen: getAnnotationLabel(formulaLocal.value?.toxValue, formulaLocal.value?.toxValue, 16, 0, 'center', formulaLocal.value?.toxColor || 'black'),
                labelMax: getAnnotationLabel(formulaLocal.value?.toxValue + formulaLocal.value?.toxValueME, formulaLocal.value?.toxValueME, 12, 0.5, 'end', 'black'),
              }
            }
          },
          indexAxis: 'y',
          scales: {
            x: {
              display: false,
              beginAtZero: true,
              min: Math.floor(formulaLocal.value?.toxValue - formulaLocal.value?.toxValueME),
              max: Math.ceil(formulaLocal.value?.toxValue + formulaLocal.value?.toxValueME),
              grid:{
                display: false
              },
            },
            y: {
              display: false,
              grid:{
                display: false
              },
            }
          }
        }
      })

      return {
        chartData,
        chartOptions
      }
    }
  }
  </script>
