<template>
  <div
    id="legend-mobile"
    class="metric-legend bottom-border d-flex align-center">

    <div
      class="px-2"
      style="background: #00C0FF; min-width: 160px;"
    >
      {{ toxinUM }}
    </div>
    <div class="d-flex justify-space-around align-center w-100"
         style="background: linear-gradient(to right, #00C0FF, #805CFF, #C090FF, #E05CFF, #FFC000, #FF8000, #FF0000);"
    >
      <!-- <span>0</span> -->
      <!-- Tick del límite legal: limit-h || limit-v -->
      <span v-for="(value, index) in toxinRango" :key="index" :class="[toxinLimit === value ? 'limit-h' : '']">
        {{ value }}

        <!-- Tick del límite legal -->
        <b></b>
      </span>
    </div>
  </div>

</template>

<script>
  import useToxinType from '@/service/useToxinType'

  export default {
    name: "map-forecast-toxintype-leyend",
    props: {
    },
    setup() {
      const { toxinUM, toxinRango, toxinLimit} = useToxinType()
      return {
        // Leyend
        toxinUM,
        toxinRango,
        toxinLimit,
      }
    },
  }
</script>



<style lang="css" scoped>


  /* *** Leyend *** */
  #legend-mobile {
    pointer-events: none;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0; /* Important*/
  }

  .metric-legend {
    /* width: 100%; */
    color: white;
    background-color: #7c7c7c;
    white-space: nowrap;
    font-size: 14px;
    box-shadow: 0 0 4px 0 black;

    height: 18px;
  }

  /* Tick indicador del límite legal */
  #legend-mobile .limit-v b {
    position: absolute;
    display: block;
    top: 0px;
    bottom: 0;

    width: 3px;

    background-color: red;
    opacity: 0.6;
  }
  #legend-mobile .limit-h b {
    position: absolute;
    display: block;
    /* top: 0px; */
    bottom: 0;

    /* Raya Horizontal: Indicamos ancho y poca altura*/
    width: 25px;
    height: 4px;

    background-color: red;
    opacity: 0.8;
  }

</style>
