<template>
  <div>

    <!-- Login dialog Form -->
    <!-- :user.sync="user" -->
    <AuthLogin
      :isDialogActive.sync="isDialogLoginVisible"
      @close="isDialogLoginVisible = false"
    />

    <!-- Login button -->
    <!-- x-small
    fab -->
    <v-btn
      class="my-1"
      color="blue"
      @click="isDialogLoginVisible = true"
      v-if="!user"
    >
      <span>LOGIN</span>
      <v-icon class="pl-1" color="white">mdi-login</v-icon>
    </v-btn>

    <!-- User menu (If loging) -->
    <v-menu
      v-if="user"
      offset-y
      left
      nudge-bottom="14"
      min-width="230"
      content-class="user-profile-menu-content"
    >
      <template v-slot:activator="{ on, attrs }">

        <!-- User info -->
        <v-avatar
          v-show="user"
          size="40px"
          color="primary"
          class="v-avatar-light-bg primary--text"
          v-bind="attrs"
          v-on="on"
        >
          <v-img :src="require('@/assets/avatar.png')"></v-img>
        </v-avatar>
        <!-- <v-btn
          color="blue"
          x-small
          fab
          v-bind="attrs"
          v-on="on"
        >
          <v-icon color="white">mdi-account</v-icon>
        </v-btn> -->
      </template>

      <v-list>
        <div class="pb-3 pt-2">
          <v-avatar
            size="40px"
            color="primary"
            class="ms-4 v-avatar-light-bg primary--text"
          >
            <v-img :src="require('@/assets/avatar.png')"></v-img>
          </v-avatar>
          <div
            class="d-inline-flex flex-column justify-center ms-3"
            style="vertical-align:middle"
          >
            <span class="text--primary font-weight-semibold mb-n1">
              {{ user.firstName }}
            </span>
            <small class="text--disabled">
              {{ user.email }}
            </small>
          </div>
        </div>

        <v-divider></v-divider>

        <!-- Profile -->
        <v-list-item href="#">
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              mdi-account-outline
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Profile</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- Settings -->
        <v-list-item href="#" @click="isDialogSettingsActive = true;">
          <v-list-item-icon class="me-2">
            <!-- <v-icon size="22">
              mdi-cog-outline
            </v-icon> -->
            <GlobalSettingsDialog
              mode="button"
              :isDialogActive.sync="isDialogSettingsActive"
              :settings.sync="settings"
            />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Settings</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- <v-divider class="mt-2"></v-divider>
        <div class="d-flex flex-row align-center ml-4 mt-4 mb-2">
          <GlobalSettingsDialog
            mode="button"
            :settings="settings"
          />
          <span>Configuraciones globales</span>
        </div> -->

        <v-divider class="my-2"></v-divider>

        <!-- Logout -->
        <v-list-item @click="onLogout">
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              mdi-logout
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
  import { ref, watch } from 'vue'

  // Components
  import AuthLogin from '@/components/auth/AuthLogin.vue'
  import GlobalSettingsDialog from '../GlobalSettingsDialog.vue'

  // Services
  import useAppConfig from '@/store/useAppConfig'

  export default {
    components: {
      AuthLogin,
      GlobalSettingsDialog
    },
    props: {
    },
    setup() {

      // Settings
      const { settings } = useAppConfig()
      const isDialogSettingsActive = ref(false)

      // Login control
      const { user } = useAppConfig()
      watch(() => user.value, () => {
        isDialogLoginVisible.value = (user.value === null)
      })

      // Si no está enseñamos LOGIN
      // const isDialogLoginVisible = computed(() => user.value === null) // No puede ser computada porque se puede setear manualmente
      const isDialogLoginVisible = ref(user.value === null)

      const onLogout = () => {
        localStorage.removeItem('token');

        // VUEX update
        user.value = null
      }
      return {
        isDialogLoginVisible,
        user,
        onLogout,

        // Settings
        settings,
        isDialogSettingsActive
      }
    },
  }
</script>

<style lang="scss">
  .user-profile-menu-content {
    .v-list-item {
      min-height: 2.5rem !important;
    }
  }
</style>
