<template>
  <!-- :fullscreen="$vuetify.breakpoint.xs" -->
  <v-dialog
    v-model="isDialogActiveLocal"
    max-width="500"
    scrollable
    @click:outside="$emit('update:is-dialog-active',false); isDialogActiveLocal = false"
  >
    <!-- <v-bottom-sheet
    v-model="isDialogActiveLocal"
    max-width="500px"
    @click:outside="$emit('update:is-dialog-active',false); isDialogActiveLocal = false"
  > -->
    <template v-slot:activator="{ on, attrs }">

      <!-- Modo: hidden => Se ocula el boton que activa el dialogo, es el Root el encargado de activarlo -->
      <v-btn
        v-if="mode == 'button'"
        v-on="on"
        v-bind="attrs"
        style="margin-left: -0.5rem;"
        height="32px"
        :small="$vuetify.breakpoint.xs"
        :icon="true"
      >
        <!-- <v-icon>mdi-dots-vertical</v-icon> -->
        <v-icon>mdi-cog</v-icon>
      </v-btn>
      <!-- <span class="d-none d-md-block  ml-2" style="font-size: .625rem;">Filtrar <br> {{  getLabelFeedTypeAP(feedTypeAPSelectedLocal) }}</span> -->

      <!-- Modo inline: Se visualiza directamente los botones y no el diálogo -->
      <!-- Ejemplo de visualizar los botones en linea -->
      <div
        v-if="mode == 'inline'"
        class="d-flex flex-row align-center">
        <v-btn
          icon
          :color="settingsSimulationStrategyLocal.color"
          @click="onChangesimulationStrategyKey()"
        >
          <v-icon>{{ settingsSimulationStrategyLocal.icon }}</v-icon>
        </v-btn>
      </div>
    </template>

    <v-card class="d-flex flex-column w-100 rounded-0 mt-4">
      <v-toolbar :elevation="8" title="Capa" color="rgb(0, 192, 255)">
        <!-- <v-toolbar-title>{{ layerType === 'toxins' ? 'Toxinas POL' : 'Células PMI' }}</v-toolbar-title> -->
        <v-toolbar-title>Partículas</v-toolbar-title>

        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('update:is-dialog-active', false); isDialogActiveLocal = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <!-- Body -->
      <div class="d-flex flex-column px-3 py-3">

        <!-- <div class="d-flex align-center my-3">
          <span class="ml-1">Título y explicación</span>
        </div>
        <v-divider></v-divider> -->

        <!-- <v-divider class="mt-2 ml-6"></v-divider> -->
        <div class="d-flex flex-row align-center ml-3 my-1">
          <!-- <span style="width: 33%;">Reproducción</span> -->
          <span
            class="text-decoration-underline"
            :style="{ height: '32px', width: '33%' }"
          >
            Reproducción
          </span>
          <div class="flex-fill d-flex flex-column align-center" style="width: 66%;">

            <!-- <span class="text-decoration-underline" :style="{ height: '32px'}">Reproducción</span> -->
            <v-btn-toggle
              v-model="settingsLocal.simulationStrategyKey"
              color="deep-purple accent-3"
              mandatory
            >
              <v-btn
                v-for="(simulationStrategy) in menuOptionsSimulationStrategyLocal"
                :key="simulationStrategy.value"
                :value="simulationStrategy.value"
                height="32px"
                text
              >
                <v-icon class="mx-2">{{ simulationStrategy.icon }}</v-icon>
              </v-btn>
            </v-btn-toggle>
            <span :style="{ height: '32px'}">{{ settingsSimulationStrategyLocal.label }}</span>
          </div>
        </div>

        <v-divider class="mt-2 ml-6"></v-divider>
        <div class="d-flex flex-row align-center ml-3 my-1">
          <span
            class="text-decoration-underline"
            :style="{ height: '32px', width: '50%' }"
          >
            Periodo
          </span>

          <v-select
            v-model="settingsLocal.periodKey"
            :items="menuOptionsPeriodLocal"
            item-text="label"
            item-value="value"
            outlined
            hide-details
            class="select-router-transition"
          ></v-select>
        </div>

        <!-- Explicación -->
        <v-divider class="mt-4 ml-6"></v-divider>
        <div class="d-flex align-center mt-2" :style="{ 'font-size': '0.8rem' }">
          <v-icon size="18">mdi-information</v-icon>
          <p class="ml-2 ma-0">
            *Reproducción
            <br> El modo de reproducción se usa en el Gemelo Digital (Simulador de fitoplancton).
          </p>
        </div>
      </div>

    </v-card>

  </v-dialog>
  <!-- </v-bottom-sheet> -->
</template>

<script>
  import { ref, watch } from 'vue';


  export default {
    name: 'menu-layer-params-dialog-component',
    components: {
    },
    props: {
      mode: {
        type: String,
        required: false,
        default: 'button' // hidden, inline, button
      },
      isDialogActive: {
        type: Boolean,
        required: false,
        default: false
      },
      settings: {
        type: Object,
        required: true,
        default: () => ({
          simulationStrategyKey: 'A',
          periodKey: 'P15D',
        }),
      },
    },
    setup(props, { emit }) {
      const isDialogActiveLocal = ref(props.isDialogActive)
      watch(( () => props.isDialogActive), () => {
        isDialogActiveLocal.value = props.isDialogActive
      })

      // Lista de opciones
      const menuOptionsSimulationStrategy = [
        { value: 'A', label: 'Moderada', color: 'grey', icon: 'mdi-alpha-a-box', text: 'A' },
        { value: 'B', label: 'Extrema', color: 'grey', icon: 'mdi-alpha-b-box', text: 'B' },
      ]
      const menuOptionsSimulationStrategyLocal = ref(menuOptionsSimulationStrategy)

      // Lista de opciones
      const menuOptionsPeriod = [
        { value: null, label: 'Todo' },
        { value: 'P7D', label: '7 días' },
        { value: 'P15D', label: '15 días' },
        { value: 'P30D', label: '30 días' },
        { value: 'P45D', label: '45 días' },
        { value: 'P2M', label: '2 meses' },
        { value: 'P3M', label: '3 meses' },
        { value: 'P6M', label: '6 meses' },
        { value: 'P1Y', label: '1 año' }
      ]
      const menuOptionsPeriodLocal = ref(menuOptionsPeriod)


      // Layer
      const settingsLocal = ref(props.settings)
      // Watch for external changes
      watch(() => props.settings, () => {
        settingsLocal.value = props.settings

        setSimulationStrategy(props.settings.simulationStrategyKey)
        setPeriod(props.settings.periodKey)
      }, { deep: true})

      watch(() => settingsLocal.value, () => {
        emit('update:settings', settingsLocal.value)
      }, { deep: true})


      // *** Simulation strategy
      const settingsSimulationStrategyLocal = ref(null)
      const setSimulationStrategy = (val) => {
        settingsSimulationStrategyLocal.value = menuOptionsSimulationStrategy.find( (element) => element.value == val )
        if (!settingsSimulationStrategyLocal.value) {
          settingsSimulationStrategyLocal.value = menuOptionsSimulationStrategy[0]
        }
        settingsLocal.value.simulationStrategyKey = settingsSimulationStrategyLocal.value.value
      }

      // Set initial and Watch for changes
      setSimulationStrategy(props.settings.simulationStrategyKey)


      // *** Period
      const settingsPeriodLocal = ref(null) // Esta var no se está usando pero podríamos usar todos sus valores
      const setPeriod = (val) => {
        settingsPeriodLocal.value = menuOptionsPeriod.find( (element) => element.value == val )
        if (!settingsPeriodLocal.value) {
          settingsPeriodLocal.value = menuOptionsPeriod[0]
        }
        settingsLocal.value.periodKey = settingsPeriodLocal.value.value
      }

      // Set initial and Watch for changes
      setPeriod(props.settings.periodKey)


      return {
        isDialogActiveLocal,

        // Toda la capa
        settingsLocal,

        // Simulation Strategy
        settingsSimulationStrategyLocal,
        menuOptionsSimulationStrategyLocal,

        // Period
        settingsPeriodLocal,
        menuOptionsPeriodLocal,
      }
    }
  }
</script>

<style>
.custom-slider .v-label {
  width: 100px; /* Establece el ancho fijo que desees */
  /* min-width: 80px; */
}
</style>
