import useToxinType from "./useToxinType";

export default function useDecorateFiabilidad(){

  // const RANGOS_DSP = [40, 80, 120, 160, 200, 300];
  // const RANGOS_ASP = [5, 10, 15, 20, 25, 37];             // DSP /8
  // const RANGOS_PSP = [200, 400, 600, 800, 1000, 1500];    // DSP x5
  const { getToxinRango } = useToxinType()


  const code = (feedValue, toxinType) =>
  {
    // Rangos
    const range = getToxinRango(toxinType)

    if (!isNaN(feedValue)) {

      if (Math.abs(feedValue) > range[5]) {  // 300 | 37 | 1500
        return 'C3';
      }
      if (Math.abs(feedValue) > range[4]) {  // 200 | 25 | 1000
        return 'C2';
      }
      if (Math.abs(feedValue) > range[3]) {  // 160 | 20 | 800
        return 'C1';
      }
      if (Math.abs(feedValue) > range[2]) {  // 120 | 15 | 600
        return 'B3';
      }
      if (Math.abs(feedValue) > range[1]) {  // 80 | 10 | 400
        return 'B2';
      }
      if (Math.abs(feedValue) > range[0]) {  // 40 | 5 | 200
        return 'B1';
      }
      return 'A';
    }
    return '';
  }

  const motion = (feedValueCode) =>
  {
    switch (feedValueCode) {
    case 'A':
      return 'Sin peligro'
    case 'B1':
      return 'Estable'
    case 'B2':
      return 'Moderado'
    case 'B3':
      return 'Precaución'
    case 'C1':
      return 'Prohibido'
    case 'C2':
    case 'C3':
      return 'Extremo'
    }

    return feedValueCode;
  }

  // ToxA Color
  //   A   Azul            #00C0FF Pte. de verificar en imagen real (Sin ejemplo)
  //   B1  Morado(Oscuro)  #805CFF
  //   B2  Morado(Clarito) #C090FF
  //   B3  Rosa            #E05CFF
  //   CC  Magenta         #CD001E No coincide con leyenda
  //   C1  Rojo            #FF0000 No coincide con leyenda
  //   C2  Naranja         #FF8000 Pte. de verificar en imagen real (Sin ejemplo)
  //   C3  Amarillo        #FFC000
  //   D   Verde           #40A080
  const color = (feedValueCode) => {

    switch (feedValueCode) {
    case 'A':
      return '#00C0FF'
    case 'B1':
      return '#805CFF'
    case 'B2':
      return '#C090FF'
    case 'B3':
      return '#E05CFF'
    case 'C1':
      return '#FFC000'
    case 'C2':
      return '#FF8000'
    case 'C3':
      return '#FF0000'
    }

    return '#000000'
  }


  //
  //  'A'     'Sin peligro'       <  40
  //  'B1'    'Estable'        40 -  80
  //  'B2'    'Moderado'       80 - 120
  //  'B3'    'Precaución'    120 - 160
  //  'C1'    'Prohibido'     160 - 200
  //  'C2'    'Extremo'       200 - 300
  //  'C3'    'Extremo'           > 200
  const icon = (feedValueCode) => {

    switch (feedValueCode) {
    case 'A':
      return 'fas fa-water';
    case 'B1':
      return 'fas fa-check-circle';
    case 'B2':
      return 'fas fa-exclamation-circle';     // Precaución
    case 'B3':
      return 'fas fa-exclamation-triangle';   // Peligro
    case 'C1':
    case 'C2':
    case 'C3':
      return 'fas fa-ban';                    // Prohibido
    }
    return '';
  }


  const getDecorateToxAColor = (value, toxinType) => {
    return color(code(value, toxinType))
  }
  const getDecorateToxAIcon = (value, toxinType) => {
    return icon(code(value, toxinType))
  }
  const getDecorateToxAMotion = (value, toxinType) => {
    return motion(code(value, toxinType))
  }

  return {
    code,
    motion,
    color,
    icon,
    getDecorateToxAColor,
    getDecorateToxAIcon,
    getDecorateToxAMotion,
  }
}
