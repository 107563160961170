<!-- Sustituido por MenuLayer -->
<!-- <MenuForecastIntoxication
  class="my-1"
  :loading="loading"
  :optionSelected.sync="layerPOL.render"
/> -->

<template>
  <div class="d-flex align-center" style="height-: 56px;">
    <!-- offset-y + top:Hacia arriba en XS  or bottom: Hacia abajo en XS -->
    <!-- <v-menu
      transition="scale-transition"
      origin="center center"
      v-model="isMenuOpen"
      open-on-hover
      offset-y
      :top="$vuetify.breakpoint.xs"
      :bottom="!$vuetify.breakpoint.xs"
    > -->

    <!-- Abrimos hacia la izquierda -->
    <v-menu
      transition="scale-transition"
      origin="center center"
      v-model="isMenuOpen"
      open-on-hover
      offset-x
      left
      direction="left"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-on="on"
          v-bind="attrs"
          v-model="isMenuOpen"
          x-small
          fab
          :loading="loading"
          :color="(optionSelectedLocal && optionSelectedLocal.color) || 'blue darken-2'"
        >
          <v-icon v-if="isMenuOpen">
            mdi-close
          </v-icon>
          <span v-else>{{ optionSelectedLocal ? optionSelectedLocal.text : '??' }}</span>
        </v-btn>
      </template>

      <!-- <div class="d-flex flex-column" :class="[$vuetify.breakpoint.xs ? 'mb-12' : 'mt-12']"> -->
      <div class="d-flex flex-column-">
        <!-- @click="toxinTypeSelected = item" -->
        <!-- v-show="optionSelected && optionSelected.value !== item.value" -->
        <!-- @click="optionSelectedLocal = item; $emit('update:optionSelected', item.value)" -->
        <v-btn
          class="mx-2"
          v-for="(item, index) in menuOptions"
          :key="index"
          fab
          x-small
          :color="item.color"
          @click="onChangeOption(item)"
        >
          {{ item.text }}
        </v-btn>
      </div>
    </v-menu>
  </div>
</template>
<script>
  import { ref, watch } from 'vue';

  export default {
    name: 'menu-forecast-intoxication',
    props: {
      optionSelected: {
        type: String,
        default: undefined, // undefined | intoxication | forecast
        require: false
      },
      loading: {
        type: Boolean,
        default: false
      }
    },
    setup(props, {emit}) {
      const menuOptions = [
        { value: undefined, text: 'Off', color: 'grey', image: ''},
        { value: 'simulation', text: 'Tox', color: 'blue', image: ''},
        { value: 'forecast', text: 'For', color: 'blue', image: ''},
      ];

      const optionSelectedLocal = ref(menuOptions.find((element) => element.value == props.optionSelected))
      watch(() => props.optionSelected, () => {
        optionSelectedLocal.value = menuOptions.find((element) => element.value == props.optionSelected)
      })
      const onChangeOption = (item) => {
        optionSelectedLocal.value = item
        localStorage.setItem('menu-option-toxins', item.value);
        emit('update:optionSelected', item.value)
      }

      // Only for internal use of control (Change icon when selected)
      const isMenuOpen = ref(false)
      return {
        isMenuOpen,
        menuOptions,
        onChangeOption,
        optionSelectedLocal
      }
    }
  }
</script>
