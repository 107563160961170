<template>

  <!-- Opciones vista: Toxinas Vs Células -->
  <div class="d-flex flex-row">

    <!-- Opcion Larga: Lista de botones agrupados -->
    <v-btn-toggle
      v-if="!$vuetify.breakpoint.xs"
      v-model="viewIdSelectedLocal"
      color="deep-purple accent-3"
      mandatory
    >
      <!-- v-for="view in ['POL', 'PMI']" -->
      <v-btn
        v-for="(view, index) in viewListLocal"
        :key="view.id"
        :value="view.id"
        height="32px"
        text
        @click="$emit('update:viewIdSelected', view.id);"
      >
        <v-icon :color="view?.icon_color" v-if="index == 0" class="mr-2">{{ view.icon }}</v-icon>
        <span class="d-none d-sm-block" style="font-size: .625rem;">{{ view.label1 }} <br> {{ view.label2 }}</span>
        <v-icon :color="view?.icon_color" v-if="index == 1" class="ml-2">{{ view.icon }}</v-icon>
      </v-btn>
      <!-- <v-btn
        :value="'POL'"
        text
        height="32px"
        @click="$emit('update:viewIdSelected', 'POL');"
      >
        <v-icon>mdi-test-tube</v-icon>
        <span
          class="d-none d-sm-block ml-2"
          :class="viewIdSelectedLocal === 'POL' ? 'd-block-' : ''"
          style="font-size: .625rem;"
        >Toxinas<br>Poligonos</span>
      </v-btn>
      <v-btn
        :value="'PMI'"
        text
        height="32px"
        @click="$emit('update:viewIdSelected', 'PMI');"
      >
        <span
          class="d-none d-sm-block mr-2"
          :class="viewIdSelectedLocal === 'PMI' ? 'd-block-' : ''"
          style="font-size: .625rem;"
        >Células<br>PMI</span>
        <v-icon>mdi-microscope</v-icon>
      </v-btn> -->
    </v-btn-toggle>

    <!-- Bottom Sheet: Opcion Corta -->
    <v-bottom-sheet
      v-if="$vuetify.breakpoint.xs"
      v-model="isDialogActiveLocal"
    >
      <template v-slot:activator="{ props }">
        <v-btn
          v-bind="props"
          height="32px"
          @click="isDialogActiveLocal = true"
          class="px-2 pr-0"
        >
          <div class="d-flex flex-row align-center">
            <v-icon :color="viewSelectedLocal?.icon_color">{{ viewSelectedLocal?.icon }}</v-icon>
            <div class="d-flex flex-column">
              <span style="font-size: .625rem;">{{ viewSelectedLocal?.label1 }}</span>
              <span style="font-size: .625rem;">{{ viewSelectedLocal?.label2 }}...</span>
            </div>
            <v-divider
              vertical
              class="pl-2"
            ></v-divider>
            <v-icon>mdi-chevron-down</v-icon>
          </div>
        </v-btn>
      </template>

      <v-list>
        <v-subheader>Seleccione una vista</v-subheader>
        <v-divider></v-divider>
        <v-list-item
          v-for="view in viewListLocal"
          :key="view.id"
          :value="view.id"
          @click="
            isDialogActiveLocal = false;
            viewSelectedLocal = view;
            viewIdSelectedLocal = view.id;
            $emit('update:viewIdSelected', view.id);
          "
          :class="view.id == viewIdSelectedLocal ? 'purple' : ''"
        >
          <div class="d-flex flex-row">
            <v-icon :color="view?.icon_color" class="flex-grow-1">{{ view.icon }}</v-icon>
            <span class="flex-grow-1 ml-2">{{ view.id }}</span>
            <span class="flex-grow-4 ml-2">{{ view.label1 }} {{ view.label2 }}</span>
          </div>
        </v-list-item>
      </v-list>
    </v-bottom-sheet>
  </div>
</template>

<script>
  import { ref, watch } from 'vue';

  export default {
    name: 'forecast-select-view-component',
    components: {
    },
    props: {
      isDialogActive: {
        type: Boolean,
        required: false,
        default: false
      },
      viewIdSelected: {
        type: String,
        require: true,
        default: 'POL'
      },
    },
    setup(props) {
      const isDialogActiveLocal = ref(props.isDialogActive)
      const viewListLocal = ref([
        { id: 'POL', label: 'Toxinas<br>Poligonos', label1: 'Toxinas', label2: 'Polígonos', icon: 'mdi-flask-outline', icon_color: 'blue darken-2' }, // mdiFlaskOutline mdi-test-tube
        { id: 'PMI', label: 'Células<br>PMI', label1: 'Células', label2: 'PMI', icon: 'mdi-microscope', icon_color: 'red darken-2' }
      ])

      // Selección actual
      const viewIdSelectedLocal = ref(props.viewIdSelected)
      const viewSelectedLocal = ref(viewListLocal.value.find((item) => item.id === props.viewIdSelected))

      // props passed to setup function is reactive object (made probably by reactive()), it's properties are getters.
      // Watching a getter: https://v3.vuejs.org/api/computed-watch-api.html#watching-a-single-source
      watch([
        () => props.viewSelected,
      ], () => {
        viewIdSelectedLocal.value = props.viewIdSelected
        viewSelectedLocal.value = viewListLocal.value.find((item) => item.id === props.viewIdSelected)
      })

      return {
        isDialogActiveLocal,

        viewListLocal,
        viewIdSelectedLocal,
        viewSelectedLocal
      }
    }
  }
</script>

<style scoped>
</style>
