<template>
  <div :class="[isScrolling && videoParticleLayerVideoPlayLocal !== true ? 'mobile-calendar-expanded' : '']">

    <div id="mobile-calendar">

      <!-- Timer Code: Hora de visualizacion -->
      <div
        id="timecode-mobile"
        class="timecode"
        :class="isOnTop ? 'timecode_on_top' : ''"
        title="Hora local"
      >
        <div
          id="mobile_box"
          class="box"
          :class="isOnTop ? 'box_on_top' : ''"
        >{{ currentTimeString }}</div>
      </div>


      <!-- Manual Play/Pause + Frecuency -->
      <!-- <div id="playpause-mobile" class="play-pause iconfont bottom-border off">
        <v-btn
          x-small
          fab
          @click="$emit('startstop')"
        >
          <v-icon color="#9d0300" size="25">
            {{ animationTimer > 0 ? 'mdi-pause' : 'mdi-play' }}
          </v-icon>
        </v-btn>
        <v-btn
          class="mr-1"
          color="secondary"
          x-small
          depressed
          @click="$emit('onChangeAnimationStepFrequency')"
        >
          {{ animationStepFrequency }}h.
        </v-btn>
      </div> -->

      <!-- Video Play/Pause + Velocity -->
      <div
        id="playpause-mobile"
        class="play-pause iconfont bottom-border off"
        v-if="videoParticleLayerVideoPlayLocal !== null">

        <!-- <MenuLayerParamsParticlesDialog
          mode="button"
          key="particles"
          layerType="particles"
          :layer.sync="layerParticles"
        /> -->

        <v-btn
          x-small
          fab
          :loading="videoParticleLayerVideoLoading"
          :disabled="videoParticleLayerVideoLoading"
          :color="videoParticleLayerVideoPlayLocal ? 'red' : 'gray'"
          @click="videoParticleLayerVideoPlayLocal = !videoParticleLayerVideoPlayLocal"
        >
          <v-icon v-if="videoParticleLayerVideoPlayLocal">mdi-pause</v-icon>
          <v-icon v-if="!videoParticleLayerVideoPlayLocal">mdi-play</v-icon>
        </v-btn>

        <v-btn
          class="ml-1-"
          color="secondary"
          x-small
          depressed
          :disabled="videoParticleLayerVideoLoading"
          @click="$emit('onChangeAnimationVelocity')"
        >
          {{ animationVelocity }}x
        </v-btn>
      </div>

      <!-- Days: Horizonte temporal -->
      <div
        ref="daysRef"
        id="days"
        style="cursor: pointer; user-select: none;"
        @scroll="onHandleScroll"
        @click.capture="onClickScroll"
        @mousedown="onMouseDownScroll"
        @mousemove="onMouseMoveScroll"
      >
        <!-- Tick que marca la hora actual -->
        <b class="marcador" :style="{ left: currentTimePixelOffset }" ></b>

        <div
          v-for="(tickLabel, index) in tickLabelsLocal"
          :key="index"
          :style="index === 0 ? 'padding-left: 50%' : ''"
        >
          <!-- Progress bar: Loading vs pending -->
          <!-- <div class="progress-bar">
            <div class="progress-line">
              <div v-if="tickLabel.loaded" class="loaded"></div>
            </div>
          </div> -->
          <div class="day">{{ tickLabel.label }}</div>
          <ul>
            <li>01</li>
            <li>04</li>
            <li>07</li>
            <li>10</li>
            <li>13</li>
            <li>16</li>
            <li>19</li>
            <li>22</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { onMounted, ref, watch } from 'vue'

  import { toDate, differenceInHours, addHours } from 'date-fns';
  import { format, fromUnixTime } from 'date-fns'
  import { es } from 'date-fns/locale';

  // Components
  import MenuLayerParamsParticlesDialog from '@/components/MenuLayerParamsParticlesDialog.vue';


  // Service:
  import useAppConfig from '@/store/useAppConfig';

  // onAdd : onAddRainViewer,
  // animationPosition, animationPositionMin, animationPositionMax, onChangeAnimationPositionSlider, getAnimationPositionDateFormatter,
  // startstop, animationTimer, stop,
  // animationVelocity, onChangeAnimationVelocity,

  // Events
  //  onAddRainViewer()                   => Añadir la capa
  //  startstop()                         => Toggle start / stop animation
  //  stop()                              => Force stop (While moving)
  //  onChangeAnimationVelocity(val)      => Set new velocity
  //  onChangeAnimationStepFrequency(val) => Set new frequency
  export default {
    name: "map-mobile-calendar",
    components: {
      // eslint-disable-next-line vue/no-unused-components
      MenuLayerParamsParticlesDialog,
    },
    props: {
      // toxinType: {
      //   type: String,
      //   default: 'dsp',
      // },

      // [1679270400, 1679698800] => 20230321T00:00:00.0000Z, 20230224T23:00:00.0000Z => ['Lunes 21', 'Martes 22', 'Miércoles 23', 'Jueves 24']
      timestamps: {
        type: Array,
        default: () => [1678665600, 1679528880] // 20230313T00:00:00.0000Z, 20230213T00:00:00.0000Z
        // default: () => [1679270400, 1679698800] // 20230321T00:00:00.0000Z, 20230224T23:00:00.0000Z
      },

      // False o timeoutID (devuelto por el setTimeout())
      animationTimer: {
        type: [Boolean, Number],
        default: true,
      },

      // Posición actual (el timer se encarga de actualizarlo)
      animationPosition: {
        type: Number,
        default: -1,
      },
      // animationPositionMin: {
      //   type: Number,
      //   default: 0,
      // },
      // animationPositionMax: {
      //   type: Number,
      //   default: 0,
      // },

      // Velocidad de animación actual (No se usa aquí)
      animationVelocity: {
        type: Number,
        default: 1,
      },

      // Frecuencia de la animación en horas: 1h, 3h, 6h, 12h o 24h
      animationStepFrequency: {
        type: Number,
        default: 6,
      },

      videoParticleLayerVideoPlay: {
        type: Boolean,
        default: false
      },
      videoParticleLayerVideoLoading: {
        type: Boolean,
        default: false
      },
      isOnTop: {
        type: Boolean,
        default: false
      }
    },
    // eslint-disable-next-line no-unused-vars
    setup(props, { emit }) {
      console.log('onSetup', 'LoadTSs', props.timestamps.length)

      const { layerParticles } = useAppConfig()

      // >>> Control del video
      const videoParticleLayerVideoPlayLocal = ref(props.videoParticleLayerVideoPlay)
      watch(() => props.videoParticleLayerVideoPlay, () => {
        videoParticleLayerVideoPlayLocal.value = props.videoParticleLayerVideoPlay
      })
      watch(() => videoParticleLayerVideoPlayLocal.value, () => {

        // La primera vez cargamos el video (El usuario tiene que volver a hacer play)
        // if (layerParticles.value?.video === undefined) {
        //   layerParticles.value.video = true
        //   videoParticleLayerVideoPlayLocal.value = false
        // }
        emit('update:videoParticleLayerVideoPlay', videoParticleLayerVideoPlayLocal.value)
      })
      // <<<

      // TODO - Reconstruir el timeline cuando cambien los timestamps
      // Actualmente solo se hace si no cargamos el video de inicio

      const isTimeMapCounterActive = ref(true)
      // Cuando cambie de posicion, actualizamos
      watch( () => props.animationPosition, () => {
        console.log('watch(props.animationPosition)', props.animationPosition)
        console.log('isTimeMapCounterActive', isTimeMapCounterActive.value)
        if (isTimeMapCounterActive.value) {
          const ts = props.timestamps[props.animationPosition]
          if (ts) {
            const fechaRef = toDate(ts * 1000) // Miliseconds
            // Desplazamiento inicial -> Según la hora actual
            const offset = getTimeOffset(fechaRef)
            // console.log(offset,'of',daysRef.value.offsetWidth)
            daysRef.value.scrollLeft = offset
          }
        }
      })

      /***  TIME */
      const fechaIni = toDate(props.timestamps[0] * 1000) // Miliseconds
      const fechaFin = toDate(props.timestamps[props.timestamps.length - 1] * 1000) // Miliseconds
      let fechaActual = new Date()
      fechaActual.setHours(12, 0, 0, 0)
      if ( !(fechaActual >= fechaIni && fechaActual <= fechaFin) ) {
        fechaActual = fechaFin
      }
      // const diferenciaHoras = differenceInHours(fechaActual, fechaInicio);


      // Extraer la lista de días entre la lista del TimeStamp
      const getTickLabels = (timestampsS = []) => {

        // Checking... Se ha indicado una lista válida
        if (timestampsS === undefined || timestampsS.length === 0) {
          return []
        }
        const iLast = timestampsS.length - 1

        // Obtener la fecha inicial y final en milisegundos
        const fechaInicial = timestampsS[0] * 1000
        const fechaFinal = timestampsS[iLast] * 1000

        // Calcular el número de días entre las dos fechas
        const diferencia = fechaFinal - fechaInicial
        // let dias = Math.round(diferencia / (1000 * 60 * 60 * 24))
        let dias = Math.ceil(diferencia / (1000 * 60 * 60 * 24))      // Redondeamos por arriba por si el video no es justo
        if (dias === 0) { dias = 1 }

        // Enumerar los días de la semana: Usamos setDate para evitar fallos con el cambio de hora
        const fecha = new Date(fechaInicial)
        const labels = []
        for (let i = 0; i < dias; i++) {

          // Sumamos 1 día usando la funcion setDate para evitar fallos con el cambio de hora
          // const fecha = new Date(fechaInicial + (i * 24 * 60 * 60 * 1000))
          if (i > 0) {
            fecha.setDate(fecha.getDate() + 1)
          }

          const dayString = format(fecha, "EEEE d", { locale: es })
          const tickLabel = {
            label: dayString,
            loaded: i == 1 ? false : true
          }
          labels.push(tickLabel)
        }

        return labels;
      }
      const tickLabelsLocal = ref(getTickLabels(props.timestamps))

      // TODO - recalcular ticklabels -> Hay que recalcular más variables
      // watch( () => props.timestamps, () => {
      //   tickLabelsLocal.value = getTickLabels(props.timestamps)
      // }, { deep: true })

      //  Timestamp in seconds 1678665600 => 20:00
      const getAnimationPositionDateFormatter = (tsS) => {
        return tsS ? format(fromUnixTime(tsS), "HH:mm", { locale: es }) : '' // fromUnixTime(timestamp in seconds)
      }

      // Devuelve el offset en pixels
      const getTimeOffset = (fechaRef, offset=0) => {
        const diferenciaHoras = differenceInHours(fechaRef, fechaIni);

        // return ((160 / 24) * diferenciaHoras)
        // return (offset + ((160 / 24) * diferenciaHoras)).toString() + 'px'
        return offset + ((160 / 24) * diferenciaHoras)
      }
      // const getTimeOffsetPx = (number) => {
      //   return number.toString() + 'px'
      // }
      // const timeOffset = (160 / 24) * diferenciaHoras
      // const timeOffset = getTimeOffset(fechaActual)

      const daysRef = ref(null)
      const currentTimeString = ref(getAnimationPositionDateFormatter(fechaActual.getTime() / 1000))  // 1678665600 => 20:00
      const currentTimePixelOffset = ref('0px')                                                       // getTimeOffset(fechaActual) 460 => 460px

      // SetInitial()
      onMounted(() => {
        // currentTimePixelOffset.value = ((daysRef.value.clientWidth / 2) + timeOffset).toString() + 'px'
        currentTimePixelOffset.value = getTimeOffset(fechaActual, (daysRef.value.clientWidth / 2)).toString() + 'px'

        // Desplazamiento inicial -> Según la hora actual ==> Esto provoca un scroll (vía onHandleScroll)
        daysRef.value.scrollLeft = Math.floor(getTimeOffset(fechaActual))
        console.log('onMounted', 'setInitial', daysRef.value.scrollLeft, getAnimationPositionDateFormatter(fechaActual.getTime() / 1000))

        // Indicar al controlador de partículas como se debe inicializar (No es necesario porque esto desencadena "onHandleScroll")
        // eslint-disable-next-line no-debugger
        // debugger
        // emit('showFrameByTimestamp', fechaActual.getTime() / 1000)
        // sendCounter(fechaActual)

        // Se levantan los eventos manualmente en el Template: Ver Setup()
        // addListeners()
      })

      const convertScroll2Date = (scroll) => {
        const horas = scroll / (160 / 24)
        const fecha = addHours(fechaIni, horas)

        return fecha
      }
      const convertScroll2TimeString = (scroll) => {
        const fecha = convertScroll2Date(scroll)
        currentTimeString.value = getAnimationPositionDateFormatter(fecha.getTime() / 1000)
      }

      // eslint-disable-next-line no-unused-vars
      // const getCurrentTimeStampPosition = () => {
      //   // eslint-disable-next-line no-debugger
      //   debugger
      //   const horas = daysRef.value.scrollLeft / (160 / 24)
      //   const fecha = addHours(fechaInicio, horas)
      //   const currentTS = fecha.getTime() / 1000
      //   const index = props.timestamps.findIndex((element) => element >= currentTS)
      //   return index
      // }

      const isScrolling = ref(false)
      const onHandleScroll = () => {
        clearTimeout(isScrolling.value);
        isScrolling.value = setTimeout(() => {
          isScrolling.value = false

          // Al terminar de hacer scroll indicamos la nueva posición
          console.log('onHandleScroll-Activo', daysRef.value.scrollLeft)
          // emit('showFrameByTimestamp', convertScroll2Date(daysRef.value.scrollLeft).getTime() / 1000)
          sendCounter(convertScroll2Date(daysRef.value.scrollLeft))
        }, 2000);
        // console.log('isScrolling', isScrolling.value)
        // currentScroll.value = daysRef.value.scrollLeft
        convertScroll2TimeString(daysRef.value.scrollLeft)
      }

      // Deshabilitamos porque se ha implementado PAN (Mover con el ratón)
      const onClickScroll = (event) => {
        console.log(event)
        // const scrollLeft = event.target.scrollLeft;
        // const scrollLeft = event.target.offsetLeft;
        // console.log('scrollLeft:', event.target.offsetLeft, event.offsetX);
        // console.log('scrollLeftDays:', event.target.offsetParent?.scrollLeft);
        // console.log('clientWidth:', event.target.offsetParent?.clientWidth);

        // if (event.target.offsetParent) {
        //   const clientWidth = event.target.offsetParent.clientWidth / 2
        //   const scrollLeft = event.target.offsetLeft
        //   const offsetLeft = event.offsetX

        //   const offset = scrollLeft - clientWidth + offsetLeft
        //   console.log(offset)
        //   // sendCounter(convertScroll2Date(offset))
        //   daysRef.value.scrollLeft = offset
        // }
      }
      /** <<< */

      /** *** SCROLL *** */
      let isMouseDown = false
      let startX = 0

      // const addListeners = () => {

      // daysRef.value.addEventListener('mousedown', function(event) {
      const onMouseDownScroll = (event) => {
        // console.log('mousedown')
        isMouseDown = true
        startX = event.clientX
      }

      // daysRef.value.addEventListener('mousemove', function(event) {
      const onMouseMoveScroll = (event) => {
        if (isMouseDown) {
          const scrollX = startX - event.clientX;
          daysRef.value.scrollLeft += scrollX;
          startX = event.clientX;
          // console.log('mousemove-L', daysRef.value.scrollLeft)
        }
      }
      window.addEventListener('mouseup', function() {
        isMouseDown = false;
      })
      /**  */


      const sendCounter = (dateToSend) => {

        // Desactivamos la comunicación de entrada -> Le estamos indicando nosotros el counter y no necesitamos actualizar
        //  Si no desactivamos: se produce una especie de fliker
        isTimeMapCounterActive.value = false
        setTimeout(function() {
          isTimeMapCounterActive.value = true
        }, 2000)

        emit('showFrameByTimestamp', dateToSend.getTime() / 1000)
      }


      return {
        // Leyend
        // toxinUM,
        // toxinRango,
        // toxinLimit,

        // Fechas de los ticks
        tickLabelsLocal,

        daysRef,
        currentTimeString,
        currentTimePixelOffset, // Indicador de la hora actual (en pixels)
        isScrolling,
        onHandleScroll,
        onClickScroll,
        onMouseDownScroll,
        onMouseMoveScroll,

        // Particles
        videoParticleLayerVideoPlayLocal, // Control Play/Pause
        layerParticles,                   // Manage
      }
    },
  }
</script>

<!-- Progress Bar loader and played -->
<style lang="css" scoped>

  /* La barra de progreso la indicamos absoluta para que no oculte la fecha */
  .progress-bar {
    position: absolute;
    width: 100%;
  }
  .progress-bar .progress-line {
    height: 6px;
    cursor: pointer;
    position: relative;
    border: 10px solid transparent;
    background-clip: padding-box;
    border-right: none;
    border-left: none;
    top: -13px;
    transition: width ease-in-out .7s;

    /* Indicamos un color de fondo para toda la barra */
    background-color: #e5e5e5;
  }

  .progress-bar .progress-line .loaded {
      background-color: #d49500;
      height: 6px;

      overflow: hidden;
      /* border-top-left-radius: 3px; */
      /* border-bottom-left-radius: 3px; */

      /* With absolute */
      /* position: absolute; */
      /* top: 0; */
      /* left: 0; */
      /* width: 15%; */

      /* With relative: No hace falta indicar el % */
      width: 100%;
  }
</style>

<!-- Calendar  -->
<style lang="css" scoped>

  /* *** Mobile calendar *** */
  #mobile-calendar {
    /* position: relative;
    left: 0;
    right: 0;
    bottom: -40px; */

    /* display: block; */
    /* transition: bottom 0.3s; */

    /* Font color */
    color: white;
  }

  /* Expandimos el calendario (Días) */
  .mobile-calendar-expanded #mobile-calendar {
    bottom: 0;
  }

  #device-tablet #mobile-calendar,
  #device-desktop #mobile-calendar {
    display: none;
  }

  /* Si queremos oculatar el panel al buscar o mostrar otras capas */
  .onsearch #mobile-calendar,
  .overlay-xxx #mobile-calendar {
    display: none;
  }




  /* *** TIME CODE: Hora seleccionada *** */

  #mobile-calendar .timecode {
    position: absolute;
    left: 50%;

    margin-left: -40px;
    font-size: 18px;
  }

  #mobile-calendar .timecode .box {
    width: 80px;
    text-align: center;
    line-height: 1.4;
    border-radius: 26px;
    box-shadow: 0 0 4px 0 black;
  }

  #mobile-calendar .timecode .box::after {
    left: 56px;
    border-top-color: #d49500;
  }

  #device-mobile .timecode .box::before {
      left: 50%
  }

  .timecode {
    font-size: 10px;
    position: absolute;
    display: block;
    box-sizing: border-box;
    top: -2.4em;
    margin-left: -2em;
  }

  .timecode_on_top {
    top: unset;
    margin-top: 54px;
  }

  .timecode .box {
      cursor: -webkit-grab;
      cursor: grab;
      position: relative;
      background-color: #d49500;
      color: white;
      height: 1.8em;
      box-sizing: border-box;
      padding: 0 .8em;
      white-space: nowrap;
      text-align: center;
      display: table-cell;
      vertical-align: middle;
      border-radius: .5em;
      box-shadow: 0 0 4px 0 black
  }

  /* Indicador que señala la hora */
  .timecode .box::before {
      top: 100%;
      left: 2.3em;
      border: solid transparent;
      content: '';
      height: 0;
      width: 0;
      position: absolute;
      border-top-color: #d49500;
      border-width: .5em;
      margin-left: -0.5em;
  }
  .timecode .box_on_top::before {
    top: -20px;
    border-top-color: transparent;
    border-bottom-color: #d49500;
  }


  /* *** Days *** */
  #mobile-calendar #days {
    overflow-x: scroll;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
  }

  #mobile-calendar #days::-webkit-scrollbar {
    display: none;
  }

  /* Marcador de la hora actual */
  .marcador {
    position: relative;
    display: block;
    top: 40px;
    /* bottom: 0; */
    width: 3px;
    background-color: yellow;
    opacity: 0.4;

    /* Esto cambia con el scroll */
    height: 40px;
    top: 40px;
    margin-top: -40px;
  }

  .mobile-calendar-expanded .marcador {

    /* 74px es la nueva altura expanded */
    height: 74px;
    top: 74px;
    margin-top: -74px;
  }



  /* 2º === equivale al primer dia del calendario (Centramos scroll por la izquierda) */
  #mobile-calendar #days > div:nth-child(2) {
    padding-left: 50%;
  }
  /* Ultimo dia (Ajustamos para poder hacer scroll por la derecha) */
  #mobile-calendar #days > div:last-child {
    padding-right: 50%;
  }
  #mobile-calendar #days > div:nth-child(odd) {
    background-color: rgba(0, 0, 0, 0.63);
  }
  #mobile-calendar #days > div:nth-child(even) {
    background-color: rgba(63, 61, 80, 0.76);
  }

  /* Dias de la semana */
  #mobile-calendar #days > div .day {
    padding-top: 6px;
    padding-left: 8px;
    height: 36px;
  }


  /* Each Day: 160px width fix */
  #mobile-calendar #days > div {
    font-size: 18px;
    display: inline-block;
    width: 160px;
    box-sizing: content-box;
    background-clip: padding-box;
  }

  #mobile-calendar #days ul {
    font-size: 11px;

    padding-left: 0; /* Para evitar pading global del ul */
    width: calc(100% - 12px);
    margin: 8px 4px 8px 4px;


    /* Ocultar elemento */
    opacity: 0;
    display: none;
    transition: opacity 0.5s ease-in-out;
  }

  .mobile-calendar-expanded #mobile-calendar #days ul {
    /* Visualizar elemento */
    opacity: 0.6;
    display: table;
  }

  #mobile-calendar #days ul li {
    text-align: center;
    display: table-cell;
  }


  /* *** Play / Stop *** */
  /* left: 10px Igual que los botones de control */
  #mobile-calendar #playpause-mobile {
    position: absolute;
    left: 10px;
    padding-top: 5px;
    z-index: 4; /* En IOS se queda debajo del calendario */
  }

</style>
