

export default function useHansontable(
  intoxicationTableListLocal,
  intoxicationTableLocal,
  viewSelected,
  cellsOptions
) {

  // Expresión regular para validar el formato AAAA-MM-DD
  // eslint-disable-next-line no-unused-vars
  const datePattern = /^\d{4}-\d{2}-\d{2}$/;

  // Lista de equivalencias entre fitos: SIM vs IP
  const FITO_EQUIVALENT = []
  FITO_EQUIVALENT['ef-feed:fito-acuminata-sim'] = 'ef-feed:fito-acuminata-ip'
  FITO_EQUIVALENT['ef-feed:fito-acuta-sim'] = 'ef-feed:fito-acuta-ip'
  FITO_EQUIVALENT['ef-feed:fito-caudata-sim'] = 'ef-feed:fito-caudata-ip'
  FITO_EQUIVALENT['ef-feed:fito-pseudo-nitz-sim'] = 'ef-feed:fito-pseudo-nitz-ip'
  FITO_EQUIVALENT['ef-feed:fito-alexandrium-sim'] = 'ef-feed:fito-alexandrium-ip'
  FITO_EQUIVALENT['ef-feed:fito-gym-catenatum-sim'] = 'ef-feed:fito-catenatum-ip'


  const getHotSettings = (idRia) => {
    intoxicationTableLocal.value = intoxicationTableListLocal.value.find((item) => item.riaId == idRia)
    if (!intoxicationTableLocal.value) return

    // const rowData = []
    // const colFormats = intoxicationFile.colFormat
    // const colHeaderStyle = intoxicationFile.colHeaderStyle

    let intoxicationTable
    let rowData
    let colFormats
    let colHeaderStyle

    // View POL Toxins Vs PMI Cells
    if (viewSelected.value === 'POL') {
      intoxicationTable = intoxicationTableLocal.value.data
    } else {
      intoxicationTable = intoxicationTableLocal.value.data.cellsTable
    }

    rowData = []
    colFormats = intoxicationTable.colFormat
    colHeaderStyle = intoxicationTable.colHeaderStyle

    intoxicationTable.rowData.map( (rowDataItem) => {

      let row = {}
      Object.keys(rowDataItem).forEach((key) => {
        // console.log(propiedad + ': ' + objeto[propiedad]);
        const value = rowDataItem[key]

        if (datePattern.test(key)) {
          // row[key] = value['ef-feed:toxa']
          row[key] = value
        }

        // const pmId = rowDataItem['ef:pmKey']
        // const pmName = rowDataItem['ef:pmName']?.['ef:pmName']
        if (key === 'ef:pmName') {
          // row[key] = value['ef:pmName']
          row[key] = value
        }

        // Objetos de Forecast y Fiabilidad
        if (key === 'ef:forecast' || key === 'ef:fiabilidad') {
          row[key] = value
        }
      })
      rowData.push(row)
    })

    // } else {
    //   const intoxicationTable = intoxicationTableLocal.value.data.recuentos.forecastTable

    //   rowData = intoxicationTable.data
    //   colFormats = intoxicationTable.colFormats
    //   colHeaderStyle = intoxicationTable.colHeaders
    // }


    function getEstadoStyle(feedValueText) {

      // Estado
      //   1   Abierto     white
      //   2   Cerrado     Rojo
      switch (feedValueText) {
      case 'A':
        return 'blue';                        // '1'   Open #F2F2F2
      case 'C':
        return 'red';                         // '2'   Close
      default:
        return '';
      }
    }

    function getPlanActuacionStyle(feedValueText) {

      // Plan de actuación
      //   A   Azul            #00C0FF Pte. de verificar en imagen real (Sin ejemplo)
      //   B1  Morado(Oscuro)  #805CFF
      //   B2  Morado(Clarito) #C090FF
      //   B3  Rosa            #E05CFF
      //   CC  Magenta         #CD001E No coincide con leyenda
      //   C1  Rojo            #FF0000 No coincide con leyenda
      //   C2  Naranja         #FF8000 Pte. de verificar en imagen real (Sin ejemplo)
      //   C3  Amarillo        #FFC000
      //   D   Verde           #40A080
      switch (feedValueText) {
      case 'A':
        return '#00C0FF';
      case 'B1':
        return '#805CFF';
      case 'B2':
        return '#C090FF';
      case 'B3':
        return '#E05CFF';
      case 'CC':
        return '#CD001E';
      case 'C1':
        return '#FF0000';
      case 'C2':
        return '#FF8000';
      case 'C3':
        return '#FFC000';
      case 'D':
        return '#40A080';
      default:
        return '#F2F2F2';           // gris
      }
    }

    function getObjectValue(object, key, defaultValue) {
      defaultValue = typeof defaultValue !== undefined ? defaultValue : '';          // Por defecto ''

      // eslint-disable-next-line no-prototype-builtins
      if (!object.hasOwnProperty(key))
        return undefined

      var value = object[key];
      if (value === undefined)
        return defaultValue;

      if (typeof value == 'string' && value === "")
        return defaultValue;

      return value;
    }

    // Render para tox/dTox detalle
    // eslint-disable-next-line no-unused-vars
    function customRendererPMName(instance, td, row, col, prop, value, cellProperties) {

      var template = null;
      if (value != null && typeof value == 'object') {                            // null es considerado un objeto

        var _pmName = getObjectValue(value, 'ef:pmName', null);
        var _bucketPlan = getObjectValue(value, 'ef-bucket-plan', null);
        var _bucketEstado = getObjectValue(value, 'ef-bucket-estado', null);

        if (_pmName && _bucketPlan && _bucketEstado) {
          template =
            "<div class='d-flex flex-row justify-space-between align-center'>" +
            "       <span class='d-flex flex-row justify-space-between align-center pr-1'>" +
            "           <span>FORECAST_VALUE</span>" +
            "       </span>" +
            "       <span class='d-flex flex-row justify-space-between align-center' style='font-size: 0.75rem;'>" +
            "           <span class='text-right' style='min-width: 10px; background-color: AD_COLOR_PLAN;'>AD_VALUE_PLAN</span>" +
            "           <span class='text-right' style='min-width: 10px; background-color: AD_COLOR;'>AD_VALUE</span>" +
            // "           <span>AD_DAYS</span>" +
            "       </span>" +
            "</div>"

          template = template.replaceAll('FORECAST_VALUE', _pmName);

          // PLAN
          // _bucketPlan.color = 'red';
          // _bucketPlan.value = 'CC';
          template = template.replaceAll('AD_COLOR_PLAN', _bucketPlan.color);
          template = template.replaceAll('AD_VALUE_PLAN', _bucketPlan.value);

          // Estado
          template = template.replaceAll('AD_COLOR', _bucketEstado.color);
          template = template.replaceAll('AD_VALUE', _bucketEstado.value);
          template = template.replaceAll('AD_DAYS', _bucketEstado.text);
        }
      }

      // Checking... Si no hay plantilla sacamos el valor directamente
      if (template == null) {
        var divElement = document.createElement("div");
        var labelElement2 = document.createElement("label");
        labelElement2.setAttribute("style", "width: 100%; text-align: left; font-weight: normal");
        labelElement2.innerHTML = value;
        divElement.appendChild(labelElement2)

        template = divElement.outerHTML;
      }

      // Handsontable.dom.fastInnerHTML(td, template);
      td.innerHTML = template;
    }


    function createDIVRow(toxinTypeValue, ipValue, faValue){

      var divElement = document.createElement("div");
      divElement.classList.add("d-flex", "flex-row");

      if (ipValue != null) {
        var style = "width: 100%; text-align: center; font-weight: bold; font-size: 10px; background-color: BACKGROUND-COLOR; color: white;";
        if (
          (toxinTypeValue == 'DSP' && ipValue > 160) ||     // 160 µg equiv. AO/kg
          (toxinTypeValue == 'ASP' && ipValue > 20) ||      // 20 mg DA/kg
          (toxinTypeValue == 'PSP' && ipValue > 800)        // 800 µg equiv. STX kg
        ) {
          style = style.replace('BACKGROUND-COLOR', 'red');
        } else {
          style = style.replace('BACKGROUND-COLOR', 'blue');
        }

        // Set DSP info: background-color: blue;
        var labelElement = document.createElement("label");
        labelElement.setAttribute("style", style);
        labelElement.innerHTML = ipValue;
        divElement.appendChild(labelElement)
      } else {
        divElement.appendChild(document.createElement("label"))
      }

      // Write cell value
      var styleWeight = 'normal';
      // eslint-disable-next-line no-redeclare
      var style = "width: WIDTH%; text-align: right; font-weight: WEIGHT;";
      if (
        (toxinTypeValue == 'DSP' && faValue > 160) ||     // 160 µg equiv. AO/kg
        (toxinTypeValue == 'ASP' && faValue > 20) ||      // 20 mg DA/kg
        (toxinTypeValue == 'PSP' && faValue > 800)        // 800 µg equiv. STX kg
      ) {
        styleWeight = 'bold';
        style += "color: red;";
      }
      style = style.replace('WIDTH', 100);
      style = style.replace('WEIGHT', styleWeight);

      var labelElement2 = document.createElement("label");
      labelElement2.setAttribute("style", style);
      labelElement2.innerHTML = faValue || 0;
      divElement.appendChild(labelElement2)

      return divElement;
    }

    // Render Para la tabla Tox/dTox multiPM
    // eslint-disable-next-line no-unused-vars
    function customRendererHTML2Vals(instance, td, row, col, prop, value, cellProperties) {
      var divElement = document.createElement("div");
      divElement.classList.add("d-flex","flex-row");

      if (value != null && typeof value == 'object') {                            // null es considerado un objeto

        //  ef-feed:analisis            => ef-feed:[dsp|asp|psp]-analisis
        //  ef-feed:analisis-progress   => ef-feed:[dsp|asp|psp]-analisis-progress
        //  ef-feed:plan                => ef-feed:[dsp|asp|psp]-plan
        //  ef-feed:estado              => ef-feed:[dsp|asp|psp]-estado
        //  ef-feed:toxa                => ef-feed:[dsp|asp|psp]-toxa
        var toxinTypeValue = getObjectValue(value, 'ef-feed:toxin-type', null);
        var analisisValue = getObjectValue(value, 'ef-feed:analisis', null);
        var analisisProgressValue = getObjectValue(value, 'ef-feed:analisis-progress', null);
        var planValue = getObjectValue(value, 'ef-feed:plan', null);
        var estadoValue = getObjectValue(value, 'ef-feed:estado', null);
        var toxAValue = getObjectValue(value, 'ef-feed:toxa', 0);


        // Por si queremos ver células con toxina
        // var faValue = getObjectValue(value, 'ef-feed:fa', 0);
        // if (showIntoxicationCells.value) {
        //   toxAValue = faValue
        // }

        var styleWidth = 100;

        if (analisisValue != null) {
          styleWidth -= 30;

          var style = "width: 30%; text-align: center; font-weight: bold; font-size: 10px; background-color: BACKGROUND-COLOR; color: white;";
          // if (analisisValue > 160) {}
          if (
            (toxinTypeValue == 'DSP' && analisisValue > 160) ||     // 160 µg equiv. AO/kg
            (toxinTypeValue == 'ASP' && analisisValue > 20) ||      // 20 mg DA/kg
            (toxinTypeValue == 'PSP' && analisisValue > 800)        // 800 µg equiv. STX kg
          ) {
            style = style.replace('BACKGROUND-COLOR', 'red');
          } else {
            style = style.replace('BACKGROUND-COLOR', 'blue');
          }

          // Set DSP info: background-color: blue;
          var labelElement = document.createElement("label");
          labelElement.setAttribute("style", style);
          labelElement.innerHTML = analisisValue;
          divElement.appendChild(labelElement)
        }
        if (analisisProgressValue != null) {
          styleWidth -= 30;

          // eslint-disable-next-line no-redeclare
          var style = "width: 30%; text-align: center; font-weight: bold; font-size: 10px; background-color: lightblue; color: white;";

          // Set DSP info: background-color: blue;
          // eslint-disable-next-line no-redeclare
          var labelElement = document.createElement("label");
          labelElement.setAttribute("style", style);
          labelElement.innerHTML = analisisProgressValue;
          divElement.appendChild(labelElement)
        }
        if (planValue != null) {
          styleWidth -= 20;
          //var dspPlanValueText = getPlanActuacionText(planValue);

          // eslint-disable-next-line no-redeclare
          var style = "width: 20%; text-align: center; font-weight: normal; font-size: 10px; background-color: BACKGROUND-COLOR; color: black;";
          style = style.replace('BACKGROUND-COLOR', getPlanActuacionStyle(planValue)); // planValue

          // Set DSP info: background-color: blue;
          // eslint-disable-next-line no-redeclare
          var labelElement = document.createElement("label");
          labelElement.setAttribute("style", style);
          labelElement.innerHTML = planValue; // dspPlanValueText
          divElement.appendChild(labelElement)
        }
        if (estadoValue != null) {
          styleWidth -= 10;
          //var polEstadoText = getEstadoText(estadoValue);

          // eslint-disable-next-line no-redeclare
          var style = "width: 10%; text-align: center; font-weight: normal; font-size: 10px; background-color: BACKGROUND-COLOR; color: white;";
          style = style.replace('BACKGROUND-COLOR', getEstadoStyle(estadoValue)); // polEstadoText

          // Set DSP info: background-color: blue;
          // eslint-disable-next-line no-redeclare
          var labelElement = document.createElement("label");
          labelElement.setAttribute("style", style);
          labelElement.innerHTML = estadoValue; // polEstadoText
          divElement.appendChild(labelElement)
        }

        // Write cell value
        var styleWeight = 'normal';
        // eslint-disable-next-line no-redeclare
        var style = "width: WIDTH%; text-align: right; font-weight: WEIGHT;";
        // if (toxAValue > 160) {
        if (
          (toxinTypeValue == 'DSP' && toxAValue > 160) ||     // 160 µg equiv. AO/kg
          (toxinTypeValue == 'ASP' && toxAValue > 20) ||      // 20 mg DA/kg
          (toxinTypeValue == 'PSP' && toxAValue > 800)        // 800 µg equiv. STX kg
        ) {
          styleWeight = 'bold';
          style += "color: red;";
        }
        style = style.replace('WIDTH', styleWidth);
        style = style.replace('WEIGHT', styleWeight);

        var labelElement2 = document.createElement("label");
        labelElement2.setAttribute("style", style);
        labelElement2.innerHTML = toxAValue;
        divElement.appendChild(labelElement2)

      } else {
        // eslint-disable-next-line no-redeclare
        var labelElement2 = document.createElement("label");
        labelElement2.setAttribute("style", "width: 100%; text-align: right; font-weight: normal");
        labelElement2.innerHTML = value;
        divElement.appendChild(labelElement2)
      }

      // Handsontable.dom.fastInnerHTML(td, divElement.outerHTML);
      td.innerHTML = divElement.outerHTML;
    }

    function sum(lista) {
      var total = undefined
      lista.forEach((item) => {
        const number = Number(item)
        if (!isNaN(number)) {
          if (total === undefined) {
            total = 0
          }
          total = total + number
        }
      })
      return total
    }

    // Render Para la tabla Tox/dTox multiPM
    // eslint-disable-next-line no-unused-vars
    function customRendererHTML2ValsCells(instance, td, row, col, prop, value, cellProperties) {

      var divElementRoot = document.createElement("div");
      divElementRoot.classList.add("d-flex", "flex-column");

      if (value != null && typeof value == 'object') {                            // null es considerado un objeto

        // Extraer datos del objeto
        var toxinTypeValue = getObjectValue(value, 'ef-feed:toxin-type', null);
        // var faValue = getObjectValue(value, 'ef-feed:fa', 0);

        // Extraer lista de fitos
        const fitoList = []
        intoxicationTableLocal.value.data?.filters?.feedTypeAPs.forEach((feedTypeAP) => {

          // Feeds equivalentes SIM vs IP
          const feedTypeSim = feedTypeAP.feedType.feedTypeKey
          const feedTypeIp  = FITO_EQUIVALENT[feedTypeSim]

          // Extraer valores
          var simValue = getObjectValue(value, feedTypeSim, 0)
          var ipValue = getObjectValue(value, feedTypeIp, 0)

          // Aplicamos filtro y agrupamos en un único fitoplancton
          // if (isTFChecked.value && typeof feedTypeAP.asimilacion === 'number' && !isNaN(feedTypeAP.asimilacion)) {
          if (cellsOptions.value.isTF && typeof feedTypeAP.asimilacion === 'number' && !isNaN(feedTypeAP.asimilacion)) {
            if (simValue) {
              simValue = Math.round(Number(simValue) * feedTypeAP.asimilacion / 100)
            }
            if (ipValue) {
              ipValue = Math.round(Number(ipValue) * feedTypeAP.asimilacion / 100)
            }
          }

          // Guardamos en la lista
          fitoList.push({
            'feedTypeKey': feedTypeSim,
            'sim': simValue,
            'ip': ipValue,
          })
        })


        // Solo agrupamos si se seleccionan todos
        if (cellsOptions.value?.isGroup && cellsOptions.value?.feedTypeAP === 'all') {

          // const allIpValue = sum([acuminataIpValue, acutaIpValue, caudataIpValue])
          // const allSimValue = sum([acuminataSimValue, acutaSimValue, caudataSimValue])
          const simList = fitoList.map(fito => fito.sim)
          const ipList = fitoList.map(fito => fito.ip)

          // Aplicamos la suma (Solo sumamos los valores correctos)
          const allSimValue = sum(simList)
          const allIpValue = sum(ipList)

          var divElementAll = createDIVRow(toxinTypeValue, allIpValue, allSimValue);
          divElementRoot.appendChild(divElementAll)

        } else {
          fitoList.forEach((fito) => {
            if ( cellsOptions.value?.feedTypeAP === fito.feedTypeKey || cellsOptions.value?.feedTypeAP === 'all') {
              var divElementAcuminata = createDIVRow(toxinTypeValue, fito.ip, fito.sim);
              divElementRoot.appendChild(divElementAcuminata)
            }
          })
        }
      } else {

        var divElement2 = document.createElement("div");
        divElement2.classList.add("d-flex", "flex-row");

        // eslint-disable-next-line no-redeclare
        var labelElement2 = document.createElement("label");
        labelElement2.setAttribute("style", "width: 100%; text-align: right; font-weight: normal");
        labelElement2.innerHTML = value;
        divElement2.appendChild(labelElement2)

        divElementRoot.appendChild(divElement2)
      }

      // Handsontable.dom.fastInnerHTML(td, divElement.outerHTML);
      td.innerHTML = divElementRoot.outerHTML;
    }

    // Render para toxA-Afinidad
    // eslint-disable-next-line no-unused-vars
    function customRendererFiabilidad(instance, td, row, col, prop, value, cellProperties) {

      var template = null;
      if (value != null && typeof value == 'object') {                            // null es considerado un objeto

        var _bucketToxA_Afinidad = getObjectValue(value, 'ef-bucket-toxa-afinidad', null);
        var _bucketToxA_Forecast = getObjectValue(value, 'ef-bucket-toxa-forecast', null);

        if (_bucketToxA_Afinidad && _bucketToxA_Forecast) {
          // Se han sustituido las clases: progress y progress-bar
          template =
            "<div class='d-flex flex-row justify-space-between align-center'>" +
            "   <span class='progress- d-flex w-100' style='height: 1.2rem; background-color: #e9ecef; font-size: .75rem;'>" +
            "       <span class='progress-bar- d-flex flex-row justify-center- align-center' style='background-color: AFINIDAD_COLOR; width: AFINIDAD_VALUE%' role='progressbar'>" +
            "           <span class='pl-1'>AFINIDAD_VALUE%</span>" +
            "       </span>" +
            "   </span>" +
            "   <span class='d-flex flex-row align-center ml-1' style='font-size: 1.5rem; color: FORECAST_COLOR'>" +
            "       <i class='FORECAST_ICON'></i>" +
            "   </span>" +
            "</div>"

          template = template.replaceAll('FORECAST_COLOR', _bucketToxA_Forecast.color);
          template = template.replaceAll('FORECAST_ICON', _bucketToxA_Forecast.icon);

          template = template.replaceAll('AFINIDAD_COLOR', _bucketToxA_Afinidad.color);
          template = template.replaceAll('AFINIDAD_VALUE', _bucketToxA_Afinidad.value);  // Repetimos
        }
      }

      // Checking... Si no hay plantilla sacamos el valor directamente
      if (template == null) {
        var divElement = document.createElement("div");
        var labelElement2 = document.createElement("label");
        labelElement2.setAttribute("style", "width: 100%; text-align: right; font-weight: normal");
        labelElement2.innerHTML = value;
        divElement.appendChild(labelElement2)

        template = divElement.outerHTML;
      }

      // Handsontable.dom.fastInnerHTML(td, template);
      td.innerHTML = template;
    }

    // eslint-disable-next-line no-unused-vars
    function customRendererForecast(instance, td, row, col, prop, value, cellProperties) {

      var template = null;
      if (value != null && typeof value == 'object') {                            // null es considerado un objeto

        var _bucketToxA_Forecast = getObjectValue(value, 'ef-bucket-toxa-forecast', null);
        var _bucketToxA_AD = getObjectValue(value, 'ef-bucket-toxa-ad', null);

        if (_bucketToxA_Forecast && _bucketToxA_AD) {
          template =
            "<div class='d-flex flex-row justify-space-between align-center'>" +
            // "   <span class='d-flex flex-row align-center' style='font-size: 1.5rem; color: FORECAST_COLOR'>" +
            // "       <i class='FORECAST_ICON'></i>" +
            // "   </span>" +
            // "   <div class='d-flex flex-row justify-space-between align-center'>" +
            "       <span class='d-flex flex-row justify-space-between align-center pr-1' style='font-size: 1rem; color: FORECAST_COLOR'>" +
            "           <span>FORECAST_VALUE</span>" +
            "       </span>" +
            "       <span class='d-flex flex-row justify-space-between align-center' style='font-size: 0.75rem; color: AD_COLOR'>" +
            "           <i class='AD_ICON'></i>" +
            "           <span class='text-right' style='min-width: 20px'>AD_VALUE</span>" +
            "       </span>" +
            // "   </div>" +
            "</div>"

          template = template.replaceAll('FORECAST_COLOR', _bucketToxA_Forecast.color);
          template = template.replaceAll('FORECAST_ICON', _bucketToxA_Forecast.icon);
          template = template.replaceAll('FORECAST_VALUE', _bucketToxA_Forecast.value);

          template = template.replaceAll('AD_COLOR', _bucketToxA_AD.color);
          template = template.replaceAll('AD_ICON', _bucketToxA_AD.icon);
          template = template.replaceAll('AD_VALUE', _bucketToxA_AD.value);
        }
      }
      // Checking... Si no hay plantilla sacamos el valor directamente
      if (template == null) {
        var divElement = document.createElement("div");
        var labelElement2 = document.createElement("label");
        labelElement2.setAttribute("style", "width: 100%; text-align: right; font-weight: normal");
        labelElement2.innerHTML = value;
        divElement.appendChild(labelElement2)

        template = divElement.outerHTML;
      }

      // Handsontable.dom.fastInnerHTML(td, template);
      td.innerHTML = template;
    }

    const cells = (row, col, prop) => {
      if (prop === 'ef:forecast') {
        return {renderer: customRendererForecast}
      }
      if (prop === 'ef:fiabilidad') {
        return {renderer: customRendererFiabilidad}
      }
      if (prop === 'ef:pmName') {
        return {renderer: customRendererPMName}
      }
      if (prop.startsWith('ef')) {
        return;
      }

      // Resto de columnas (Las Diarias)
      return { renderer: viewSelected.value === 'PMI' ? customRendererHTML2ValsCells : customRendererHTML2Vals }
    }

    // Genera un borde a la izquierda para una co
    const getCustomBorder = (index) => {
      return {
        range: {
          from: {
            row: 0,                                     // Primera fila
            col: index
          },
          to: {
            row: rowData.length - 1,                    // Última fila
            col: index
          },
        },
        left: {
          width: 3,
          color: 'black'
        }
      }
    }
    // Comprobamos las fechas que sean lunes
    const borders = [];
    for (let i = 0; i < colFormats.length; i++) {
      const fechaString = colFormats[i]['data']
      if (datePattern.test(fechaString) === true) {
        const fecha = new Date(fechaString)
        if (fecha.getDay() === 1) {
          borders.push(getCustomBorder(i))
        }
      }
    }

    const hotSettings = {
      data: rowData,
      columns: colFormats,
      colHeaders: colHeaderStyle,
      width: 'auto',
      height: 'auto',
      fixedColumnsLeft: 1,

      // El servidor determina el valor del atributo classColor => [ color1: Sin Recuentos, color2: Con Recuentos ]
      afterGetColHeader: function (col, TH) {
        if (colFormats[col]) {
          let classColor = colFormats[col]['classColor'];
          if (classColor !== undefined && classColor !== null && classColor !== "") {
            // TH.style.backgroundColor = "yellow"
            if (!TH.classList.contains(classColor)) {
              TH.classList.add(classColor)
            }
          }
        }
      },

      licenseKey: 'non-commercial-and-evaluation',
      cells: cells,
      disableVisualSelection: true,
      customBorders: borders,
    }

    return hotSettings
  }
  return {
    getHotSettings,
  }
}
