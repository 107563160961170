<template>
  <div class="gauge">
    <!-- :viewBox="`0 0 ${(RADIUS * 2) +20} ${height+10}`" height="100%" width="100%" -->
    <svg
      v-if="HEIGHT"
      :viewBox="`0 0 ${WIDTH} ${HEIGHT}`" height="100%" width="100%"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <!-- This puts an inner shadow on the empty part of gauge -->
        <filter :id="`innershadow-${_uid}`">
          <feFlood flood-color="#c7c6c6" />
          <feComposite in2="SourceAlpha" operator="out" />
          <feGaussianBlur stdDeviation="2" result="blur" />
          <feComposite operator="atop" in2="SourceGraphic" />
        </filter>

        <!-- Determine the gradient color on the full part of the gauge -->
        <linearGradient
          v-if="hasGradient"
          :id="`gaugeGradient-${_uid}`"
        >
          <stop
            v-for="(color, index) in gaugeColor"
            :key="`${color.color}-${index}`"
            :offset="`${color.offset}%`" :stop-color="color.color"
          />
        </linearGradient>

        <!-- RealTime: Elipse interior -->
        <mask :id="`innerCircleInt-${_uid}`">

          <!--  -->
          <circle :r="90" :cx="X_CENTER" :cy="Y_CENTER" fill="white" />

          <!-- Esto elimina el interior de la elipse -->
          <circle :r="55" :cx="X_CENTER" :cy="Y_CENTER" fill="black" />

          <template v-if="separatorPaths">
            <path
              v-for="(separator, index) in separatorPaths"
              :key="index"
              :d="separator" fill="black"
            />
          </template>
        </mask>

        <!-- Prev72h: Elipse exterior -->
        <mask :id="`innerCircleExt-${_uid}`">

          <!--  -->
          <circle :r="100" :cx="X_CENTER" :cy="Y_CENTER" fill="white" />

          <!-- Esto elimina el interior de la elipse -->
          <circle :r="92" :cx="X_CENTER" :cy="Y_CENTER" fill="black" />

          <template v-if="separatorPaths">
            <path
              v-for="(separator, index) in separatorPaths"
              :key="index"
              :d="separator" fill="black"
            />
          </template>
        </mask>
      </defs>

      <!-- RealTime: Interior -->
      <g :mask="`url(#innerCircleInt-${_uid})`">
        <path :d="gaugeRealTime.basePath" :fill="hasGradient ? `url(#gaugeGradient-${_uid})` : gaugeColor" />
        <path :d="gaugeRealTime.gaugePath" :fill="baseColor" :filter="`url(#innershadow-${_uid})`" />
        <path :d="gaugeRealTime.gaugePathME" fill="red" />
      </g>

      <!-- RealTime: Indicador triangular -->
      <polygon
        :points="`${pointsTiangleInt[0][0]},${pointsTiangleInt[0][1]} ${pointsTiangleInt[1][0]},${pointsTiangleInt[1][1]} ${pointsTiangleInt[2][0]},${pointsTiangleInt[2][1]}`"
        :fill="gaugeRealTime.color" />

      <!-- RealTime: LastAnalítica -->
      <!-- <circle
        :cx="pointLastAnalitica.vertex[0]"
        :cy="pointLastAnalitica.vertex[1]" r="2" :fill="pointLastAnalitica.color"
      /> -->
      <circle
        :cx="pointToxAAnalitica.vertex[0]"
        :cy="pointToxAAnalitica.vertex[1]" r="2" :fill="pointToxAAnalitica.color"
      />


      <!-- Prev72h: Colorear Elipse exterior -->
      <g :mask="`url(#innerCircleExt-${_uid})`">
        <!-- Pinta el color con gradiente -->
        <path :d="basePath" :fill="hasGradient ? `url(#gaugeGradient-${_uid})` : gaugeColor"/>

        <!-- Ocultar el resto del círculo con el color base -->
        <path :d="gaugePathExt" :fill="baseColor" />
        <path :d="gaugePathExtME" fill="red" />
      </g>


      <!-- PREV72H: Exterior -->
      <!-- Indicadores numéricos de Gauge: Minimo, límite(central) y máximo -->
      <!-- <text x="2" y="108" font-family="Roboto" font-size="8" fill="black" text-anchor="start">{{min}}</text> -->
      <!-- <text x="198" y="108" font-family="Roboto" font-size="8" fill="black" text-anchor="end">{{max}}</text> -->
      <!-- <text x="100" y="8" font-family="Roboto" font-size="8" fill="black" text-anchor="middle">{{limit}}</text> -->
      <text
        v-for="(label, index) in labels"
        :key="index"
        font-family="Roboto" font-size="8" fill="black"
        :x="label.x" :y="label.y"
        :text-anchor="label.align"
      >{{ label.value }}</text>

      <!-- Prev72h: Indicador triangular -->
      <polygon
        :points="`${pointsTiangle[0][0]},${pointsTiangle[0][1]} ${pointsTiangle[1][0]},${pointsTiangle[1][1]} ${pointsTiangle[2][0]},${pointsTiangle[2][1]}`"
        fill="blue" />

      <!-- Prev72h: Valor en texto -->
      <!-- transform="rotate(0, 110, 110)" -->
      <text :x="forecastText.point[0]" :y="forecastText.point[1]"
            font-family="Roboto" font-size="14" fill="black"
            text-anchor="middle"
            :transform="`rotate(${forecastText.angle + 90}, ${forecastText.point[0]}, ${forecastText.point[1]})`"
      >{{ forecastText.text }}</text>
    </svg>
  </div>
</template>

<script>
  import TWEEN from '@tweenjs/tween.js'
  import _get from 'lodash/get'
  import { computed, ref, watch } from 'vue'

  // Main radius of the gauge
  const RADIUS = 100

  // Coordinates of the center based on the radius
  // Box: 240x140
  const HEIGHT = RADIUS + 20
  const WIDTH = 20 + RADIUS + RADIUS + 20
  const X_CENTER = WIDTH / 2
  const Y_CENTER = HEIGHT     // Aumentamos la caja +10 para introducir el límite

  /**
   * Turn polar coordinate to cartesians
   * @param   {Number} centerX - abscisse of the center
   * @param   {Number} centerY - ordinate of the center
   * @param   {Number} radius  - radius of the circle
   * @param   {Number} angle   - angle in degres
   * @returns {String}         - d property of the path
   */
  function polarToCartesian(radius, angle) {
    const angleInRadians = (angle - 90) * Math.PI / 180

    return {
      x: X_CENTER + (radius * Math.cos(angleInRadians)),
      y: Y_CENTER + (radius * Math.sin(angleInRadians)),
    }
  }

  /**
   * Describe a gauge path according
   * @param   {Number} radius
   * @param   {Number} startAngle - in degre
   * @param   {Number} endAngle   - in degre
   * @returns {String}            - d property of the path
   */
  function describePath(radius, startAngle, endAngle) {
    var start = polarToCartesian(radius, endAngle)
    var end = polarToCartesian(radius, startAngle)

    const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1'

    const d = [
      'M', start.x, start.y,
      'A', radius, radius, 0, largeArcFlag, 0, end.x, end.y,
      'L', X_CENTER, Y_CENTER,
    ].join(' ')

    return d
  }

  // Función para aplicar rotación a un punto (x, y) alrededor de un punto central (cx, cy)
  function rotarPunto(x, y, cx, cy, angulo) {
    var radianes = (Math.PI / 180) * angulo;
    var x_rotado = Math.cos(radianes) * (x - cx) - Math.sin(radianes) * (y - cy) + cx;
    var y_rotado = Math.sin(radianes) * (x - cx) + Math.cos(radianes) * (y - cy) + cy;

    return [x_rotado, y_rotado];
  }

  // point = [20, 20], angle = 45
  // eslint-disable-next-line no-unused-vars
  function describePathTriangle(point, angle) {

    const side = 10;
    // M 30 30 L 50 30 L 50 50
    var x1 = point[0];  // 20
    var y1 = point[1];  // 20
    var x2 = x1 - side;
    var y2 = y1;
    var x3 = x1;
    var y3 = y1 - side;

    // Ángulo de rotación en grados
    // var angulo = 45;

    // Aplicar rotación a cada vértice
    // var punto1 = rotarPunto(x1, y1, 100, 100, angle - 45);
    // var punto2 = rotarPunto(x2, y2, 100, 100, angle - 45);
    // var punto3 = rotarPunto(x3, y3, 100, 100, angle - 45);
    const punto1 = [x1, y1]
    const punto2 = [x2, y2]
    const punto3 = [x3, y3]

    // Crear el path con las coordenadas rotadas
    // var path = "M " + punto1[0] + " " + punto1[1] + " L " + punto2[0] + " " + punto2[1] + " L " + punto3[0] + " " + punto3[1] + " Z";
    const d = [
      'M', punto1[0], punto1[1],
      'L', punto2[0], punto2[1],
      'L', punto3[0], punto3[1],
      'Z'
    ].join(' ')

    return d
  }


  export default {
    name: 'Gauge',
    props: {
      formulaRealTime: {
        type: Object,
        default: () => ({
          fiaValue: 70,
          fiaValueME: 2,
          fiaColor: null,
          toxValue: null,
          toxValueME: null,
          toxValueHigh: null,
          toxValueLow: null,
          toxColor: null,
          toxIcon: null,
          toxMotion: null,
        })
      },
      formulaPrev72h: {
        type: Object,
        default: () => ({ toxValue: 100, toxValueME: 20 })
      },
      formulaLastAnalisis: {
        type: Object,
        default: () => ({ value: 140, days: 7, color: 'blue' })
      },
      toxAAnalitica: {
        type: Number,
        default: 77,
      },
      // Gauge value
      value: {
        type: Number,
        default: 70,
      },
      valueME: {
        type: Number,
        default: 10,
      },
      valueForecast: {
        type: Number,
        default: 90,
      },
      valueForecastME: {
        type: Number,
        default: 10,
      },
      // Gauge min value
      min: {
        type: Number,
        default: 0,
      },
      // Gauge max value
      max: {
        type: Number,
        default: 300,
      },
      limit: {
        type: Number,
        default: 160,
      },
      /**
       * Must be between -360 and 360
       * startAngle MUST be inferior to endAngle
       */
      startAngle: {
        type: Number,
        default: -90,
        validator: (value) => {
          if (value < -360 || value > 360) {
            console.warn('GaugeChart - props "startAngle" must be between -360 and 360')
          }
          return true
        },
      },
      /**
       * Must be between -360 and 360
       * startAngle MUST be inferior to endAngle
       */
      endAngle: {
        type: Number,
        default: 90,
        validator: (value) => {
          if (value < -360 || value > 360) {
            console.warn('GaugeChart - props "endAngle" must be between -360 and 360')
          }
          return true
        },
      },
      /**
       * Size of the inner radius between 0 and RADIUS
       * The closer to RADIUS, the thinner the gauge will be
       */
      innerRadius: {
        type: Number,
        default: 60,
        validator: (value) => {
          if (value < 0 || value > 100) {
            console.warn(`GaugeChart - props "innerRadius" must be between 0 and ${RADIUS}`)
          }
          return true
        },
      },
      /**
       * Separator step, will display a separator each min + (n * separatorStep)
       * Won't display any separator if 0 or null
       */
      separatorStep: {
        type: Number,
        default: 10,
        validator: (value) => {
          if (value !== null && value < 0) {
            console.warn('GaugeChart - props "separatorStep" must be null or >= 0')
          }
          return true
        },
      },
      /**
       * Separator Thickness, unit is in degree
       */
      separatorThickness: {
        type: Number,
        default: 4,
      },
      /**
       * Gauge color. Can be :
       * - a simple color if passed as a 'string'
       * - a gradient if is an array of objects :
       * { offset: percentage where the color starts, color: color to display }
       */
      // #00C0FF, #805CFF, #C090FF, #E05CFF, #FFC000, #FF8000, #FF0000
      gaugeColor: {
        type: [Array, String],
        default: () => ([
          { offset: 0, color: '#00C0FF' },
          { offset: 15, color: '#805CFF' },
          { offset: 35, color: '#C090FF' },
          { offset: 50, color: '#E05CFF' },
          { offset: 65, color: '#FFC000' },
          { offset: 85, color: '#FF8000' },
          { offset: 100, color: '#FF0000' },
        ]),
      },
      /**
       * Color of the base of the gauge
       */
      baseColor: {
        type: String,
        default: '#DDDDDD',
      },
      /**
       * Animation easing option
       * You can check the Tween.js doc here :
       * https://github.com/tweenjs/tween.js/blob/master/docs/user_guide.md
       *
       * There are a few existing function gourped by equation they represent:
       * Linear, Quadratic, Cubic, Quartic, Quintic, Sinusoidal, Exponential,
       * Circular, Elastic, Back and Bounce
       *
       * And then by the easing type: In, Out and InOut.
       * The syntaxe is : equation.easingType
       */
      easing: {
        type: String,
        default: 'Circular.Out',
      },
      /**
       * Scale interval
       * Won't display any scall if 0 or `null`
       */
      scaleInterval: {
        type: Number,
        default: 5,
        validator: (value) => {
          if (value !== null && value < 0) {
            console.warn('GaugeChart - props "scaleInterval" must be null or >= 0')
          }
          return true
        },
      },
      /**
       * Transition duration in ms
       */
      transitionDuration: {
        type: Number,
        default: 1500,
      },
    },
    setup(props) {

      // Computed...
      const totalAngle = computed( () => {
        const { startAngle, endAngle } = props

        return Math.abs(endAngle - startAngle)
      })

      // Methods
      const getAngle = (value) =>  {
        const { min, max, startAngle } = props
        // Make sure not to divide by 0
        const totalValue = (max - min) || 1

        return ((value * totalAngle.value) / totalValue) + startAngle
      }

      // Lista de colores
      const gaugeColorTo = ref([])
      gaugeColorTo.value = props.gaugeColor
        .filter((item) => {
          const offsetRelative = item.offset * props.max / 100
          return (props.valueForecast >= offsetRelative)
        })
        .map( (item) => item.color ).join(';')

      // Paths
      const gaugePath = computed( () => {
        const { endAngle } = props

        return describePath(RADIUS, getAngle(tweenedValue.value), endAngle)
      })
      // Margen de error para Prev72h
      const gaugePathME = computed( () => {
        return describePath(RADIUS-41, getAngle(tweenedValue.value - tweenedValueME.value), getAngle(tweenedValue.value+tweenedValueME.value))
      })

      const gaugePathExt = computed( () => {
        const { endAngle } = props

        return describePath(RADIUS, getAngle(tweenedForecast.value), endAngle)
      })

      // Margen de error para Prev72h
      const gaugePathExtME = computed( () => {
        return describePath(RADIUS-4, getAngle(tweenedForecast.value - tweenedForecastME.value), getAngle(tweenedForecast.value+tweenedForecastME.value))
      })

      // REALTIME
      const formulaRealTimeLocal = ref(props.formulaRealTime)
      watch(() => props.formulaRealTime, () => {
        formulaRealTimeLocal.value = props.formulaRealTime
      })


      // Array => String: [ [10,10], [10,20], [20,10]] => 10,10, 10,20 20,10
      const triangle2points = (pointsTiangle) => {
        return [
          [pointsTiangle[0][0], pointsTiangle[0][1]].join(','),
          [pointsTiangle[1][0], pointsTiangle[1][1]].join(','),
          [pointsTiangle[2][0], pointsTiangle[2][1]].join(','),
        ].join(' ')
      }

      // RealTime
      //    basePath:     Describe toda la elipse
      //    gaugePath:    Colorear hasta el valor
      //    gaugePathME:  Colorear el margen de error
      const gaugeRealTime = computed( () => {
        const { startAngle, endAngle } = props

        // Aguja en forma de triángulo -> Indicar el valor
        const angle = getAngle(tweenedValue.value) + startAngle
        const point = calcularCoordenadasCircunferencia(centroX, centroY, radio-45, angle)  // -45 por el radio interior
        const pointsTiangle = getVertices(point, angle - 180)  // Al estar en el interior, invertimos

        return {
          basePath: describePath(RADIUS, startAngle, endAngle),
          gaugePath: describePath(RADIUS, getAngle(tweenedValue.value), endAngle),
          gaugePathME: describePath(RADIUS-41, getAngle(tweenedValue.value - tweenedValueME.value), getAngle(tweenedValue.value+tweenedValueME.value)),

          points: triangle2points(pointsTiangle),
          color: formulaRealTimeLocal.toxColor || 'blue',
        }
      })

      const tweenedForecast = ref(0)
      const tweenedForecastME = ref(props.valueForecastME)
      const tweenedValue = ref(0)
      const tweenedValueME = ref(props.valueME)
      watch( [
        () => props.valueForecast,
        () => props.valueForecastME,
        () => props.value,
        () => props.valueME,
      ], () => {
        // Avance:    Forecast > Current: Indicamos directamente el Forecast
        // Descenso:  Forecast < Current: Primero indicamos el valor actual y luego avanzamos/retrocedemos al Forecast
        // tweenForecast(0, props.valueForecast)
        if (props.valueForecast > props.value) {
          tweenForecast(0, props.valueForecast)
        } else {
          tweenForecast(0, props.value)
          setTimeout(() => { tweenForecast(props.value, props.valueForecast) }, props.transitionDuration)
        }
        tweenedForecastME.value = props.valueForecastME

        // Current: Indicamos directamente el valor
        tweenValue(0, props.value)
        tweenedValueME.value = props.valueME
      })

      const animate = () => {
        if (TWEEN.update()) {
          requestAnimationFrame(animate)
        }
      }
      const getSafeValue = (next) => {
        const { min, max } = props

        let safeValue = next
        if (next < min) {
          safeValue = min
        }
        if (next > max) {
          safeValue = max
        }
        return safeValue
      }

      // Forecast Animation
      const tweenForecast = (from, to) => {
        const { transitionDuration, easing } = props

        const safeValueFrom = getSafeValue(from)
        const safeValueTo = getSafeValue(to)

        new TWEEN.Tween({ tweeningForecast: safeValueFrom })
          .to({ tweeningForecast: safeValueTo }, transitionDuration)
          .easing(_get(TWEEN.Easing, easing))
          .onUpdate((object) => {
            tweenedForecast.value = object.tweeningForecast
          })
          .start()

        animate()
      }

      // Current Value
      const tweenValue = (from, to) => {
        const { transitionDuration, easing } = props

        const safeValueFrom = getSafeValue(from)
        const safeValueTo = getSafeValue(to)

        new TWEEN.Tween({ tweeningValue: safeValueFrom })
          .to({ tweeningValue: safeValueTo }, transitionDuration)
          .easing(_get(TWEEN.Easing, easing))
          .onUpdate((object) => {
            tweenedValue.value = object.tweeningValue
          })
          .start()

        animate()
      }

      // Avance:    Forecast > Current: Indicamos directamente el Forecast
      // Descenso:  Forecast < Current: Primero indicamos el valor actual y luego avanzamos/retrocedemos al Forecast
      // tweenForecast(0, props.valueForecast)
      if (props.valueForecast > props.value) {
        tweenForecast(0, props.valueForecast)
      } else {
        tweenForecast(0, props.value)
        setTimeout(() => { tweenForecast(props.value, props.valueForecast) }, props.transitionDuration)
      }

      // Current animation: Siempre de 0 al valor actual
      tweenValue(0, props.value)



      // Coordenadas del punto central de una circunferencia
      const calcularCoordenadasCircunferencia = (Cx, Cy, R, anguloGrados) => {
        // Convertir el ángulo de grados a radianes
        var anguloRadianes = (anguloGrados * Math.PI) / 180;

        // Calcular las coordenadas
        var Px = Cx + R * Math.cos(anguloRadianes);
        var Py = Cy + R * Math.sin(anguloRadianes);

        // return { x: Px, y: Py };
        return [Px, Py];
      }

      // const pathTriangle = computed( () => {
      //   return describePathTriangle([20, 20], 45)
      // })
      // debugger
      const center1 = calcularCoordenadasCircunferencia(X_CENTER/2, Y_CENTER/2, RADIUS/2, 90)
      const pathTriangle = describePathTriangle(center1, 0)


      const center2 = calcularCoordenadasCircunferencia(X_CENTER/2, Y_CENTER/2, RADIUS/2, 90-45)
      // const pathTriangleAngle = describePathTriangle([20, 80], 90)
      const pathTriangleAngle = describePathTriangle(center2, 90)


      // const pathTriangle45 = describePathTriangle([30, 20], 45)
      // const pathTriangle90 = describePathTriangle([100, 20], 90)
      const listaPuntos = []
      var centroX = X_CENTER;
      var centroY = Y_CENTER;
      var radio = RADIUS;
      var numPuntos = 36; // Número de puntos
      // var anguloIncremento = (2 * Math.PI) / numPuntos;
      var anguloIncremento = 360 / numPuntos;

      // for (var i = 0; i < numPuntos; i++) {
      //   var angulo = i * anguloIncremento;
      //   listaPuntos[i] = calcularCoordenadasCircunferencia(centroX, centroY, radio, angulo)
      // }

      // A partir del vértice principal monta el resto del triángulo
      // Vertex es el punto del perímetro del círculo
      const getPoints = (vertex) => {
        const side = 10;
        // M 30 30 L 50 30 L 50 50
        var x1 = vertex[0];  // 20
        var y1 = vertex[1];  // 20
        var x2 = x1 - side;
        var y2 = y1;
        var x3 = x1;
        var y3 = y1 - side;

        return [ [x1, y1], [x2, y2], [x3, y3] ]
      }

      const getVertices = (vertex, angle) => {

        const points = getPoints(vertex)

        // El triangulo base se define así:  _^_
        // Tenemos que aplicar una rotación de 135 grados para que el vértice apunte hacia el centro del círculo
        var correccion = 135; // 90 + 45

        // Aplicar rotación a cada vértice (Sobre el vértice principal)
        // var punto1 = [x1, y1];
        // var punto2 = rotarPunto(x2, y2, x1, y1, angle + correccion);
        // var punto3 = rotarPunto(x3, y3, x1, y1, angle + correccion);
        var punto1 = points[0];
        var punto2 = rotarPunto(points[1][0], points[1][1], punto1[0], punto1[1], angle + correccion);
        var punto3 = rotarPunto(points[2][0], points[2][1], punto1[0], punto1[1], angle + correccion);

        return [ punto1, punto2, punto3 ]
      }
      // Vx,Vy X1,Y1 X2,Y2
      // const listaVertices = listaPuntos.map((point) => {
      // debugger
      const listaVertices = []
      for (var i2 = 0; i2 < numPuntos; i2++) {
        var angle = i2 * anguloIncremento;
        const point = calcularCoordenadasCircunferencia(centroX, centroY, radio, angle)

        listaVertices[i2] = getVertices(point, angle)
      }

      // debugger
      const pointsTiangle = computed( () => {
        const { startAngle } = props

        // const angle = getAngle(props.valueForecast) + startAngle
        const angle = getAngle(tweenedForecast.value) + startAngle
        const point = calcularCoordenadasCircunferencia(centroX, centroY, radio, angle)

        return getVertices(point, angle)
      })
      // const pointsTiangle = listaVertices[30]

      // RealTime: Triángulo indicador interior
      const pointsTiangleInt = computed( () => {
        const { startAngle } = props

        const angle = getAngle(tweenedValue.value) + startAngle
        const vertex = calcularCoordenadasCircunferencia(centroX, centroY, radio-45, angle)  // -45 por el radio interior

        return getVertices(vertex, angle - 180)  // Al estar en el interior, invertimos
      })


      // Renderizamos la última el valor de la última analítica
      const pointLastAnalitica = computed( () => {
        const { startAngle,  formulaLastAnalisis} = props

        const angle = getAngle(formulaLastAnalisis.value) + startAngle
        const vertex = calcularCoordenadasCircunferencia(centroX, centroY, radio-45, angle)

        return {vertex, color: formulaLastAnalisis.color}
      })

      // Renderizamos la última el valor de la última analítica
      const pointToxAAnalitica = computed( () => {
        const { startAngle,  toxAAnalitica} = props

        const angle = getAngle(toxAAnalitica) + startAngle
        const vertex = calcularCoordenadasCircunferencia(centroX, centroY, radio-45, angle)

        return {vertex, color: 'blue'}
      })

      // Trazamos una trayectoria entre A y B, continuamos 10 puntos hacia fuera del cículo
      const calcularPuntoExterior = (pointA, pointB) => {
        var dx = pointB[0] - pointA[0];
        var dy = pointB[1] - pointA[1];
        var longitud = Math.sqrt(dx * dx + dy * dy); // Longitud de la trayectoria
        var direccionX = dx / longitud;
        var direccionY = dy / longitud;

        var posicionX = pointB[0] + direccionX * 10;
        var posicionY = pointB[1] + direccionY * 10;

        return [posicionX, posicionY]
      }

      // Texto, ángulo y posición del Forecast
      const forecastText = computed( () => {
        const { startAngle } = props

        // const angle = getAngle(valueForecast) + startAngle
        const angle = getAngle(tweenedForecast.value) + startAngle
        const vertex = calcularCoordenadasCircunferencia(centroX, centroY, radio, angle)
        const centralPoint = calcularPuntoExterior([centroX, centroY], vertex)

        return {
          vertex: vertex,
          point: centralPoint,
          angle: angle,
          text: Math.round(tweenedForecast.value)
        }
      })

      // Indicamos las referencias más importantes [180, 90, 0] (Ver orden de ángulos de una circunferencia)
      // const angleOffset = 180 // esfera superior
      // Manual
      // const labels = [
      //   { x: 2, y: 108, align: "start", value: props.min},
      //   { x: 198, y: 108, align: "end", value: props.max},
      //   { x: 100, y: 8, align: "middle", value: props.limit}
      // ]
      const center0 = calcularCoordenadasCircunferencia(centroX, centroY, radio, 0)
      const center90 = calcularCoordenadasCircunferencia(centroX, centroY, radio, -90)
      const center180 = calcularCoordenadasCircunferencia(centroX, centroY, radio, -180)
      const labels = [
        { x: center180[0]+2, y: center180[1]-2, align: "start", value: props.min},  // Left   Min
        { x: center0[0]-2, y: center0[1]-2, align: "end", value: props.max},        // Right  Max
        { x: center90[0], y: center90[1]+7, align: "middle", value: props.limit},   // Center Limit
      ]

      return {
        gaugeRealTime,

        gaugePath,      // Path Value
        gaugePathExt,   // PathForecast
        gaugePathME,    // Path para Margen de error (RealTime)
        gaugePathExtME, // Path para margen de error (Prev72h)

        listaPuntos,
        listaVertices,
        labels,

        // Prev72h
        pointsTiangle,
        forecastText,

        // RealTime
        pointsTiangleInt,
        pointLastAnalitica,     // Última analítica (<7d)
        pointToxAAnalitica,     // ToxA con analítica

        center1,
        center2,
        pathTriangle,
        pathTriangleAngle,
        // pathTriangle45,
        // pathTriangle90,

        gaugeColorTo,
      }
    },
    data() {
      return {
        HEIGHT: HEIGHT,
        WIDTH: WIDTH,
        X_CENTER: X_CENTER,
        Y_CENTER: Y_CENTER,
        RADIUS: RADIUS,
        /**
         * Tweened value for the animation of the gauge
         * Starts at `min`
         * @type {Number}
         */
        tweenedValue: this.min,
      }
    },
    computed: {
      /**
       * d property of the path of the base gauge (the colored one)
       * @type {String}
       */
      basePath() {
        const { startAngle, endAngle } = this

        return describePath(RADIUS, startAngle, endAngle)
      },

      /**
       * Total angle of the gauge
       * @type {Number}
       */
      totalAngle() {
        const { startAngle, endAngle } = this

        return Math.abs(endAngle - startAngle)
      },
      /**
       * True if the gauge is a full circle
       * @type {Boolean}
       */
      isCircle() {
        return Math.abs(this.totalAngle) === 360
      },
      /**
       * True if the gaugeColor is an array
       * Result in displaying a gradient instead of a simple color
       * @type {Boolean}
       */
      hasGradient() {
        return Array.isArray(this.gaugeColor)
      },
      /**
       * Array of the path of each separator
       */
      separatorPaths() {
        const {
          separatorStep, getAngle, min, max, separatorThickness, isCircle,
        } = this

        if (separatorStep > 0) {
          const paths = []
          // If the gauge is a circle, this will add a separator at the start
          let i = isCircle ? min : min + separatorStep

          for (i; i < max; i += separatorStep) {
            const angle = getAngle(i)
            const halfAngle = separatorThickness / 2

            paths.push(describePath(RADIUS + 2, angle - halfAngle, angle + halfAngle))
          }

          return paths
        }

        return null
      },
      /**
       * Array of line configuration for each scale
       */
      scaleLines() {
        const {
          scaleInterval, isCircle, min, max, getAngle, innerRadius,
        } = this

        if (scaleInterval > 0) {
          const lines = []
          // if gauge is a circle, remove the first scale
          let i = isCircle ? min + scaleInterval : min

          for (i; i < max + scaleInterval; i += scaleInterval) {
            const angle = getAngle(i)
            const startCoordinate = polarToCartesian(innerRadius - 4, angle)
            const endCoordinate = polarToCartesian(innerRadius - 8, angle)

            lines.push({
              xS: startCoordinate.x,
              yS: startCoordinate.y,
              xE: endCoordinate.x,
              yE: endCoordinate.y,
            })
          }

          return lines
        }

        return null
      },
    },
    watch: {
    },
    methods: {
      /**
       * Get an angle for a value
       * @param   {Number} value
       * @returns {Number} angle - in degree
       */
      getAngle(value) {
        const { min, max, startAngle, totalAngle } = this
        // Make sure not to divide by 0
        const totalValue = (max - min) || 1

        return ((value * totalAngle) / totalValue) + startAngle
      },
    },
  }
</script>

<style lang="css">
  .gauge {
    width: 100%;
    height: 100%;
  }
</style>
