<template>
  <div class="d-flex align-center" style="height-: 56px;">
    <!-- offset-y + top:Hacia arriba en XS  or bottom: Hacia abajo en XS -->
    <!-- <v-menu
      transition="scale-transition"
      origin="center center"
      v-model="isToxinTypeOpen"
      open-on-hover
      offset-y
      :top="$vuetify.breakpoint.xs"
      :bottom="!$vuetify.breakpoint.xs"
    > -->

    <!-- Abrimos hacia la izquierda -->
    <v-menu
      transition="scale-transition"
      origin="center center"
      v-model="isToxinTypeOpen"
      open-on-hover
      offset-x
      left
      direction="left"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-on="on"
          v-bind="attrs"
          v-model="isToxinTypeOpen"
          small
          fab
          :loading="loading"
          :color="(toxinTypeSelectedLocal && toxinTypeSelectedLocal.color) || 'blue darken-2'"
        >
          <v-icon v-if="isToxinTypeOpen">
            mdi-close
          </v-icon>
          <span v-else>{{ toxinTypeSelectedLocal ? toxinTypeSelectedLocal.value : '??' }}</span>
        </v-btn>
      </template>

      <!-- <div class="d-flex flex-column" :class="[$vuetify.breakpoint.xs ? 'mb-12' : 'mt-12']"> -->
      <div class="d-flex flex-column-">
        <!-- @click="$emit('update:toxinTypeSelectedLocal', item)" -->
        <v-btn
          class="mx-2"
          v-for="(item, index) in toxinTypeOptions"
          :key="index"
          v-show="toxinTypeSelectedLocal && toxinTypeSelectedLocal.value !== item.value"
          fab
          small
          :color="item.color"
          @click="toxinTypeSelectedLocal = item"
        >
          {{ item.text }}
        </v-btn>
      </div>
    </v-menu>
  </div>
  <!-- <v-progress-circular color="white" indeterminate :size="35" v-if="loading"></v-progress-circular> -->
  <!-- <div>
      <v-speed-dial
        v-model="isToxinTypeOpen"
        absolute
        right
        top
        small
        direction="bottom"
        open-on-hover
        transition="slide-y-reverse-transition"
      >
        <template v-slot:activator>
          <v-btn
            v-model="isToxinTypeOpen"
            dark
            fab
            :loading="loading"
            :color="(toxinTypeSelectedLocal && toxinTypeSelectedLocal.color) || 'blue darken-2'"
          >
            <v-icon v-if="isToxinTypeOpen">
              mdi-close
            </v-icon>
            <span v-else>{{ toxinTypeSelectedLocal ? toxinTypeSelectedLocal.value : '??' }}</span>

          </v-btn>
        </template>
        <v-btn
          v-for="(item, index) in toxinTypeOptions"
          :key="index"
          v-show="toxinTypeSelectedLocal && toxinTypeSelectedLocal.value !== item.value"
          fab
          small
          :color="item.color"
          @click="$emit('update:toxinTypeSelectedLocal', item)"
        >
          {{ item.text }}
        </v-btn>
      </v-speed-dial>
    </div> -->
</template>
<script>
  import { ref, watch } from 'vue';
  import useAppConfig from '@/store/useAppConfig';



  export default {
    name: 'forecast-toxin-type',
    props: {
      // toxinTypeSelected: {
      //   type: Object,
      //   default: () => ({
      //     value: 'DSP',
      //     text: 'LIPO',
      //     color: 'blue',
      //     image: ''
      //   }),
      // },
      // toxinTypeOptions: {
      //   type: Array,
      //   default: () => [
      //     { value: 'DSP', text: 'LIPO', color: 'blue', image: ''},
      //     { value: 'ASP', text: 'ASP', color: 'orange', image: ''},
      //     { value: 'PSP', text: 'PSP', color: 'green', image: ''},
      //   ],
      // },
      loading: {
        type: Boolean,
        default: false
      }
    },
    setup() {

      // Al cambiar de Tipo de toxina, indicamos el por defecto
      // TODO - Tener un fito por defecto para cada tipo de toxina
      const { cellsOptions } = useAppConfig()

      const { toxinType } = useAppConfig()
      watch(() => toxinType.value, () => {
        cellsOptions.value.feedTypeAP = 'all';
      })

      // Lista de Tipos de Toxinas
      const toxinTypeOptions = [
        { value: 'DSP', text: 'LIPO', color: 'blue', image: ''},
        { value: 'ASP', text: 'ASP', color: 'orange', image: ''},
        { value: 'PSP', text: 'PSP', color: 'green', image: ''},
      ]

      // ToxinType Local
      const toxinTypeSelectedLocal = ref(toxinTypeOptions.find(item => item.value === toxinType.value))
      if (!toxinTypeSelectedLocal.value) {
        toxinTypeSelectedLocal.value = toxinTypeOptions[0]
      }
      watch(() => toxinTypeSelectedLocal.value, () => {
        toxinType.value = toxinTypeSelectedLocal.value.value
      })

      // Only for internal use of control (Change icon when selected)
      const isToxinTypeOpen = ref(false)
      return {
        isToxinTypeOpen,

        toxinTypeOptions,
        toxinTypeSelectedLocal
      }
    }
  }
</script>
