// axiosConfig.js
import Vue from 'vue'
import axios from 'axios';


// Función para mostrar un toast con Vuetify
// const showToast = (message, type='error') => {
//   Vue.prototype.$snackbar.show({
//     message: message,
//     color: type, // Puedes cambiar 'success', 'error', 'warning', 'info', etc.
//     timeout: 3000, // Duración en milisegundos (3 segundos en este caso)
//   });
// };

// Params
const XDEBUG = process.env.VUE_APP_XDEBUG === 'true' || false // X-DEBUG indicator

// Crea una instancia de Axios con configuraciones personalizadas
const axiosInstance = axios.create({
  // baseURL: 'localhost:8050', // Cambia esto a la URL base de tu API (Por ahora indicamos la baseURL en la llamada)
});

// Interceptar las solicitudes para agregar el token de autorización en el encabezado
axiosInstance.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token'); // Obtén el token del almacenamiento local o de donde lo estés almacenando
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    // Add x-debug param
    // console.log(XDEBUG)
    if (XDEBUG) {
      if (!config.params) {
        Object.assign(config, { params: {} })
      }
      Object.assign(config.params, { XDEBUG_SESSION_START: 'PHPSTORM' })
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  }
)

// Interceptor para capturar la excepción 401
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {

    // Checking... Cuando el usuario se haya quedado sin permisos -> Actualizamos
    if (error.response && error.response.status === 401) {
      // console.log('AxiosError401',error)

      // Actualizamos la página solo una vez
      const hasToken = localStorage.getItem('token')
      if (hasToken) {
        console.log('AxiosError401-Remove and reload')
        localStorage.removeItem('token');
        localStorage.removeItem('user');

        window.location.reload();
      }
    }

    // Error
    if (error.response) {

      // TODO - Temporal => Por ahora sacamos el error
      // Errores de dominio se resuelven en cada formulario
      if (error.response && error.response.data && error.response.data.error) {
        let errorMessage = null
        if (typeof error.response.data.error === 'object') {
          Object.keys(error.response.data.error).forEach(key => {
            let errorArr = error.response.data.error[key]
            if (errorArr) {
              errorArr = typeof errorArr === 'string' ? [errorArr] : errorArr

              // eslint-disable-next-line prefer-destructuring
              errorMessage += `${key}: ${errorArr[0]}`
            }
          })
        }
        if ( typeof error.response.data.error === 'string') {
          errorMessage = error.response.data.error
        }
        errorMessage = errorMessage || 'Error de dominio'
        console.log('Axios.Response', errorMessage)

        // store.commit('app/ERROR', `(${error.response.status}) ${errorMessage}`)
        Vue.$toast.error(`(${error.response.status}) ${errorMessage}`, { position: 'bottom-center' })
      } else {
        // store.commit('app/ERROR', `(${error.response.status}) ${error.message}`)
        Vue.$toast.error(`(${error.response.status}) ${error.message}`, { position: 'top-center' })
      }
    } else {
      Vue.$toast.error(error.message, { position: 'top-center' })
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
