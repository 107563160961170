<template>

  <!-- Lista de Rías -->
  <div class="d-flex flex-row">

    <!-- Opcion Larga: Lista de botones agrupados -->
    <!-- <div class="d-flex flex-row"> -->
    <!-- group -->
    <!-- borderless -->
    <v-btn-toggle
      v-if="!$vuetify.breakpoint.xs"
      v-model="riaIdSelectedLocal"
      color="deep-purple accent-3"
      mandatory
    >
      <!-- :class="intoxication.ria.id == riaIdSelectedLocal ? 'deep-purple accent-3' : ''" -->
      <v-btn
        v-for="intoxication in intoxicationTableListLocal"
        :key="intoxication.ria.id"
        :value="intoxication.ria.id"
        text
        height="32px"
        @click="$emit('update:riaIdSelected', intoxication.ria.id)"
      >
        <span>{{ intoxication.ria.key }}</span>
      </v-btn>
    </v-btn-toggle>

    <!-- Bottom Sheet: Opcion Corta -->
    <v-bottom-sheet
      v-if="$vuetify.breakpoint.xs"
      v-model="isDialogActiveLocal"
    >
      <template v-slot:activator="{ props }">
        <v-btn
          v-bind="props"
          height="32px"
          @click="isDialogActiveLocal = true"
          class="px-2 pr-0"
        >
          <div class="d-flex flex-row align-center">
            <span style="font-size: .625rem;">{{ riaSelectedLocal?.ria?.name }}</span>
            <v-divider
              vertical
              class="pl-2"
            ></v-divider>
            <v-icon>mdi-chevron-down</v-icon>
          </div>
        </v-btn>
      </template>

      <v-list>
        <v-subheader>Seleccione una Ría</v-subheader>
        <v-divider></v-divider>
        <v-list-item
          v-for="intoxication in intoxicationTableListLocal"
          :key="intoxication.ria.id"
          :value="intoxication.ria.id"
          @click="
            isDialogActiveLocal = false;
            riaSelectedLocal = intoxication;
            riaIdSelectedLocal = intoxication.ria.id;
            $emit('update:riaIdSelected', intoxication.ria.id);
          "
          :class="intoxication.ria.id == riaIdSelectedLocal ? 'purple' : ''"
        >
          <div class="d-flex flex-row">
            <span class="flex-grow-1" style="min-width: 60px;">{{ intoxication.ria.key }}</span>
            <span class="flex-grow-5 ml-2">{{ intoxication.ria.name }}</span>
          </div>
        </v-list-item>
      </v-list>
    </v-bottom-sheet>

    <!-- Menu: Opcion Corta -->
    <!-- <v-menu v-if="$vuetify.breakpoint.xs">
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          height="32px"
        >
          {{ riaSelectedLocal?.ria?.name }}
        </v-btn>
      </template>

      <v-list>
        <v-list-item
          v-for="intoxication in intoxicationTableListLocal"
          :key="intoxication.ria.id"
          :value="intoxication.ria.id"
          @click="
            riaSelectedLocal = intoxication;
            riaIdSelectedLocal = intoxication.ria.id;
            $emit('update:riaIdSelected', intoxication.ria.id);
          "
          :class="intoxication.ria.id == riaIdSelectedLocal ? 'purple' : ''"
        >
          <v-list-item-title>{{ intoxication.ria.name }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu> -->

  </div>
</template>

<script>
  import { ref, watch } from 'vue';


  export default {
    name: 'forecast-select-ria-component',
    components: {
    },
    props: {
      isDialogActive: {
        type: Boolean,
        required: false,
        default: false
      },
      intoxicationTableList: {
        type: Array,
        default: () => []
      },
      riaIdSelected: {
        type: Number,
        require: true,
        default: undefined
      },
    },
    setup(props) {
      const isDialogActiveLocal = ref(props.isDialogActive)
      const intoxicationTableListLocal = ref(JSON.parse(JSON.stringify(props.intoxicationTableList)))

      // debugger
      // Selección actual
      const riaIdSelectedLocal = ref(props.riaIdSelected)
      const riaSelectedLocal = ref(props.intoxicationTableList.find((item) => item.ria.id == props.riaIdSelected))

      // props passed to setup function is reactive object (made probably by reactive()), it's properties are getters.
      // Watching a getter: https://v3.vuejs.org/api/computed-watch-api.html#watching-a-single-source
      watch([
        () => props.intoxicationTableList,
        () => props.riaIdSelected,
      ], () => {
        intoxicationTableListLocal.value = JSON.parse(JSON.stringify(props.intoxicationTableList));
        riaIdSelectedLocal.value = props.riaIdSelected

        riaSelectedLocal.value = props.intoxicationTableList.find((item) => item.ria.id == props.riaIdSelected)
      })

      return {
        isDialogActiveLocal,
        intoxicationTableListLocal,

        riaIdSelectedLocal,
        riaSelectedLocal
      }
    }
  }
</script>

<style scoped>
</style>
