<template>
  <!-- :fullscreen="$vuetify.breakpoint.xs" -->
  <v-dialog
    v-model="isDialogActiveLocal"
    max-width="500"
    scrollable
    @click:outside="$emit('update:is-dialog-active',false); isDialogActiveLocal = false"
  >
    <!-- <v-bottom-sheet
    v-model="isDialogActiveLocal"
    max-width="500px"
    @click:outside="$emit('update:is-dialog-active',false); isDialogActiveLocal = false"
  > -->
    <template v-slot:activator="{ on, attrs }">

      <!-- Modo: hidden => Se ocula el boton que activa el dialogo, es el Root el encargado de activarlo -->
      <v-btn
        v-if="mode == 'button'"
        v-on="on"
        v-bind="attrs"
        style="margin-left: -0.5rem;"
        height="32px"
        :small="$vuetify.breakpoint.xs"
        :icon="true"
      >
        <!-- <v-icon>mdi-dots-vertical</v-icon> -->
        <v-icon>mdi-cog</v-icon>
      </v-btn>
      <!-- <span class="d-none d-md-block  ml-2" style="font-size: .625rem;">Filtrar <br> {{  getLabelFeedTypeAP(feedTypeAPSelectedLocal) }}</span> -->

      <!-- Modo inline: Se visualiza directamente los botones y no el diálogo -->
      <!-- Ejemplo de visualizar los botones en linea -->
      <div
        v-if="mode == 'inline'"
        class="d-flex flex-row align-center">
        <v-btn
          icon
          :color="layerBorderLocal.color"
          @click="onChangeBorder()"
        >
          <v-icon>{{ layerBorderLocal.icon }}</v-icon>
        </v-btn>
        <v-btn
          icon
          :color="layerBackgroundLocal.color"
          @click="onChangeBackground()"
        >
          <v-icon>{{ layerBackgroundLocal.icon }}</v-icon>
        </v-btn>
        <v-btn
          icon
          :color="layerRenderLocal.color"
          @click="onChangeRender()"
        >
          <v-icon>{{ layerRenderLocal.icon }}</v-icon>
        </v-btn>
      </div>
    </template>

    <v-card class="d-flex flex-column w-100 rounded-0 mt-4">
      <v-toolbar :elevation="8" title="Filtrar fitoplancton" color="rgb(0, 192, 255)">
        <v-toolbar-title>{{ layerType === 'toxins' ? 'Toxinas POL' : 'Células PMI' }}</v-toolbar-title>

        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('update:is-dialog-active', false); isDialogActiveLocal = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <!-- Body -->
      <div class="d-flex flex-column px-3 py-3">

        <!-- <div class="d-flex align-center my-3">
          <span class="ml-1">Tipos de fitoplancton a visualizar</span>
        </div>
        <v-divider></v-divider> -->

        <!-- <div class="d-flex flex-column my-3"> -->
        <div class="d-flex flex-row align-center ml-3 my-1">
          <!-- @change="$emit('update:isTFChecked', isTFCheckedLocal)" -->
          <v-switch
            style="width: 66%;"
            v-model="layerLocal.active"
            label="Mostrar capa"
          ></v-switch>
          <div class="flex-fill d-flex flex-column align-center" style="width: 33%;">
            <div
              class="d-flex flex-column align-center"
              :style="{
                'width': '64px',
                'height': '64px',
                'border-radius': layerType === 'toxins' ? '0' : '50%',
                'border': '2px solid', 'border-color': layerBorderLocal.color == 'grey' ? 'transparent' : layerBorderLocal.color,
                'background-color': layerBackgroundLocal.color == 'grey' ? 'transparent' : layerBackgroundLocal.color,
              }">
              <!-- Icono -->
              <div
                v-if="['simulation-daily', 'simulation-forecast'].includes(layerRenderLocal.value)"
                class="d-flex algign-center forecast-point forecast-icon"
                style="background-color: rgb(192, 144, 255); height: 21px; position: relative; top: 26px; left: 21px; width: 44px"
              >
                <i v-if="layerRenderLocal.value === 'simulation-forecast'"
                   class="v-icon notranslate mdi mdi-navigation theme--light white--text"
                   style="font-size: 14px; transform: rotate(45deg); -webkit-transform:rotate(45deg);">
                </i>
                83
              </div>
              <!-- Nombre -->
              <div
                v-if="layerRenderLocal.value === 'name'"
                :style="{ 'padding-top': '20px' }"
              >
                <span>{{ layerType === 'toxins' ? 'Bueu B' : 'V1' }}</span>
              </div>
            </div>
          </div>
        </div>
        <v-divider></v-divider>

        <div class="d-flex flex-row align-center ml-3 mt-3">

          <!-- Borde -->
          <div class="flex-fill d-flex flex-column align-center" style="width: 33%;">
            <span class="text-decoration-underline">Borde</span>
            <v-btn
              icon
              :color="layerBorderLocal.color !== 'transparent' ? layerBorderLocal.color : 'grey'"
              @click="onChangeBorder()"
              :disabled="!layerLocal.active"
            >
              <v-icon>{{ layerBorderLocal.icon }}</v-icon>
            </v-btn>
            <span>{{ layerBorderLocal.label }}</span>
          </div>

          <!-- Background: layerLocal.background layerBackgroundLocal -->
          <div class="flex-fill d-flex flex-column align-center" style="width: 33%;">
            <span class="text-decoration-underline">Relleno</span>
            <v-btn
              icon
              :color="layerBackgroundLocal.color !== 'transparent' ? layerBackgroundLocal.color : 'grey'"
              @click="onChangeBackground()"
              :disabled="!layerLocal.active"
            >
              <v-icon>{{ layerBackgroundLocal.icon }}</v-icon>
            </v-btn>
            <span>{{ layerBackgroundLocal.label }}</span>
          </div>

          <!-- Renderizado -->
          <div class="flex-fill d-flex flex-column align-center" style="width: 33%;">
            <span class="text-decoration-underline">Renderizado</span>
            <v-divider></v-divider>
            <v-btn
              icon
              :color="layerRenderLocal.color"
              @click="onChangeRender()"
              :disabled="!layerLocal.active"
            >
              <v-icon>{{ layerRenderLocal.icon }}</v-icon>
            </v-btn>
            <span>{{ layerRenderLocal.label }}</span>
          </div>
        </div>

        <!--  -->
        <v-divider class="mt-4 ml-6"></v-divider>
        <div class="d-flex flex-column ml-6">
          <v-checkbox
            v-model="layerLocal.isBorderAlwaysVisible"
            label="Borde siempre visible"
            hide-details
            :disabled="!layerLocal.active"
          ></v-checkbox>

          <v-checkbox
            v-model="layerLocal.isRenderHiddenOnZoom"
            label="Ocultar renderizado con Vista global (4 rías)"
            hide-details
            :disabled="!layerLocal.active"
          ></v-checkbox>
        </div>

      </div>
    </v-card>

  </v-dialog>
  <!-- </v-bottom-sheet> -->
</template>

<script>
  import { ref, watch } from 'vue';

  // TODO - para a constantes globales para poder usar en appStore
  // Opciones de renderizado: Toxinas y células
  const menuOptionsRenderToxins = [
    { value: undefined, label: 'Off', color: 'grey', icon: 'mdi-map-marker-off'},
    { value: 'simulation-daily', label: 'Toxinas', color: 'blue', icon: 'mdi-map-marker'},
    { value: 'simulation-forecast', label: 'Predicción', color: 'blue', icon: 'mdi-navigation'},
    { value: 'name', label: 'Nombre', color: 'grey', icon: 'mdi-text-recognition'},
  ];
  const menuOptionsRenderCells = [
    { value: undefined, label: 'Off', color: 'grey', icon: 'mdi-map-marker-off'},
    { value: 'simulation-daily', label: 'Células', color: 'blue', icon: 'mdi-map-marker'},
    { value: 'name', label: 'Nombre', color: 'grey', icon: 'mdi-text-recognition'},
    // { value: 'simulation-forecast', label: 'For', color: 'blue', icon: 'mdi-map-marker-distance'},
  ]

  //    layerPOLBorder:     [ undefined, 'color-estado', 'color-plan', 'color-simulation-daily', 'color-simulation-forecast' ]
  //    layerPOLBackground: [ undefined, 'color-estado', 'color-plan', 'color-simulation-daily', 'color-simulation-forecast' ]
  //    layerPOLRender:     [ undefined, 'simulation-daily', 'simulation-forecast' ]
  const menuOptionsBorderToxins = [
    { value: undefined, label: 'Sin borde', color: 'grey', icon: 'mdi-square-off-outline', text: '' },
    { value: 'color-estado', label: 'Estado', color: 'purple', icon: 'mdi-square-outline', text: 'E' },
    { value: 'color-plan', label: 'Plan Actuación', color: 'purple', icon: 'mdi-square-outline', text: 'P' },
    // { value: 'color-simulation-daily', label: 'SIM.Diaria', color: 'purple', icon: false, text: 'Sim' },
    // { value: 'color-simulation-forecast', label: 'Previsión', color: 'purple', icon: false, text: 'P' },
  ]
  const menuOptionsBorderCells = [
    { value: undefined, label: 'Sin borde', color: 'grey', icon: 'mdi-circle-off-outline', text: '' },
    { value: 'border', label: 'Con borde', color: 'yellow', icon: 'mdi-circle-outline', text: '' },
  ]

  // Background options
  const menuOptionsBackgroundToxins = [
    { value: undefined, label: 'Sin relleno', color: 'grey', icon: 'mdi-square-off', text: '' },
    { value: 'color-estado', label: 'Estado', color: '#AB47BC', icon: 'mdi-square', text: 'E' },
    { value: 'color-plan', label: 'Plan Actuación', color: '#AB47BC', icon: 'mdi-square', text: 'P' },
  ]
  const menuOptionsBackgroundCells = [
    { value: undefined, label: 'Sin relleno', color: 'grey', icon: 'mdi-circle-off-outline', text: '' },
    { value: 'background', label: 'Con relleno', color: '#AB47BC', icon: 'mdi-circle', text: '' },
  ]

  export default {
    name: 'menu-layer-params-dialog-component',
    components: {
    },
    props: {
      mode: {
        type: String,
        required: false,
        default: 'button' // hidden, inline, button
      },
      isDialogActive: {
        type: Boolean,
        required: false,
        default: false
      },
      layerType: {
        type: String,
        required: true, // ['']
        default: 'toxins' // 'toxins' || 'cells'
      },
      layer: {
        type: Object,
        required: true,
        default: () => ({
          active: false,
          border: undefined,
          background: undefined,
          render: undefined,
        }),
      },
    },
    setup(props, { emit }) {
      const isDialogActiveLocal = ref(props.isDialogActive)
      watch(( () => props.isDialogActive), () => {
        isDialogActiveLocal.value = props.isDialogActive
      })

      // Inicializamos con: Menu Toxinas Vs Menu Células
      let menuOptionsBorder = []
      let menuOptionsBackground = []
      let menuOptionsRender = []
      if (props.layerType === 'toxins') {
        menuOptionsBorder = menuOptionsBorderToxins
        menuOptionsBackground = menuOptionsBackgroundToxins
        menuOptionsRender = menuOptionsRenderToxins
      }
      if (props.layerType === 'cells') {
        menuOptionsBorder = menuOptionsBorderCells
        menuOptionsBackground = menuOptionsBackgroundCells
        menuOptionsRender = menuOptionsRenderCells
      }

      // Layer
      const layerLocal = ref(props.layer)
      const layerBorderLocal = ref(null)
      const layerBackgroundLocal = ref(null)
      const layerRenderLocal = ref(null)

      const setBorder = (val) => {
        layerBorderLocal.value = menuOptionsBorder.find( (element) => element.value == val )
        if (!layerBorderLocal.value) {
          layerBorderLocal.value = menuOptionsBorder[0]
        }
        layerLocal.value.border = layerBorderLocal.value.value
      }
      const setBackground = (val) => {
        layerBackgroundLocal.value = menuOptionsBackground.find( (element) => element.value == val )
        if (!layerBackgroundLocal.value) {
          layerBackgroundLocal.value = menuOptionsBackground[0]
        }
        layerLocal.value.background = layerBackgroundLocal.value.value
      }
      const setRender = (val) => {
        layerRenderLocal.value = menuOptionsRender.find((element) => element.value == val)
        if (!layerRenderLocal.value) {
          layerRenderLocal.value = menuOptionsRender[0]
        }
        layerLocal.value.render = layerRenderLocal.value.value
      }

      // Set initial and Watch for changes
      setBorder(props.layer.border)
      setBackground(props.layer.background)
      setRender(props.layer.render)
      // Watch for external changes
      watch(() => props.layer, () => {
        layerLocal.value = props.layer

        setBorder(props.layer.border)
        setBackground(props.layer.background)
        setRender(props.layer.render)
      }, { deep: true})

      watch(() => layerLocal.value, () => {
        emit('update:layer', layerLocal.value)
      }, { deep: true})
      // watch([
      //   () => layerBorderLocal.value,
      //   () => layerBackgroundLocal.value,
      //   () => layerRenderLocal.value,
      // ], () => {
      //   debugger
      //   layerLocal.value.border = layerBorderLocal.value.value
      //   layerLocal.value.background = layerBackgroundLocal.value.value
      //   layerLocal.value.render = layerRenderLocal.value.value
      // })


      // const onChangeActive = (newValue) => {
      //   isTFCheckedLocal.value = newValue
      //   emit('update:isTFChecked', isTFCheckedLocal.value)
      //   localStorage.setItem('cells-tf', isTFCheckedLocal.value)
      // }

      // Next
      const getNextIndex = (arrayList, val) => {
        const index = arrayList.findIndex((element) => element.value == val)
        let next = index +1
        if (next > arrayList.length - 1) {
          next = 0
        }
        return next
      }
      const onChangeBorder = () => {
        const next = getNextIndex(menuOptionsBorder, layerBorderLocal.value.value)
        setBorder(menuOptionsBorder[next]?.value )
      }
      const onChangeBackground = () => {
        const next = getNextIndex(menuOptionsBackground, layerBackgroundLocal.value.value)
        setBackground(menuOptionsBackground[next]?.value )
      }
      const onChangeRender = () => {
        const next = getNextIndex(menuOptionsRender, layerRenderLocal.value.value)
        setRender(menuOptionsRender[next]?.value )
      }

      return {
        isDialogActiveLocal,

        layerLocal,
        layerBorderLocal,
        layerBackgroundLocal,
        layerRenderLocal,
        onChangeBorder,
        onChangeBackground,
        onChangeRender,
      }
    }
  }
</script>

<style>
.custom-slider .v-label {
  width: 100px; /* Establece el ancho fijo que desees */
  /* min-width: 80px; */
}
</style>
