<template>
  <!-- :fullscreen="$vuetify.breakpoint.xs" -->
  <v-dialog
    v-model="isDialogActiveLocal"
    max-width="500"
    scrollable
    @click:outside="$emit('update:is-dialog-active',false); isDialogActiveLocal = false"
  >
    <!-- <v-bottom-sheet
    v-model="isDialogActiveLocal"
    max-width="500px"
    @click:outside="$emit('update:is-dialog-active',false); isDialogActiveLocal = false"
  > -->
    <template v-slot:activator="{ on, attrs }">

      <!-- Modo: hidden => Se ocula el boton que activa el dialogo, es el Root el encargado de activarlo -->
      <div class="d-flex flex-row ml-2">
        <v-btn
          v-if="mode == 'button'"
          v-on="on"
          v-bind="attrs"
          style="margin-left: -0.5rem;"
          height="32px"
          :small="$vuetify.breakpoint.xs"
          :icon="true"
        >
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </div>
    </template>

    <v-card class="d-flex flex-column w-100 rounded-0 mt-4">
      <v-toolbar :elevation="8" title="Capa" color="rgb(0, 192, 255)">
        <!-- <v-toolbar-title>{{ layerType === 'toxins' ? 'Toxinas POL' : 'Células PMI' }}</v-toolbar-title> -->
        <v-toolbar-title>Opciones de tabla</v-toolbar-title>

        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('update:is-dialog-active', false); isDialogActiveLocal = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <!-- Body -->
      <div class="d-flex flex-row ml-6 mb-6">

        <!-- Checkbox -->
        <div class="d-flex flex-column">
          <v-checkbox
            v-model="tableSettingsLocal.changeWithMap"
            label="Cambiar tabla con el mapa"
            hide-details
            :disabled="false"
          ></v-checkbox>
          <v-checkbox
            v-model="tableSettingsLocal.changeWithFeature"
            label="Cambiar tabla al seleccionar un polígono o PMI"
            hide-details
            :disabled="false"
          ></v-checkbox>
          <v-checkbox
            v-model="tableSettingsLocal.fullTableHeight"
            label="Visualizar toda la tabla (En móbiles ocupa casi toda la pantalla)"
            hide-details
            :disabled="false"
          ></v-checkbox>
        </div>
      </div>

      <!-- Info table -->
      <v-divider></v-divider>
      <div class="d-flex flex-row mt-4 ml-6 mb-4">
        <div class="d-flex align-center" :style="{ 'font-size': '0.8rem' }">
          <v-icon size="18">mdi-information</v-icon>
          <p class="ml-2 ma-0">
            <!-- Última actualización: {{ infoTable?.publicationRecountSim?.updatedAt }} <br> -->
            Última actualización: {{ convertirFecha(infoTable?.publicationRecountSim?.updatedAt) }}
          </p>
        </div>
      </div>
    </v-card>

  </v-dialog>
  <!-- </v-bottom-sheet> -->
</template>

<script>
  import { ref, watch } from 'vue';

  import { format, parse } from 'date-fns'
  // import { es } from 'date-fns/locale';

  export default {
    name: 'forecast-table-settings-dialog-component',
    components: {
    },
    props: {
      mode: {
        type: String,
        required: false,
        default: 'button' // hidden, inline, button
      },
      isDialogActive: {
        type: Boolean,
        required: false,
        default: false
      },
      tableSettings: {
        type: Object,
        required: true,
        default: () => ({
          changeWithMap: true,
          changeWithFeature: true,
        }),
      },
      infoTable: {
        type: Object,
        required: false,
        default: () => ({
          publication: {
            updatedAt : '2023-10-17T15:08:17+00:00'
          },
        }),
      },
    },
    setup(props, { emit }) {
      const isDialogActiveLocal = ref(props.isDialogActive)
      watch(( () => props.isDialogActive), () => {
        isDialogActiveLocal.value = props.isDialogActive
      })

      // TableSettigns Local
      const tableSettingsLocal = ref(props.tableSettings)
      // Watch for external changes
      watch(() => props.tableSettings, () => {
        tableSettingsLocal.value = props.tableSettings
      }, { deep: true})

      watch(() => tableSettingsLocal.value, () => {
        emit('update:tableSettings', tableSettingsLocal.value)
      }, { deep: true})


      const convertirFecha = (fechaString) => {
        // Format: 2023-10-17T15:08:17+00:00
        const fechaDate = parse(fechaString, 'yyyy-MM-dd\'T\'HH:mm:ssXXX', new Date())
        // const dayString = format(fechaDate, "EEEE d/M/y  HH:mm", { locale: es })
        const dayString = format(fechaDate, "EEEE d/M/y  HH:mm")

        return dayString
      }

      return {
        isDialogActiveLocal,
        tableSettingsLocal,

        convertirFecha
      }
    }
  }
</script>

<style>
.custom-slider .v-label {
  width: 100px; /* Establece el ancho fijo que desees */
  /* min-width: 80px; */
}
</style>
