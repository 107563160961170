<template>
  <!--
    <div class="d-flex flex-row align-center">
      <v-btn
      class=""
      small
      fab
      :color="videoOverlayLayerActive ? 'blue' : 'gray'"
      @click="videoOverlayLayerActive = !videoOverlayLayerActive"
    >
      <v-icon>mdi-video</v-icon>
    </v-btn>
    <v-btn
      v-if="videoOverlayLayerActive"
      class="ml-1"
      small
      fab
      :color="videoParticleLayerVideoPlay ? 'red' : 'gray'"
      @click="videoParticleLayerVideoPlay = !videoParticleLayerVideoPlay"
    >
      <v-icon v-if="videoParticleLayerVideoPlay">mdi-pause</v-icon>
      <v-icon v-if="!videoParticleLayerVideoPlay">mdi-play</v-icon>
    </v-btn>
  </div>
  -->

  <div id="device-desktop" style="cursor: pointer; user-select: none;">
    <!-- Small < 800; Días: NO -->
    <!-- <div id="calendar" data-ref="calendar"></div> -->

    <!-- Medium: >= 800 && < 996; Días: Vie, Sab, Dom, Lun, ... -->
    <!-- <div id="calendar" data-ref="calendar">
      <div data-do="1694772000000" class="uiyellow clickable" style="width: 10%">Vie</div>
      <div data-do="1694858400000" class="uiyellow clickable" style="width: 10%">Sáb</div>
      <div data-do="1694944800000" class="uiyellow clickable" style="width: 10%">Dom</div>
      <div data-do="1695031200000" class="uiyellow clickable" style="width: 10%">Lun</div>
      <div data-do="1695117600000" class="uiyellow clickable" style="width: 10%">Mar</div>
      <div data-do="1695204000000" class="uiyellow clickable" style="width: 10%">Mié</div>
      <div data-do="1695290400000" class="uiyellow clickable" style="width: 10%">Jue</div>
      <div data-do="1695376800000" class="uiyellow clickable" style="width: 10%">Vie</div>
      <div data-do="1695463200000" class="uiyellow clickable" style="width: 10%">Sáb</div>
      <div data-do="1695549600000" class="uiyellow clickable" style="width: 10%">Dom</div>
    </div> -->

    <!-- BIG >= 996; Días: NO -->
    <div id="plugin-progress-bar" class="plugin-bottom progress-bar open" style="display: block">
      <div class="progress-line" style="width: 633.267px">
        <div class="avbl">
          <div class="premium" style="width: 246.867px"></div>
        </div>
        <div class="played" style="width: 169.374px">
          <div class="free" style="width: 386.4px"></div>
        </div>
        <i style="left: 45.9297px"></i>
      </div>

      <!-- Indicador de la hora -->
      <div class="timecode ghost-timecode" style="opacity: 0; left: 302px; margin-top: -25px">
        <div class="box">23:00</div>
      </div>

      <!-- Indicador de la Fecha y hora actual -->
      <!-- @mousedown="startDrag"
      @mousemove="moveElement"
      @mouseup="stopDrag" -->
      <div
        data-title="D_LT2"
        class="timecode main-timecode"
        title="Hora local"
        :style="{ left: currentTimePixelOffset }"
      >
        <div class="box">Domingo 17 - 15:00</div>
        <div class="loading ld-lgray size-l loader-path"></div>
      </div>
      <div id="playpause" class="play-pause iconfont clickable off" data-ref="playPause"></div>
      <div ref="daysRef" id="calendar" data-ref="calendar">
        <!-- <div data-do="1694772000000" class="uiyellow clickable" style="width: 10%">Vie 15</div>
        <div data-do="1694858400000" class="uiyellow clickable" style="width: 10%">Sáb 16</div>
        <div data-do="1694944800000" class="uiyellow clickable" style="width: 10%">Dom 17</div>
        <div data-do="1695031200000" class="uiyellow clickable" style="width: 10%">Lun 18</div>
        <div data-do="1695117600000" class="uiyellow clickable" style="width: 10%">Mar 19</div>
        <div data-do="1695204000000" class="uiyellow clickable" style="width: 10%">Mié 20</div>
        <div data-do="1695290400000" class="uiyellow clickable" style="width: 10%">Jue 21</div>
        <div data-do="1695376800000" class="uiyellow clickable" style="width: 10%">Vie 22</div>
        <div data-do="1695463200000" class="uiyellow clickable" style="width: 10%">Sáb 23</div>
        <div data-do="1695549600000" class="uiyellow clickable" style="width: 10%">Dom 24</div> -->
        <div
          v-for="(tickLabel, index) in tickLabelsLocal"
          :key="index"
          class="uiyellow clickable"
          style="width: 10%"
        >
          {{ tickLabel.label }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { onMounted, ref, watch } from "vue";

  import { toDate, differenceInHours, addHours } from "date-fns";
  import { format, fromUnixTime } from "date-fns";
  import { es } from "date-fns/locale";

  // onAdd : onAddRainViewer,
  // animationPosition, animationPositionMin, animationPositionMax, onChangeAnimationPositionSlider, getAnimationPositionDateFormatter,
  // startstop, animationTimer, stop,
  // animationVelocity, onChangeAnimationVelocity,

  // Events
  //  onAddRainViewer()                   => Añadir la capa
  //  startstop()                         => Toggle start / stop animation
  //  stop()                              => Force stop (While moving)
  //  onChangeAnimationVelocity(val)      => Set new velocity
  //  onChangeAnimationStepFrequency(val) => Set new frequency
  export default {
    name: "map-desktop-calendar",
    props: {
      // toxinType: {
      //   type: String,
      //   default: 'dsp',
      // },

      // [1679270400, 1679698800] => 20230321T00:00:00.0000Z, 20230224T23:00:00.0000Z => ['Lunes 21', 'Martes 22', 'Miércoles 23', 'Jueves 24']
      timestamps: {
        type: Array,
        default: () => [1678665600, 1679528880], // 20230313T00:00:00.0000Z, 20230213T00:00:00.0000Z
      // default: () => [1679270400, 1679698800] // 20230321T00:00:00.0000Z, 20230224T23:00:00.0000Z
      },

      // False o timeoutID (devuelto por el setTimeout())
      animationTimer: {
        type: [Boolean, Number],
        default: true,
      },

      // Posición actual (el timer se encarga de actualizarlo)
      animationPosition: {
        type: Number,
        default: -1,
      },
      // animationPositionMin: {
      //   type: Number,
      //   default: 0,
      // },
      // animationPositionMax: {
      //   type: Number,
      //   default: 0,
      // },

      // Velocidad de animación actual (No se usa aquí)
      animationVelocity: {
        type: Number,
        default: 1,
      },

      // Frecuencia de la animación en horas: 1h, 3h, 6h, 12h o 24h
      animationStepFrequency: {
        type: Number,
        default: 6,
      },
    },
    // eslint-disable-next-line no-unused-vars
    setup(props, { emit }) {
      console.log("onSetup", "LoadTSs", props.timestamps.length);

      const isTimeMapCounterActive = ref(true);
      // Cuando cambie de posicion, actualizamos
      watch(
        () => props.animationPosition,
        () => {
          console.log("watch(props.animationPosition)", props.animationPosition);
          console.log("isTimeMapCounterActive", isTimeMapCounterActive.value);
          if (isTimeMapCounterActive.value) {
            const ts = props.timestamps[props.animationPosition];
            if (ts) {
              const fechaRef = toDate(ts * 1000); // Miliseconds
              // Desplazamiento inicial -> Según la hora actual
              const offset = getTimeOffset(fechaRef);
              // console.log(offset,'of',daysRef.value.offsetWidth)
              daysRef.value.scrollLeft = offset;
            }
          }
        }
      );

      /***  TIME */
      const fechaInicio = toDate(props.timestamps[0] * 1000); // Miliseconds
      const fechaFin = toDate(props.timestamps[props.timestamps.length - 1] * 1000); // Miliseconds
      let fechaActual = new Date();
      fechaActual.setHours(12, 0, 0, 0);
      if (!(fechaActual >= fechaInicio && fechaActual <= fechaFin)) {
        fechaActual = fechaFin;
      }
      // const diferenciaHoras = differenceInHours(fechaActual, fechaInicio);

      // Extraer la lista de días entre la lista del TimeStamp
      const getTickLabels = (timestampsS = []) => {
        // Checking... Se ha indicado una lista válida
        if (timestampsS === undefined || timestampsS.length === 0) {
          return [];
        }
        const iLast = timestampsS.length - 1;

        // Obtener la fecha inicial y final en milisegundos
        const fechaInicial = timestampsS[0] * 1000;
        const fechaFinal = timestampsS[iLast] * 1000;

        // Calcular el número de días entre las dos fechas
        const diferencia = fechaFinal - fechaInicial
        // let dias = Math.round(diferencia / (1000 * 60 * 60 * 24))
        let dias = Math.ceil(diferencia / (1000 * 60 * 60 * 24))      // Redondeamos por arriba por si el video no es justo
        if (dias === 0) { dias = 1 }

        // Enumerar los días de la semana: Usamos setDate para evitar fallos con el cambio de hora
        const fecha = new Date(fechaInicial)
        const labels = []
        for (let i = 0; i < dias; i++) {

          // Sumamos 1 día usando la funcion setDate para evitar fallos con el cambio de hora
          // const fecha = new Date(fechaInicial + (i * 24 * 60 * 60 * 1000))
          if (i > 0) {
            fecha.setDate(fecha.getDate() + 1)
          }

          // const dayString = format(fecha, "EEEE d", { locale: es })
          const dayString = format(fecha, "EE d", { locale: es })
          const tickLabel = {
            label: dayString,
            loaded: i == 1 ? false : true
          }
          labels.push(tickLabel)
        }

        return labels;
      };
      const tickLabelsLocal = getTickLabels(props.timestamps);

      // TODO - recalcular ticklabels -> Hay que recalcular más variables
      // watch( () => props.timestamps, () => {
      //   tickLabelsLocal.value = getTickLabels(props.timestamps)
      // }, { deep: true })
      
      //  Timestamp in seconds 1678665600 => 20:00
      const getAnimationPositionDateFormatter = (tsS) => {
        return tsS ? format(fromUnixTime(tsS), "HH:mm", { locale: es }) : ""; // fromUnixTime(timestamp in seconds)
      };

      // Devuelve el offset en pixels
      const getTimeOffset = (fechaRef, offset = 0) => {
        const diferenciaHoras = differenceInHours(fechaRef, fechaInicio);

        // return ((160 / 24) * diferenciaHoras)
        // return (offset + ((160 / 24) * diferenciaHoras)).toString() + 'px'
        return offset + (160 / 24) * diferenciaHoras;
      };
      // const getTimeOffsetPx = (number) => {
      //   return number.toString() + 'px'
      // }
      // const timeOffset = (160 / 24) * diferenciaHoras
      // const timeOffset = getTimeOffset(fechaActual)

      // const progressBarRef = ref(null)
      const daysRef = ref(null);
      const currentTimeString = ref(getAnimationPositionDateFormatter(fechaActual.getTime() / 1000)); // 1678665600 => 20:00
      const currentTimePixelOffset = ref("0px"); // getTimeOffset(fechaActual) 460 => 460px

      // SetInitial()
      onMounted(() => {
        // currentTimePixelOffset.value = ((daysRef.value.clientWidth / 2) + timeOffset).toString() + 'px'
        currentTimePixelOffset.value = getTimeOffset(fechaActual, daysRef.value.clientWidth / 2).toString() + "px";

        // Desplazamiento inicial -> Según la hora actual ==> Esto provoca un scroll (vía onHandleScroll)
        daysRef.value.scrollLeft = Math.floor(getTimeOffset(fechaActual));
        console.log("onMounted","setInitial",daysRef.value.scrollLeft,getAnimationPositionDateFormatter(fechaActual.getTime() / 1000));

      // Indicar al controlador de partículas como se debe inicializar (No es necesario porque esto desencadena "onHandleScroll")
      // eslint-disable-next-line no-debugger
      // debugger
      // emit('showFrameByTimestamp', fechaActual.getTime() / 1000)
      // sendCounter(fechaActual)

      // Se levantan los eventos manualmente en el Template: Ver Setup()
      // addListeners()
      });

      const convertScroll2Date = (scroll) => {
        const horas = scroll / (160 / 24);
        const fecha = addHours(fechaInicio, horas);

        return fecha;
      };
      const convertScroll2TimeString = (scroll) => {
        const fecha = convertScroll2Date(scroll);
        currentTimeString.value = getAnimationPositionDateFormatter(fecha.getTime() / 1000);
      };

      // eslint-disable-next-line no-unused-vars
      // const getCurrentTimeStampPosition = () => {
      //   // eslint-disable-next-line no-debugger
      //   debugger
      //   const horas = daysRef.value.scrollLeft / (160 / 24)
      //   const fecha = addHours(fechaInicio, horas)
      //   const currentTS = fecha.getTime() / 1000
      //   const index = props.timestamps.findIndex((element) => element >= currentTS)
      //   return index
      // }

      const isScrolling = ref(false);
      const onHandleScroll = () => {
        // debugger
        clearTimeout(isScrolling.value);
        isScrolling.value = setTimeout(() => {
          isScrolling.value = false;

          // Al terminar de hacer scroll indicamos la nueva posición
          console.log("onHandleScroll-Activo", daysRef.value.scrollLeft);
          // emit('showFrameByTimestamp', convertScroll2Date(daysRef.value.scrollLeft).getTime() / 1000)
          sendCounter(convertScroll2Date(daysRef.value.scrollLeft));
        }, 2000);
        // console.log('isScrolling', isScrolling.value)
        // currentScroll.value = daysRef.value.scrollLeft
        convertScroll2TimeString(daysRef.value.scrollLeft);
      };

      // Deshabilitamos porque se ha implementado PAN (Mover con el ratón)
      const onClickScroll = (event) => {
        console.log(event);
      // const scrollLeft = event.target.scrollLeft;
      // const scrollLeft = event.target.offsetLeft;
      // console.log('scrollLeft:', event.target.offsetLeft, event.offsetX);
      // console.log('scrollLeftDays:', event.target.offsetParent?.scrollLeft);
      // console.log('clientWidth:', event.target.offsetParent?.clientWidth);

      // if (event.target.offsetParent) {
      //   const clientWidth = event.target.offsetParent.clientWidth / 2
      //   const scrollLeft = event.target.offsetLeft
      //   const offsetLeft = event.offsetX

      //   const offset = scrollLeft - clientWidth + offsetLeft
      //   console.log(offset)
      //   // sendCounter(convertScroll2Date(offset))
      //   daysRef.value.scrollLeft = offset
      // }
      };
      /** <<< */

      /** *** SCROLL *** */
      let isMouseDown = false;
      let startX = 0;

      // const addListeners = () => {
      // debugger
      // daysRef.value.addEventListener('mousedown', function(event) {
      const onMouseDownScroll = (event) => {
        // console.log('mousedown')
        isMouseDown = true;
        startX = event.clientX;
      };

      // daysRef.value.addEventListener('mousemove', function(event) {
      const onMouseMoveScroll = (event) => {
        if (isMouseDown) {
          const scrollX = startX - event.clientX;
          daysRef.value.scrollLeft += scrollX;
          startX = event.clientX;
        // console.log('mousemove-L', daysRef.value.scrollLeft)
        }
      };
      window.addEventListener("mouseup", function () {
        isMouseDown = false;
      });
      /**  */

      const sendCounter = (dateToSend) => {
        // Desactivamos la comunicación de entrada -> Le estamos indicando nosotros el counter y no necesitamos actualizar
        //  Si no desactivamos: se produce una especie de fliker
        isTimeMapCounterActive.value = false;
        setTimeout(function () {
          isTimeMapCounterActive.value = true;
        }, 2000);

        emit("showFrameByTimestamp", dateToSend.getTime() / 1000);
      };

      return {
        // Fechas de los ticks
        tickLabelsLocal,

        daysRef,
        currentTimeString,
        currentTimePixelOffset, // Indicador de la hora actual (en pixels)
        isScrolling,
        onHandleScroll,
        onClickScroll,
        onMouseDownScroll,
        onMouseMoveScroll,
      };
    },
  };
</script>

<style lang="css" scoped>

  /* >>> BEGIN: #device-desktop .plugin-bottom */
  /* #device-desktop: Id de todo el contenedor */
  /* .plugin-bottom: Posición del Calendario */
  #device-desktop .plugin-bottom {
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 15px;
    pointer-events: none;
  }
  #device-desktop .plugin-bottom > * {
    pointer-events: auto;
  }
  .plugin-bottom {
    position: relative;
  }
  /* <<< END .plugin-bottom */


  /* >>> BEGIN: #plugin-progress-bar: Es el contenedor de toda la barra */
  #plugin-progress-bar {
    height: 30px;
  }
  #device-desktop #plugin-progress-bar {
    margin-bottom: 0;
  }
  #plugin-progress-bar .progress-line {
    width: 50%;
    z-index: 1;
    left: 45px;
  }
  #plugin-progress-bar .timecode {
    font-size: 13px;
  }
  #plugin-progress-bar #playpause {
    position: absolute;
    top: -12px;
    left: 5px;
    z-index: 10;
  }
  #plugin-progress-bar #calendar {
    position: absolute;
    left: 45px;
    right: 0;
    top: 6px;
    white-space: nowrap;
  }
  #plugin-progress-bar #calendar div {
    display: inline-block;
    box-sizing: border-box;
    text-align: left;
    padding: 6px 0 0 8px;
    font-size: 12px;
    line-height: 1;
    height: 26px;
    white-space: nowrap;
    overflow: hidden;
  }
  #plugin-progress-bar #calendar div:not(:first-child) {
    border-left: 1px solid rgba(68, 65, 65, 0.84);
  }
  /* <<< END */


  /* BEGIN: .progress-bar: Es la barra */
  .progress-bar {
      position: relative
  }

  .progress-bar .progress-line {
      height: 6px;
      cursor: pointer;
      position: relative;
      border: 10px solid transparent;
      background-clip: padding-box;
      border-right: none;
      border-left: none;
      top: -10px;
      transition: width ease-in-out .7s
  }

  .progress-bar .progress-line .played {
      background-color: #d49500;
      height: 6px;
      position: absolute;
      top: 0;
      left: 0;
      overflow: hidden;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
      width: 15%
  }

  .progress-bar .progress-line .played .free {
      background-color: #e5e5e5;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 0px
  }

  .progress-bar .progress-line .avbl {
      height: 6px;
      background-color: rgba(68,65,65,0.84);
      width: 100%;
      border-radius: 6px;
      overflow: hidden;
      position: relative
  }

  .progress-bar .progress-line .avbl .premium {
      background: #c69f42;
      opacity: .6;
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 0px
  }

  .progress-bar .progress-line i {
      display: block;
      width: 3px;
      height: 6px;
      position: absolute;
      background-color: #946051;
      top: 0
  }
  /* <<< END */


  /* >>> BEGIN: timecode ghost-timecode */
  .timecode {
      font-size: 10px;
      position: absolute;
      display: block;
      box-sizing: border-box;
      top: -2.4em;
      margin-left: -2em
  }

  .timecode .loading {
      position: absolute;
      top: .2em;
      left: -1.4em
  }

  .timecode .box {
      cursor: grab;
      position: relative;
      background-color: #d49500;
      color: white;
      height: 1.8em;
      box-sizing: border-box;
      padding: 0 .8em;
      white-space: nowrap;
      text-align: center;
      display: table-cell;
      vertical-align: middle;
      border-radius: .5em;
      box-shadow: 0 0 4px 0 black
  }

  .timecode .box:active {
      cursor: grabbing
  }

  .timecode .box::before {
      top: 100%;
      left: 2em;
      border: solid transparent;
      content: '';
      height: 0;
      width: 0;
      position: absolute;
      border-top-color: #d49500;
      border-width: .5em;
      margin-left: -0.5em
  }


  .timecode .box[data-zulu]::after {
      content: attr(data-zulu);
      font-size: .8em;
      opacity: .7;
      margin-left: 5px
  }

  .timecode.ghost-timecode {
      transition: .3s opacity 0s;
      -webkit-transition: .3s opacity 0s;
      opacity: 0;
      pointer-events: none
  }

  .timecode.ghost-timecode .box {
      box-shadow: none;
      cursor: pointer;
      color: white;
      background-color: rgba(68,65,65,0.84)
  }

  .timecode.ghost-timecode .box::before {
      border-top-color: rgba(68,65,65,0.84)
  }
  /* <<< END */
</style>
