<template>
  <div class="d-flex flex-column px-3">

    <div class="d-flex align-center my-3">
      <span class="ml-1">Parámetros de conversión de Fitoplancton sobre toxina base</span>
    </div>

    <v-divider></v-divider>

    <!-- TF: Toxina por fitoplancton -->
    <div class="d-flex flex-column my-3">

      <v-slider
        v-for="feedTypeAP in feedTypeAPsLocal"
        :key="feedTypeAP.feedType.id"
        v-model="feedTypeAP.asimilacion"
        :color="sliderColorFito(feedTypeAP.asimilacion)"
        track-color="grey"
        class="align-top custom-slider"
        :label="feedTypeAP.feedType.feedTypeName"
        always-dirty

        min="0"
        max="200"
        step="5"

        readonly
      >

        <!-- <template v-slot:prepend>
          <div class="d-flex align-center" style="width: 120px">
            <label>feedTypeAP.feedType.name</label>
          </div>
        </template> -->
        <template v-slot:append>
          <v-btn
            :color="sliderColorFito(feedTypeAP.asimilacion)"
            depressed
            small
          >
            {{ feedTypeAP.asimilacion }}%
          </v-btn>
        </template>
      </v-slider>

      <div class="d-flex align-center" :style="{ 'font-size': '0.8rem' }">
        <v-icon size="18">mdi-information</v-icon>
        <p class="ml-2 ma-0">
          TF: Toxinas en Fitoplancton.
          <br> Este porcentaje ayuda a ajustar la toxina de cada fitoplancton a una toxicidad base (*).
        </p>
        <!-- Icon: purple -->
        <span style="
          width: 44px;
          height: 38px;
          background: #e480f8;
          border-radius: 50%;
          padding: 8px;
          position: relative;
        ">
          <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
            <g transform="translate(11,11) translate(-8.5,-8)" style="animation: 5s linear 0s 1 normal both running oao-navi-a0_t;">
              <g transform="translate(8,8) translate(-8,-8)" style="animation: 5s linear 0s 1 normal both running oao-navi-a1_t;">
                <g style="animation: 5s linear 0s 1 normal both running oao-navi-a4_fo;">
                  <path class="oao-navi-highlight" d="M6.2 11.7c-0.1 0-0.2-0.1-0.2-0.2c0-0.2 .1-0.3 .2-0.3c.9 0 1.4 .3 1.8 .6v-5.5c-0.6-0.2-2-0.6-3.3 1c0 0-0.1 0-0.2 0c0 0-0.1 0-0.1 0c-0.1-0.1-0.1-0.3 0-0.4c.3-0.4 .6-0.7 1-0.9c-1.1-0.9-1-1.8-1-1.9c.1-0.1 .2-0.2 .3-0.2c.2 0 .3 .2 .2 .3c0 0 0 .8 1 1.6c.8-0.3 1.6-0.1 2.1 0v-4.5c-0.6-0.8-1.6-1.3-2.7-1.3c-1.9 0-3.4 1.5-3.4 3.4c0 .4 .1 .7 .1 1c-1.1 .4-2 1.5-2 2.8c0 1.1 .5 2 1.3 2.5c-0.1 .3-0.2 .6-0.2 1c0 1.5 1.1 2.8 2.5 3.3c.4 1.1 1.5 2 2.9 2c.5 0 1.1-0.2 1.5-0.4v-3.1c-0.2-0.3-0.7-0.8-1.8-0.8Z" fill="#0b2a63" transform="translate(4.2,8) scale(1.00625,1.00625) translate(-4,-8)" style="animation: 5s linear 0s 1 normal both running oao-navi-a2_t, 5s linear 0s 1 normal both running oao-navi-a2_f;"></path>
                  <!-- <path class="oao-navi-highlight" d="M6.2 11.7c-0.1 0-0.2-0.1-0.2-0.2c0-0.2 .1-0.3 .2-0.3c.9 0 1.4 .3 1.8 .6v-5.5c-0.6-0.2-2-0.6-3.3 1c0 0-0.1 0-0.2 0c0 0-0.1 0-0.1 0c-0.1-0.1-0.1-0.3 0-0.4c.3-0.4 .6-0.7 1-0.9c-1.1-0.9-1-1.8-1-1.9c.1-0.1 .2-0.2 .3-0.2c.2 0 .3 .2 .2 .3c0 0 0 .8 1 1.6c.8-0.3 1.6-0.1 2.1 0v-4.5c-0.6-0.8-1.6-1.3-2.7-1.3c-1.9 0-3.4 1.5-3.4 3.4c0 .4 .1 .7 .1 1c-1.1 .4-2 1.5-2 2.8c0 1.1 .5 2 1.3 2.5c-0.1 .3-0.2 .6-0.2 1c0 1.5 1.1 2.8 2.5 3.3c.4 1.1 1.5 2 2.9 2c.5 0 1.1-0.2 1.5-0.4v-3.1c-0.2-0.3-0.7-0.8-1.8-0.8Z" fill="#0b2a63" transform="translate(13,8) scale(-1,1) translate(-4,-8)" style="animation: 5s linear 0s 1 normal both running oao-navi-a3_t, 5s linear 0s 1 normal both running oao-navi-a3_fo, 5s linear 0s 1 normal both running oao-navi-a3_f;"></path> -->
                  <path
                    class="oao-navi-highlight"
                    style="animation: 5s linear 0s 1 normal both running oao-navi-a4_fo;"
                    transform="translate(10,-5) scale(.75,.75) translate(-6,9.5)"
                    fill-opacity="1"
                    fill="#0b2a63"
                    d="M13.6 4c-0.4 0-0.8 .1-1.1 .3l-3.3-3.1c.3-0.3 .4-0.7 .4-1.2c0-1.3-1.1-2.4-2.4-2.4c-1.3 0-2.4 1.1-2.4 2.4c0 1.1 .7 1.9 1.6 2.3v11.5c-0.9 .3-1.5 1.2-1.5 2.2c0 1.3 1 2.4 2.4 2.4c1.3 0 2.3-1.1 2.3-2.4c0-1.1-0.7-1.9-1.6-2.3v-11.5c0 0 0 0 0 0l3.4 3.3c-0.1 .2-0.1 .5-0.1 .8c0 1.4 1 2.4 2.3 2.4c1.3 0 2.4-1 2.4-2.4c0-1.3-1.1-2.3-2.4-2.3Z"></path>
                </g>
              </g>
            </g>
            <!-- <g transform="translate(46.6,10.7) scale(1.36227,1.36227) translate(-8,-8)"></g> -->
          </svg>
        </span>
      </div>

      <div class="d-flex align-center" :style="{ 'font-size': '0.8rem' }">
        <p class="mt-2 ml-2 ma-0">
          (*) La toxicidad base es un parámetro interno fijo calculado a partir de la toxicidad media del fitoplancton más común (Ej. Acuminata para las DSP)
        </p>

      </div>
    </div>

  </div>
</template>

<script>
  import { ref, watch } from 'vue';

  export default {
    name: 'forecast-chart-params-component',
    components: {
    },
    props: {
      feedTypeAPs: {
        type: Array,
        default: () => []
      }
    },
    setup(props) {
      const feedTypeAPsLocal = ref(JSON.parse(JSON.stringify(props.feedTypeAPs)))

      // props passed to setup function is reactive object (made probably by reactive()), it's properties are getters.
      // Watching a getter: https://v3.vuejs.org/api/computed-watch-api.html#watching-a-single-source
      watch(() => props.feedTypeAPs, () => {
        feedTypeAPsLocal.value = JSON.parse(JSON.stringify(props.feedTypeAPs));
      })

      //   0-50  %  Verde
      //  50-100 %  Azul
      // 100-150 %  Naranja
      // 150-200 %  Rojo
      const sliderColorFito = (value) => {
        if (value <  80) return 'teal'
        if (value <= 100) return 'indigo'
        // if (value < 140) return 'green'
        if (value <  120) return 'orange'
        return 'red'
      }

      return {
        feedTypeAPsLocal,

        sliderColorFito,  // Func. para aplicar color segun %
      }
    }
  }
</script>

<style>
.custom-slider .v-label {
  width: 100px; /* Establece el ancho fijo que desees */
  /* min-width: 80px; */
}
</style>
