<template>
  <!-- <v-dialog
    v-model="isDialogActiveLocal"
    width="500"
    :fullscreen="$vuetify.breakpoint.xs"
    scrollable
    @click:outside="$emit('update:is-dialog-active',false); isDialogActiveLocal = false"
  > -->
  <v-bottom-sheet
    v-model="isDialogActiveLocal"
    max-width="500px"
    @click:outside="$emit('update:is-dialog-active',false); isDialogActiveLocal = false"
  >
    <template v-slot:activator="{ on, attrs }">

      <v-btn
        v-if="mode == 'button'"
        v-on="on"
        v-bind="attrs"
        class="ml-2"
        height="32px"
        :small="$vuetify.breakpoint.xs"
        :icon="$vuetify.breakpoint.xs"
      >
        <v-icon>mdi-tune-vertical</v-icon>
        <span class="d-none d-md-block  ml-2" style="font-size: .625rem;">Filtrar <br> {{  getLabelFeedTypeAP(cellsOptions.feedTypeAP) }}</span>
      </v-btn>
    </template>

    <v-card class="d-flex flex-column w-100 rounded-0 mt-4">
      <v-toolbar :elevation="8" title="Filtrar fitoplancton" color="rgb(0, 192, 255)">
        <v-toolbar-title>{{ ria?.name }}</v-toolbar-title>

        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('update:is-dialog-active', false); isDialogActiveLocal = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <!-- Body -->
      <div class="d-flex flex-column px-3">

        <!-- <div class="d-flex align-center my-3">
          <span class="ml-1">Tipos de fitoplancton a visualizar</span>
        </div>
        <v-divider></v-divider> -->


        <!-- Toxina -->
        <div class="d-flex flex-column my-3">

          <!-- Left Options:   -->
          <div class="d-flex flex-row align-center justify-space-between-">

            <!-- @change="$emit('update:feedTypeAPSelected',feedTypeAPSelectedLocal)" -->
            <!-- v-model="feedTypeAPSelectedLocal" -->
            <!-- @change="value => onChangeFeedTypeAP(value)" -->
            <v-btn-toggle
              v-model="cellsOptionsLocal.feedTypeAP"
              borderless
              tile
              color="deep-purple accent-3"
              group
            >
              <v-btn value="all" text class="flex-fill">
                <span>TODOS</span>
              </v-btn>
              <v-btn
                v-for="feedTypeAP in feedTypeAPsLocal"
                :key="feedTypeAP.feedType.feedTypeKey"
                :value="feedTypeAP.feedType.feedTypeKey"
                text
                class="flex-fill"
              >
                <span>{{ feedTypeAP.feedType.feedTypeName }}</span>
              </v-btn>
            </v-btn-toggle>
          </div>

          <div class="d-flex flex-row align-center ml-3">
            <!-- @change="$emit('update:isTFChecked', isTFCheckedLocal)" -->
            <!-- v-model="isTFCheckedLocal" -->
            <!-- @change="value => onChangeTF(value)" -->
            <v-switch
              v-model="cellsOptionsLocal.isTF"
              label="Aplicar % TF"
              class="flex-fill"
            ></v-switch>

            <!-- @change="$emit('update:isGroupChecked', isGroupCheckedLocal)" -->
            <!-- @change="value => onChangeGroup(value)" -->
            <!-- v-model="isGroupCheckedLocal" -->
            <!-- :disabled="feedTypeAPSelectedLocal !== 'all'" -->
            <v-checkbox
              v-model="cellsOptionsLocal.isGroup"
              label="Agrupar"
              class="flex-fill"
              :disabled="cellsOptionsLocal.feedTypeAP !== 'all'"
            ></v-checkbox>
          </div>

          <!-- Video Test -->
          <!-- <div class="d-flex flex-column align-center ml-3">
            <video ref="myVideo" autoplay loop muted playsinline style="opacity: 0.8; width: 100%; height: 100%;">
              <source class="" src="https://prediccion.empromar.com/cdn/videos/pastoreo_20231030_DSP_10.mp4">
            </video>
            <div>
              <v-btn x-small fab @click="onVideoNext(-3)">
                <v-icon>mdi-minus</v-icon>
              </v-btn>
              <v-btn
                x-small
                fab
                :loading="videoParticleLayerVideoLoading"
                :disabled="videoParticleLayerVideoLoading"
                :color="videoParticleLayerVideoPlayLocal ? 'red' : 'gray'"
                @click="videoParticleLayerVideoPlayLocal = !videoParticleLayerVideoPlayLocal"
              >
                <v-icon v-if="videoParticleLayerVideoPlayLocal">mdi-pause</v-icon>
                <v-icon v-if="!videoParticleLayerVideoPlayLocal">mdi-play</v-icon>
              </v-btn>

              <v-btn x-small fab @click="onVideoNext(+3)">
                <v-icon>mdi-plus</v-icon>
              </v-btn>

              <span>Time: {{ myVideoCurrentTime }}</span>
            </div>
          </div> -->

          <ForecastCardToxinParamsTF :feedTypeAPs="feedTypeAPsLocal"/>
        </div>

      </div>
    </v-card>

  <!-- </v-dialog> -->
  </v-bottom-sheet>
</template>

<script>
  import { ref, watch } from 'vue';

  import ForecastCardToxinParamsTF from './ForecastCardToxinParamsTF.vue';
  // import useAppConfig from '@/store/useAppConfig';

  export default {
    name: 'forecast-card-cells-params-component',
    components: {
      ForecastCardToxinParamsTF
    },
    props: {
      mode: {
        type: String,
        required: false,
        default: 'button' // hidden, inline, button
      },

      isDialogActive: {
        type: Boolean,
        required: false,
        default: false
      },

      // Parámetros
      ria: {
        type: Object,
        required: false,
        default: () => ({ name: '' })
      },
      feedTypeAPs: {
        type: Array,
        required: true,
      },
      cellsOptions: {
        type: Object,
        required: false,
        default: () => ({
          feedTypeAP: 'all',
          isTF: true,
          isGroup: true
        })
      },
    },
    setup(props, { emit }) {


      // const myVideo = ref(null)
      // const myVideoCurrentTime = ref(0)
      // const videoParticleLayerVideoLoading = ref(true)
      // const videoParticleLayerVideoPlayLocal = ref(false)

      // watch( () => videoParticleLayerVideoPlayLocal.value, () => {
      //   if (videoParticleLayerVideoPlayLocal.value === true) {
      //     myVideo.value.play();
      //     console.log('video-play')
      //   } else {
      //     myVideo.value.pause();
      //     console.log('video-pause')
      //   }
      // })
      // // const onVideoLoad = () => {
      // //   videoParticleLayerVideoLoading.value = false
      // //   onVideoNext(10)
      // // }

      // // Cuando se renderiza el video
      // watch( () => myVideo.value, () => {
      //   if (myVideo.value) {
      //     // myVideo.value.addEventListener('canplay', function() {
      //     //   console.log('event-canplay')
      //     //   videoParticleLayerVideoLoading.value = false
      //     //   onVideoNext(9)
      //     //   // myVideo.value.pause()

      //     //   // Activamos el evento cuando se pueda hacer PLAY
      //     //   myVideo.value.addEventListener('timeupdate', function() {
      //     //     console.log('event-timeupdate', myVideo.value.currentTime)
      //     //     myVideoCurrentTime.value = myVideo.value.currentTime
      //     //   })
      //     // });

      //     myVideo.value.addEventListener('loadedmetadata', function() {
      //       console.log('event-loadedmetadata')
      //       videoParticleLayerVideoLoading.value = false
      //       myVideo.value.pause()
      //       onVideoNext(11)

      //       // Activamos el evento cuando se pueda hacer PLAY
      //       myVideo.value.addEventListener('timeupdate', function() {
      //         console.log('event-timeupdate', myVideo.value.currentTime)
      //         myVideoCurrentTime.value = myVideo.value.currentTime
      //       })
      //     });
      //     // myVideo.value.addEventListener('loadeddata', function() {
      //     //   console.log('event-loadeddata')
      //     //   videoParticleLayerVideoLoading.value = false
      //     //   onVideoNext(10)
      //     // });
      //     // myVideo.value.addEventListener('timeupdate', function() {
      //     //   console.log('event-timeupdate', myVideo.value.currentTime)
      //     //   myVideoCurrentTime.value = myVideo.value.currentTime
      //     // })
      //   }
      // })

      // const onVideoNext = (seconds = 2) => {
      //   myVideo.value.currentTime = myVideo.value.currentTime + seconds
      //   console.log('currentTime', myVideo.value.currentTime)
      // }


      const isDialogActiveLocal = ref(props.isDialogActive)
      watch( () => props.isDialogActive, () => {
        isDialogActiveLocal.value = props.isDialogActive
      })

      // Lista de fitoplancton
      const feedTypeAPsLocal = ref(JSON.parse(JSON.stringify(props.feedTypeAPs)))

      // props passed to setup function is reactive object (made probably by reactive()), it's properties are getters.
      // Watching a getter: https://v3.vuejs.org/api/computed-watch-api.html#watching-a-single-source
      watch(() => props.feedTypeAPs, () => {
        feedTypeAPsLocal.value = ref(JSON.parse(JSON.stringify(props.feedTypeAPs)))
      })

      // // Fitoplancton
      // const getFeedTypeAPOptions = (feedTypeAPs) => {
      //   const cellOptions = feedTypeAPs.map( (item) => {
      //     return {
      //       key: item.feedType.feedTypeKey,
      //       label: item.feedType.feedTypeName
      //     }
      //   })
      //   cellOptions.push({ key: 'all', label: '∑[Fito]'});
      //   return cellOptions
      // }
      // const feedTypeAPOptions = ref(getFeedTypeAPOptions(feedTypeAPsLocal))


      // Cells Options
      const cellsOptionsLocal = ref(props.cellsOptions)
      watch( () => props.cellsOptions, () => {
        cellsOptionsLocal.value = props.cellsOptions
      }, { deep: true })
      watch( () => cellsOptionsLocal.value, () => {
        emit('update:cellsOptions', cellsOptionsLocal.value)
      }, { deep: true })

      // Get Label
      const getLabelFeedTypeAP = (feedTypeKey) => {
        if (feedTypeKey === 'all') return 'Todos'

        const feedTypeAP = feedTypeAPsLocal.value.find(feedTypeAP => feedTypeAP.feedType.feedTypeKey === feedTypeKey)
        if (feedTypeAP) {
          return feedTypeAP.feedType.feedTypeName
        } else {
          return ''
        }
      }

      return {
        // myVideo,
        // myVideoCurrentTime,
        // onVideoNext,
        // videoParticleLayerVideoLoading,
        // videoParticleLayerVideoPlayLocal,

        // Component
        isDialogActiveLocal,

        // Opciones de selección
        feedTypeAPsLocal,
        cellsOptionsLocal,

        // Funciones
        getLabelFeedTypeAP,
      }
    }
  }
</script>

<style>
.custom-slider .v-label {
  width: 100px; /* Establece el ancho fijo que desees */
  /* min-width: 80px; */
}
</style>
