<template>
  <div class="d-flex flex-column">

    <!-- Test Card Admin -->
    <v-card class="d-flex flex-column w-100 rounded-0 align-center" v-if="isFormulaVisible">
      <div class="d-flex flex-column w-100 px-2">
        <v-slider
          v-model="currentValue"
          :min="minValue"
          :max="maxValue * 2"
          thumb-label="always"
          label="Current"
        ></v-slider>
        <v-slider
          v-model="currentForecast"
          :min="minValue"
          :max="maxValue * 2"
          thumb-label="always"
          label="Forecast"
        ></v-slider>
        <!-- <v-range-slider
          v-model="forecastRange"
          :min="minValue"
          :max="maxValue"
          step="5"
          thumb-label="always"
          label="Forecast"
        ></v-range-slider> -->

        <v-slider
          v-model="formulaLastRecount"
          :min="0"
          :max="15"
          thumb-label="always"
          label="Últ.Recuento(d)"
        ></v-slider>
        <v-slider
          v-model="formulaLastAnalisis.days"
          :min="0"
          :max="15"
          thumb-label="always"
          label="Últ.Analítica(d.)"
        ></v-slider>
        <v-slider
          v-model="formulaLastAnalisis.value"
          :min="0"
          :max="300"
          thumb-label="always"
          label="Últ.Analítica(Val)"
        ></v-slider>
        <v-slider
          v-model="formulaParidad"
          :min="0"
          :max="100"
          thumb-label="always"
          label="Paridad(%)"
        ></v-slider>
        <v-slider
          v-model="formulaParidad7d"
          :min="0"
          :max="100"
          thumb-label="always"
          label="Paridad7d(%)"
        ></v-slider>

        <div class="w-100" style="display: grid; grid-template-columns: 33.3% 33.4% 33.3%; font-size: 14px;">
          <span class="d-flex flex-row justify-start"></span>
          <span class="d-flex flex-row justify-center">Tiempo Real</span>
          <span class="d-flex flex-row justify-end">Prev.72h</span>
        </div>

        <!-- Margen de Error correlativo -->
        <div class="w-100" style="display: grid; grid-template-columns: 33.3% 33.4% 33.3%; font-size: 14px;">
          <div class="d-flex flex-row justify-start">
            <span>MargenE.TASA 2%</span>
          </div>
          <div class="d-flex flex-row justify-center">
            <!-- <span>{{ formulaFiabilidadRealTimeMErrorTasa }}</span> -->
            <span>{{ formulaRealTime.fiaValueME }}</span>
          </div>
          <div class="d-flex flex-row justify-end">
            <!-- <span>{{ formulaFiabilidadPrev72hMErrorTasa }}</span> -->
            <span>{{ formulaPrev72h.fiaValueME }}</span>
          </div>
        </div>

        <!-- Margen de Error Absoluto -->
        <div class="w-100" style="display: grid; grid-template-columns: 33.3% 33.4% 33.3%; font-size: 14px;">
          <div class="d-flex flex-row justify-start">
            <span>MargenE. ABS</span>
          </div>
          <div class="d-flex flex-row justify-center">
            <span>{{ formulaRealTime.toxValueME * 2 }}</span>
          </div>
          <div class="d-flex flex-row justify-end">
            <span>{{ formulaPrev72h.toxValueME * 2 }}</span>
          </div>
        </div>

      </div>
    </v-card>

    <!-- Main card -->
    <v-card class="d-flex flex-column w-100 rounded-0 py-2 px-3" style="">

      <div class="d-flex flex-row align-center justify-center w-100">
        <span :style="{ position: 'absolute', left: '12px', fontSize: '12px' }">Incert.: {{ incertidumbreDays }}d.</span>
        <div class="d-flex align-baseline">
          <h3>Fiabilidad</h3>
        </div>

        <!-- <v-spacer></v-spacer> -->
        <v-switch
          v-if="isUserAdmin"
          :style="{ position: 'absolute', right: '2px' }"
          v-model="isFormulaVisible"
          label=""
          x-small
        ></v-switch>
      </div>

      <div class="w-100 mb-1" style="display: grid; grid-template-columns: 33.3% 33.4% 33.3%; font-size: 14px;">
        <span class="d-flex flex-row justify-start font-weight-bold">Tiempo Real</span>
        <span class="d-flex flex-row justify-center text-decoration-underline">Histórica</span>
        <span class="d-flex flex-row justify-end font-weight-bold">Prev.72h</span>
      </div>


      <div class="w-100" style="display: grid; grid-template-columns: 33.3% 33.4% 33.3%; font-size: 14px;">

        <!-- RealTime:  [Fiabilidad: % y +/-ME] [L2: Valor [Marge Inf - Sup] Icono] -->
        <div class="d-flex flex-column">

          <div class="d-flex flex-row justify-start">
            <ChartProgress :value="formulaRealTime.fiaValue" :color="formulaRealTime.fiaColor"/>
            <!-- <div class="d-flex flex-row align-center ml-1">
              <v-icon x-small>mdi-plus-minus-variant</v-icon>
              <span>{{formulaRealTime.toxValueME | toFixed(0) }}</span>
            </div> -->
          </div>

          <!-- <div class="d-flex flex-row justify-start ml-2">
            <div class="d-flex flex-row align-center">
              <span class="font-weight-bold" :style="{ 'font-size': '1rem', color: formulaRealTime.toxColor }">
                {{ formulaRealTime.toxValue }}
              </span>
              <v-icon x-small>mdi-plus-minus-variant</v-icon>
              <span>{{formulaRealTime.toxValueME | toFixed(0) }}</span>
            </div>
          </div> -->

          <div class="d-flex flex-column justify-start align-center">
            <ChartBoxPlotForecast :formula="formulaRealTime"></ChartBoxPlotForecast>
            <div style="position: absolute; margin-top: 54px;">
              <v-icon x-small>mdi-plus-minus-variant</v-icon>
              <span>{{formulaRealTime.toxValueME | toFixed(0) }}</span>
            </div>
          </div>

          <!-- <div class="d-flex flex-row justify-start ml-4">
            <span class="font-weight-bold" :style="{ 'font-size': '2.5rem', color: formulaRealTime.toxColor }">
              <i :class="formulaRealTime.toxIcon"></i>
            </span>
          </div> -->

          <div class="d-flex flex-row justify-start ml-3-">
            <div class="d-flex flex-column align-center">
              <span class="font-weight-bold" :style="{ 'font-size': '2rem', color: formulaRealTime.toxColor }">
                <i :class="formulaRealTime.toxIcon"></i>
              </span>
              <span style="margin-top: -10px;">{{ formulaRealTime.toxMotion }}</span>
            </div>
          </div>

        </div>

        <!-- History -->
        <div class="d-flex flex-column">
          <div class="d-flex flex-row justify-center">
            <ChartProgress :value="formulaHistory.fiaValue" :color="formulaHistory.fiaColor"/> <span class="ml-1">{{ formulaHistory.fiaPeriodKey }}</span>
          </div>
          <div class="d-flex flex-row justify-center">
            <ChartProgress :value="formulaHistory7d.fiaValue" :color="formulaHistory7d.fiaColor"/> <span class="ml-1">{{ formulaHistory7d.fiaPeriodKey }}</span>
          </div>
        </div>

        <!-- Prev72h: [Fiabilidad: % y +/-ME] [L2: Valor [Marge Inf - Sup] Icono]-->
        <div class="d-flex flex-column">

          <div class="d-flex flex-row justify-end">
            <ChartProgress :value="formulaPrev72h.fiaValue" :color="formulaPrev72h.fiaColor"/>
            <!-- <div class="d-flex flex-row align-center ml-1">
              <v-icon x-small>mdi-plus-minus-variant</v-icon>
              <span>{{formulaPrev72h.toxValueME | toFixed(0) }}</span>
            </div> -->
          </div>

          <!-- <div class="d-flex flex-row justify-end mr-2">
            <div class="d-flex flex-row align-center">
              <span class="font-weight-bold" :style="{ 'font-size': '1rem', color: formulaPrev72h.toxColor }">
                {{ formulaPrev72h.toxValue }}
              </span>
              <v-icon x-small>mdi-plus-minus-variant</v-icon>
              <span>{{formulaPrev72h.toxValueME | toFixed(0) }}</span>
            </div>
          </div> -->

          <div class="d-flex flex-column justify-end align-center">
            <ChartBoxPlotForecast :formula="formulaPrev72h"></ChartBoxPlotForecast>
            <div style="position: absolute; margin-top: 54px;">
              <v-icon x-small>mdi-plus-minus-variant</v-icon>
              <span>{{formulaPrev72h.toxValueME | toFixed(0) }}</span>
            </div>
          </div>

          <div class="d-flex flex-row justify-end mr-3-">
            <div class="d-flex flex-column align-center">
              <span class="font-weight-bold" :style="{ 'font-size': '2rem', color: formulaPrev72h.toxColor }">
                <i :class="formulaPrev72h.toxIcon"></i>
              </span>
              <span style="margin-top: -10px;">{{ formulaPrev72h.toxMotion }}</span>
            </div>
          </div>

        </div>
      </div>

      <div class="w-100"
           style="position: absolute; bottom: 0; left: 0;"
      >
        <ChartBarGaugeForecast
          v-if="isAvailable"
          :start-angle="-90"
          :end-angle="90"
          :value="formulaRealTime.toxValue"
          :value-m-e="formulaRealTime.toxValueME"
          :value-forecast="formulaPrev72h.toxValue"
          :value-forecast-m-e="formulaPrev72h.toxValueME"
          :formulaLastAnalisis="formulaLastAnalisis"
          :toxAAnalitica="formulaToxAAnalitica"
          :min="minValue"
          :max="maxValue"
          :limit="limitValue"
          :separator-step="maxValue / 8"
          :separator-thickness="1"
          :scale-interval="0"
        />
      </div>

      <!-- style=" position: absolute; bottom: 6px; font-size: 14px;" -->
      <div class="d-flex flex-column align-center w-100"
           style="margin-top: 56px; font-size: 14px;"
      >
        <!-- 98 mg/AO -->
        <div class="d-flex flex-row align-baseline" style="margin-bottom: -6px;">
          <span class="font-weight-bold" :style="{ 'font-size': '2rem', color: getBucketToxA(forecast).color }">
            <!-- {{ getBucketToxA(forecast).value }} -->
            {{ currentValue }}
          </span>
          <span class="text-muted ml-1">{{ getHeader(forecast).feedType.feedTypeUnit }}</span>
        </div>

        <!-- XX con analíticas -->
        <div class="d-flex flex-row align-baseline">
          <span class="text-muted">{{ getBucketToxA(forecast).text }}</span>
        </div>

        <!-- Ultimos valores: Análisis, Plan Actuación y estado: [20]8d [B1]7d [A]7d -->
        <div class="d-flex">
          <div v-if="getBucketAnalisis(forecast)" class="mr-1">
            <span :style="{ padding: '1px', 'background-color': getBucketAnalisis(forecast).color }">
              {{ getBucketAnalisis(forecast).value }}
            </span>
            <span class="text-muted">{{ getBucketAnalisis(forecast).text }}</span>
          </div>
          <div v-if="getBucketPlan(forecast)" class="mr-1">
            <span :style="{ padding: '1px', 'background-color': getBucketPlan(forecast).color }">
              {{ getBucketPlan(forecast).value }}
            </span>
            <span class="text-muted">{{ getBucketPlan(forecast).text }}</span>
          </div>
          <div v-if="getBucketEstado(forecast)">
            <span :style="{ padding: '1px', 'background-color': getBucketEstado(forecast).color }">
              {{ getBucketEstado(forecast).value }}
            </span>
            <span class="text-muted">{{ getBucketEstado(forecast).text }}</span>
          </div>
        </div>

      </div>
    </v-card>
  </div>
</template>

<script>
  import { computed, ref, watch } from 'vue';
  import { parseISO } from 'date-fns'

  // Components
  import { VueSvgGauge } from 'vue-svg-gauge'
  import ChartBarGaugeForecast from './ChartBarGaugeForecast.vue'
  import ChartProgress from './ChartProgress.vue'
  import ChartBoxPlotForecast from './ChartBoxPlotForecast.vue'

  // Services
  import useToxinType from '@/service/useToxinType'
  import useDecorateFiabilidad from '@/service/useDecorateFiabilidad'
  import useDecorateToxA from '@/service/useDecorateToxA'
  import useAppConfig from '@/store/useAppConfig'

  export default {
    name: 'GaugeChart',
    components: {
      // eslint-disable-next-line vue/no-unused-components
      VueSvgGauge,
      // eslint-disable-next-line vue/no-unused-components
      ChartBarGaugeForecast,
      ChartProgress,
      ChartBoxPlotForecast,
    },
    props: {
      forecast: {
        type: Object,
        default: () => {}
      }
    },
    filters: {
      toFixed: function (valor, decimales) {
        return valor.toFixed(decimales);
      }
    },
    setup(props) {

      const forecastLocal = ref(props.forecast)
      const isAvailable = ref(props.forecast || false)
      watch(() => props.forecast, () => {
        forecastLocal.value = props.forecast
        isAvailable.value = props.forecast || false

        // Set formula
        formulaParidad.value = parseInt(getBucketToxAAfinidad(forecastLocal.value).value, 10)
        formulaParidad7d.value = parseInt(getBucketToxAAfinidad7d(forecastLocal.value).value, 10)

        currentValue.value = parseInt(getBucketToxA(forecastLocal.value).value, 10)
        currentForecast.value = parseInt(getBucketToxAForecast(forecastLocal.value).value, 10)
        // forecastRange.value = [ currentValue.value, currentForecast.value]

        // Last
        formulaLastRecount.value = getFormulaLastRecount(getHeader(forecastLocal.value)?.pm?.riaId) // Por si cambia de ría
        formulaLastAnalisis.value = getFormulaLastAnalisis(getBucketAnalisis(forecastLocal.value))
        formulaToxAAnalitica.value = convertString2Number(getBucketToxA(forecastLocal.value).text)
      })

      const {
        toxinMin: minValue,
        toxinLimit: limitValue,
        toxinMax: maxValue,
      } = useToxinType()

      const getHeader = (forecast) => forecast?.forecastItemHeader?.forecastHeader;

      const getBucketToxA = (forecast) => forecast?.forecastItemHeader?.forecastHeader?.forecastBuckets[0];         // ToxA-Current
      const getBucketToxAForecast = (forecast) => forecast?.forecastItemHeader?.forecastHeader?.forecastBuckets[1]; // ToxA-Forecast
      const getBucketToxAAD = (forecast) => forecast?.forecastItemHeader?.forecastHeader?.forecastBuckets[2];       // ToxA-Current
      const getBucketH = (forecast) => forecast?.forecastItemHeader?.forecastHeader?.forecastBuckets[3];

      const getBucketToxAColor = (forecast) => getBucketToxA(forecast)?.color || 'blue'
      const getBucketHColor = (forecast) => getBucketH(forecast)?.color || 'red'

      const getBucketEstado = (forecast) => forecast?.forecastItemHeader?.forecastHeader?.forecastBuckets[4];
      const getBucketPlan = (forecast) => forecast?.forecastItemHeader?.forecastHeader?.forecastBuckets[5];
      const getBucketAnalisis = (forecast) => forecast?.forecastItemHeader?.forecastHeader?.forecastBuckets[6];
      const getBucketToxAAfinidad = (forecast) => forecast?.forecastItemHeader?.forecastHeader?.forecastBuckets[7];   // Bucket Afinidad total
      const getBucketToxAAfinidad7d = (forecast) => forecast?.forecastItemHeader?.forecastHeader?.forecastBuckets[8]; // Bucket Afinidad 7d


      // Saca el número de dentro del texto: P45D -> 45
      const convertString2Number = (valueString) => {
        const value = parseInt(valueString.match(/\d+/)[0])
        return value
      }

      // Diferencia de días entre 2 fechas
      const getDateDiffDays = (fechaInicial, fechaFinal) => {

        // Modificar la hora
        fechaInicial.setHours(12)
        fechaInicial.setMinutes(0)
        fechaInicial.setSeconds(0)
        fechaFinal.setHours(12)
        fechaFinal.setMinutes(0)
        fechaFinal.setSeconds(0)

        // Calcular el número de días entre las dos fechas
        const diferencia = Math.abs(fechaFinal.getTime() - fechaInicial.getTime())
        const dias = Math.floor(diferencia / (1000 * 60 * 60 * 24)) // Dias de diferencia en negativo (Pasado[-] Futuro[+])

        return dias
      }

      // Analizamos la fecha de creación del documento FA
      //  CR - La antiguedad es el inicio de la simulación
      //  SR - Inicio de la simulación + una semana (7 días)
      const getFormulaLastRecount = (riaId) => {
        // Vamos añadiendo a la lista y ordenamos
        const infoTableRia = infoTable.value.find( (item) => item.riaId === riaId)
        if (infoTableRia !== undefined) {

          const publicationRecountIP = infoTableRia?.info?.publicationRecountIP
          if (publicationRecountIP) {

            // eslint-disable-next-line no-unused-vars
            // const fechaIni1 = parse(publicationRecountSim.createdAt, 'yyyy-MM-dd\'T\'HH:mm:ssXXX', new Date())
            const fechaIni = parseISO(publicationRecountIP.publicationDate)
            const fechaFin = parseISO(dateRef.value)
            const daysDiff = getDateDiffDays(fechaIni, fechaFin)
            // if (publicationRecountSim.withRecounts) {
            //   return daysDiff
            // } else {
            //   return 7 + daysDiff
            // }
            return daysDiff

          }
        }
        return 7
      }
      const getFormulaLastAnalisis = (bucket) => {
        return {
          days: convertString2Number(bucket.text),      // '7d'  -> 7     Solo se queda con el número
          value: convertString2Number(bucket.value),    // '140' -> 140
          color: bucket.color
        }
      }

      // Decorate
      const { getDecorateFiabilidadColor } = useDecorateFiabilidad()
      const { getDecorateToxAColor, getDecorateToxAIcon, getDecorateToxAMotion } = useDecorateToxA()

      // VUEX user
      const { user } = useAppConfig()
      const isUserAdmin = ref(false)
      const isFormulaVisible = ref(false)
      if (user.value?.email.includes('sferrol@gmail.com')) {
        isUserAdmin.value = true
      }

      // VUEX InfoTable: Información de la tabla de intoxicación por ría
      const { infoTable, dateRef, toxinType } = useAppConfig()

      // Formula
      const currentValue = ref(0)
      const currentForecast = ref(0)
      // Otros
      const formulaLastRecount = ref(0)                                   // Dias desde el último recuento
      const formulaLastAnalisis = ref({ days: 0, value: 0, color: '' })   // Última analítica: Días y Valor
      const formulaToxAAnalitica = ref(0)                                 // Tox
      const formulaParidad = ref(0)
      const formulaParidad7d = ref(0)

      if (forecastLocal.value?.forecastItemHeader) {
        formulaParidad.value = parseInt(getBucketToxAAfinidad(forecastLocal.value).value, 10)
        formulaParidad7d.value = parseInt(getBucketToxAAfinidad7d(forecastLocal.value).value, 10)

        currentValue.value = parseInt(getBucketToxA(forecastLocal.value).value, 10)
        currentForecast.value = parseInt(getBucketToxAForecast(forecastLocal.value).value, 10)

        // Last
        formulaLastRecount.value = getFormulaLastRecount(getHeader(forecastLocal.value)?.pm?.riaId) // Por si cambia de ría
        formulaLastAnalisis.value = getFormulaLastAnalisis(getBucketAnalisis(forecastLocal.value))
        formulaToxAAnalitica.value = convertString2Number(getBucketToxA(forecastLocal.value).text)
      }


      // Fiabilidad
      // Pasada entre la serie
      const TASA = 2
      const formulaCalculateMargenErrorPorc = (rDays, daysOffset) => {

        const recountDays = (rDays + daysOffset)        // Días desde el último recuento
        const margenError = TASA * (-3 + recountDays)   // Margen de error

        return margenError
      }

      // eslint-disable-next-line no-unused-vars
      const formulaCalculateFiabilidad = (fPast, rDays, daysOffset) => {
        return fPast - formulaCalculateMargenErrorPorc(rDays, daysOffset)
      }

      // 70% + ME(2%) = 72% => 100-72 = 28%
      //  Valor es 100: el 28% de 100 es 28 => +-14
      const formulaCalculateMEAbsoluto = (toxValue, porcFiabilidad) => {

        const meAbs = 100 - porcFiabilidad  // Porcentaje del margen de Error (Inverso Fiabilidad)
        return toxValue * meAbs / 100
      }

      // ---------- //
      const { settings } = useAppConfig()

      const formulaHistory = computed( () => {
        const periodDays = convertString2Number(settings.value?.periodKey)

        return {
          fiaPeriodKey: `${periodDays}d.`,
          fiaValue: formulaParidad.value,                               // Fiabilidad del histórico
          fiaValueME: null,
          fiaColor: getDecorateFiabilidadColor(formulaParidad.value),   // Su color
          toxValue: null,
          toxValueME: null,
          toxValueHigh: null,
          toxValueLow: null,
          toxColor: null,
          toxIcon: null,
          toxMotion: null,
        }
      })
      const formulaHistory7d = computed( () => {
        return {
          fiaPeriodKey: `0${convertString2Number('P7D')}d.`,
          fiaValue: formulaParidad7d.value,                               // Fiabilidad del histórico
          fiaValueME: null,
          fiaColor: getDecorateFiabilidadColor(formulaParidad7d.value),   // Su color
          toxValue: null,
          toxValueME: null,
          toxValueHigh: null,
          toxValueLow: null,
          toxColor: null,
          toxIcon: null,
          toxMotion: null,
        }
      })


      // Calcular los días de incertidumbre
      const incertidumbreDays = computed( () => {
        // Si hay analítica nos comparamos con ella
        if (formulaLastAnalisis.value) {

          // Los recuentos son más recientes ---> ok
          if (formulaLastRecount.value <= formulaLastAnalisis.value?.days) {
            return formulaLastRecount.value
          }

          // Si la analítica tiene mucha afinidad (Para eso anallizamos Histórico de 7D)
          if (formulaHistory7d.value && formulaHistory7d.value.fiaValue >= 70) {
            return formulaLastAnalisis.value?.days
          }
        }

        return formulaLastRecount.value
      })

      const formulaRealTime = computed( () => {

        const fiaValueBest = formulaHistory.value.fiaValue < formulaHistory7d.value.fiaValue ? formulaHistory.value.fiaValue : formulaHistory7d.value.fiaValue

        // Calculamos la fiabilidad: A partir de la fiabilidad histórica + Últimos recuentos
        // const fiaValue = formulaCalculateFiabilidad(formulaHistory.value.fiaValue, formulaLastRecount.value, 0)  // 0 => 0h
        const fiaValueME = formulaCalculateMargenErrorPorc(incertidumbreDays.value, 0)                             // 0 => 0h
        const fiaValue = fiaValueBest - fiaValueME

        // ToxA: Valor y Margen de error
        const toxValue = currentValue.value
        const toxValueME = formulaCalculateMEAbsoluto(toxValue, fiaValue) // TODO balancear el margen de error según histórico 7D/15D

        return {
          fiaValue: fiaValue,                               // Fiabilidad
          fiaValueME: fiaValueME,                           // Margen de Error (%)
          fiaColor: getDecorateFiabilidadColor(fiaValue),   // Su color
          toxValue: toxValue,
          toxValueME: toxValueME,
          toxValueHigh: toxValue + (toxValueME / 2),
          toxValueLow: toxValue - (toxValueME / 2),
          toxColor: getDecorateToxAColor(toxValue, toxinType.value),
          toxIcon: getDecorateToxAIcon(toxValue, toxinType.value),
          toxMotion: getDecorateToxAMotion(toxValue, toxinType.value),
        }
      })

      const formulaPrev72h = computed( () => {
        const fiaValueBest = formulaHistory.value.fiaValue < formulaHistory7d.value.fiaValue ? formulaHistory.value.fiaValue : formulaHistory7d.value.fiaValue

        // Calculamos la fiabilidad: A partir de la fiabilidad histórica + Últimos recuentos
        // const fiaValue = formulaCalculateFiabilidad(formulaHistory.value.fiaValue, formulaLastRecount.value, 3)  // 3 => 72h
        const fiaValueME = formulaCalculateMargenErrorPorc(incertidumbreDays.value, 3)                             // 3 => 72h
        const fiaValue = fiaValueBest - fiaValueME

        // ToxA: Valor y Margen de error
        const toxValue = currentForecast.value
        const toxValueME = formulaCalculateMEAbsoluto(toxValue, fiaValue) // TODO balancear el margen de error según histórico 7D/15D
        return {
          fiaValue: fiaValue,                               // Fiabilidad (%)
          fiaValueME: fiaValueME,                           // Margen de Error (%)
          fiaColor: getDecorateFiabilidadColor(fiaValue),   // Su color
          toxValue: toxValue,
          toxValueME: toxValueME,
          toxValueHigh: toxValue + (toxValueME / 2),
          toxValueLow: toxValue - (toxValueME / 2),
          toxColor: getDecorateToxAColor(toxValue, toxinType.value),
          toxIcon: getDecorateToxAIcon(toxValue, toxinType.value),
          toxMotion: getDecorateToxAMotion(toxValue, toxinType.value),
        }
      })

      // const formulaFiabilidadRealTimeMErrorTasa = computed( () => {
      //   return formulaCalculateMargenErrorPorc(formulaLastRecount.value, 0)
      // })
      // const formulaFiabilidadPrev72hMErrorTasa = computed( () => {
      //   return formulaCalculateMargenErrorPorc(formulaLastRecount.value, 3)
      // })


      return {
        isAvailable,

        currentValue,
        currentForecast,
        minValue,
        maxValue,
        limitValue,

        // todo
        incertidumbreDays,

        // Test formula
        isUserAdmin,
        isFormulaVisible,
        formulaLastRecount,
        formulaLastAnalisis,
        formulaToxAAnalitica,
        formulaParidad,
        formulaParidad7d,

        // Calculos
        formulaHistory,     // Histórico de todo el periodo de análisis
        formulaHistory7d,   // Histórico de la última semana
        formulaRealTime,
        formulaPrev72h,

        // formulaFiabilidadRealTimeMErrorTasa,
        // formulaFiabilidadPrev72hMErrorTasa,

        getHeader,
        getBucketToxA,
        getBucketToxAForecast,
        getBucketToxAAD,
        getBucketH,
        getBucketToxAColor,
        getBucketHColor,
        getBucketEstado,
        getBucketPlan,
        getBucketAnalisis,
        getBucketToxAAfinidad,
      }
    }
  }
</script>
<style scoped>
  .circulo {
    width: 20px;
    height: 20px;
    background-color: #3498db; /* Color de fondo del círculo */
    border-radius: 50%; /* Establece el radio de las esquinas al 50%, lo que crea un círculo */
    text-align: center;
    line-height: 100px; /* Centra el contenido verticalmente */
    color: #fff; /* Color del texto dentro del círculo */
  }
</style>
