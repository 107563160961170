
<template>
  <!-- :fullscreen="$vuetify.breakpoint.xs" -->
  <v-dialog
    ref="dialog"
    v-model="isDialogActiveLocal"
    :return-value.sync="dateRef"
    width="290px"
    @click:outside="$emit('update:is-dialog-active',false); isDialogActiveLocal = false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        overlap
        color="green"
        :content="dateRefDiffFormatted"
        :value="dateRefDiffFormatted"
      >
        <v-btn
          v-on="on"
          v-bind="attrs"
          class="my-1"
          x-small
          fab
          color="primary"
          :loading="loading"
        >
          <span style="text-transform: capitalize;">{{ dateRefFormatted }}</span>
        </v-btn>
        <!-- <v-btn
          v-on="on"
          v-bind="attrs"
          style="margin-left: -0.5rem;"
          height="32px"
          :small="$vuetify.breakpoint.xs"
          :icon="true"
        >
          <v-icon>mdi-cog</v-icon>
        </v-btn> -->
      </v-badge>
    </template>
    <v-date-picker
      v-model="dateRef"
      scrollable
      locale="ES-es"
      first-day-of-week="1"
      locale-first-day-of-year="1"
      show-week
      @input="$refs.dialog.save(dateRef)"
    >
    </v-date-picker>
  </v-dialog>
</template>

<script>
  import { computed, ref, watch } from 'vue';

  // import { format, parseISO, addDays, subDays } from 'date-fns'
  import { format, parseISO } from 'date-fns'
  import { es } from 'date-fns/locale';

  // Servicios
  import useAppConfig from '@/store/useAppConfig';


  export default {
    name: 'menu-layer-params-dialog-component',
    components: {
    },
    props: {
      isDialogActive: {
        type: Boolean,
        required: false,
        default: false
      },
      loading: {
        type: Boolean,
        required: false,
        default: false
      },
    },
    setup(props) {
      const isDialogActiveLocal = ref(props.isDialogActive)
      watch(( () => props.isDialogActive), () => {
        isDialogActiveLocal.value = props.isDialogActive
      })

      // AppConfig: Fecha de referencia
      const { dateRef } = useAppConfig()

      const dateRefDiffFormatted = computed( () => {
        // Obtener la fecha inicial y final en milisegundos
        const fechaInicial = parseISO(dateRef.value).getTime();                                           // Fecha de referencia
        const fechaFinal = parseISO(format(parseISO(new Date().toISOString()), 'yyyy-MM-dd')).getTime();  // Today

        // Calcular el número de días entre las dos fechas
        const diferencia = fechaFinal - fechaInicial
        const dias = Math.round(diferencia / (1000 * 60 * 60 * 24)) * -1 // Dias de diferencia en negativo (Pasado[-] Futuro[+])

        return dias !== 0 ? (dias.toString() + 'd') : 0
      })
      const dateRefFormatted = computed( () => {
        return  dateRef.value ? format(parseISO(dateRef.value), "EEEEEEd", { locale: es }) : '' // ['EEE d' => MAR 14] [ 'EEEEEEd' => Ma14]
      })

      // TODO - No se usan
      // const setDatePrev = () => {
      //   if (dateRef.value) {
      //     dateRef.value = format(subDays(parseISO(dateRef.value), 1), 'yyyy-MM-dd');
      //   }
      // }
      // const setDateNext = () => {
      //   if (dateRef.value) {
      //     dateRef.value = format(addDays(parseISO(dateRef.value), 1), 'yyyy-MM-dd');
      //   }
      // }

      return {
        isDialogActiveLocal,

        // DateRef
        dateRef,
        dateRefFormatted,       // Computed DateRef with format
        dateRefDiffFormatted,   // Computed DateRef
      }
    }
  }
</script>
