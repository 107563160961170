<template>

  <!-- <v-dialog
    v-model="isDialogActiveLocal"
    width="500"
    :fullscreen="$vuetify.breakpoint.xs"
    scrollable
    @click:outside="$emit('update:is-dialog-active',false); isDialogActiveLocal = false"
  > -->
  <v-bottom-sheet
    v-model="isDialogActiveLocal"
    max-width="500px"
    @click:outside="$emit('update:is-dialog-active',false); isDialogActiveLocal = false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-on="on"
        v-bind="attrs"
        class="ml-2"
        height="32px"
        :small="$vuetify.breakpoint.xs"
        :icon="$vuetify.breakpoint.xs"
      >
        <v-icon>mdi-tune-vertical</v-icon>
        <span class="d-none d-md-block ml-2" style="font-size: .625rem;">Parám.<br>tox/dTox</span>
      </v-btn>
    </template>

    <v-card class="d-flex flex-column w-100 rounded-0 mt-4">
      <v-toolbar :elevation="8" title="Conversor" color="rgb(0, 192, 255)">
        <v-toolbar-title>{{ filtersLocal?.ria.name }}</v-toolbar-title>

        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('update:is-dialog-active',false); isDialogActiveLocal = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <!-- Body -->
      <div class="d-flex flex-column px-3">

        <div class="d-flex align-center my-3">
          <!-- <v-icon>mdi-information</v-icon> -->
          <span class="ml-1">Parámetros de conversión de células a toxina simulada.</span>
        </div>

        <v-divider></v-divider>

        <!-- Tox/dTox -->
        <div class="d-flex flex-column my-3 pt-3">

          <!-- hint="Factor de intoxicación" -->
          <!-- persistent-hint -->
          <!-- readonly -->
          <!-- thumb-color="grey" -->
          <v-slider
            v-model="intox"
            :color="sliderColorTox(intox)"
            track-color="grey"
            class="align-center custom-slider"
            label="Intox."
            always-dirty

            ticks="always"
            tick-size="1"
            min="0"
            max="10"
            :thumb-size="24"
            thumb-label="always"

            readonly
          >
            <template v-slot:thumb-label="{ value }">
              x{{ (value * (2 / 10)).toFixed(1) }}
              <!-- {{ satisfactionEmojis[Math.min(Math.floor(value / 10), 9)] }} -->
            </template>

            <!-- <template v-slot:append>
              <v-text-field
                v-model="intox"
                class="mt-0 pt-0"
                type="number"
                style="width: 60px"
              ></v-text-field>
            </template> -->
          </v-slider>

          <v-slider
            v-model="detox"
            :color="sliderColorTox(detox)"
            track-color="grey"
            class="align-center custom-slider"
            label="Desintox."
            always-dirty

            ticks="always"
            tick-size="1"
            min="0"
            max="10"
            :thumb-size="24"
            thumb-label="always"

            readonly
          >
            <template v-slot:thumb-label="{ value }">
              x{{ (value * (2 / 10)).toFixed(1) }}
            </template>
          </v-slider>

          <div class="d-flex align-center" :style="{ 'font-size': '0.8rem' }">
            <v-icon size="18">mdi-information</v-icon>
            <p class="ml-2 ma-0">Factores de intoxicación y desintoxicación. <br> Estos factores dependen del estado del mejillón (Cambios en el metabolismo, desove, etc...)</p>
          </div>
        </div>
        <v-divider></v-divider>

        <!-- Toxina por Fitoplancton -->
        <ForecastCardToxinParamsTF :feedTypeAPs="filters?.feedTypeAPs"/>
      </div>
    </v-card>

  </v-bottom-sheet>
</template>

<script>
  import { ref, watch } from 'vue';

  import ForecastCardToxinParamsTF from './ForecastCardToxinParamsTF.vue';

  export default {
    name: 'forecast-chart-params-component',
    components: {
      ForecastCardToxinParamsTF
    },
    props: {
      isDialogActive: {
        type: Boolean,
        required: false,
        default: false
      },
      filters: {
        type: Object,
        default: () => {}
      }
    },
    setup(props) {
      const isDialogActiveLocal = ref(props.isDialogActive)
      const filtersLocal = ref(JSON.parse(JSON.stringify(props.filters)))

      // props passed to setup function is reactive object (made probably by reactive()), it's properties are getters.
      // Watching a getter: https://v3.vuejs.org/api/computed-watch-api.html#watching-a-single-source
      watch(() => props.filters, () => {
        filtersLocal.value = JSON.parse(JSON.stringify(props.filters));

        // Reconstruimos
        setRanges()
      })

      // const satisfactionEmojis = ref(['😭', '😢', '☹️', '🙁', '😐', '🙂', '😊', '😁', '😄', '😍']);
      // const intensityEmojis = ref(['😴', '😅', '💧','💦','😨', '😱', '😡', '🔥'])

      const setRanges = () => {
        intox.value = filtersLocal.value?.intoxRange
        detox.value = filtersLocal.value?.detoxRange
      }
      // Tox/dTox
      const intox = ref(5);
      const detox = ref(5);
      setRanges()

      // 0-3  Verde
      // 4-6  Azul
      // 7-8  Naranja
      // 9-10 Rojo
      const sliderColorTox = (value) => {
        if (value <= 3) return 'teal'
        if (value <= 6) return 'indigo'
        if (value <= 8) return 'orange'
        return 'red'
      }

      return {
        isDialogActiveLocal,
        filtersLocal,

        // Intox & Detox values and colors
        intox,
        detox,
        sliderColorTox,
      }
    }
  }
</script>

<style>
.custom-slider .v-label {
  width: 100px; /* Establece el ancho fijo que desees */
  /* min-width: 80px; */
}
</style>
